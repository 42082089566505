import Stack from "@mui/material/Stack";
import React from "react";
import { useTranslation } from "react-i18next";
import GradusSupportButton from "../../../Buttons/GradusSupportButton";
import GradusPrimaryButton from "../../../Buttons/GradusPrimaryButton";
import FilterResultsCounter from "./FilterResultsCounter";

export default function FilterTabFooter({
  setDefaultFilters,
  handleFilter,
  isCompare,
  saveButtonTitle,
}: {
  setDefaultFilters: () => void;
  handleFilter: () => void;
  isCompare: boolean;
  saveButtonTitle?: string;
}) {
  const { t } = useTranslation();

  return (
    <Stack
      mt={4}
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <FilterResultsCounter isCompare={isCompare} />
      <Stack direction="row" gap={2}>
        <GradusSupportButton onClick={setDefaultFilters}>
          {t("CLEAR")}
        </GradusSupportButton>
        <GradusPrimaryButton onClick={handleFilter}>
          {t(saveButtonTitle as string)}
        </GradusPrimaryButton>
      </Stack>
    </Stack>
  );
}

FilterTabFooter.defaultProps = {
  saveButtonTitle: "APPLY_AND_SAVE",
};
