import MarketFilter from "../MarketFilter";
import PayrollFilter from "../PayrollFilter";

export default class CompareGetMetricsWithRelaxationDTO {
  marketFilters: MarketFilter;

  companyFilters: PayrollFilter;

  shouldRelax: boolean;

  correctionIndex: number;

  analysisId: number;

  constructor(
    marketFilters: MarketFilter,
    companyFilters: PayrollFilter,
    shouldRelax: boolean,
    correctionIndex: number,
    analysisId: number
  ) {
    this.marketFilters = marketFilters;
    this.companyFilters = companyFilters;
    this.shouldRelax = shouldRelax;
    this.correctionIndex = correctionIndex;
    this.analysisId = analysisId;
  }
}
