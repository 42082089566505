/* eslint-disable react/prop-types */
import React from "react";

export default function withHideOnCondition({
  ComponentA,
  ComponentB,
  shouldShowComponentA,
}) {
  const WithHideOnCondition = ({
    forwardedRef,
    componentAProps,
    componentBProps,
    children,
  }) => {
    if (shouldShowComponentA) {
      return <ComponentA ref={forwardedRef} {...componentBProps} />;
    }
    return (
      <ComponentB ref={forwardedRef} {...componentAProps}>
        {children}
      </ComponentB>
    );
  };

  return React.forwardRef(
    ({ componentAProps, componentBProps, children, ...props }, ref) => (
      <WithHideOnCondition
        forwardedRef={ref}
        componentAProps={componentAProps}
        componentBProps={componentBProps}
        {...props}
      >
        {children}
      </WithHideOnCondition>
    )
  );
}
