import { create } from "zustand";
import { devtools } from "zustand/middleware";
import SystemSettingsDTO from "../dtos/SystemSettingsDTO";
import systemSettingsApi from "../apis/systemSettingsApi";

export class SystemConfiguration {
  yearRais: string;

  statisticallyInsignificantQuantityThreshold: number;

  constructor({
    yearRais,
    statisticallyInsignificantQuantityThreshold,
  }: {
    yearRais?: string;
    statisticallyInsignificantQuantityThreshold?: number;
  }) {
    this.yearRais = yearRais || "";
    this.statisticallyInsignificantQuantityThreshold =
      statisticallyInsignificantQuantityThreshold || 40;
  }
}

type SystemConfigurationsProps = {
  configurations: SystemConfiguration;

  getConfigurations: () => void;
};

const initialState = {
  configurations: new SystemConfiguration({}),
};

const useSystemConfigurationsStore = create<SystemConfigurationsProps>()(
  devtools(
    (set) => ({
      ...initialState,

      getConfigurations: async () => {
        const response: SystemSettingsDTO[] =
          await systemSettingsApi.getSystemSettings();

        const yearRais = response.find(
          (config) => config.name === "YEAR_RAIS"
        )?.value;
        const statisticallyInsignificantQuantityThreshold = Number(
          response.find(
            (config) =>
              config.name === "STATISTICALLY_INSIGNIFICANT_QUANTITY_THRESHOLD"
          )?.value
        );
        const configurations = new SystemConfiguration({
          yearRais,
          statisticallyInsignificantQuantityThreshold,
        });
        set({ configurations });
      },
    }),
    { name: "SystemConfigurations" }
  )
);

export default useSystemConfigurationsStore;
