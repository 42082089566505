import { create } from "zustand";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import PayrollFilter from "../../dtos/PayrollFilter";
import FilterOptionDTO from "../../dtos/FilterOptionDTO";
import CompareAnalysisPresenter from "../../dtos/CompareAnalysis/CompareAnalysisPresenter";
import useMetricsStore from "../useMetricsStore";
import createPayrollFilterSlice from "./createPayrollFilterSlice";
import createMarketFilterSlice from "./createMarketFilterSlice";
import {
  FilterResultPreviewType,
  FilterStoreProps,
  MarketFilterSlice,
  PayrollFilterSlice,
} from "./filterStoreUtils";
import useFilterOptionsStore from "../FilterOptionsStore/useFilterOptionsStore";
import MarketFilter from "../../dtos/MarketFilter";
import BaseFilter from "../../dtos/BaseFilter";
import useRelaxationSelectorStore from "../useRelaxationSelectorStore";

function unifyFilters(
  origin: PayrollFilter | MarketFilter | BaseFilter,
  destiny: PayrollFilter | MarketFilter
): PayrollFilter | MarketFilter {
  const baseOrigin = new BaseFilter(origin);

  if (destiny instanceof PayrollFilter) {
    return new PayrollFilter({ ...destiny, ...baseOrigin });
  }
  return new MarketFilter({ ...destiny, ...baseOrigin });
}

function getAllowedMarketFiltersForPayroll(
  modalSelectedMarketFilters: PayrollFilter | MarketFilter,
  companyOptions: PayrollFilter
): BaseFilter {
  const baseMarketFilters = new BaseFilter(modalSelectedMarketFilters);

  const newFilters = Object.entries(baseMarketFilters).reduce(
    (acc: { [key: string]: FilterOptionDTO[] }, entry) => {
      const filterField = entry[0];
      const selectedMarketOptions = _.cloneDeep(entry[1]);
      const validOptions = companyOptions.getOptionsByField(filterField);

      acc[filterField] = _.filter(
        selectedMarketOptions,
        (selectedMarketOption) =>
          _.some(
            validOptions,
            (validOption) => validOption.id === selectedMarketOption.id
          )
      );
      return acc;
    },
    {}
  );

  return new BaseFilter(newFilters);
}

const initialState = {
  filterResultPreview: {
    market: {
      quantity: 0,
      isLoading: true,
    },
    company: {
      quantity: 0,
      isLoading: true,
    },
  },
};

const useFilterStore = create<
  FilterStoreProps & PayrollFilterSlice & MarketFilterSlice
>()(
  devtools(
    (set, get, store) => ({
      ...initialState,
      ...createPayrollFilterSlice(set, get, store),
      ...createMarketFilterSlice(set, get, store),

      initFilters: async (isCompare, analysis) => {
        const { _initializeMarketFilters, _initializePayrollFilters } = get();
        const { companyMetrics, marketMetrics } = useMetricsStore.getState();
        const { initRelaxationSelector } =
          useRelaxationSelectorStore.getState();

        const filterResultPreview = {
          market: {
            quantity: marketMetrics?.quantity || 0,
            isLoading: false,
          },
          company: {
            quantity: companyMetrics?.quantity || 0,
            isLoading: false,
          },
        };
        if (isCompare) {
          const compareAnalysis = analysis as CompareAnalysisPresenter;
          await _initializePayrollFilters(compareAnalysis);
          initRelaxationSelector(compareAnalysis);
        }
        await _initializeMarketFilters(analysis.getMarketFilters());
        set({ filterResultPreview });
      },

      arePreviewsLoading: (
        filterResultPreview: FilterResultPreviewType,
        isCompare: boolean
      ) => {
        if (isCompare) {
          return (
            filterResultPreview?.company.isLoading ||
            filterResultPreview?.market.isLoading
          );
        }
        return filterResultPreview?.market.isLoading;
      },

      applyFilters: () => {
        const { modalSelectedPayrollFilters, modalSelectedMarketFilters } =
          get();

        let appliedFilters = {
          appliedPayrollFilters: modalSelectedPayrollFilters,
          appliedMarketFilters: modalSelectedMarketFilters,
        };

        set((state) => {
          appliedFilters = {
            ...appliedFilters,
            appliedPayrollFilters: new PayrollFilter({
              ...modalSelectedPayrollFilters,
            }),
          };

          return { ...state, ...appliedFilters };
        });

        return appliedFilters;
      },

      changeRelaxationState: (analysisId) => {
        const { updateModalSelectedMarketFilters, modalSelectedMarketFilters } =
          get();

        updateModalSelectedMarketFilters(
          { ...modalSelectedMarketFilters },
          true,
          analysisId
        );
      },

      copyPayrollFiltersToMarket: (analysisId) => {
        const {
          updateModalSelectedMarketFilters,
          modalSelectedMarketFilters,
          modalSelectedPayrollFilters,
        } = get();

        const unifiedFilters = unifyFilters(
          modalSelectedPayrollFilters,
          modalSelectedMarketFilters
        ) as MarketFilter;

        updateModalSelectedMarketFilters(
          { ...unifiedFilters },
          true,
          analysisId
        );
      },

      copyMarketFiltersToPayroll: (analysisId) => {
        const {
          modalSelectedMarketFilters,
          modalSelectedPayrollFilters,
          updateModalSelectedPayrollFilters,
        } = get();
        const { companyOptions } = useFilterOptionsStore.getState();

        const filteredMarketFilters = getAllowedMarketFiltersForPayroll(
          modalSelectedMarketFilters,
          companyOptions
        );

        const unifiedFilters = unifyFilters(
          filteredMarketFilters,
          modalSelectedPayrollFilters
        ) as PayrollFilter;

        updateModalSelectedPayrollFilters({ ...unifiedFilters }, analysisId);
      },
    }),
    { name: "FilterStore" }
  )
);

export default useFilterStore;
