import MarketFilter from "../MarketFilter";
import ConsulteAnalysisPresenter from "./ConsulteAnalysisPresenter";

export default class ConsulteAnalysisUpdateDTO {
  private readonly id: number;

  private readonly name: string;

  private readonly marketFilters: MarketFilter;

  constructor(id: number, name: string, marketFilters: MarketFilter) {
    this.id = id;
    this.name = name;
    this.marketFilters = marketFilters;
  }

  static fromPresenter(analysis: ConsulteAnalysisPresenter) {
    return new ConsulteAnalysisUpdateDTO(
      analysis.getId(),
      analysis.getName(),
      analysis.getMarketFilters().onlyWithIds()
    );
  }
}
