import React from "react";
import ActionButtonFactory, {
  ActionButtonFactoryProps,
} from "./ActionButtonFactory";

export default function Actions({
  actions,
}: {
  actions: ActionButtonFactoryProps[];
}) {
  return actions.map((action) => (
    <ActionButtonFactory key={action.type} {...action} />
  ));
}
