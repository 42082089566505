import Typography from "@mui/material/Typography";
import React from "react";
import { Grid, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Trans, useTranslation } from "react-i18next";
import MarketFilter from "../../../../dtos/MarketFilter";
import GradusAutocomplete from "../../../GradusComponents/GradusAutocomplete";
import InfoIcon from "../../../InfoIcon/InfoIcon";
import GradusTooltip from "../../../GradusComponents/GradusTooltip";
import FilterOptionDTO from "../../../../dtos/FilterOptionDTO";

function CompanyDataFilter({
  options,
  selectedFilters,
  setSelectedFilters,
}: {
  options: MarketFilter;
  selectedFilters: MarketFilter;
  setSelectedFilters: (values: Partial<MarketFilter>) => void;
}) {
  const { t } = useTranslation();

  return (
    <Container disableGutters>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h6">{t("COMPANY_SECTOR")}</Typography>
        <GradusTooltip
          title={
            <Trans
              i18nKey="FILTER_MODAL.CNAE_TOOLTIP"
              components={{ list: <ul />, item: <li />, bold: <strong /> }}
            />
          }
          placement="right"
        >
          <span>
            <InfoIcon icon={solid("question-circle")} />
          </span>
        </GradusTooltip>
      </Stack>
      <Grid container justifyContent="space-between">
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("SECTION")}
            options={options.sections}
            selectedOptions={selectedFilters?.sections || []}
            setSelectedOptions={(sections: FilterOptionDTO[]) => {
              setSelectedFilters({
                sections,
                divisions: [],
                groups: [],
                classes: [],
                subclasses: [],
              });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("DIVISION")}
            options={options.divisions}
            selectedOptions={selectedFilters?.divisions || []}
            setSelectedOptions={(divisions: FilterOptionDTO[]) => {
              setSelectedFilters({
                divisions,
                groups: [],
                classes: [],
                subclasses: [],
              });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("GROUP")}
            options={options.groups}
            selectedOptions={selectedFilters?.groups || []}
            setSelectedOptions={(groups: FilterOptionDTO[]) => {
              setSelectedFilters({
                groups,
                classes: [],
                subclasses: [],
              });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("CLASS")}
            options={options.classes}
            selectedOptions={selectedFilters?.classes || []}
            setSelectedOptions={(classes: FilterOptionDTO[]) => {
              setSelectedFilters({ classes, subclasses: [] });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("SUBCLASS")}
            options={options.subclasses}
            selectedOptions={selectedFilters?.subclasses || []}
            setSelectedOptions={(subclasses: FilterOptionDTO[]) => {
              setSelectedFilters({ subclasses });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default CompanyDataFilter;
