import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "@mui/material/Container";
import _ from "lodash";
import ExternalModalContainer from "./ExternalModalContainer";
import ExitButton from "./ExitButton";

export default function GradusModal({
  open,
  handleClose,
  handleOpen,
  children,
  containerStyle,
}) {
  useEffect(() => {
    (async () => {
      if (open) {
        handleOpen?.();
      }
    })();
  }, [open, handleOpen]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
      disableScrollLock
    >
      <ExternalModalContainer
        sx={{
          boxShadow: 24,
          position: "relative",
          ...containerStyle,
        }}
        disableGutters
      >
        <ExitButton
          onClick={handleClose}
          sx={{
            position: "absolute",
          }}
        >
          <FontAwesomeIcon icon={light("xmark")} />
        </ExitButton>
        <Container disableGutters>{children}</Container>
      </ExternalModalContainer>
    </Modal>
  );
}

GradusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  containerStyle: PropTypes.any,
};

GradusModal.defaultProps = {
  containerStyle: {},
  handleOpen: _.noop,
};
