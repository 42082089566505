import _ from "lodash";
import api from "./api";

const baseUrl = "/api/v1/filter-option";

// TODO: retornar diretamente MarketFilter ou PayrollFilter
const filterOptionApi = {
  getJobChainedFilters: (appliedJobFilters) =>
    api.post(`${baseUrl}/job`, appliedJobFilters),
  getGeographicChainedFilters: (appliedGeographicFilters) =>
    api.post(`${baseUrl}/geographic`, appliedGeographicFilters),
  getCompanyChainedFilters: (appliedCompanyFilters) =>
    api.post(`${baseUrl}/company`, appliedCompanyFilters),
  getCompanySizes: () => api.get(`${baseUrl}/company-size`),

  getJobChainedFiltersByAnalysis: (analysisId, appliedJobFilters) => {
    let body = appliedJobFilters;
    if (_.isEmpty(body)) {
      body = {
        occupations: [],
        families: [],
        primarySubgroups: [],
        subgroups: [],
        largeGroups: [],
      };
    }
    return api.post(`${baseUrl}/job/${analysisId}`, body);
  },
  getGeographicChainedFiltersByAnalysis: (
    analysisId,
    appliedGeographicFilters
  ) =>
    api.post(`${baseUrl}/geographic/${analysisId}`, appliedGeographicFilters),
  getCustomColumnChainedFiltersByAnalysis: (analysisId, filters) =>
    api.post(`${baseUrl}/payroll-parameter/${analysisId}`, filters),

  getMarketConsolidatedChainedFilters: (filters) =>
    api.post(`${baseUrl}/consolidated`, filters),
  getCompanyConsolidatedChainedFilters: (filters, compareAnalysisId) =>
    api.post(`${baseUrl}/consolidated/${compareAnalysisId}`, filters),
};

export default filterOptionApi;
