import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectMapping from "../../components/MappingsTableWithSearch/SelectMapping";
import ExcelColumn from "../../components/CreateMapping/ExcelColumn";

interface SelectMappingStepProps {
  canSelectMapping: boolean;
  computedCompanySize: number;
  columns: ExcelColumn[];
  applyMapping: (applyConfigurationId: number) => void;
}

const SelectMappingStep = ({
  canSelectMapping,
  computedCompanySize,
  columns,
  applyMapping,
}: SelectMappingStepProps) => {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  const HideSelectMapping = useMemo(() => {
    if (canSelectMapping) {
      return (
        <>
          <Typography variant="body1" style={{ textAlign: "justify" }}>
            {t("COMPARE_CREATE_ANALYSIS_PAGE.SELECT_MAPPING_TEXT")}
          </Typography>
          <SelectMapping
            computedCompanySize={computedCompanySize}
            columns={columns}
            applyMapping={applyMapping}
          />
        </>
      );
    }
    return t("FILL_IN_THE_PREVIOUS_FIELDS_BEFORE_CONTINUING", {
      lng: language,
    });
  }, [
    applyMapping,
    canSelectMapping,
    columns,
    computedCompanySize,
    t,
    language,
  ]);

  return (
    <Stack gap={1}>
      <Typography variant="h6" textAlign="left">
        {`3. ${t("MAPPING")}`}
      </Typography>
      {HideSelectMapping}
    </Stack>
  );
};

export default SelectMappingStep;
