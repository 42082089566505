import Stack from "@mui/material/Stack";
import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AlertContext from "../../../contexts/AlertContext";
import { handleApiError } from "../../../utils/ErrorUtils";
import SearchBar from "../../SearchBar/SearchBar";
import consulteAnalysisApi from "../../../apis/consulteAnalysisApi";
import ConsulteAnalysisTable from "./ConsulteAnalysisTable";
import withGradusLoading from "../../../hocs/withGradusLoading";
import GradusPrimaryButton from "../../Buttons/GradusPrimaryButton";
import useConsulteAnalysisStore from "../../../stores/useConsulteAnalysisStore";

export default function ConsulteAnalysisTableWithSearch({ open, handleClose }) {
  const [options, setOptions] = useState([]);
  const [searchedOptions, setSearchedOptions] = useState(options);
  const [isLoading, setIsLoading] = useState(false);

  const { fireAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { id: currentAnalysisId } = useParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const { getAll, deleteAnalysis, renameAnalysis } = useConsulteAnalysisStore();

  const updateOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAll();
      setOptions(response);
      setSearchedOptions(response);
    } catch (error) {
      handleApiError(error, fireAlert, t);
    } finally {
      setIsLoading(false);
    }
  }, [fireAlert, getAll, t]);

  const handleDeleteAnalysis = useCallback(
    async (deleteId) => {
      try {
        await deleteAnalysis(deleteId);
        fireAlert(t("ALERTS.SUCCESSFULLY_REMOVED_ANALYSIS"));
        if (
          !_.isEmpty(currentAnalysisId) &&
          Number(currentAnalysisId) === Number(deleteId)
        ) {
          navigate("/");
        }
      } catch (error) {
        handleApiError(error, fireAlert, t);
      } finally {
        await updateOptions();
      }
    },
    [currentAnalysisId, deleteAnalysis, fireAlert, navigate, t, updateOptions]
  );

  const handleRenameAnalysis = useCallback(
    async (newName, updateId) => {
      try {
        await renameAnalysis(updateId, newName);
        fireAlert(t("ALERTS.SUCCESSFULLY_RENAMED_ANALYSIS"));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      } finally {
        await updateOptions();
      }
    },
    [fireAlert, renameAnalysis, t, updateOptions]
  );

  const openAnalysis = useCallback(
    (id) => {
      navigate(`/consulte/${id}`);
      handleClose();
    },
    [handleClose, navigate]
  );

  const handleCreateAnalysis = useCallback(async () => {
    try {
      const response = await consulteAnalysisApi.createAnalysis();
      fireAlert(t("ALERTS.SUCCESSFULLY_CREATED_ANALYSIS"));
      navigate(`/consulte/${response.data}`);
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  }, [fireAlert, t, navigate]);

  useEffect(() => {
    updateOptions();
  }, [updateOptions, open]);

  const AnalysisTableComponentWithLoading = withGradusLoading(
    searchedOptions.length <= 0
      ? () => (
          <p style={{ textAlign: "center" }}>
            {`${t("LIST_ANALYSIS_MODAL.NO_ANALYSIS_FOUND")}.`}
          </p>
        )
      : () => (
          <ConsulteAnalysisTable
            options={searchedOptions}
            handleDeleteAnalysis={handleDeleteAnalysis}
            handleOpenAnalysis={openAnalysis}
            handleRenameAnalysis={handleRenameAnalysis}
          />
        )
  );

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <GradusPrimaryButton onClick={handleCreateAnalysis}>
          <FontAwesomeIcon
            icon={solid("plus")}
            style={{ marginRight: theme.spacing(1) }}
          />
          {t("CREATE_ANALYSIS")}
        </GradusPrimaryButton>

        <SearchBar
          options={options}
          setSearchedOptions={setSearchedOptions}
          parametersToSearch={["name"]}
          placeholder={t("LIST_ANALYSIS_MODAL.SEARCH_BAR_PLACEHOLDER")}
        />
      </Stack>
      <AnalysisTableComponentWithLoading
        isLoading={isLoading}
        gradusLoadingContainerSx={{ mt: 2 }}
      />
    </Stack>
  );
}

ConsulteAnalysisTableWithSearch.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

ConsulteAnalysisTableWithSearch.defaultProps = {
  open: false,
};
