import _ from "lodash";
import api from "../api";
import HistogramBinDTO from "../../dtos/HistogramBinDTO";
import SalaryAverageAndQuantity from "../../dtos/SalaryAverageAndQuantity";
import SalaryAverageAndQuantityAndCost from "../../dtos/SalaryAverageAndQuantityAndCost";
import MarketCompanyWithDifference from "../../stores/ChartStore/MarketCompanyWithDifference";
import CompareGetChartDTO from "../../dtos/chartGenerate/CompareGetChartDTO";

const BASE_URL = "/api/v1/payroll/chart";

const payrollChartApi = {
  getEmployeeCountBySalaryRange: (
    dto: CompareGetChartDTO
  ): Promise<HistogramBinDTO[]> =>
    new Promise<HistogramBinDTO[]>((resolve, reject) => {
      api
        .post(`${BASE_URL}/count-by-salary-range`, dto)
        .then((response) => {
          const bins = response.data.map(
            (bin: HistogramBinDTO) => new HistogramBinDTO({ ...bin })
          );
          return resolve(bins);
        })
        .catch(reject);
    }),

  getEmployeeAverageSalaryByState: (
    dto: CompareGetChartDTO
  ): Promise<Record<string, SalaryAverageAndQuantity>> =>
    new Promise<Record<string, SalaryAverageAndQuantity>>((resolve, reject) => {
      api
        .post(`${BASE_URL}/average-salary-by-state`, dto)
        .then((response) => {
          const map: Record<string, SalaryAverageAndQuantity> = {};

          const responseData = response.data;
          Object.keys(responseData).forEach((key) => {
            map[key] = new SalaryAverageAndQuantity({ ...responseData[key] });
          });
          return resolve(map);
        })
        .catch(reject);
    }),

  getEmployeeAverageSalaryByCompanySize: (
    dto: CompareGetChartDTO
  ): Promise<Record<string, SalaryAverageAndQuantity>> =>
    new Promise<Record<string, SalaryAverageAndQuantity>>((resolve, reject) => {
      api
        .post(`${BASE_URL}/average-salary-by-company-size`, dto)
        .then((response) => {
          const map: Record<string, SalaryAverageAndQuantity> = {};

          const responseData = response.data;
          Object.keys(responseData).forEach((key) => {
            map[key] = new SalaryAverageAndQuantity({ ...responseData[key] });
          });
          return resolve(map);
        })
        .catch(reject);
    }),

  getAverageSalaryByOccupation: (
    chartTypeId: number,
    dto: CompareGetChartDTO
  ): Promise<
    Record<string, MarketCompanyWithDifference<SalaryAverageAndQuantityAndCost>>
  > => {
    const params: { chartTypeId?: number } = {};
    if (_.isNumber(chartTypeId)) {
      params.chartTypeId = chartTypeId;
    }

    return new Promise((resolve, reject) => {
      api
        .post(`${BASE_URL}/average-salary-by-occupation`, dto, {
          params,
        })
        .then((response) => {
          const map: Record<
            string,
            MarketCompanyWithDifference<SalaryAverageAndQuantityAndCost>
          > = {};

          const responseData = response.data;
          Object.keys(responseData).forEach((key) => {
            map[key] =
              new MarketCompanyWithDifference<SalaryAverageAndQuantityAndCost>({
                ...responseData[key],
              });
          });
          return resolve(map);
        })
        .catch(reject);
    });
  },
};

export default payrollChartApi;
