import IconButton from "@mui/material/IconButton";
import React, { Fragment, useMemo } from "react";
import _ from "lodash";
import { BaseButtonProps } from "./GradusButtonBase";

const GradusSupportButton = ({
  onClick,
  children,
  sx,
  isSecondary,
  buttonProps,
}: BaseButtonProps) => {
  const colorType = useMemo<string>(
    () => (isSecondary ? "secondary" : "primary"),
    [isSecondary]
  );
  return (
    <IconButton
      size="small"
      {...buttonProps}
      sx={{
        alignSelf: "center",
        fontSize: 15,
        color: `${colorType}.main`,
        px: 2,
        py: "4.5px",
        lineHeight: 1.5,
        border: 1,
        borderRadius: "10px",
        fontWeight: "bold",
        height: "31px",
        width: "167px",
        ...sx,
      }}
      title=""
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

GradusSupportButton.defaultProps = {
  onClick: _.noop,
  isSecondary: false,
  sx: {},
  children: Fragment,
  buttonProps: {},
};

export default GradusSupportButton;
