import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModuleCard from "./ModuleCard";

export default function CompareModuleCard({ isCompareAvailable }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={6}>
      <ModuleCard
        title={t("COMPARE")}
        text={t("HOME_PAGE.COMPARE_TEXT")}
        buttonText={t("COMPARE")}
        handleClick={() => navigate("/compare/create-analysis")}
        isAvailable={isCompareAvailable}
        isCompare
      />
    </Grid>
  );
}

CompareModuleCard.propTypes = {
  isCompareAvailable: PropTypes.bool,
};

CompareModuleCard.defaultProps = {
  isCompareAvailable: false,
};
