import { create } from "zustand";
import CompareAnalysisPresenter from "../dtos/CompareAnalysis/CompareAnalysisPresenter";

export interface RelaxationSelectorStoreState {
  isRelaxed: boolean;

  initRelaxationSelector: (analysis: CompareAnalysisPresenter) => void;
  updateRelaxationStatus: (isRelaxed: boolean) => void;
}

const initialRelaxationSelectorStoreState = {
  isRelaxed: false,
};

const useRelaxationSelectorStore = create<RelaxationSelectorStoreState>()(
  (set) => ({
    ...initialRelaxationSelectorStoreState,

    initRelaxationSelector: async (analysis) => {
      const relaxedFilter = analysis.getActiveFilter().getRelaxedFilter();

      set({
        isRelaxed: relaxedFilter.isActive,
      });
    },

    updateRelaxationStatus: (isRelaxed) => {
      set({ isRelaxed });
    },
  })
);

export { initialRelaxationSelectorStoreState };

export default useRelaxationSelectorStore;
