import { InputAdornment } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

export default function GradusPercentageInput({
  id,
  value,
  setValue,
  canExceedHundred,
}: {
  id: string;
  value: number;
  setValue: (newValue: number) => void;
  canExceedHundred?: boolean;
}) {
  const theme = useTheme();

  const handleChange = (newValue: string) => {
    let percentage = Number(newValue);
    if (canExceedHundred || percentage <= 100) {
      setValue(percentage);
    } else {
      percentage = value;
    }
    setValue(percentage);
  };

  return (
    <TextField
      id={id}
      value={value}
      onChange={(event) => handleChange(event.target.value)}
      sx={{ width: theme.spacing(17) }}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  );
}

GradusPercentageInput.defaultProps = {
  canExceedHundred: false,
};
