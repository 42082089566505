/* eslint-disable max-len */
import { CSSProperties, ReactNode } from "react";
import _ from "lodash";
import { DefaultTFuncReturn } from "i18next";

type TableCellAlignment =
  | "inherit"
  | "left"
  | "center"
  | "right"
  | "justify"
  | undefined;

type RenderedCell = number | string | ReactNode;

// FIXME: classe muito grande, refatorar em classes menores
export default class GradusTableColumn<T> {
  field: string;

  getValue: (row: T) => string;

  headerLabel: string | DefaultTFuncReturn;

  render: (row: T) => RenderedCell;

  renderCollapse: (row: T) => RenderedCell;

  renderFilter: (item: string | number) => RenderedCell;

  hasFilter: boolean;

  align: TableCellAlignment;

  cellStyle: CSSProperties;

  collapsedCellStyle: CSSProperties;

  order: number;

  isFixed: boolean;

  shortenTextWithEllipsis: boolean;

  shouldFilterChildren: boolean;

  headerAlign: TableCellAlignment;

  /**
   * Configuração da coluna da tabela.
   * @param field campo que será configurado pela classe
   * @param headerLabel cabeçalho da coluna
   * @param order posição da coluna
   * @param render função utilizada para customizar o elemento renderizado, podendo retornar
   * componentes
   * @param renderFilter função utilizada para customizar o elemento renderizado do
   * filtro, podendo retornar componentes
   * @param renderCollapse função utilizada para customizar o elemento renderizado de linhas
   * colapsadas, podendo retornar componentes. Se não é passado nada, a função render é usada.
   * @param getValue função que retorna o valor utilizado para filtragem/ordenação
   * @param hasFilter se a coluna pode ter filtros (útil no caso de checkbox)
   * @param align alinhamento da coluna
   * @param cellStyle estilo das células da coluna (linhas não colapsadas)
   * @param collapsedCellStyle estilo das células da coluna (linhas colapsadas)
   * @param order index para ordenação das colunas
   * @param isFixed fixa a posição da coluna
   * @param shortenTextWithEllipsis coloca reticências e tooltip no campo caso o texto
   * fique muito grande
   * @param shouldFilterChildren muda o comportamento da filtragem para filtrar somente
   * linhas colapsadas (o comportamento padrão é filtrar linhas pais)
   * @param headerAlign alinhamento da célula do header
   */
  constructor({
    field,
    headerLabel,
    order,
    render = (row) => _.get(row, field),
    renderFilter = (item) => item,
    renderCollapse = render,
    getValue = (row) => _.get(row, field),
    hasFilter = true,
    align = "left",
    cellStyle = {},
    collapsedCellStyle = {},
    isFixed = false,
    shortenTextWithEllipsis = false,
    shouldFilterChildren = false,
    headerAlign = "left",
  }: {
    field: string;
    headerLabel: string | DefaultTFuncReturn;
    order: number;
    render?: (row: T) => RenderedCell;
    renderFilter?: (item: string | number) => string | number;
    renderCollapse?: (row: T) => RenderedCell;
    getValue?: (row: T) => string;
    hasFilter?: boolean;
    align?: TableCellAlignment;
    cellStyle?: CSSProperties;
    collapsedCellStyle?: CSSProperties;
    isFixed?: boolean;
    shortenTextWithEllipsis?: boolean;
    shouldFilterChildren?: boolean;
    headerAlign?: TableCellAlignment;
  }) {
    this.field = field;
    this.getValue = getValue;
    this.headerLabel = headerLabel;
    this.render = render;
    this.renderCollapse = renderCollapse;
    this.renderFilter = renderFilter;
    this.hasFilter = hasFilter;
    this.align = align;
    this.cellStyle = cellStyle;
    this.collapsedCellStyle = collapsedCellStyle;
    this.order = order;
    this.isFixed = isFixed;
    this.shortenTextWithEllipsis = shortenTextWithEllipsis;
    this.shouldFilterChildren = shouldFilterChildren;
    this.headerAlign = headerAlign;
  }
}
