import React, { useEffect, useMemo } from "react";
import { create } from "zustand";
import createContext from "zustand/context";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import PropTypes from "prop-types";

const { Provider, useStore } = createContext();
const stores = {};

const initialState = {
  // Linhas da tabela sem filtrar
  rows: [],
  // Colunas da tabela
  columns: [],
  // Linhas com filtros já aplicados
  filteredRows: [],
  // Todos os filtros possíveis para cada coluna
  filters: {},
  // Valores possíveis para cada filtro. Estes valores contêm somente valores existentes nas linhas filtradas.
  possibleFilters: {},
  // Filtros a serem aplicados para cada coluna
  selectedFilters: {},
  // Filtros que estão atualmente nos "modais" de filtragem
  shownFilters: {},
  // Estado controlado internamente pela função de inicialização
  isInitialized: false,
  // Caso esteja ativa a funcionalidade de checkbox na tabela, os valores selecionados estarão aqui
  checkedRows: {},
  // Linhas colapsadas
  collapseCheckedRows: {},
  // Colunas configuradas
  filteredColumns: [],
  // Colunas que não aparecerão na tabela
  hiddenColumns: [],
  // Campo que contém dados das linhas colapsadas
  collapseField: "collapseRows",
};

function useGradusTableStore(storeName, selector = (state) => state) {
  let store = useStore(selector);
  if (storeName) {
    store = stores[storeName](selector);
  }
  return store;
}

function createStore(storeName) {
  return create()(
    devtools(
      (set, get) => ({
        ...initialState,

        /**
         * Inicializa a tabela.
         */
        initTable: (rows, columns, collapseField = "collapseRows") => {
          const { isInitialized, columns: lastColumns, rows: lastRows } = get();

          if (isInitialized && columns === lastColumns && rows === lastRows) {
            return;
          }

          const filters = columns
            .filter((column) => column.hasFilter)
            .reduce((reducedFilters, column) => {
              const { field, getValue, shouldFilterChildren } = column;
              let filterValues;

              if (shouldFilterChildren) {
                filterValues = _.flatMap(rows, collapseField).map(
                  (row) => `${getValue(row)}`
                );
              } else {
                filterValues = _.map(rows, (row) => `${getValue(row)}`);
              }

              // eslint-disable-next-line no-param-reassign
              reducedFilters[field] = [...new Set(filterValues)];
              return reducedFilters;
            }, {});

          set(() => ({
            rows,
            filteredRows: _.cloneDeep(rows),
            columns,
            filters,
            selectedFilters: _.cloneDeep(filters),
            shownFilters: _.cloneDeep(filters),
            possibleFilters: _.cloneDeep(filters),
            isInitialized: true,
            collapseField,
          }));
        },

        addSelectedFilterValues: (field, values) => {
          set(({ selectedFilters }) => ({
            selectedFilters: {
              ...selectedFilters,
              [field]: [...new Set(values)],
            },
          }));
        },

        addShownFilterValues: (field, values) => {
          set(({ shownFilters }) => ({
            shownFilters: {
              ...shownFilters,
              [field]: [...new Set(values)],
            },
          }));
        },

        setColumns: (columns) => {
          set(() => ({
            columns,
            hiddenColumns: get().hiddenColumns.filter(
              (hiddenColumn) => !columns.includes(hiddenColumn)
            ),
          }));
        },

        setHiddenColumns: (hiddenColumns) => {
          set(() => ({
            hiddenColumns,
            columns: get().columns.filter(
              (column) => !hiddenColumns.includes(column)
            ),
          }));
        },

        /**
         * Armazena um valor de filtro no store, porém com o comportamento de checkbox
         * @param field {string} campo a ser 'togglado'
         * @param itemName {unknown} valor a ser 'togglado'
         */
        toggleFilterValue: (field, itemName) => {
          const { selectedFilters, addSelectedFilterValues } = get();

          const fieldFilters = selectedFilters[field];

          let newValues;
          if (fieldFilters.includes(itemName)) {
            newValues = fieldFilters.filter(
              (toFilterItem) => toFilterItem !== itemName
            );
          } else {
            newValues = fieldFilters.concat([itemName]);
          }

          addSelectedFilterValues(field, newValues);
        },

        selectAllForField: (field, checked) => {
          const { addSelectedFilterValues, possibleFilters } = get();
          addSelectedFilterValues(field, checked ? possibleFilters[field] : []);
        },

        getFilterableColumns: () => {
          const { columns } = get();
          return columns.filter((column) => column.hasFilter);
        },

        clearFilters: () => {
          set(({ filters }) => ({
            selectedFilters: _.cloneDeep(filters),
            shownFilters: _.cloneDeep(filters),
          }));
        },

        cancelFiltering: () => {
          set(({ possibleFilters }) => ({
            selectedFilters: _.cloneDeep(possibleFilters),
            shownFilters: _.cloneDeep(possibleFilters),
          }));
        },

        applyFilters: () => {
          const { selectedFilters, rows, getFilterableColumns, collapseField } =
            get();

          const newPossibleFilters = {};
          let filterResult = rows;

          const applyFiltersForField = (column) => {
            const { field, getValue, shouldFilterChildren } = column;

            const filterItemBasedOnSelectedFilters = (item) => {
              const filtersForField = selectedFilters[field];
              const filterableItemValue = String(getValue(item));

              return filtersForField.includes(filterableItemValue);
            };

            if (shouldFilterChildren) {
              return filterResult
                .map((item) => {
                  // TODO: e se existirem linhas que já não tinham nenhum filho antes da filtragem? Eles
                  //  devem ser removidos também?
                  const filteredSubItems = _.get(
                    item,
                    collapseField,
                    []
                  ).filter(filterItemBasedOnSelectedFilters);
                  if (_.isEmpty(filteredSubItems)) {
                    return null;
                  }
                  return { ...item, [collapseField]: filteredSubItems };
                })
                .filter((item) => !_.isEmpty(item));
            }
            return filterResult.filter(filterItemBasedOnSelectedFilters);
          };

          const getPossibleFiltersForNewValues = (column) => {
            const { shouldFilterChildren, getValue } = column;
            const mappedValues = shouldFilterChildren
              ? _.flatMap(filterResult, collapseField)
              : filterResult;
            return [...new Set(mappedValues.map((res) => `${getValue(res)}`))];
          };
          getFilterableColumns().forEach((column) => {
            const { field } = column;
            filterResult = applyFiltersForField(column);
            newPossibleFilters[field] = getPossibleFiltersForNewValues(column);
          });

          set(() => ({
            filteredRows: filterResult,
            checkedRows: {},
            possibleFilters: newPossibleFilters,
            shownFilters: newPossibleFilters,
          }));
          return filterResult;
        },

        sortFilteredRows: (field, isDescending) => {
          set(({ filteredRows, collapseField }) => {
            const sorted = _.chain(filteredRows)
              .map((filteredRow) => {
                if (_.isEmpty(filteredRow[collapseField])) {
                  return filteredRow;
                }
                const sortedCollapsedRow = _.sortBy(
                  filteredRow[collapseField],
                  field
                );

                if (isDescending) {
                  sortedCollapsedRow.reverse();
                }

                return {
                  ...filteredRow,
                  [collapseField]: sortedCollapsedRow,
                };
              })
              .sortBy(field)
              .value();

            if (isDescending) {
              sorted.reverse();
            }

            return {
              filteredRows: sorted,
            };
          });
        },

        checkRow: (rowId, checked, isCollapseRow = false) => {
          set((state) => {
            const checkedRows = isCollapseRow
              ? _.cloneDeep(state.collapseCheckedRows)
              : _.cloneDeep(state.checkedRows);

            if (checked) {
              checkedRows[rowId] = true;
            } else {
              delete checkedRows[rowId];
            }

            return isCollapseRow
              ? { collapseCheckedRows: checkedRows }
              : { checkedRows };
          });
        },

        checkAllRows: (isChecked) => {
          set(({ filteredRows, collapseField }) => {
            let newCheckedRows = {};
            let newCollapseCheckedRows = {};
            if (isChecked) {
              newCheckedRows = filteredRows.reduce((acc, currentRow) => {
                acc[currentRow.id] = isChecked;
                return acc;
              }, {});

              newCollapseCheckedRows = filteredRows
                .map((row) => _.get(row, collapseField, []))
                .flatMap((collapsed) => collapsed)
                .reduce((acc, currentRow) => {
                  acc[currentRow.id] = isChecked;
                  return acc;
                }, {});
            }

            return {
              checkedRows: newCheckedRows,
              collapseCheckedRows: newCollapseCheckedRows,
            };
          });
        },
      }),
      { name: storeName }
    )
  );
}

function GradusTableProvider({ storeName, children }) {
  const store = useMemo(() => {
    if (!_.has(stores, storeName)) {
      stores[storeName] = createStore(storeName);
    }

    return stores[storeName];
  }, [storeName]);

  useEffect(
    () => () => {
      store.setState(() => ({ ...initialState }));
    },
    [store]
  );

  return <Provider createStore={() => store}>{children}</Provider>;
}

GradusTableProvider.propTypes = {
  children: PropTypes.node.isRequired,
  storeName: PropTypes.string.isRequired,
};

export { GradusTableProvider, useGradusTableStore };
