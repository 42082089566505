import SystemSettingsDTO from "../dtos/SystemSettingsDTO";
import api from "./api";

const baseUrl = "/api/v2/system_configuration";

const systemSettingsApi = {
  getSystemSettings: (): Promise<SystemSettingsDTO[]> =>
    new Promise<SystemSettingsDTO[]>((resolve, reject) => {
      api
        .get(`${baseUrl}/list`)
        .then((response) =>
          resolve(
            response.data.map(
              (config: SystemSettingsDTO) =>
                new SystemSettingsDTO({ ...config })
            )
          )
        )
        .catch(reject);
    }),
};

export default systemSettingsApi;
