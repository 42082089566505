import React, { ReactNode } from "react";
import { ButtonProps } from "@mui/material";
import GradusPrimaryButton from "./GradusPrimaryButton";
import GradusSupportButton from "./GradusSupportButton";
import { BaseButtonProps } from "./GradusButtonBase";

type GradusButtonVariant = "primary" | "support";
type GradusButtonColorVariant = "primary" | "secondary";

export interface GradusButtonProps {
  variant: GradusButtonVariant;
  colorVariant: GradusButtonColorVariant;
  children: ReactNode;
  buttonProps?: ButtonProps;
}

// TODO: substituir todos os Gradus*Button por styled Buttons
const GradusButton = (props: BaseButtonProps & GradusButtonProps) => {
  const { variant, colorVariant, children } = props;

  if (variant === "support") {
    return (
      <GradusSupportButton
        isSecondary={colorVariant === "secondary"}
        {...props}
      >
        {children}
      </GradusSupportButton>
    );
  }
  return (
    <GradusPrimaryButton isSecondary={colorVariant === "secondary"} {...props}>
      {children}
    </GradusPrimaryButton>
  );
};

GradusButton.defaultProps = {
  buttonProps: {},
};

export default GradusButton;
