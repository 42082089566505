import { Collapse, Grid } from "@mui/material";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import FilterTags from "../FilterTags/FilterTags";
import PayrollFilter from "../../dtos/PayrollFilter";
import MarketFilter from "../../dtos/MarketFilter";
import SavedFilters from "./SavedFilters";

export default function AppliedFiltersList({
  isShown,
  isCompare,
  marketFilter,
  companyFilter,
}) {
  const filters = useMemo(
    () => [marketFilter, companyFilter].filter((value) => value),
    [marketFilter, companyFilter]
  );

  return (
    <Collapse in={isShown}>
      <Container sx={{ width: "100%" }}>
        {isCompare && <SavedFilters />}
        <Grid container>
          {filters.map(({ filter, title }) => (
            <Grid item xs={6} key={`${filter}-${title}`}>
              <FilterTags filters={filter} title={title} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Collapse>
  );
}

AppliedFiltersList.propTypes = {
  isShown: PropTypes.bool.isRequired,
  isCompare: PropTypes.bool,
  marketFilter: PropTypes.shape({
    filter: PropTypes.shape(MarketFilter),
    title: PropTypes.string,
  }).isRequired,
  companyFilter: PropTypes.shape({
    filter: PropTypes.shape(PayrollFilter),
    title: PropTypes.string,
  }),
};

AppliedFiltersList.defaultProps = {
  isCompare: false,
  companyFilter: null,
};
