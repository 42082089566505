import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { alpha, useTheme } from "@mui/material";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";

const InfoIcon = ({
  style,
  icon,
}: {
  style?: CSSProperties;
  icon?: IconDefinition;
}) => {
  const theme = useTheme();

  return (
    <FontAwesomeIcon
      // Apesar de ter um valor default, o TS reclama que pode ser undefined
      icon={icon || solid("exclamation-circle")}
      style={{
        fontSize: "15px",
        color: alpha(theme.palette.grey[700], 0.3),
        ...style,
      }}
    />
  );
};

InfoIcon.defaultProps = {
  style: {},
  icon: solid("exclamation-circle"),
};

export default InfoIcon;
