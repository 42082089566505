import React from "react";
import JobDataFilter from "./FilterGroups/JobDataFilter";
import useFilterStore from "../../../stores/FilterStore/useFilterStore";
import GeographicDataFilter from "./FilterGroups/GeographicDataFilter";
import CustomParametersFilters from "./FilterGroups/CustomParametersFilters";
import useFilterOptionsStore from "../../../stores/FilterOptionsStore/useFilterOptionsStore";
import useCompareAnalysisStore from "../../../stores/useCompareAnalysisStore";
import BaseFilter from "../../../dtos/BaseFilter";

export default function PayrollFilters() {
  const { updateModalSelectedPayrollFilters, modalSelectedPayrollFilters } =
    useFilterStore((state) => ({
      updateModalSelectedPayrollFilters:
        state.updateModalSelectedPayrollFilters,
      modalSelectedPayrollFilters: state.modalSelectedPayrollFilters,
    }));
  const {
    companyOptions,
    getPayrollJobChainedFilters,
    getPayrollGeographyChainedFilters,
    getPayrollCustomColumnsChainedFilters,
  } = useFilterOptionsStore((state) => ({
    companyOptions: state.companyOptions,
    getPayrollJobChainedFilters: state.getPayrollJobChainedFilters,
    getPayrollGeographyChainedFilters: state.getPayrollGeographyChainedFilters,
    getPayrollCustomColumnsChainedFilters:
      state.getPayrollCustomColumnsChainedFilters,
  }));
  const { analysis } = useCompareAnalysisStore((state) => ({
    analysis: state.analysis,
  }));

  const setJobDataFilters = async (values: Partial<BaseFilter>) => {
    const newFilters = await updateModalSelectedPayrollFilters(
      values,
      analysis.getId()
    );
    await getPayrollJobChainedFilters(newFilters, analysis.getId());
  };
  const setGeographicDataFilters = async (values: Partial<BaseFilter>) => {
    const newFilters = await updateModalSelectedPayrollFilters(
      values,
      analysis.getId()
    );
    await getPayrollGeographyChainedFilters(newFilters, analysis.getId());
  };

  return (
    <>
      <JobDataFilter
        setSelectedFilters={setJobDataFilters}
        selectedFilters={modalSelectedPayrollFilters}
        options={companyOptions}
      />
      <GeographicDataFilter
        setSelectedFilters={setGeographicDataFilters}
        selectedFilters={modalSelectedPayrollFilters}
        options={companyOptions}
      />
      <CustomParametersFilters />
    </>
  );
}
