function formatCurrency(
  value: number,
  locale = "pt-BR",
  currency = "BRL"
): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    maximumFractionDigits: 0,
  }).format(value);
}

function formatPercentage(
  value: number,
  locale = "pt-BR",
  decimalPlaces = 2,
  isAlreadyInPercentage = true
): string {
  return new Intl.NumberFormat(locale, {
    style: "percent",
    maximumFractionDigits: decimalPlaces,
  }).format(isAlreadyInPercentage ? value / 100.0 : value);
}

function formatCurrencyAbbreviate(
  value: number,
  locale = "pt-BR",
  decimalPlaces = 1
): string {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: decimalPlaces,
  }).format(value / 1000);
}

function formatInteger(value: number, locale = "pt-BR") {
  return new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(
    value
  );
}

// eslint-disable-next-line import/prefer-default-export
export {
  formatCurrency,
  formatPercentage,
  formatCurrencyAbbreviate,
  formatInteger,
};
