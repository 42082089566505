export default {
  common: {
    FOOTER: "©{{year}} Gradus Tech - Todos os direitos reservados. ",
    CHANGE_PASSWORD: "Alterar senha",
    LOGOUT: "Sair",
    EXIT: "Fechar",
    CANCEL: "Cancelar",
    APPLY: "Aplicar",
    NAME: "Nome",
    MARKET_FILTERS: "Filtros de mercado",
    COMPANY_FILTERS: "Filtros de empresa",
    MY_FILTERS: "Meus filtros",
    ACTIONS: "Ações",
    HOME: "Home",
    CREATE: "Criar",
    SALARY: "Salário",
    OCCUPATION_CBO: "Ocupação (CBO)",
    COMPANY_SIZE_LABEL: "Tamanho da empresa",
    COMPANY_CNAES: "CNAEs da empresa",
    COUNTY: "Município",
    STATE: "Estado",
    REGION: "Região",
    INTERMEDIATE_GEOGRAPHIC_REGION: "Região Geográfica Intermediária",
    INTERMEDIATE_REGION: "Região intermediária",
    IMMEDIATE_GEOGRAPHIC_REGION: "Região Geográfica Imediata",
    IMMEDIATE_REGION: "Região imediata",
    GEOGRAPHY: "Geografia",
    CUSTOM_COLUMN: "Coluna customizada",
    CUSTOM_COLUMNS: "Colunas customizadas",
    CREATE_ANALYSIS: "Criar análise",
    NEW_ANALYSIS: "Nova análise",
    ANALYSIS: "Análises",
    ANALYSIS_NAME: "Nome da análise",
    COMPARE: "Compare",
    CONSULT: "Consulte",
    PAYROLL: "Folha de pagamento",
    EXAMPLE: "exemplo",
    MAPPING: "Mapeamento",
    SELECT_COLUMN: "Selecione a coluna",
    CREATE_MAPPING: "Criar mapeamento",
    APPLY_MAPPING: "Aplicar mapeamento",
    EDIT_MAPPING: "Editar mapeamento",
    DUPLICATE_MAPPING: "Duplicar mapeamento",
    REMOVE_MAPPING: "Remover mapeamento",
    HIDE_MAPPING: "Esconder mapeamento",
    SHOW_MAPPING: "Mostrar mapeamento",
    MAPPING_NAME: "Nome do mapeamento",
    FILL_IN_THE_PREVIOUS_FIELDS_BEFORE_CONTINUING:
      "Preencha os campos anteriores antes de continuar",
    UPLOAD_FIELD_PLACEHOLDER: "Carregue ou arraste seu arquivo aqui",
    NO_MAPPINGS_HAVE_BEEN_FOUND: "Nenhum mapeamento encontrado",
    SELECT_ALL: "Selecionar todos",
    NO_OPTIONS: "Nenhuma opção",
    LOADING: "Carregando",
    COMPANY: "Empresa",
    MARKET: "Mercado",
    DOWNLOAD_REPORT: "Baixar relatório",
    DOWNLOAD_OCCUPATIONS_REPORT: "Baixar relatório de ocupações completo",
    DOWNLOAD_RAIS_RAW_CSV: "Baixar CSV dos dados filtrados",
    PROCESSING_THE_REPORT: "Processando o relatório",
    NO_RESULTS: "Sem resultados",
    PERCENTAGE: "Porcentagem",
    OPTIONS: "Opções",
    CHART_OPTIONS: "Opções do gráfico",
    TOTAL_ESTIMATE_COST: "Custo total estimado",
    EMPLOYEES: "Empregados",
    SALARY_AVERAGE: "Média salarial",
    TOTAL_COST: "Custo total",
    DIFFERENCE_IN_RELATION_TO_MARKET: "Diferença em relação ao mercado",
    SIZE: "Tamanho",
    METRICS: "Métricas",
    CHARTS: "Gráficos",
    REMUNERATION_AVERAGE: "Remuneração média",
    "10TH_PERCENTILE": "Percentil 10",
    REMUNERATION_MEDIAN: "Mediana de remuneração",
    "90TH_PERCENTILE": "Percentil 90",
    EMPLOYEE_TOTAL_QUANTITY: "Quantidade total de colaboradores",
    BACK_TO_DEFAULTS: "Voltar ao padrão",
    BUY_COMPARE_MODULE:
      "Adquira já o módulo de comparação e obtenha a análise completa da sua atratividade salarial em comparação com o mercado. Entre em contato conosco pelo telefone (11) 3167-2542.",
    BUY_CONSULT_MODULE:
      "Adquira já o módulo de consulta e obtenha a análise completa da sua atratividade salarial em comparação com o mercado. Entre em contato conosco pelo telefone (11) 3167-2542.",
    SAVED_FILTERS: "Filtros salvos",
    NEW_FILTER: "Novo filtro",
    OPEN: "Abrir",
    RENAME: "Renomear",
    DELETE: "Remover",
    APPLY_AND_SAVE: "Aplicar e salvar",
    RELAX_AND_SAVE: "Relaxar e salvar",
    CLEAR: "Limpar",
    LARGE_GROUP: "Grande grupo",
    PRIMARY_SUBGROUP: "Subgrupo principal",
    SUBGROUP: "Subgrupo",
    FAMILY: "Família",
    OCCUPATION: "Ocupação",
    ROLE: "Cargo",
    JOB: "Cargo",
    COMPANY_SECTOR: "Setor da empresa",
    COMPANY_SECTORS: "Setores da empresa",
    SECTION: "Seção",
    DIVISION: "Divisão",
    GROUP: "Grupo",
    CLASS: "Classe",
    SUBCLASS: "Subclasse",
    COMPANY_SIZE: "Tamanho da empresa",
    GRADUS_LOGO_ALT: "Logo da Gradus Tech",
    PASSWORD: "Senha",
    ACCESS: "Acessar",
    REDEFINE_PASSWORD_FIRST_ACCESS: "Redefinir senha/Primeiro acesso",
    USER: "Usuário",
    REDEFINE_PASSWORD: "Redefinir senha",
    UPDATE_PASSWORD: "Atualizar senha",
    NEW_PASSWORD: "Nova senha",
    REPEAT_NEW_PASSWORD: "Repita a nova senha",
    GO_BACK: "Voltar",
    NO_FILTER_APPLIED: "Nenhum filtro aplicado.",
    GEOGRAPHIC_DATA: "Dados geográficos",
    CREATE_NEW_FILTER: "Criar novo filtro",
    SYSTEM_FIELD: "Campo do sistema",
    SPREADSHEET_FIELD: "Columna de la planilla",
    ACCEPT: "Aceitar",
    FONT_RAIS: "Fonte: Última RAIS atualizada ({{yearRais}})",
    APPLY_FILTERS: "Aplicar filtros",
    DUPLICATE: "Duplicar",
    OPTIONAL: "Opcional",
    VALUE: "Valor",
    PAYROLL_EXAMPLE: "Exemplo de folha de pagamento",
    EDIT: "Editar",
    SAVE: "Salvar",
    ALL: "Todos",
    DOWNLOAD_TEMPLATE: "Baixar template",
    ALERTS: {
      SUCCESSFULLY_UPDATED_PASSWORD: "Senha atualizada com sucesso",
      SUCCESSFULLY_REMOVED_ANALYSIS: "Análise excluída com sucesso",
      SUCCESSFULLY_RENAMED_ANALYSIS: "Análise renomeada com sucesso",
      SUCCESSFULLY_CREATED_ANALYSIS: "Análise criada com sucesso",
      SUCCESSFULLY_SAVED_ANALYSIS: "Análise salva com sucesso",
      SUCCESSFULLY_CREATED_MAPPING: "Mapeamento criado com sucesso",
      SUCCESSFULLY_REMOVED_MAPPING: "Mapeamento excluído com sucesso",
      SUCCESSFULLY_DUPLICATED_MAPPING: "Mapeamento duplicado com sucesso",
      SUCCESSFULLY_EDITED_MAPPING: "Mapeamento editado com sucesso",
      GENERATING_REPORT:
        "Requisição enviada! A geração do relatório pode demorar alguns minutos dependendo dos seus filtros.",
      GENERATING_TEMPLATE: "Template gerado com sucesso",
      SUCCESSFULLY_CHANGED_METRICS: "Métricas alteradas com sucesso",
      SUCCESSFULLY_CHANGED_CHARTS: "Gráficos alterados com sucesso",
      SUCCESSFULLY_APPLIED_PARAMETERS: "Parâmetros alterados com sucesso",
      SUCCESSFULLY_APPLIED_FILTER: "Filtro aplicado com sucesso",
      SUCCESSFULLY_REMOVED_FILTER: "Filtro excluído com sucesso",
      SUCCESSFULLY_RENAMED_FILTER: "Filtro renomeado com sucesso",
      SUCCESSFULLY_CREATED_FILTER: "Filtro criado com sucesso",
      SUCCESSFULLY_COPIED_FILTER: "Filtros copiados com sucesso",
      USERNAME_OR_EMAIL_NOT_FOUND: "Usuário ou email informado não encontrado",
      LINK_FOR_PASSWORD_RESET_MESSAGE:
        "Será enviado um link para redefinição de senha para o e-mail cadastrado. Aguarde alguns instantes para o recebimento da mensagem com as instruções de procedimento. Caso não tenha recebido o e-mail, verifique também sua caixa de spam.",
      UNKNOWN_ERROR_PLEASE_CONTACT_SUPPORT:
        "Houve um problema ao realizar a requisição ao servidor. Erro não esperado, entre em contato com o suporte.",
      DENIED_ACCESS: "Acesso negado",
      SUCCESSFULLY_APPLIED_OPTIONS: "Opções aplicadas com sucesso",
    },
    PASSWORD_MODAL: {
      CURRENT_PASSWORD: "Senha atual",
      YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST: "Sua senha deve conter no mínimo",
    },
    PASSWORD_REQUIREMENTS: {
      HAS_MINIMUM_CHARACTERS_LENGTH: "8 caracteres",
      HAS_AT_LEAST_ONE_NUMBER: "1 número",
      HAS_AT_LEAST_ONE_UPPER_CASE: "1 caractere maiúsculo",
      HAS_AT_LEAST_ONE_LOWER_CASE: "1 caractere minúsculo",
      HAS_AT_LEAST_ONE_SPECIAL_CHARACTER: "1 caractere especial",
      PASSWORD_EQUALS_CONFIRMATION: "Confirmação de senha",
      HAS_NOT_USER_DETAILS: "Ausência do nome e do login",
      HAS_NO_SEQUENCES: "Não deve possuir sequências",
    },
    HOME_PAGE: {
      COMPARE_TEXT:
        "Realize uma análise completa da sua atratividade salarial em comparação com o mercado, a partir das informações na sua folha de pagamento.",
      CONSULT_TEXT:
        "Filtre e consulte salários do mercado de acordo com a sua necessidade.",
    },
    LIST_ANALYSIS_MODAL: {
      COMPARE_TITLE: "Análises - Compare",
      CONSULT_TITLE: "Análises - Consulte",
      NO_ANALYSIS_FOUND: "Nenhuma análise encontrada",
      SEARCH_BAR_PLACEHOLDER: "Ex.: Análise 1",
    },
    COMPARE_CREATE_ANALYSIS_PAGE: {
      CREATE_AN_UNIQUE_NAME: "Crie um nome único para identificar sua análise.",
      PAYROLL_UPLOAD_TEXT_1:
        "Nesta etapa você inserirá sua folha de pagamento e criará um mapeamento para definir os parâmetros da análise ou selecionará um mapeamento já salvo anteriormente.",
      PAYROLL_UPLOAD_TEXT_2:
        "Em conformidade com a nossa <0>política de segurança da informação</0> solicitamos que dados como nome, CPF, endereço, sexo, faixa etária e quaisquer informações sensíveis que identifiquem o funcionário sejam excluídas da planilha anteriormente ao upload.",
      POLICY_CONSENT_BUTTON:
        "Declaro que li a <0>política de segurança da informação</0> e estou ciente dos dados que devem ou não ser enviados ao sistema.",
      SELECT_MAPPING_TEXT:
        "Selecione o mapeamento desejado para aplicar à folha de pagamento inserida, desde que esta possua as mesmas colunas do mapeamento salvo anteriormente.",
      SELECT_MAPPING_SEARCH_PLACEHOLDER: "Ex.: Mapeamento 1",
    },
    MAPPINGS_TABLE: {
      IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_MAPPING:
        "Não é possível excluir mapeamentos que estão sendo usados em alguma análise",
      IT_IS_NOT_POSSIBLE_TO_EDIT_AN_ALREADY_USED_MAPPING:
        "Não é possível editar mapeamentos que estão sendo usados em alguma análise",
      IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING:
        "Não é possível editar campos da folha em mapeamentos que estão sendo usados em alguma análise",
    },
    CREATE_MAPPING_MODAL: {
      DESCRIPTION:
        "O mapeamento é uma configuração que liga os campos de sua folha de pagamentos aos campos do sistema.",
      CBO_EXPLANATION:
        "Classificação brasileira de ocupações. É o número de registro utilizado como referência para cadastros administrativos de atividades.",
    },
    COMPANY_SIZE_ENUM: {
      NO_EMPLOYEES: "Nenhum funcionário",
      UPTO_4: "Até 4 funcionários",
      FROM_5_TO_9: "De 5 a 9 funcionários",
      FROM_10_TO_19: "De 10 a 19 funcionários",
      FROM_20_TO_49: "De 20 a 49 funcionários",
      FROM_50_TO_99: "De 50 a 99 funcionários",
      FROM_100_TO_249: "De 100 a 249 funcionários",
      FROM_250_TO_499: "De 250 a 499 funcionários",
      FROM_500_TO_999: "De 500 a 999 funcionários",
      FROM_1000: "A partir de 1000 funcionários",
    },
    METRICS_BOARD: {
      MONETARY_VALUES_TOOLTIP:
        "Todos os valores referentes à remuneração estão em R$ mil",
      ABOVE_AVERAGE:
        "A cesta em questão está <0>{{diff}} acima da média do mercado</0> para uma cesta similar na amostra selecionada.",
      BELOW_AVERAGE:
        "A cesta em questão está <0>{{diff}} abaixo da média do mercado</0> para uma cesta similar na amostra selecionada.",
      AT_AVERAGE:
        "A cesta em questão está <0>igual à média do mercado</0> para uma cesta similar na amostra selecionada.",
      "10TH_PERCENTILE_TOOLTIP":
        "10% dos profissionais têm um salário menor ou igual a esse valor, enquanto os demais 90% recebem uma remuneração igual ou superior",
      MEDIAN_TOOLTIP:
        "Metade dos profissionais têm um salário menor ou igual a esse valor, enquanto a outra metade recebe uma remuneração igual ou superior",
      "90TH_PERCENTILE_TOOLTIP":
        "90% dos profissionais têm um salário menor ou igual a esse valor, enquanto os demais 10% recebem uma remuneração igual ou superior",
    },
    DASHBOARD_HEADER: {
      APPLIED_FILTERS: "Filtros aplicados",
      EDIT_OR_CREATE_NEW_FILTERS: "Editar ou criar novos filtros",
      CUSTOMIZE_INFORMATIONS: "Customizar informações",
      SAVE_ANALYSIS: "Salvar análise",
      NO_MODIFICATIONS_TO_SAVE: "Não há alterações a serem salvas",
      RENAME_ANALYSIS: "Renomear análise",
      ENTER_PRESENTATION_MODE: "Modo de apresentação",
      EXIT_PRESENTATION_MODE: "Sair do modo de apresentação",
    },
    CHARTS_DASHBOARD: {
      MARKET_AVERAGE: "Média - Mercado",
      COMPANY_AVERAGE: "Média - Empresa",
      EMPLOYEE_PERCENTAGE_BY_SALARY_RANGE:
        "% de funcionários por faixa salarial",
      SALARY_RANGE: "Faixa salarial (R$ mil)",
      AVERAGE_SALARY: "Salário médio (R$ mil)",
      EMPLOYEES_BY_SALARY_RANGE_COMPANY:
        "Empregados por faixa salarial - Empresa",
      EMPLOYEES_BY_SALARY_RANGE_MARKET:
        "Empregados por faixa salarial - Mercado",
      EMPLOYEES_BY_SALARY_RANGE_BOTH:
        "Empregados por faixa salarial - Unificado",
      SALARY_RANGE_BY_STATE: "Média salarial por estado",
      SALARY_RANGE_BY_COMPANY_SIZE: "Média salarial por tamanho de empresa",
      SALARY_AVERAGE_BY_OCCUPATION: "Ocupações com maior média salarial",
      HIGHER_AVERAGE: "Ocupações com maior média salarial",
      HIGHER_COSTS: "Ocupações com maior custo na folha",
      COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE:
        "Ocupações com média salarial superior à do mercado",
      COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE:
        "Ocupações com média salarial inferior à do mercado",
      AVERAGE_SALARY_BY_OCCUPATION: "Média salarial por ocupação",
      OCCUPATIONS_CHART_TOOLTIP:
        "O custo total de mercado é calculado multiplicando-se a média de mercado pelo número de funcionários da sua empresa.",
    },
    OCCUPATIONS_CHART_OPTIONS_MODAL: {
      SELECT_CHART_INFORMATION: "Selecione o tipo de informação do gráfico",
    },
    PARAMETERIZATION_MODAL: {
      CHART_PARAMETERIZATION: "Parametrização de gráficos",
      METRICS_ORGANIZATION: "Organização das métricas",
      CHARTS_ORGANIZATION: "Organização dos gráficos",
      INDEX_APPLICATION: "Aplicação de índices",
      METRICS_TAB_DESCRIPTION:
        "Escolha quais métricas serão mostradas na barra de métricas. Elas ficarão na ordem que forem selecionadas.",
      CHARTS_TAB_DESCRIPTION:
        "Escolha quais gráficos serão mostrados. Eles ficarão na ordem que forem selecionados.",
      APPLY_SALARY_CORRECTION_INDEX: "Aplicar índice de correção salarial",
      SALARY_CORRECTION_INDEX_TOOLTIP:
        "O índice pode ser usado para corrigir discrepâncias nos dados de mercado, dado que a fonte de dados é a última RAIS atualizada. O valor selecionado será aplicado a todos os valores monetários referentes ao mercado, inclusive em relatórios.",
      CUT_SALARIES_ABOVE_PERCENTILE: "Cortar salários acima do percentil",
    },
    FILTER_MODAL: {
      FILTER_EXAMPLE: "Ex.: Filtro 1",
      IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_FILTER:
        "Não é possível apagar filtros em uso",
      COPY_FILTER_FROM_CURRENT_TO_OTHER:
        "Copiar os filtros selecionados na aba atual para a aba de {{otherTabName}}",
      COPY_FILTER_FROM_OTHER_TO_CURRENT:
        "Copiar os filtros selecionados na aba de {{otherTabName}} para a aba atual",
      FILTERING_RESULT: "Resultado da filtragem",
      FILTERING_RESULT_TOOLTIP:
        "Quantidade de funcionários que serão retornados pela filtragem utilizando os filtros selecionados",
      INSUFFICIENT_DATA_DISCLAIMER:
        "Os dados de mercado são insuficientes para uma comparação significativa, considere aplicar filtros mais abrangentes.",
      CBO_TOOLTIP:
        "Os cargos são especificados por um número chamado CBO (Classificação Brasileira de Ocupações). O CBO é formado por 5 partes: <list><item>O primeiro dígito indica o <bold>grande grupo</bold>.</item><item>O segundo dígito indica o <bold>subgrupo principal</bold>.</item><item>O terceiro dígito indica o <bold>subgrupo</bold>.</item><item>O quarto dígito indica a <bold>família</bold>.</item><item>Os dois últimos números do código indicam a <bold>ocupação</bold>.</item></list>",
      GEOGRAPHY_TOOLTIP:
        "A geografia é formada por cinco informações: <list><item>A <bold>região</bold> se refere a cada uma das unidades federativas do Brasil.</item><item>O <bold>estado</bold> se refere a cada estado brasileiro (São Paulo, Santa Catarina etc.).</item><item>A <bold>região geográfica intermediária</bold> é um agrupamento de regiões geográficas imediatas.</item><item>A <bold>região geográfica imediata</bold> é um agrupamento de municípios.</item><item>O <bold>município</bold> é a menor unidade autônoma da Federação.</item></list>",
      CNAE_TOOLTIP:
        "Os setores da empresa são especificados por um número chamado CNAE (Classificação Nacional de Atividades Econômicas). O CNAE é formado por 5 partes:<list><item>O primeiro dígito indica a <bold>seção</bold>.</item><item>O segundo dígito indica a <bold>divisão</bold>.</item><item>O terceiro dígito indica o <bold>grupo</bold>.</item><item>O quarto dígito e seu dígito verificador (o quinto) indicam a <bold>classe</bold>.</item><item>Os dois últimos números do código que ficam após o dígito verificador indicam a <bold>subclasse</bold>.</item></list>",
    },
    REMOVE_FILTER_DIALOG: {
      TITLE: "Removendo filtro",
      CONFIRMATION_MESSAGE:
        "Deseja realmente remover o filtro '{{filterName}}'?",
    },
    LOGIN_PAGE: {
      PLEASE_TICK_IM_NOT_A_ROBOT: 'Por favor, marque "Não sou um robô"',
      INCORRECT_USERNAME_OR_PASSWORD: "Usuário ou senha incorretos",
      USERNAME_OR_EMAIL: "Usuário ou email",
    },
    GRADUS_TABLE: {
      ROWS_PER_PAGE: "Linhas por página",
      DISPLAYED_ROWS: "{{from}}-{{to}} de {{count}} linhas",
    },
    CONFIRMATION_DIALOG: {
      CONFIRM_ACTION: "Confirmar a ação",
      DO_YOU_REALLY_WANT_TO_EXECUTE_THE_SELECTED_ACTION:
        "Deseja realmente executar a ação selecionada?",
    },
    PRIVACY_POLICY: {
      TITLE: "Política de Privacidade",
      OBJECTIVES: "Objetivos",
      OBJECTIVES_PARAGRAPH:
        "Esta política tem como objetivo esclarecer para o usuário como os dados pessoais utilizados no sistema Remuneratio da Gradus são tratados e para qual finalidade são coletados.Além disso, também é objetivo dessa política informar como o usuário pode exercer os seus direitos relativos aos seus dados.",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS: "Sobre a empresa e seus contratos",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_1:
        "A Gradus é uma empresa de consultoria, tecnologia e analytics que possui mais de 20 anos de experiência em consultoria de gestão e que transforma metodologias comprovadas em soluções que aumentam drasticamente a eficiência de nossos clientes.",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_2:
        "Dúvidas, informações sobre como tratamos os dados pessoais, para solicitar os dados presentes nos sistemas, ou para solicitar a exclusão de seus dados entre em contato por e-mail através de dpo@gradustech.com.br. Para quaisquer outras dúvidas utilize suporte@gradustech.com.br ou ligue para (11) 3167-2542.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM:
        "Quais dados o usuário não deve incluir ao sistema",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_1:
        "Para que a análise no Remuneratio seja operacional, eficaz e apresente a comparação entre as informações do cliente e do mercado, no Módulo de Comparação é solicitado o upload da folha de pagamento do cliente.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_2:
        "Visando maior segurança dos dados pessoais do cliente, solicitamos que dados como nome, CPF, endereço, sexo, faixa etária e quaisquer informações sensíveis ou que identifiquem o funcionário sejam excluídas da planilha anteriormente ao upload.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_3:
        "De qualquer modo, após definição do mapeamento de quais informações serão utilizadas na comparação, o sistema mantém apenas os dados selecionados pelo usuário, com único propósito de acesso às informações pelo mesmo em sua análise.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_4:
        "Logo, a Gradus não armazena nenhum dado pessoal presente na folha de pagamento que permita associar as informações de remuneração com a pessoa física.",
      WHY_DO_WE_COLLECT_PERSONAL_DATA: "Porque coletamos dados pessoais",
      WHY_DO_WE_COLLECT_PERSONAL_DATA_PARAGRAPH_1:
        "Os dados pessoais do usuário são coletados exclusivamente para uso da Gradus para permitir:",
      WHY_DO_WE_COLLECT_PERSONAL_DATA_LIST:
        "<li>O uso do sistema, de forma a possibilitar que os usuários possam explorar suas funcionalidades, como por exemplo, o Módulo de Comparação</li><li>Garantir a segurança dos dados do usuário</li><li>Investigar e corrigir problemas encontrados pelos usuários ou pela equipe da Gradus</li><li>Criar novas funcionalidades e melhorar as que já existem de forma a evoluir a experiência do usuário</li><li>Permitir a comunicação de atualizações, serviços de suporte, alertas e notificações ao usuário</li><li>Cumprir obrigações legais e contratuais</li>",
      WHICH_DATA_DO_WE_COLLECT: "Quais dados coletamos",
      WHICH_DATA_DO_WE_COLLECT_LIST:
        "<li>Dados presentes na folha de pagamento inserida pelo usuário, incluindo remuneração, cargos, localização, tamanho da empresa, CNAE e demais parâmetros que usuário considerar adequado para compor a análise</li><li>Dados de uso: são coletadas informações sobre sua navegação em nosso site, quais conteúdos e relatórios foram acessados</li><li>Informações técnicas: são registrados todos os acessos por usuário na plataforma como data e hora do acesso, endereço IP, geolocalização, tipo de sistema operacional, versão do browser e a preferência de idioma</li><li>Informações salvas diretamente pelo usuário no Sistema, como mapeamentos e análises salvas, são armazenadas com único propósito de acesso às informações pelo usuário</li>",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA:
        "Por quanto tempo armazenamos os dados",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_1:
        "Os dados coletados são armazenados enquanto necessário para garantir o cumprimento dos objetivos pelos quais eles foram coletados ou para cumprir obrigações legais e contratuais.",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_2:
        "Conforme mencionado, os dados que não forem selecionados na etapa de mapeamento das informações, serão excluídos do sistema quando a parametrização for aplicada. Já os dados que forem mapeados e as análises salvas poderão ser excluídas pelo usuário diretamente no sistema.",
      REGARDING_DATA_TRANSFER: "Quanto à transferência de dados",
      REGARDING_DATA_TRANSFER_PARAGRAPH_1:
        "Todos os dados coletados são utilizados apenas pela Gradus e pelo cliente sem compartilhamento com terceiros, com exceção dos serviços básicos para o atendimento ao cliente (CRM, e-mail e treinamento), serviços de análise de dados de navegação e infraestrutura dos sistemas que está localizada nos Estados Unidos.",
      WHAT_ARE_THE_USER_RIGHTS: "Quais os direitos do usuário",
      WHAT_ARE_THE_USER_RIGHTS_LIST:
        "<li>Confirmar a existência de tratamento de seus dados pessoais</li><li>Acessar seus dados pessoais</li><li>Corrigir dados pessoais incompletos, inexatos ou desatualizados</li><li>Anonimização, bloqueio ou eliminação de dados pessoais desnecessários, excessivos ou tratados em desconformidade com a LGPD</li><li>Portabilidade de dados pessoais a outro fornecedor de produto ou serviço</li><li>Eliminação de dados tratados com o seu consentimento</li><li>Obtenção de informações sobre as entidades públicas e privadas com as quais o controlador realizou o compartilhamento de dados pessoais</li><li>Obtenção de informações sobre a possibilidade de não consentir com o tratamento de dados pessoais e sobre as consequências da negativa</li>",
      WHAT_SECURITY_MEASURES_ARE_TAKEN: "Quais as medidas de segurança tomadas",
      WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_1:
        "As medidas de segurança utilizadas pela Gradus como, por exemplo, backup, firewall e antivírus, são realizadas de forma a garantir que os dados dos usuários estejam sempre protegidos, tal que apenas membros autorizados da equipe da Gradus possuam acesso direto à infraestrutura dos sistemas.",
      WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_2:
        "Além de toda a estrutura de segurança implementada pela Gradus, também são realizados testes massivos de segurança por auditorias terceiras renomadas.",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY:
        "Da revisão e atualização da política de controle de acesso",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_1:
        "O documento é válido a partir de 02/05/2022 e caso ocorra alguma alteração na política de privacidade, as informações contidas neste documento serão atualizadas.",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_2:
        "A Política de Privacidade deverá ser revista e atualizada, ao menos anualmente, com a intenção de manter a sintonia com as regras de negócios, com as melhores práticas do mercado, leis, regulamentos e demais aspectos.",
      FOOTER_LINK: "Política de Privacidade",
    },
    BACKEND_MESSAGES: {
      ANALYSIS_DOES_NOT_EXIST: "A análise não existe",
      FIELD_NAME_IS_REQUIRED: "O campo Nome é obrigatório",
      FIELD_SALARY_IS_REQUIRED: "O campo Salário é obrigatório",
      FIELD_JOB_IS_REQUIRED: "O campo Cargo (CBO) é obrigatório",
      FIELD_COMPANY_IS_REQUIRED: "O campo CNAE é obrigatório",
      FIELD_STATE_IS_REQUIRED_WHEN_MAPPING_COUNTIES:
        "O campo Estado é obrigatório ao mapear o campo Município",
      FIELD_CONFIGURATION_IS_REQUIRED: "O campo Configuração é obrigatório",
      FIELD_COMPANY_SIZE_IS_REQUIRED:
        "O campo Tamanho da empresa é obrigatório",
      INVALID_VALUE_FOR_COMPANY_SIZE:
        "Valor inválido para o campo Tamanho da Empresa",
      INVALID_VALUE_FOR_CONFIGURATION:
        "Valor inválido para o campo Configuração",
      YOU_SHOULD_ACCEPT_THE_INFORMATION_SECURITY_POLICY_TO_USE_THE_SYSTEM:
        "Você deve aceitar a política de segurança da informação para utilizar o sistema",
      FILTER_NAME_IS_REQUIRED: "O nome do filtro é obrigatório",
      FILTER_DOES_NOT_EXIST: "O filtro não existe",
      FILTER_SHOULD_BE_RELATED_TO_AN_ANALYSIS:
        "É necessário o filtro estar atrelado a uma análise",
    },
    GENERAL: {
      CANCEL: "Cancelar",
    },
    GRADUS_DOUBLE_LIST_FIELD_SELECTOR: {
      TO_INCLUDE: "Incluídos",
      TO_EXCLUDE: "Excluídos",
      DROP_THE_DESIRED_ITEM_HERE: "Arraste aqui o item desejado",
    },
    RELAXATION_SELECTOR: {
      FILTERS_RELAXATION: "Relaxamento de filtros",
      EXCLUDED_FILTERS: "Filtros excluídos do relaxamento",
      INCLUDED_FILTERS: "Filtros incluídos no relaxamento",
      EXPLANATION_TOOLTIP:
        "O relaxamento busca, para cada dupla de ocupação e município filtrados na folha de pagamentos, aumentar a significância estatística da amostra de mercado para o mínimo de {{quantity}} empregados por meio da ampliação do filtro de geografia",
      RELAXATION_NOT_AVAILABLE_FOR_PAYROLLS_WITHOUT_COUNTIES:
        "O relaxamento não está disponível para folhas de pagamento sem município",
    },
    COMPANY_SIZE_FILTER_TAG: {
      BETWEEN_X_AND_Y_EMPLOYEES: "De {{from}} até {{to}} funcionários",
      UPTO_X_EMPLOYEES: "Até {{to}} funcionários",
      FROM_X_EMPLOYEE: "A partir de {{from}} funcionário",
      FROM_X_EMPLOYEES: "A partir de {{from}} funcionários",
      ANY_NUMBER_OF_EMPLOYEES: "Qualquer número de funcionários",
    },
  },
};
