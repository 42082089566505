import React, {
  FormEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import SelectParameterMapping from "./SelectParameterMapping";
import payrollConfigurationsApi from "../../apis/payrollConfigurationsApi";
import companySubclassApi from "../../apis/companySubclassApi";
import StringUtils from "../../utils/StringUtils";
import AlertContext from "../../contexts/AlertContext";
import { OPTIONS } from "../../models/CompanySize";
import GradusTextField from "../GradusComponents/GradusTextField";
import GradusModal from "../GradusComponents/GradusModal/GradusModal";
import { handleApiError } from "../../utils/ErrorUtils";
import PayrollConfigurationUpdateDTO from "../../dtos/PayrollConfiguration/PayrollConfigurationUpdateDTO";
import FilterOptionDTO from "../../dtos/FilterOptionDTO";
import GradusSupportButton from "../Buttons/GradusSupportButton";
import PayrollConfigurationDTO from "../../dtos/PayrollConfiguration/PayrollConfigurationDTO";
import ConfigurationView from "../../dtos/PayrollConfiguration/ConfigurationView";
import ExcelColumn from "./ExcelColumn";
import GradusButton from "../Buttons/GradusButton";

const UpdateMappingModal = ({
  columns,
  handleClose,
  isOpen,
  onSuccessfulUpdate,
  mapping,
}: {
  columns: ExcelColumn[];
  handleClose: () => void;
  isOpen: boolean;
  onSuccessfulUpdate?: () => void;
  mapping: PayrollConfigurationDTO | null | undefined;
}) => {
  const { t } = useTranslation();

  const { fireAlert } = useContext(AlertContext);

  const [selectedCNAEs, setSelectedCNAEs] = useState<FilterOptionDTO[]>([]);
  const [CNAEs, setCNAEs] = useState<FilterOptionDTO[]>([]);
  const [tabInfo, setTabInfo] = useState<ConfigurationView>(
    ConfigurationView.buildDefault(mapping?.companySize || 1)
  );

  const closeModal = useCallback(() => {
    setSelectedCNAEs([]);
    setCNAEs([]);
    setTabInfo(ConfigurationView.buildDefault(1));

    handleClose();
  }, [handleClose]);

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length <= 64) {
        setTabInfo(
          (prevTabInfo) =>
            new ConfigurationView({
              ...prevTabInfo,
              name: event.target.value,
            })
        );
      }
    },
    [setTabInfo]
  );

  const handleUpdateMapping: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault();

      const payload = PayrollConfigurationUpdateDTO.fromView(
        new ConfigurationView({
          ...mapping,
          ...tabInfo,
          cnaes: selectedCNAEs,
        })
      );

      try {
        await payrollConfigurationsApi.updateConfiguration(payload);
        closeModal();
        fireAlert(t("ALERTS.SUCCESSFULLY_EDITED_MAPPING"));
        onSuccessfulUpdate?.();
      } catch (error) {
        handleApiError(error, fireAlert, t);
      }
    },
    [
      mapping,
      tabInfo,
      selectedCNAEs,
      closeModal,
      fireAlert,
      onSuccessfulUpdate,
      t,
    ]
  );

  const handleOpen = useCallback(
    () => {
      companySubclassApi.getAll().then(({ data }) => {
        setCNAEs(data);
      });
      setSelectedCNAEs(mapping?.cnaes || []);
      setTabInfo(
        mapping
          ? ConfigurationView.fromDTO(mapping)
          : ConfigurationView.buildDefault(1)
      );
    },
    [mapping],
  )

  if (_.isEmpty(mapping)) {
    return null;
  }

  return (
    <GradusModal handleClose={closeModal} open={isOpen} handleOpen={handleOpen}>
      <Stack>
        <Typography variant="h5">{t("EDIT_MAPPING")}</Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {t("CREATE_MAPPING_MODAL.DESCRIPTION")}
        </Typography>
      </Stack>
      <Stack gap={2}>
        <form onSubmit={handleUpdateMapping}>
          <Stack direction="row" gap={2}>
            <GradusTextField
              id="payroll-configuration-name"
              label={t("MAPPING_NAME")}
              value={tabInfo.name}
              onChange={handleNameChange}
              sx={{ width: "50%" }}
              required
            />
            <Autocomplete
              disablePortal
              size="small"
              options={OPTIONS}
              getOptionLabel={({ name }) => t(name)}
              noOptionsText={t("NO_OPTIONS")}
              sx={{ width: "50%" }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(_event, newValue: FilterOptionDTO) =>
                setTabInfo(
                  new ConfigurationView({
                    ...tabInfo,
                    companySize: newValue,
                  })
                )
              }
              value={tabInfo.companySize}
              renderInput={(params) => (
                <GradusTextField
                  {...params}
                  label={t("COMPANY_SIZE")}
                  required
                />
              )}
            />
          </Stack>

          <Autocomplete
            disablePortal
            size="small"
            noOptionsText={t("NO_OPTIONS")}
            value={selectedCNAEs}
            options={CNAEs}
            filterSelectedOptions
            onChange={(_event, selected) => setSelectedCNAEs(selected)}
            sx={{ width: "100%", my: 2 }}
            loading={CNAEs.length <= 0}
            loadingText={`${t("LOADING")}...`}
            renderInput={(params) => (
              <GradusTextField
                {...params}
                label={t("COMPANY_CNAES")}
                required
                inputProps={{
                  ...params.inputProps,
                  required: _.isEmpty(selectedCNAEs),
                }}
              />
            )}
            renderTags={(value: FilterOptionDTO[], getTagProps) =>
              value.map((option: FilterOptionDTO, index) => (
                <Chip
                  variant="outlined"
                  label={`${StringUtils.formatCNAE(option.id)} - ${option.name
                    }`}
                  {...getTagProps({ index })}
                />
              ))
            }
            getOptionLabel={(CNAE) =>
              `${StringUtils.formatCNAE(CNAE.id)} - ${CNAE.name}`
            }
            multiple
          />

          <SelectParameterMapping
            columns={columns}
            tabInfo={tabInfo}
            setTabInfo={setTabInfo}
            disabled={mapping?.isUsed || false}
          />

          <Stack direction="row-reverse" gap={2}>
            <GradusButton
              variant="primary"
              colorVariant="primary"
              buttonProps={{ type: "submit" }}
            >
              {t("SAVE")}
            </GradusButton>
            <GradusSupportButton onClick={() => closeModal()}>
              {t("CANCEL")}
            </GradusSupportButton>
          </Stack>
        </form>
      </Stack>
    </GradusModal>
  );
};

UpdateMappingModal.defaultProps = {
  onSuccessfulUpdate: _.noop,
};

export default UpdateMappingModal;
