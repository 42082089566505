import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import { SxProps, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilterOptionDTO from "../../dtos/FilterOptionDTO";

const StyledChip = styled(Chip)(() => ({
  maxWidth: "150px",
  height: "28px",
}));

export function AutocompleteChip({
  values,
}: {
  values: string[] | FilterOptionDTO[];
}) {
  const [label, setLabel] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const first = values[0];
    let newTitle: FilterOptionDTO[];

    if (_.isString(first)) {
      const stringValues = values as string[];
      setTitle(stringValues.join("\n"));
      setLabel(first);
    } else {
      const filterOptionValues = values as FilterOptionDTO[];
      const withoutFirst = filterOptionValues.slice(1);
      if (withoutFirst.length > 10) {
        newTitle = withoutFirst.slice(0, 10);
        newTitle.push(
          new FilterOptionDTO({
            id: -1,
            name: `+${withoutFirst.length - newTitle.length}`,
          })
        );
      } else {
        newTitle = withoutFirst;
      }
      setTitle(newTitle.map((v) => v.name).join("\n"));
      setLabel(first.name);
    }
  }, [values]);

  if (values.length <= 1) {
    return <StyledChip title={label} label={label} />;
  }

  return (
    <Stack direction="column" gap={0.5}>
      <StyledChip title={label} label={label} />
      <span>
        <StyledChip title={title} label={`+${values.length - 1}`} />
      </span>
    </Stack>
  );
}

AutocompleteChip.propTypes = {
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.instanceOf(FilterOptionDTO)),
  ]).isRequired,
};

export default function GradusAutocomplete({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  sx,
  required,
  addSelectAll,
  handleSelectAll,
  ...props
}: {
  options: FilterOptionDTO[];
  selectedOptions: FilterOptionDTO[];
  setSelectedOptions: (newOptions: FilterOptionDTO[]) => void;
  label: string;
  sx?: SxProps;
  required?: boolean;
  addSelectAll?: boolean;
  handleSelectAll?: () => void;
}) {
  const { t } = useTranslation();

  const selectAllField = new FilterOptionDTO({
    id: -1,
    name: t("SELECT_ALL") as string,
  });

  return (
    <Autocomplete
      noOptionsText={t("NO_OPTIONS")}
      multiple
      disablePortal
      size="small"
      value={selectedOptions}
      onChange={(_event, newValue) => {
        const findSelectAllField = _.find(
          newValue,
          (item) => item.id === selectAllField.id
        );
        if (!_.isEmpty(findSelectAllField)) {
          handleSelectAll?.();
        } else {
          setSelectedOptions(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} />
      )}
      renderOption={(renderOptionProps, { id, name }) => (
        <li {...renderOptionProps} key={id}>
          {name}
        </li>
      )}
      {...props}
      sx={{ pt: 1, ...sx }}
      options={addSelectAll ? [selectAllField, ...options] : options}
      renderTags={(values: FilterOptionDTO[] | string[]) => (
        <AutocompleteChip values={values} />
      )}
    />
  );
}

GradusAutocomplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  isOptionEqualToValue: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  required: PropTypes.bool,
  addSelectAll: PropTypes.bool,
  handleSelectAll: PropTypes.func,
};

GradusAutocomplete.defaultProps = {
  required: false,
  sx: {},
  addSelectAll: false,
  handleSelectAll: _.noop,
};
