const data = {
  Acre: { abbr: "AC", region: "Norte" },
  Alagoas: { abbr: "AL", region: "Nordeste" },
  Amapá: { abbr: "AP", region: "Norte" },
  Amazonas: { abbr: "AM", region: "Norte" },
  Bahia: { abbr: "BA", region: "Nordeste" },
  Ceará: { abbr: "CE", region: "Nordeste" },
  "Distrito Federal": { abbr: "DF", region: "Centro-Oeste" },
  "Espírito Santo": { abbr: "ES", region: "Sudeste" },
  Goiás: { abbr: "GO", region: "Centro-Oeste" },
  Maranhão: { abbr: "MA", region: "Nordeste" },
  "Mato Grosso": { abbr: "MT", region: "Centro-Oeste" },
  "Mato Grosso do Sul": { abbr: "MS", region: "Centro-Oeste" },
  "Minas Gerais": { abbr: "MG", region: "Sudeste" },
  Pará: { abbr: "PA", region: "Norte" },
  Paraíba: { abbr: "PB", region: "Nordeste" },
  Paraná: { abbr: "PR", region: "Sul" },
  Pernambuco: { abbr: "PE", region: "Nordeste" },
  Piauí: { abbr: "PI", region: "Nordeste" },
  Roraima: { abbr: "RR", region: "Norte" },
  Rondônia: { abbr: "RO", region: "Norte" },
  "Rio de Janeiro": { abbr: "RJ", region: "Sudeste" },
  "Rio Grande do Norte": { abbr: "RN", region: "Nordeste" },
  "Rio Grande do Sul": { abbr: "RS", region: "Sul" },
  "Santa Catarina": { abbr: "SC", region: "Sul" },
  "São Paulo": { abbr: "SP", region: "Sudeste" },
  Sergipe: { abbr: "SE", region: "Nordeste" },
  Tocantins: { abbr: "TO", region: "Norte" },
};

export default class State {
  static getStateRegion(stateName) {
    return data[stateName].region;
  }

  static getStateAbbr(stateName) {
    return data[stateName]?.abbr;
  }
}
