import Histogram from "../../calculators/Histogram";
import SalaryAverageAndQuantity from "../../dtos/SalaryAverageAndQuantity";
import Chart from "../../components/Charts/Chart";
import MarketCompanyTuple from "./MarketCompanyTuple";
import MarketCompanyWithDifference from "./MarketCompanyWithDifference";

export default class ChartsStorer {
  private employeesBySalaryRangeCompany: Histogram;

  private employeesBySalaryRangeMarket: Histogram;

  private employeesBySalaryRangeBoth: Histogram;

  private salaryRangeByState: MarketCompanyTuple<
    Record<string, SalaryAverageAndQuantity>
  >;

  private salaryRangeByCompanySize: MarketCompanyTuple<
    Record<string, SalaryAverageAndQuantity>
  >;

  private salaryAverageByOccupation: MarketCompanyWithDifference<
    Record<string, SalaryAverageAndQuantity>
  >;

  // FIXME: corrigir esses ts-ignore quando aplicar tipagem à classe Histogram
  constructor({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    employeesBySalaryRangeCompany = new Histogram({}),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    employeesBySalaryRangeMarket = new Histogram({}),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    employeesBySalaryRangeBoth = new Histogram({}),
    salaryRangeByState = new MarketCompanyTuple({}),
    salaryRangeByCompanySize = new MarketCompanyTuple({}),
    salaryAverageByOccupation = new MarketCompanyWithDifference({}),
  }: {
    employeesBySalaryRangeCompany?: Histogram;
    employeesBySalaryRangeMarket?: Histogram;
    employeesBySalaryRangeBoth?: Histogram;
    salaryRangeByState?: MarketCompanyTuple<
      Record<string, SalaryAverageAndQuantity>
    >;
    salaryRangeByCompanySize?: MarketCompanyTuple<
      Record<string, SalaryAverageAndQuantity>
    >;
    salaryAverageByOccupation?: MarketCompanyWithDifference<
      Record<string, SalaryAverageAndQuantity>
    >;
  }) {
    this.employeesBySalaryRangeCompany = employeesBySalaryRangeCompany;
    this.employeesBySalaryRangeMarket = employeesBySalaryRangeMarket;
    this.employeesBySalaryRangeBoth = employeesBySalaryRangeBoth;
    this.salaryRangeByState = salaryRangeByState;
    this.salaryRangeByCompanySize = salaryRangeByCompanySize;
    this.salaryAverageByOccupation = salaryAverageByOccupation;
  }

  getChartData(
    chart: Chart
  ):
    | Histogram
    | MarketCompanyTuple<Record<string, SalaryAverageAndQuantity>>
    | MarketCompanyWithDifference<Record<string, SalaryAverageAndQuantity>> {
    switch (chart) {
      case Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH:
        return this.employeesBySalaryRangeBoth;
      case Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY:
        return this.employeesBySalaryRangeCompany;
      case Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET:
        return this.employeesBySalaryRangeMarket;
      case Chart.SALARY_RANGE_BY_STATE:
        return this.salaryRangeByState;
      case Chart.SALARY_RANGE_BY_COMPANY_SIZE:
        return this.salaryRangeByCompanySize;
      case Chart.SALARY_AVERAGE_BY_OCCUPATION:
        return this.salaryAverageByOccupation;
      default:
        return new MarketCompanyTuple<Record<string, SalaryAverageAndQuantity>>(
          {}
        );
    }
  }

  private setChartData(
    chart: Chart,
    value:
      | Histogram
      | Partial<MarketCompanyTuple<Record<string, SalaryAverageAndQuantity>>>
      | Partial<
          MarketCompanyWithDifference<Record<string, SalaryAverageAndQuantity>>
        >
  ) {
    switch (chart) {
      case Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH:
        this.employeesBySalaryRangeBoth = value as Histogram;
        break;
      case Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY:
        this.employeesBySalaryRangeCompany = value as Histogram;
        break;
      case Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET:
        this.employeesBySalaryRangeMarket = value as Histogram;
        break;
      case Chart.SALARY_RANGE_BY_STATE:
        this.salaryRangeByState = value as MarketCompanyTuple<
          Record<string, SalaryAverageAndQuantity>
        >;
        break;
      case Chart.SALARY_RANGE_BY_COMPANY_SIZE:
        this.salaryRangeByCompanySize = value as MarketCompanyTuple<
          Record<string, SalaryAverageAndQuantity>
        >;
        break;
      case Chart.SALARY_AVERAGE_BY_OCCUPATION:
        this.salaryAverageByOccupation = value as MarketCompanyWithDifference<
          Record<string, SalaryAverageAndQuantity>
        >;
        break;
      default:
        break;
    }
  }

  static addChartData(
    storer: ChartsStorer,
    chart: Chart,
    value:
      | Histogram
      | Partial<MarketCompanyTuple<Record<string, SalaryAverageAndQuantity>>>
      | Partial<
          MarketCompanyWithDifference<Record<string, SalaryAverageAndQuantity>>
        >
  ) {
    storer.setChartData(chart, value);
    return new ChartsStorer({ ...storer });
  }
}
