import { devtools } from "zustand/middleware";
import { create } from "zustand";
import Chart, { getAll } from "../../components/Charts/Chart";

export interface ChartLoadingStoreProps extends Record<Chart, boolean> {
  setIsLoading: (chart: Chart, isLoading: boolean) => void;
  reset: () => void;
}

const initialState = getAll().reduce((acc, chart) => {
  acc[chart] = true;
  return acc;
}, {} as Record<Chart, boolean>);

const useChartLoadingStore = create<ChartLoadingStoreProps>()(
  devtools(
    (set) => ({
      ...initialState,

      setIsLoading: (chart: Chart, isLoading: boolean) => {
        set({ [chart]: isLoading });
      },

      reset: () => {
        set({ ...initialState });
      },
    }),
    { name: "ChartLoadingStore" }
  )
);

export default useChartLoadingStore;
