/* eslint-disable max-len */
/* eslint-disable max-lines */
import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import {List} from "react-virtualized";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import {Checkbox, FormControlLabel, FormGroup, Typography,} from "@mui/material";
import {useTranslation} from "react-i18next";
import OutsideClicked from "../../OutsideClicked/OutsideClicked";
import SortTableItems from "./SortTableItems";
import {useGradusTableStore} from "./GradusTableStore";
import GradusTableColumn from "./GradusTableColumn";

const filterLabelStyle = {
  color: "#516374",
  fontSize: "12px",
};

const filterTextInputStyle = {
  borderRadius: "0px",
  boxShadow: "inset -1px 1px 1px 0 #f0f0f0",
  border: "1px solid #E5E5E5",
  borderColor: "#ddd",
  backgroundColor: "#fff",
  marginBottom: "10px",
  marginTop: "10px",
  width: "100%",
  lineHeight: "20px",
};

export default function FilterableHeader({
  closeFilter,
  showFilterForColumn,
  setShowFilterForColumn,
  isNumericValue,
  onSelectedFilterChange,
  column,
  storeName,
  isLast,
}) {
  const { t } = useTranslation();

  const { possibleFilters } = useGradusTableStore(storeName);
  const { selectedFilters } = useGradusTableStore(storeName);
  const { toggleFilterValue } = useGradusTableStore(storeName);
  const { selectAllForField } = useGradusTableStore(storeName);
  const { sortFilteredRows } = useGradusTableStore(storeName);
  const { addSelectedFilterValues } = useGradusTableStore(storeName);
  const { clearFilters } = useGradusTableStore(storeName);
  const { shownFilters } = useGradusTableStore(storeName);
  const { addShownFilterValues } = useGradusTableStore(storeName);
  const { cancelFiltering } = useGradusTableStore(storeName);

  const [filterTextValue, setFilterTextValue] = useState("");

  const handleFilterTextValueChange = useCallback(
    (event) => {
      const itemName = event.target.value;
      setFilterTextValue(itemName);

      let toShow = possibleFilters[column.field];
      if (!_.isEmpty(itemName)) {
        toShow = toShow.filter((item) =>
          column
            .renderFilter(item.toLowerCase())
            .includes(itemName.toLowerCase())
        );
      }

      const newValues = [...new Set(toShow)];
      addShownFilterValues(column.field, newValues);
      addSelectedFilterValues(column.field, newValues);
    },
    [addSelectedFilterValues, addShownFilterValues, column, possibleFilters]
  );

  const rowRenderer = useCallback(
    ({ style, index }) => {
      const aux = Immutable.get(shownFilters[column.field], index);
      const item = aux === undefined ? "undefined" : aux.toString();

      const renderedItem = column.renderFilter(item);

      return (
        <FormControlLabel
          sx={style}
          control={
            <Checkbox
              id={`itemToFilter-${item}`}
              checked={selectedFilters[column.field].includes(item)}
              onChange={() => toggleFilterValue(column.field, item)}
              data-testid={`filter-checkbox-${item}`}
              size="small"
            />
          }
          label={
            <Typography
              component="p"
              title={renderedItem}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {renderedItem}
            </Typography>
          }
        />
      );
    },
    [column, shownFilters, selectedFilters, toggleFilterValue]
  );

  const isAllItemsSelected = useCallback(
    () => !_.isEmpty(selectedFilters[column.field]),
    [selectedFilters, column.field]
  );

  const handleFilterSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onSelectedFilterChange(column.field);
      setFilterTextValue("");
      closeFilter();
    },
    [onSelectedFilterChange, column.field, closeFilter]
  );

  const handleFilterCancel = useCallback(() => {
    setFilterTextValue("");
    cancelFiltering();
    closeFilter();
  }, [cancelFiltering, closeFilter]);

  const handleFilterClear = useCallback(() => {
    clearFilters();
    setFilterTextValue("");
  }, [clearFilters]);

  const handleSort = useCallback(
    (isDescending) => {
      sortFilteredRows(column.field, isDescending);
    },
    [column.field, sortFilteredRows]
  );

  useEffect(() => {
    addShownFilterValues(possibleFilters[column.field]);
  }, [addShownFilterValues, column.field, possibleFilters]);

  return (
    <TableCell
      align={column.headerAlign}
      sx={{
        fontWeight: "bold",
        backgroundColor: "#456bb0",
        color: "#FFF",
        height: "35px",
      }}
    >
      {column.headerLabel}
      {column.hasFilter && (
        <button
          aria-label="Abrir filtros"
          type="button"
          onClick={() => setShowFilterForColumn(column.field)}
          data-testid={`open-filter-${column.field}`}
          style={{
            padding: "0",
            border: "none",
            background: "none",
            marginLeft: "5px",
          }}
        >
          <FontAwesomeIcon
            icon={faFilter}
            style={{ fontSize: "13px", color: "#FFFFFF" }}
          />
        </button>
      )}
      {showFilterForColumn === column.field && (
        <OutsideClicked onOutsideClick={handleFilterCancel}>
          <Paper
            sx={{
              zIndex: "99",
              position: "absolute",
              padding: "10px",
              ...(isLast ? { right: "1%" } : {}),
            }}
            elevation={4}
          >
            <form onSubmit={handleFilterSubmit}>
              <SortTableItems
                isNumericValue={isNumericValue}
                sortAscOrder={() => handleSort(false)}
                sortDescOrder={() => handleSort(true)}
              />
              <label htmlFor="filterValue" style={filterLabelStyle}>
                Filtrar por valores:
                <div>
                  <input
                    data-testid="filter-input-text"
                    id="filterValue"
                    type="text"
                    value={filterTextValue}
                    onChange={handleFilterTextValueChange}
                    style={filterTextInputStyle}
                    autoComplete="off"
                  />
                </div>
              </label>
              <List
                height={200}
                rowCount={shownFilters[column.field].length}
                rowHeight={20}
                rowRenderer={rowRenderer}
                width={200}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`allItems-${column.field}`}
                      checked={isAllItemsSelected()}
                      onChange={(event) => {
                        selectAllForField(
                          column.field,
                          event.currentTarget.checked
                        );
                      }}
                      data-testid="filter-all"
                      size="small"
                    />
                  }
                  label={t("ALL")}
                />
              </FormGroup>
              <Stack
                disablegutters="true"
                sx={{ mt: "10px" }}
                direction="row-reverse"
                gap={1}
              >
                <button
                  disabled={_.isEmpty(selectedFilters[column.field])}
                  className="btn btn-primary"
                  type="submit"
                >
                  {t("APPLY")}
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={handleFilterCancel}
                >
                  {t("GENERAL.CANCEL")}
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={handleFilterClear}
                >
                  {t("CLEAR")}
                </button>
              </Stack>
            </form>
          </Paper>
        </OutsideClicked>
      )}
    </TableCell>
  );
}

FilterableHeader.propTypes = {
  closeFilter: PropTypes.func.isRequired,
  onSelectedFilterChange: PropTypes.func.isRequired,
  showFilterForColumn: PropTypes.string.isRequired,
  setShowFilterForColumn: PropTypes.func.isRequired,
  column: PropTypes.instanceOf(GradusTableColumn).isRequired,
  storeName: PropTypes.string.isRequired,
  isNumericValue: PropTypes.bool,
  isLast: PropTypes.bool,
};

FilterableHeader.defaultProps = {
  isNumericValue: false,
  isLast: false,
};
