import Typography from "@mui/material/Typography";
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Stack } from "@mui/material";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import GradusAutocomplete from "../../../GradusComponents/GradusAutocomplete";
import GradusTooltip from "../../../GradusComponents/GradusTooltip";
import InfoIcon from "../../../InfoIcon/InfoIcon";
import FilterOptionDTO from "../../../../dtos/FilterOptionDTO";
import BaseFilter from "../../../../dtos/BaseFilter";
import PayrollFilter from "../../../../dtos/PayrollFilter";
import MarketFilter from "../../../../dtos/MarketFilter";

const TooltipText = () => {
  const theme = useTheme();

  return (
    <div style={{ padding: theme.spacing(1) }}>
      <Typography sx={{ color: "white" }}>
        <Trans
          i18nKey="FILTER_MODAL.CBO_TOOLTIP"
          components={{ list: <ul />, item: <li />, bold: <strong /> }}
          style={{ marginTop: "100px" }}
        />
      </Typography>
    </div>
  );
};

function JobDataFilter({
  options,
  selectedFilters,
  setSelectedFilters,
  isMarketTab,
}: {
  options: BaseFilter;
  selectedFilters: BaseFilter;
  setSelectedFilters: (
    values: Partial<BaseFilter | PayrollFilter | MarketFilter>
  ) => void;
  isMarketTab?: boolean;
}) {
  const { t } = useTranslation();

  const handleAddOccupationsButton = () => {
    setSelectedFilters({
      largeGroups: [],
      primarySubgroups: [],
      subgroups: [],
      families: [],
      occupations: options.occupations,
    });
  };

  return (
    <Container disableGutters>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h6">{t("ROLE")}</Typography>
        <GradusTooltip title={<TooltipText />} placement="right">
          <span>
            <InfoIcon icon={solid("question-circle")} />
          </span>
        </GradusTooltip>
      </Stack>
      <Grid container justifyContent="space-between">
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("LARGE_GROUP")}
            setSelectedOptions={(largeGroups: FilterOptionDTO[]) => {
              setSelectedFilters({
                largeGroups,
                primarySubgroups: [],
                subgroups: [],
                families: [],
                occupations: [],
              });
            }}
            selectedOptions={selectedFilters?.largeGroups || []}
            options={options.largeGroups}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("PRIMARY_SUBGROUP")}
            setSelectedOptions={(primarySubgroups: FilterOptionDTO[]) => {
              setSelectedFilters({
                primarySubgroups,
                subgroups: [],
                families: [],
                occupations: [],
              });
            }}
            selectedOptions={selectedFilters?.primarySubgroups || []}
            options={options.primarySubgroups}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("SUBGROUP")}
            setSelectedOptions={(subgroups: FilterOptionDTO[]) => {
              setSelectedFilters({
                subgroups,
                families: [],
                occupations: [],
              });
            }}
            selectedOptions={selectedFilters?.subgroups || []}
            options={options.subgroups}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("FAMILY")}
            setSelectedOptions={(families: FilterOptionDTO[]) => {
              setSelectedFilters({
                families,
                occupations: [],
              });
            }}
            selectedOptions={selectedFilters?.families || []}
            options={options.families}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("OCCUPATION")}
            setSelectedOptions={(occupations: FilterOptionDTO[]) => {
              setSelectedFilters({ occupations });
            }}
            selectedOptions={selectedFilters?.occupations || []}
            options={options.occupations}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
            handleSelectAll={handleAddOccupationsButton}
            addSelectAll={!isMarketTab}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

JobDataFilter.defaultProps = {
  isMarketTab: false,
};

export default JobDataFilter;
