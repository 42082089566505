import React, { useContext } from "react";
import Stack from "@mui/material/Stack";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import AlertContext from "../../../../contexts/AlertContext";
import useFilterStore from "../../../../stores/FilterStore/useFilterStore";
import GradusIconButton from "../../../GradusComponents/GradusIconButton";
import useCompareAnalysisStore from "../../../../stores/useCompareAnalysisStore";

export default function FilterTabHeader({
  isOtherTabMarket,
}: {
  isOtherTabMarket?: boolean;
}) {
  const { fireAlert } = useContext(AlertContext);

  const { copyMarketFiltersToPayroll, copyPayrollFiltersToMarket } =
    useFilterStore((state) => ({
      copyMarketFiltersToPayroll: state.copyMarketFiltersToPayroll,
      copyPayrollFiltersToMarket: state.copyPayrollFiltersToMarket,
    }));

  const { analysis } = useCompareAnalysisStore((state) => ({
    analysis: state.analysis,
  }));

  const { t } = useTranslation();

  const otherTabName = t(isOtherTabMarket ? "MARKET" : "COMPANY").toLowerCase();

  const handleCopyFromCurrentToOther = () => {
    if (isOtherTabMarket) {
      copyPayrollFiltersToMarket(analysis.getId());
    } else {
      copyMarketFiltersToPayroll(analysis.getId());
    }
    fireAlert(t("ALERTS.SUCCESSFULLY_COPIED_FILTER"));
  };
  const handleCopyFromOtherToCurrent = () => {
    if (isOtherTabMarket) {
      copyMarketFiltersToPayroll(analysis.getId());
    } else {
      copyPayrollFiltersToMarket(analysis.getId());
    }
    fireAlert(t("ALERTS.SUCCESSFULLY_COPIED_FILTER"));
  };

  return (
    <Stack gap={1}>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="flex-end"
      >
        <GradusIconButton
          title={t("FILTER_MODAL.COPY_FILTER_FROM_CURRENT_TO_OTHER", {
            otherTabName,
          })}
          icon={regular("share-all")}
          onClick={handleCopyFromCurrentToOther}
        />
        <GradusIconButton
          title={t("FILTER_MODAL.COPY_FILTER_FROM_OTHER_TO_CURRENT", {
            otherTabName,
          })}
          icon={regular("share-all")}
          sx={{ transform: "rotate(180deg)" }}
          onClick={handleCopyFromOtherToCurrent}
        />
      </Stack>
      <Divider />
    </Stack>
  );
}
FilterTabHeader.defaultProps = {
  isOtherTabMarket: false,
};
