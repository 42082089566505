import { css } from "@emotion/css";

const selectColumnsFont = css`
  color: #000000 !important;
  font-size: 1rem !important;
  display: none;
`;

const selectColumnsFontBackground = css`
  color: rgba(53, 54, 57, 0.5) !important;
`;

export { selectColumnsFont, selectColumnsFontBackground };
