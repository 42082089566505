import _ from "lodash";
import BaseFilter from "../BaseFilter";
import MarketFilter from "../MarketFilter";
import ChartParameters from "../../stores/ChartParametersStore/ChartParameters";
import GlobalParameters from "../../stores/ChartParametersStore/GlobalParameters";

export default class ConsulteGetChartDTO {
  filters: MarketFilter;

  correctionIndex: number;

  shouldUseNewJobFilters: boolean;

  newJobFilters: BaseFilter;

  constructor({
    filters,
    correctionIndex,
    shouldUseNewJobFilters,
    newJobFilters,
  }: {
    filters: MarketFilter;
    correctionIndex: number;
    shouldUseNewJobFilters?: boolean;
    newJobFilters?: BaseFilter;
  }) {
    this.filters = filters.onlyWithIds();
    this.correctionIndex = correctionIndex;
    this.shouldUseNewJobFilters = shouldUseNewJobFilters || false;
    this.newJobFilters = (
      _.isEmpty(newJobFilters) ? new BaseFilter({}) : newJobFilters
    ).onlyWithIds();
  }

  static from(
    filters: MarketFilter,
    chartParameters: ChartParameters,
    globalParameters: GlobalParameters
  ): ConsulteGetChartDTO {
    return new ConsulteGetChartDTO({
      filters,
      correctionIndex: globalParameters.correctionIndex,
      shouldUseNewJobFilters: chartParameters.shouldUseNewJobFilters,
      newJobFilters: chartParameters.newJobFilters,
    });
  }
}
