import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../apis/api";
import GradusButton from "../../components/Buttons/GradusButton";
import AuthenticationWrapper from "./AuthenticationWrapper";
import PasswordTextField from "../../components/PasswordTextField/PasswordTextField";
import { setToken } from "../../hooks/useToken";
import sessionApi from "../../apis/sessionApi";
import withGradusLoading from "../../hocs/withGradusLoading";

const LoginContent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [reCaptchaPublicKey, setReCaptchaPublicKey] = useState("");
  const [showReCaptcha, setShowReCaptcha] = useState(false);
  const recaptchaRef = useRef();

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const consumeReCaptchaToken = useCallback(async (cb) => {
    const token = recaptchaRef.current?.getValue?.() || "";
    try {
      await cb(token);
    } finally {
      if (token.length > 0) {
        recaptchaRef.current?.reset?.();
      }
    }
  }, []);

  useEffect(() => {
    const getReCaptchaPublicKey = async () => {
      const response = await sessionApi.getReCaptchaPublicKey();
      setReCaptchaPublicKey(response.data);
    };

    getReCaptchaPublicKey();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setLoginMessage("");

    await consumeReCaptchaToken(async (token) => {
      api
        .post(`/api/v2/sessions/`, {
          usernameOrEmail: username,
          password,
          token,
        })
        .then(async (response) => {
          if (response.data?.token) {
            if (response.data.type === "AUTH") {
              localStorage.setItem(
                "remuneratio-bearer-token",
                response.data.token
              );
              navigate("/", {
                replace: true,
              });
            } else if (response.data.type === "TWO_FACTOR") {
              navigate("/two_factor", { replace: true });
              setToken(response.data.token);
            }
          }
        })
        .catch((error) => {
          if (error.response?.status === 429) {
            // TOO MANY REQUESTS
            setShowReCaptcha(true);
            setLoginMessage("LOGIN_PAGE.PLEASE_TICK_IM_NOT_A_ROBOT");
          } else {
            setLoginMessage("LOGIN_PAGE.INCORRECT_USERNAME_OR_PASSWORD");
          }
        })
        .finally(() => setIsLoading(false));
    });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const BoxWithLoading = useMemo(() => withGradusLoading(Box), []);
  return (
    <BoxWithLoading isLoading={isLoading}>
      <Grid item>
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: theme.palette.grey[700], fontWeight: "bold" }}
        >
          Remuneratio
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                type="username"
                error={loginMessage !== ""}
                placeholder={t("USER")}
                fullWidth
                name="username"
                variant="outlined"
                value={username}
                onChange={(event) => handleUsernameChange(event)}
                required
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={light("user")}
                        style={{ color: theme.palette.primary.main }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <PasswordTextField
                error={loginMessage !== ""}
                placeholder={t("PASSWORD")}
                fullWidth
                name="password"
                value={password}
                onChange={(event) => handlePasswordChange(event)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={light("lock")}
                        style={{ color: theme.palette.primary.main }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              {loginMessage !== "" && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  {t(loginMessage, { lng: language })}
                </div>
              )}
              {showReCaptcha && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={reCaptchaPublicKey}
                  style={{ marginBottom: "8px" }}
                />
              )}
              <GradusButton
                variant="primary"
                colorVariant="primary"
                buttonProps={{ type: "submit" }}
                sx={{ width: "300px", mb: 2 }}
              >
                {t("ACCESS")}
              </GradusButton>
              <Link
                href="#/forgot_password"
                underline="hover"
                sx={{
                  mt: 2,
                  fontSize: "14px",
                  color: "#999999",
                  fontWeight: "bold",
                }}
              >
                {t("REDEFINE_PASSWORD_FIRST_ACCESS")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BoxWithLoading>
  );
};

export default function Login() {
  return (
    <AuthenticationWrapper height="255px" width="300px">
      <LoginContent />
    </AuthenticationWrapper>
  );
}
