import { TooltipProps } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import isEmpty from "lodash/fp/isEmpty";

const GradusTooltipTitle = ({ title }: { title: ReactNode }) => {
  const theme = useTheme();
  return (
    <div style={{ padding: theme.spacing(1) }}>
      <Typography sx={{ color: "white" }}>{title}</Typography>
    </div>
  );
};

const GradusTooltip = ({ title, children, ...props }: TooltipProps) => {
  if (isEmpty(title)) {
    return children;
  }
  return (
    <Tooltip title={<GradusTooltipTitle title={title} />} arrow {...props}>
      {children}
    </Tooltip>
  );
};

export default GradusTooltip;
