import api from "./api";
import ChartOptionDTO from "../dtos/ChartOptionDTO";

const BASE_URL = "/api/v1/charts-options";

const chartsOptionsApi = {
  getChartsOptions: (isCompare: boolean): Promise<ChartOptionDTO[]> =>
    new Promise((resolve, reject) => {
      api
        .get(`${BASE_URL}/all?isCompare=${isCompare}`)
        .then((response) => {
          const options = response.data.map(
            (option: ChartOptionDTO) => new ChartOptionDTO({ ...option })
          );
          return resolve(options);
        })
        .catch(reject);
    }),

  getChartsDefaultOptions: (isCompare: boolean): Promise<ChartOptionDTO[]> =>
    new Promise((resolve, reject) => {
      api
        .get(`${BASE_URL}/defaults?isCompare=${isCompare}`)
        .then((response) => {
          const options = response.data.map(
            (option: ChartOptionDTO) => new ChartOptionDTO({ ...option })
          );
          return resolve(options);
        })
        .catch(reject);
    }),
};

export default chartsOptionsApi;
