import React, { Dispatch, ReactNode, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Chart from "../Chart";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import AlertContext from "../../../contexts/AlertContext";
import useChartParametersStore from "../../../stores/ChartParametersStore/useChartParametersStore";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import { handleApiError } from "../../../utils/ErrorUtils";
import GradusModal from "../../GradusComponents/GradusModal/GradusModal";
import GradusPrimaryButton from "../../Buttons/GradusPrimaryButton";
import GradusSupportButton from "../../Buttons/GradusSupportButton";

export default function ChartOptionsModal({
  isOpen,
  setIsOpen,
  chart,
  children,
  isCompare,
  analysis,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  chart: Chart;
  children: ReactNode;
  isCompare: boolean;
  analysis: AnalysisPresenter;
}) {
  const { t } = useTranslation();

  const { fireAlert } = useContext(AlertContext);

  const { applySelected, resetSelected, revertSelectedToApplied } =
    useChartParametersStore((state) => ({
      applySelected: state.applySelected,
      resetSelected: state.resetSelected,
      revertSelectedToApplied: state.revertSelectedToApplied,
    }));
  const { updateChart } = useChartStore();

  const applyOptions = () => {
    try {
      applySelected(chart);
      updateChart(isCompare, analysis, chart.valueOf());
      fireAlert(t("ALERTS.SUCCESSFULLY_APPLIED_OPTIONS"));
    } catch (e) {
      handleApiError(e, fireAlert, t);
    }
  };

  const setDefaultOptions = () => {
    resetSelected(chart);
  };

  const handleClose = () => {
    revertSelectedToApplied(chart);
    setIsOpen(false);
  };

  return (
    <GradusModal
      handleClose={handleClose}
      open={isOpen}
      containerStyle={{ width: "70%", py: 2 }}
    >
      <Typography variant="h5">{t("CHART_OPTIONS")}</Typography>

      <Divider sx={{ my: 2 }} />
      {children}

      <Box
        my={2}
        sx={{
          display: "flex",
          justifyItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <GradusPrimaryButton onClick={applyOptions} sx={{ my: 0, mx: "10px" }}>
          {t("APPLY")}
        </GradusPrimaryButton>

        <GradusSupportButton onClick={setDefaultOptions} sx={{ ml: "10px" }}>
          {t("BACK_TO_DEFAULTS")}
        </GradusSupportButton>
      </Box>
    </GradusModal>
  );
}
