import { ReactNode } from "react";

class ConfigurationPreviewView {
  id: string;

  system: string;

  payroll: string | ReactNode;

  constructor({
    id,
    system,
    payroll,
  }: {
    id: string;
    system: string;
    payroll: string | ReactNode;
  }) {
    this.id = id;
    this.system = system;
    this.payroll = payroll;
  }
}

export default ConfigurationPreviewView;
