export default class Metrics {
  quantity?: number;

  average?: number;

  median?: number;

  p10?: number;

  p90?: number;

  min?: number;

  max?: number;

  constructor({
    quantity = undefined,
    average = undefined,
    median = undefined,
    p10 = undefined,
    p90 = undefined,
    min = undefined,
    max = undefined,
  }) {
    this.quantity = quantity;
    this.average = average;
    this.median = median;
    this.p10 = p10;
    this.p90 = p90;
    this.min = min;
    this.max = max;
  }
}
