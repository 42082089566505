import React, { useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Autocomplete, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { handleApiError } from "../../utils/ErrorUtils";
import withGradusTooltip from "../../hocs/withGradusTooltip";
import AlertContext from "../../contexts/AlertContext";
import useDashboardVisualOptionsStore from "../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import withHideOnPresentationMode from "../../hocs/withHideOnPresentationMode";
import CompareAnalysisSavedFiltersPresenter from "../../dtos/CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersPresenter";
import useCompareAnalysisStore from "../../stores/useCompareAnalysisStore";

export default function SavedFilters() {
  const {
    changeFilter,
    createFilter,
    savedFilterOptions,
    getAllSavedFilters,
    analysis,
  } = useCompareAnalysisStore((state) => ({
    changeFilter: state.changeFilter,
    createFilter: state.createFilter,
    savedFilterOptions: state.savedFilterOptions,
    getAllSavedFilters: state.getAllSavedFilters,
    analysis: state.analysis,
  }));

  const [currentFilter, setCurrentFilter] = useState<
    CompareAnalysisSavedFiltersPresenter | undefined
  >();

  const { fireAlert } = useContext(AlertContext);

  const { t, i18n } = useTranslation();

  const { isPresentationMode } = useDashboardVisualOptionsStore((state) => ({
    isPresentationMode: state.isPresentationMode,
  }));

  const IconWithTooltip = useMemo(
    () =>
      withGradusTooltip(FontAwesomeIcon, {
        defaultTooltipProps: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style: {
            alignSelf: "center",
            fontSize: "24px",
          },
        },
      }),
    []
  );

  const handleCreateFilter = async (): Promise<void> => {
    try {
      await createFilter();
      await getAllSavedFilters();
      fireAlert(
        t("ALERTS.SUCCESSFULLY_CREATED_FILTER", { lng: i18n.language }),
        false
      );
    } catch (e) {
      handleApiError(e, fireAlert, t);
    }
  };

  const changeCurrentFilter = async (
    newActiveFilter: CompareAnalysisSavedFiltersPresenter
  ) => {
    await changeFilter(newActiveFilter.getId());
    setCurrentFilter(newActiveFilter);
  };

  useEffect(() => {
    const foundValue = _.find(
      savedFilterOptions,
      (value) => value.getId() === analysis.getActiveFilterId()
    );
    if (foundValue) {
      setCurrentFilter(foundValue);
    } else if (savedFilterOptions && savedFilterOptions.length > 0) {
      setCurrentFilter(savedFilterOptions[0]);
    }
  }, [analysis, savedFilterOptions]);

  const HideOnPresentationMode = withHideOnPresentationMode(IconButton);

  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        mb: 2,
      }}
    >
      <Stack direction="row" sx={{ mt: 2 }}>
        <Autocomplete
          id="saved-filters-select"
          renderInput={(params) => (
            <TextField {...params} label={t("SAVED_FILTERS")} />
          )}
          renderOption={(
            props,
            filterOption: CompareAnalysisSavedFiltersPresenter
          ) => (
            <li
              {...props}
              key={filterOption.getId()}
              style={{ textAlign: "left" }}
            >
              {filterOption.getName()}
            </li>
          )}
          getOptionLabel={(savedFilter: CompareAnalysisSavedFiltersPresenter) =>
            savedFilter?.getName() || ""
          }
          isOptionEqualToValue={(option, value) =>
            option.getId() === value.getId()
          }
          size="small"
          noOptionsText={t("NO_OPTIONS")}
          sx={{ width: 300, mr: isPresentationMode ? 0 : 1 }}
          options={savedFilterOptions}
          value={currentFilter}
          onChange={(_event, selectedFilter) =>
            changeCurrentFilter(selectedFilter)
          }
          inputValue={currentFilter?.getName()}
          disableClearable
          disablePortal
        />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <HideOnPresentationMode onClick={handleCreateFilter}>
          <IconWithTooltip
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            icon={light("plus-circle")}
            title={t("CREATE_NEW_FILTER")}
          />
        </HideOnPresentationMode>
      </Stack>
    </Container>
  );
}
