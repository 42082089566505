import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import GradusModal from "../../GradusComponents/GradusModal/GradusModal";
import CompareFilterModal from "./Compare/CompareFilterModal";
import ConsulteFilterModal from "./Consulte/ConsulteFilterModal";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import useFilterStore from "../../../stores/FilterStore/useFilterStore";
import { handleApiError } from "../../../utils/ErrorUtils";
import AlertContext from "../../../contexts/AlertContext";

export default function FilterModal({
  isOpen,
  handleClose,
  analysis,
  isCompare,
}: {
  isOpen: boolean;
  handleClose: () => void;
  analysis: AnalysisPresenter;
  isCompare: boolean;
}) {
  const [openTab, setOpenTab] = useState(0);

  const { initFilters } = useFilterStore((state) => ({
    initFilters: state.initFilters,
  }));
  const { fireAlert } = useContext(AlertContext);
  const { t } = useTranslation();

  const handleOpen = useCallback(async () => {
    try {
      await initFilters(isCompare, analysis);
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  }, [analysis, fireAlert, initFilters, isCompare, t]);

  return (
    <GradusModal
      open={isOpen}
      handleClose={handleClose}
      handleOpen={handleOpen}
    >
      {isCompare ? (
        <CompareFilterModal
          open={isOpen}
          handleClose={handleClose}
          tab={openTab}
          setTab={setOpenTab}
        />
      ) : (
        <ConsulteFilterModal handleClose={handleClose} />
      )}
    </GradusModal>
  );
}
