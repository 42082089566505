import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DefaultMetricsCard from "./DefaultMetricsCard";
import Metrics from "../../models/Metrics";
import useDashboardVisualOptionsStore from "../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import {
  formatCurrencyAbbreviate,
  formatInteger,
} from "../../utils/NumberFormatUtils";

export default function MetricsOrganization({
  marketMetrics,
  companyMetrics,
  isCompare,
}) {
  const { selectedMetricsOptions } = useDashboardVisualOptionsStore(
    (state) => ({
      selectedMetricsOptions: state.selectedMetricsOptions,
    })
  );
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  return selectedMetricsOptions
    .map(({ id }) => {
      switch (id) {
        case "average":
          return (
            <DefaultMetricsCard
              key={id}
              label={t("REMUNERATION_AVERAGE")}
              marketValue={formatCurrencyAbbreviate(
                marketMetrics.average,
                language
              )}
              companyValue={formatCurrencyAbbreviate(
                companyMetrics.average,
                language
              )}
              isCompare={isCompare}
            />
          );
        case "p10":
          return (
            <DefaultMetricsCard
              key={id}
              label={t("10TH_PERCENTILE")}
              marketValue={formatCurrencyAbbreviate(
                marketMetrics.p10,
                language
              )}
              companyValue={formatCurrencyAbbreviate(
                companyMetrics.p10,
                language
              )}
              isCompare={isCompare}
              tooltip={t("METRICS_BOARD.10TH_PERCENTILE_TOOLTIP")}
            />
          );
        case "median":
          return (
            <DefaultMetricsCard
              key={id}
              label={t("REMUNERATION_MEDIAN")}
              marketValue={formatCurrencyAbbreviate(
                marketMetrics.median,
                language
              )}
              companyValue={formatCurrencyAbbreviate(
                companyMetrics.median,
                language
              )}
              isCompare={isCompare}
              tooltip={t("METRICS_BOARD.MEDIAN_TOOLTIP")}
            />
          );
        case "p90":
          return (
            <DefaultMetricsCard
              key={id}
              label={t("90TH_PERCENTILE")}
              marketValue={formatCurrencyAbbreviate(
                marketMetrics.p90,
                language
              )}
              companyValue={formatCurrencyAbbreviate(
                companyMetrics.p90,
                language
              )}
              isCompare={isCompare}
              tooltip={t("METRICS_BOARD.90TH_PERCENTILE_TOOLTIP")}
            />
          );
        case "quantity":
          return (
            <DefaultMetricsCard
              key={id}
              label={t("EMPLOYEE_TOTAL_QUANTITY")}
              marketValue={formatInteger(marketMetrics.quantity, language)}
              companyValue={formatInteger(companyMetrics.quantity, language)}
              isCompare={isCompare}
            />
          );
        default:
          return null;
      }
    })
    .filter((metric) => !_.isEmpty(metric));
}

MetricsOrganization.propTypes = {
  marketMetrics: PropTypes.shape(Metrics),
  companyMetrics: PropTypes.shape(Metrics),
  isCompare: PropTypes.bool,
};

MetricsOrganization.defaultProps = {
  marketMetrics: new Metrics({}),
  companyMetrics: new Metrics({}),
  isCompare: false,
};
