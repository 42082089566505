import { alpha } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import HistogramBarChart from "../HistogramBarChart";
import BaseChartContainer from "../BaseComponents/BaseChartContainer";
import useMetricsStore from "../../../stores/useMetricsStore";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import Chart from "../Chart";
import ChartHeader from "./ChartHeader";
import useChartLoadingStore from "../../../stores/ChartLoadingStore/useChartLoadingStore";

export default function EmployeesBySalaryRangeCompany({
  isCompare,
  title,
  analysis,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { marketMetrics, companyMetrics } = useMetricsStore((state) => ({
    marketMetrics: state.marketMetrics,
    companyMetrics: state.companyMetrics,
  }));
  const { getChart, maxX, maxY, charts } = useChartStore((state) => ({
    getChart: state.getChart,
    maxX: state.maxX,
    maxY: state.maxY,
    charts: state.charts,
  }));

  const { [Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY]: isLoading } =
    useChartLoadingStore();

  return (
    <BaseChartContainer size={6}>
      <ChartHeader
        title={title}
        analysis={analysis}
        chart={Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY}
        isCompare
      />
      <HistogramBarChart
        isHistogramLoading={isLoading}
        max={{ x: maxX, y: maxY }}
        referenceLines={[
          {
            color: theme.palette.primary.main,
            value: _.isNumber(companyMetrics.average)
              ? companyMetrics.average
              : 0,
            label: t("CHARTS_DASHBOARD.COMPANY_AVERAGE"),
            id: "MED01",
            inactive: false,
            canHide: true,
            order: 2,
            position: "insideTop",
          },
          {
            color: alpha(theme.palette.grey[700], 0.5),
            value: _.isNumber(marketMetrics.average)
              ? marketMetrics.average
              : 0,
            label: t("CHARTS_DASHBOARD.MARKET_AVERAGE"),
            id: "MED02",
            inactive: true,
            canHide: true,
            order: 3,
            position: "inside",
          },
        ]}
        bars={[
          {
            color: theme.palette.primary.main,
            label: t("CHARTS_DASHBOARD.EMPLOYEE_PERCENTAGE_BY_SALARY_RANGE"),
            id: "ID01",
            inactive: false,
            canHide: false,
            order: 1,
          },
        ]}
        histogram={getChart(charts, Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY)}
        legendHeight={50}
      />
    </BaseChartContainer>
  );
}

EmployeesBySalaryRangeCompany.propTypes = {
  isCompare: PropTypes.bool,
  title: PropTypes.string.isRequired,
  analysis: PropTypes.objectOf(AnalysisPresenter).isRequired,
};

EmployeesBySalaryRangeCompany.defaultProps = {
  isCompare: false,
};
