import React from "react";
import PropTypes from "prop-types";
import MuiTextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

const Input = (props) => <MuiTextField {...props} />;

const TextField = ({ label, id, sx, components, ...props }) => (
  <Stack sx={sx}>
    <label
      style={{ fontSize: "12px", fontWeight: "bold", margin: "8px 0 0 0" }}
      htmlFor={id}
    >
      {label}
    </label>
    {components.input(props)}
  </Stack>
);

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  components: PropTypes.shape({
    input: PropTypes.node,
  }),
};

TextField.defaultProps = {
  sx: {},
  components: { input: Input },
};

export default TextField;
