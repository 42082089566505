import MarketFilter from "../MarketFilter";

export default class ConsulteGetMetricDTO {
  filters: MarketFilter;

  correctionIndex: number;

  constructor(filters: MarketFilter, correctionIndex: number) {
    this.filters = filters;
    this.correctionIndex = correctionIndex;
  }
}
