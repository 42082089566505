import _ from "lodash";
import api from "../api";
import Metrics from "../../models/Metrics";
import PayrollFilter from "../../dtos/PayrollFilter";

const BASE_URL = "/api/v1/payroll/metrics";

const payrollMetricsApi = {
  getMetrics: (filters: PayrollFilter, analysisId: number): Promise<Metrics> =>
    new Promise((resolve, reject) => {
      api
        .post(
          `${BASE_URL}/${analysisId}`,
          _.isEmpty(filters) ? {} : filters.onlyWithIds()
        )
        .then((response) => resolve(new Metrics(response.data)), reject);
    }),
};

export default payrollMetricsApi;
