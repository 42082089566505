import React, { Dispatch, SetStateAction, useMemo } from "react";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import RenameComponent from "./RenameComponent";
import ExpandToggleButton from "../Buttons/ExpandToggleButton";
import PresentationModeButton from "./PresentationModeButton";
import FilterModal from "../Modals/FilterModal/FilterModal";
import ParameterModal from "../Modals/ParameterModal/ParameterModal";
import GradusIconButton from "../GradusComponents/GradusIconButton";
import withHideOnPresentationMode from "../../hocs/withHideOnPresentationMode";
import AnalysisPresenter from "../../dtos/AnalysisPresenter";
import useMetricsStore from "../../stores/useMetricsStore";
import { useUser } from "../../stores/useUserStore";

export default function CompareDashboardHeader({
  analysis,
  renameAnalysis,
  setShowFilters,
  showFilters,
  downloadAnalysisReport,
  isDownloadingAnalysisReport,
  downloadRaisRawCSV,
  isDownloadingRaisRawCSV,
}: {
  analysis: AnalysisPresenter;
  renameAnalysis: (newName: string) => void;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  showFilters: boolean;
  downloadAnalysisReport: () => void;
  downloadRaisRawCSV: () => void;
  isDownloadingAnalysisReport?: boolean;
  isDownloadingRaisRawCSV?: boolean;
}) {
  const { t } = useTranslation();

  const { isLoadingCompareMetrics } = useMetricsStore((state) => ({
    isLoadingCompareMetrics: state.isLoadingCompareMetrics,
  }));

  const HiddenOnPresentationMode = withHideOnPresentationMode(GradusIconButton);

  const [isRenaming, setIsRenaming] = React.useState(false);
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [openParameterModal, setOpenParameterModal] = React.useState(false);

  const user = useUser((state) => state);

  const analysisReportTooltipTitle = useMemo(() => {
    if (isDownloadingAnalysisReport) {
      return t("PROCESSING_THE_REPORT");
    }
    return t("DOWNLOAD_REPORT");
  }, [isDownloadingAnalysisReport, t]);
  const raisRawDataTooltipTitle = useMemo(() => {
    if (isDownloadingAnalysisReport) {
      return t("PROCESSING_THE_REPORT");
    }
    return t("DOWNLOAD_RAIS_RAW_CSV");
  }, [isDownloadingAnalysisReport, t]);

  const isGradusUser = useMemo(() => {
    const lowerCaseEmail = user.email.toLocaleLowerCase();
    return /.*@gradus.*/.test(lowerCaseEmail);
  }, [user.email]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          my: 0,
        }}
      >
        <RenameComponent
          isRenaming={isRenaming}
          setIsRenaming={setIsRenaming}
          handleRenameAnalysis={renameAnalysis}
          analysis={analysis}
        />

        <span style={{ display: "flex" }}>
          <ExpandToggleButton
            show={showFilters}
            handleToggle={() => setShowFilters(!showFilters)}
            buttonTitle={t("DASHBOARD_HEADER.APPLIED_FILTERS")}
          />

          <Divider orientation="vertical" sx={{ ml: 2, mr: 1 }} flexItem />

          <GradusIconButton
            onClick={() => setOpenFilterModal(true)}
            title={t("DASHBOARD_HEADER.EDIT_OR_CREATE_NEW_FILTERS")}
            icon={light("filter")}
            disabled={isLoadingCompareMetrics()}
          />
          <GradusIconButton
            onClick={() => setOpenParameterModal(true)}
            title={t("DASHBOARD_HEADER.CUSTOMIZE_INFORMATIONS")}
            icon={light("sliders")}
          />
          <HiddenOnPresentationMode
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => setIsRenaming(true)}
            title={t("DASHBOARD_HEADER.RENAME_ANALYSIS")}
            icon={light("pencil")}
          />

          <Divider orientation="vertical" sx={{ mx: 1 }} flexItem />

          <HiddenOnPresentationMode
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={downloadAnalysisReport}
            disabled={isDownloadingAnalysisReport}
            title={analysisReportTooltipTitle}
            icon={light("file-spreadsheet")}
          />
          {isGradusUser && (
            <HiddenOnPresentationMode
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={downloadRaisRawCSV}
              disabled={isDownloadingRaisRawCSV}
              title={raisRawDataTooltipTitle}
              icon={light("file-csv")}
            />
          )}
          <PresentationModeButton />
        </span>
      </Container>

      <FilterModal
        isOpen={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        analysis={analysis}
        isCompare
      />
      <ParameterModal
        open={openParameterModal}
        close={() => setOpenParameterModal(false)}
        analysis={analysis}
        isCompare
      />
    </>
  );
}

CompareDashboardHeader.defaultProps = {
  isDownloadingAnalysisReport: false,
  isDownloadingRaisRawCSV: false,
};
