import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ModuleCard from "./ModuleCard";

export default function ConsulteModuleCard({
  setConsulteAnalysisModal,
  isConsulteAvailable,
}) {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={6}>
      <ModuleCard
        title={t("CONSULT")}
        text={t("HOME_PAGE.CONSULT_TEXT")}
        buttonText={t("CONSULT")}
        handleClick={() => setConsulteAnalysisModal(true)}
        isAvailable={isConsulteAvailable}
      />
    </Grid>
  );
}

ConsulteModuleCard.propTypes = {
  setConsulteAnalysisModal: PropTypes.func.isRequired,
  isConsulteAvailable: PropTypes.bool,
};

ConsulteModuleCard.defaultProps = {
  isConsulteAvailable: false,
};
