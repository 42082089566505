import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import TabPanel from "../../../TabPanel/TabPanel";
import PayrollFilterTab from "./PayrollFilterTab";
import CompareAnalysisSavedFiltersTableWithSearch from "./CompareAnalysisSavedFiltersTableWithSearch";
import GradusTabs from "../../../GradusComponents/Tabs/GradusTabs";
import MarketFilterTab from "./CompareMarketFilterTab";
import useCompareAnalysisStore from "../../../../stores/useCompareAnalysisStore";

export default function CompareFilterModal({ open, handleClose, tab, setTab }) {
  const theme = useTheme();
  const { analysis } = useCompareAnalysisStore();
  const { t } = useTranslation();

  return (
    <Container disableGutters>
      <GradusTabs
        tab={tab}
        setTab={setTab}
        labels={[t("SAVED_FILTERS"), t("COMPANY_FILTERS"), t("MARKET_FILTERS")]}
      />

      <TabPanel
        value={tab}
        index={0}
        style={{
          marginTop: theme.spacing(3),
          width: "100%",
        }}
      >
        <CompareAnalysisSavedFiltersTableWithSearch
          open={open}
          handleClose={handleClose}
        />
      </TabPanel>
      <TabPanel
        value={tab}
        index={1}
        style={{
          marginTop: theme.spacing(1),
          width: "100%",
        }}
      >
        <PayrollFilterTab closeModal={handleClose} analysis={analysis} />
      </TabPanel>
      <TabPanel
        value={tab}
        index={2}
        style={{
          marginTop: theme.spacing(1),
          width: "100%",
        }}
      >
        <MarketFilterTab isCompare closeModal={handleClose} />
      </TabPanel>
    </Container>
  );
}

CompareFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  tab: PropTypes.number,
};

CompareFilterModal.defaultProps = {
  tab: 0,
};
