import Stack from "@mui/material/Stack";
import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CompareAnalysisTable from "./CompareAnalysisTable";
import AlertContext from "../../../contexts/AlertContext";
import { handleApiError } from "../../../utils/ErrorUtils";
import SearchBar from "../../SearchBar/SearchBar";
import withGradusLoading from "../../../hocs/withGradusLoading";
import GradusPrimaryButton from "../../Buttons/GradusPrimaryButton";
import useCompareAnalysisStore from "../../../stores/useCompareAnalysisStore";

export default function CompareAnalysisTableWithSearch({ open, handleClose }) {
  const theme = useTheme();

  const { getAll, deleteAnalysis, renameAnalysis } = useCompareAnalysisStore(
    (state) => ({
      getAll: state.getAll,
      deleteAnalysis: state.deleteAnalysis,
      renameAnalysis: state.renameAnalysis,
    })
  );

  const [options, setOptions] = useState([]);
  const [searchedOptions, setSearchedOptions] = useState(options);
  const [isLoading, setIsLoading] = useState(false);

  const { fireAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { id: currentAnalysisId } = useParams();

  const { t } = useTranslation();

  const handleUpdateOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAll();
      setOptions(response);
      setSearchedOptions(response);
    } catch (error) {
      handleApiError(error, fireAlert, t);
    } finally {
      setIsLoading(false);
    }
  }, [fireAlert, getAll, t]);

  const handleDeleteAnalysis = useCallback(
    async (deleteId) => {
      setIsLoading(true);
      try {
        await deleteAnalysis(deleteId);
        fireAlert(t("ALERTS.SUCCESSFULLY_REMOVED_ANALYSIS"));
        if (
          !_.isEmpty(currentAnalysisId) &&
          Number(currentAnalysisId) === Number(deleteId)
        ) {
          navigate("/");
        }
      } catch (error) {
        handleApiError(error, fireAlert, t);
      } finally {
        await handleUpdateOptions();
      }
    },
    [
      deleteAnalysis,
      fireAlert,
      t,
      currentAnalysisId,
      navigate,
      handleUpdateOptions,
    ]
  );

  const handleRenameAnalysis = useCallback(
    async (newName, updateId) => {
      try {
        await renameAnalysis(newName, updateId);
        fireAlert(t("ALERTS.SUCCESSFULLY_RENAMED_ANALYSIS"));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      } finally {
        await handleUpdateOptions();
      }
    },
    [renameAnalysis, fireAlert, t, handleUpdateOptions]
  );

  const openAnalysis = useCallback(
    (id) => {
      navigate(`/compare/${id}`);
      handleClose();
    },
    [handleClose, navigate]
  );

  useEffect(() => {
    if (open) {
      handleUpdateOptions();
    }
  }, [open, handleUpdateOptions]);

  const AnalysisTableComponent = withGradusLoading(
    searchedOptions.length <= 0
      ? () => (
        <p style={{ textAlign: "center" }}>
          {`${t("LIST_ANALYSIS_MODAL.NO_ANALYSIS_FOUND")}.`}
        </p>
      )
      : () => (
        <CompareAnalysisTable
          options={searchedOptions}
          handleDeleteAnalysis={handleDeleteAnalysis}
          handleOpenAnalysis={openAnalysis}
          handleRenameAnalysis={handleRenameAnalysis}
        />
      )
  );

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <GradusPrimaryButton
          onClick={() => {
            handleClose();
            navigate("/compare/create-analysis");
          }}
        >
          <FontAwesomeIcon
            icon={solid("plus")}
            style={{ marginRight: theme.spacing(1) }}
          />
          {t("CREATE_ANALYSIS")}
        </GradusPrimaryButton>
        <SearchBar
          options={options}
          setSearchedOptions={setSearchedOptions}
          parametersToSearch={["name"]}
          placeholder={t("LIST_ANALYSIS_MODAL.SEARCH_BAR_PLACEHOLDER")}
        />
      </Stack>
      <AnalysisTableComponent
        isLoading={isLoading}
        gradusLoadingContainerSx={{ mt: 2 }}
      />
    </Stack>
  );
}

CompareAnalysisTableWithSearch.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

CompareAnalysisTableWithSearch.defaultProps = {
  open: false,
};
