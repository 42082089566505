import { create } from "zustand";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import Chart, { getChartById } from "../../components/Charts/Chart";
import useDashboardVisualOptionsStore from "../DashboardVisualOptions/useDashboardVisualOptionsStore";
import CompareAnalysisPresenter from "../../dtos/CompareAnalysis/CompareAnalysisPresenter";
import compareEmployeeChartApi from "../../apis/charts/compareEmployeeChartApi";
import consulteEmployeeChartApi from "../../apis/charts/consulteEmployeeChartApi";
import Histogram from "../../calculators/Histogram";
import useMetricsStore from "../useMetricsStore";
import payrollChartApi from "../../apis/charts/payrollChartApi";
import MarketCompanyTuple from "./MarketCompanyTuple";
import ChartsStorer from "./ChartStoreTypes";
import SalaryAverageAndQuantity from "../../dtos/SalaryAverageAndQuantity";
import MarketCompanyWithDifference from "./MarketCompanyWithDifference";
import AnalysisPresenter from "../../dtos/AnalysisPresenter";
import ConsulteGetChartDTO from "../../dtos/chartGenerate/ConsulteGetChartDTO";
import CompareGetChartDTO from "../../dtos/chartGenerate/CompareGetChartDTO";
import useChartParametersStore from "../ChartParametersStore/useChartParametersStore";
import useChartLoadingStore from "../ChartLoadingStore/useChartLoadingStore";

// FIXME: separar em alguns slices isso aqui, vai ficar bem melhor
export type ChartStoreProps = {
  charts: ChartsStorer;

  // Valor máximo no eixo X entre os histogramas
  maxX: number;
  // Valor máximo no eixo Y entre os histogramas
  maxY: number;

  initCharts: (isCompare: boolean, analysis: AnalysisPresenter) => void;

  updateCharts: (isCompare: boolean, analysis: AnalysisPresenter) => void;

  updateChart: (
    isCompare: boolean,
    analysis: AnalysisPresenter,
    chartId: number
  ) => void;

  setChart: (
    chart: Chart,
    value:
      | Histogram
      | Partial<MarketCompanyTuple<Record<string, SalaryAverageAndQuantity>>>
      | Partial<
          MarketCompanyWithDifference<Record<string, SalaryAverageAndQuantity>>
        >
  ) => void;
  // Precisamos que o usuário do Store passe o chartData para termos como
  // re-renderizar o componente quando mudar um valor nele
  getChart: (
    chartData: ChartsStorer,
    chart: Chart
  ) =>
    | Histogram
    | MarketCompanyTuple<Record<string, SalaryAverageAndQuantity>>
    | MarketCompanyWithDifference<Record<string, SalaryAverageAndQuantity>>;

  setMax: (histogram: Histogram) => void;

  getEmployeesBySalaryRangeBoth: (
    analysis: CompareAnalysisPresenter
  ) => Promise<void>;
  getEmployeesBySalaryRangeCompany: (
    analysis: CompareAnalysisPresenter
  ) => Promise<void>;
  getEmployeesBySalaryRangeMarket: (
    isCompare: boolean,
    analysis: AnalysisPresenter
  ) => Promise<void>;
  getSalaryRangeByState: (
    isCompare: boolean,
    analysis: AnalysisPresenter
  ) => Promise<void>;
  getSalaryRangeByCompanySize: (
    isCompare: boolean,
    analysis: AnalysisPresenter
  ) => Promise<void>;
  getSalaryAverageByOccupation: (
    analysis: CompareAnalysisPresenter
  ) => Promise<void>;

  changeSalaryAverageByOccupationChartType: (
    analysis: CompareAnalysisPresenter
  ) => void;

  resetCharts: () => void;
};

const initialState = {
  charts: new ChartsStorer({}),
  maxX: 0,
  maxY: 0,
};

const useChartStore = create<ChartStoreProps>()(
  devtools(
    (set, get) => ({
      ...initialState,

      initCharts: async (isCompare, analysis) => {
        const { resetCharts, updateCharts } = get();

        resetCharts();
        await updateCharts(isCompare, analysis);
      },

      updateCharts: async (isCompare, analysis) => {
        const { updateChart } = get();

        const { getMetrics } = useMetricsStore.getState();

        const { selectedChartOptions } =
          useDashboardVisualOptionsStore.getState();

        await getMetrics(isCompare, analysis);

        for (let i = 0; i < selectedChartOptions.length; i++) {
          const option = selectedChartOptions[i];
          updateChart(isCompare, analysis, option.id);
        }
      },

      updateChart: async (isCompare, analysis, chartId) => {
        const chart = getChartById(chartId) as Chart;

        const {
          getEmployeesBySalaryRangeBoth,
          getEmployeesBySalaryRangeCompany,
          getEmployeesBySalaryRangeMarket,
          getSalaryRangeByState,
          getSalaryRangeByCompanySize,
          getSalaryAverageByOccupation,
        } = get();

        const updateChartFnByChart: Record<Chart, () => Promise<void>> = {
          [Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH]: async () =>
            getEmployeesBySalaryRangeBoth(analysis as CompareAnalysisPresenter),
          [Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY]: async () =>
            getEmployeesBySalaryRangeCompany(
              analysis as CompareAnalysisPresenter
            ),
          [Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET]: async () =>
            getEmployeesBySalaryRangeMarket(isCompare, analysis),
          [Chart.SALARY_RANGE_BY_STATE]: async () =>
            getSalaryRangeByState(isCompare, analysis),
          [Chart.SALARY_RANGE_BY_COMPANY_SIZE]: async () =>
            getSalaryRangeByCompanySize(isCompare, analysis),
          [Chart.SALARY_AVERAGE_BY_OCCUPATION]: async () =>
            getSalaryAverageByOccupation(analysis as CompareAnalysisPresenter),
        };

        await _.get(updateChartFnByChart, chart, _.noop)();
      },

      setChart: (chart, value) => {
        set((prevState) => ({
          ...prevState,
          charts: ChartsStorer.addChartData(prevState.charts, chart, value),
        }));
      },
      getChart: (chartData, chart) => chartData.getChartData(chart),

      setMax: (histogram: Histogram) => {
        set((prevState) => {
          const maxXBin = Histogram.getMaxXBin(histogram);
          const maxX = _.max([prevState.maxX, maxXBin?.supBound || 0]);
          const maxY = _.max([prevState.maxY, histogram.biggestBin.y]);

          return { maxX, maxY };
        });
      },

      getEmployeesBySalaryRangeBoth: async (analysis) => {
        const { setChart, setMax } = get();
        const { companyMetrics, marketMetrics } = useMetricsStore.getState();
        const { setIsLoading } = useChartLoadingStore.getState();

        const chart = Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH;

        const {
          [chart]: { applied },
          globalParameters,
        } = useChartParametersStore.getState();

        setIsLoading(chart, true);

        try {
          const responses = await Promise.all([
            compareEmployeeChartApi.getEmployeeCountBySalaryRange(
              CompareGetChartDTO.from(
                (analysis as CompareAnalysisPresenter).getActiveFilterId(),
                analysis.getId(),
                applied,
                globalParameters.applied
              )
            ),
            payrollChartApi.getEmployeeCountBySalaryRange(
              CompareGetChartDTO.from(
                analysis.getActiveFilterId(),
                analysis.getId(),
                applied,
                globalParameters.applied
              )
            ),
          ]);
          const marketHistogram = new Histogram({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            bins: responses[0],
            metrics: marketMetrics,
            capPercentile: applied.capPercentile,
          }).calculateHistogram();
          const companyHistogram = new Histogram({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            bins: responses[1],
            metrics: companyMetrics,
            capPercentile: applied.capPercentile,
          }).calculateHistogram();

          setMax(companyHistogram);
          setMax(marketHistogram);

          const unifiedHistogram = Histogram.mergeHistograms(
            marketHistogram,
            companyHistogram,
            "market",
            "company"
          );

          setChart(chart, unifiedHistogram);
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const histogram = new Histogram({}).calculateHistogram();
          setChart(chart, histogram);
        } finally {
          setIsLoading(chart, false);
        }
      },

      getEmployeesBySalaryRangeCompany: async (analysis) => {
        const { setChart, setMax } = get();
        const { companyMetrics } = useMetricsStore.getState();
        const { setIsLoading } = useChartLoadingStore.getState();
        const chart = Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY;
        const {
          [chart]: { applied },
          globalParameters,
        } = useChartParametersStore.getState();

        setIsLoading(chart, true);

        try {
          const bins = await payrollChartApi.getEmployeeCountBySalaryRange(
            CompareGetChartDTO.from(
              analysis.getActiveFilterId(),
              analysis.getId(),
              applied,
              globalParameters.applied
            )
          );
          const histogram = new Histogram({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            bins,
            metrics: companyMetrics,
            capPercentile: applied.capPercentile,
          }).calculateHistogram();

          setChart(chart, histogram);
          setMax(histogram);
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const histogram = new Histogram({}).calculateHistogram();
          setChart(chart, histogram);
          setMax(histogram);
        } finally {
          setIsLoading(chart, false);
        }
      },

      getEmployeesBySalaryRangeMarket: async (isCompare, analysis) => {
        const { setChart, setMax } = get();
        const { marketMetrics } = useMetricsStore.getState();
        const { setIsLoading } = useChartLoadingStore.getState();
        const chart = Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET;
        const {
          [chart]: { applied },
          globalParameters,
        } = useChartParametersStore.getState();

        setIsLoading(chart, true);

        const promise = isCompare
          ? compareEmployeeChartApi.getEmployeeCountBySalaryRange(
              CompareGetChartDTO.from(
                (analysis as CompareAnalysisPresenter).getActiveFilterId(),
                analysis.getId(),
                applied,
                globalParameters.applied
              )
            )
          : consulteEmployeeChartApi.getEmployeeCountBySalaryRange(
              ConsulteGetChartDTO.from(
                analysis.getMarketFilters(),
                applied,
                globalParameters.applied
              )
            );

        try {
          const bins = await promise;
          const histogram = new Histogram({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            bins,
            metrics: marketMetrics,
            capPercentile: applied.capPercentile,
          }).calculateHistogram();

          setChart(chart, histogram);
          setMax(histogram);
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const histogram = new Histogram({}).calculateHistogram();
          setChart(chart, histogram);
          setMax(histogram);
        } finally {
          setIsLoading(chart, false);
        }
      },

      getSalaryRangeByState: async (isCompare, analysis) => {
        const { setChart } = get();
        const { setIsLoading } = useChartLoadingStore.getState();

        const chart = Chart.SALARY_RANGE_BY_STATE;
        setIsLoading(chart, true);
        const {
          [chart]: { applied },
          globalParameters,
        } = useChartParametersStore.getState();

        if (isCompare) {
          const dto = CompareGetChartDTO.from(
            (analysis as CompareAnalysisPresenter).getActiveFilterId(),
            analysis.getId(),
            applied,
            globalParameters.applied
          );

          try {
            const responses = await Promise.all([
              compareEmployeeChartApi.getEmployeeAverageSalaryByState(dto),
              payrollChartApi.getEmployeeAverageSalaryByState(dto),
            ]);
            const market = responses[0];
            const company = responses[1];
            setChart(chart, { market, company });
          } catch (e) {
            setChart(chart, { market: {}, company: {} });
          } finally {
            setIsLoading(chart, false);
          }
        } else {
          try {
            const market =
              await consulteEmployeeChartApi.getEmployeeAverageSalaryByState(
                ConsulteGetChartDTO.from(
                  analysis.getMarketFilters(),
                  applied,
                  globalParameters.applied
                )
              );
            setChart(chart, {
              market,
            });
          } catch (e) {
            setChart(chart, {
              market: {},
            });
          } finally {
            setIsLoading(chart, false);
          }
        }
      },

      getSalaryRangeByCompanySize: async (
        isCompare: boolean,
        analysis: AnalysisPresenter
      ) => {
        const { setChart } = get();
        const { setIsLoading } = useChartLoadingStore.getState();

        const chart = Chart.SALARY_RANGE_BY_COMPANY_SIZE;
        setIsLoading(chart, true);
        const {
          [chart]: { applied },
          globalParameters,
        } = useChartParametersStore.getState();

        if (isCompare) {
          const dto = CompareGetChartDTO.from(
            (analysis as CompareAnalysisPresenter).getActiveFilterId(),
            analysis.getId(),
            applied,
            globalParameters.applied
          );

          try {
            const responses = await Promise.all([
              compareEmployeeChartApi.getEmployeeAverageSalaryByCompanySize(
                dto
              ),
              payrollChartApi.getEmployeeAverageSalaryByCompanySize(dto),
            ]);
            const [market, company] = responses;
            setChart(chart, {
              market,
              company,
            });
          } catch (e) {
            setChart(chart, {
              market: {},
              company: {},
            });
          } finally {
            setIsLoading(chart, false);
          }
        } else {
          try {
            const getChartDTO = ConsulteGetChartDTO.from(
              analysis.getMarketFilters(),
              applied,
              globalParameters.applied
            );
            const market =
              await consulteEmployeeChartApi.getEmployeeAverageSalaryByCompanySize(
                getChartDTO
              );
            setChart(chart, {
              market,
            });
          } catch (e) {
            setChart(chart, {
              market: {},
            });
          } finally {
            setIsLoading(chart, false);
          }
        }
      },

      getSalaryAverageByOccupation: async (analysis) => {
        const { setIsLoading } = useChartLoadingStore.getState();

        const chart = Chart.SALARY_AVERAGE_BY_OCCUPATION;

        const { setChart } = get();
        const {
          [chart]: { applied },
          globalParameters,
        } = useChartParametersStore.getState();

        setIsLoading(chart, true);

        try {
          const response = await payrollChartApi.getAverageSalaryByOccupation(
            applied.subchart.id,
            CompareGetChartDTO.from(
              (analysis as CompareAnalysisPresenter).getActiveFilterId(),
              analysis.getId(),
              applied,
              globalParameters.applied
            )
          );
          setChart(chart, response);
        } catch (e) {
          setChart(chart, {});
        } finally {
          setIsLoading(chart, false);
        }
      },

      changeSalaryAverageByOccupationChartType: (analysis) => {
        get().getSalaryAverageByOccupation(analysis);
      },

      resetCharts: () => {
        set(initialState);
      },
    }),
    { name: "ChartStore" }
  )
);

export default useChartStore;
