import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Bar,
  BarChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Histogram from "../../calculators/Histogram";
import {
  formatCurrency,
  formatCurrencyAbbreviate,
  formatPercentage,
} from "../../utils/NumberFormatUtils";
import LegendItem from "./ChartUtility/LegendItem";
import withGradusLoading from "../../hocs/withGradusLoading";

function tooltipLabelFormatter(value, language) {
  if (!value) {
    return "";
  }
  const split = value.split(",");
  return `${formatCurrency(split[0], language)} - ${formatCurrency(
    split[1],
    language
  )}`;
}

function tooltipFormatter(value, language) {
  return [formatPercentage(value, language)];
}

function formatTick(value, language) {
  if (!value.split) {
    return "";
  }
  const split = value.split(",");
  return `${formatCurrencyAbbreviate(
    split[0],
    language
  )}-${formatCurrencyAbbreviate(split[1], language)}`;
}

function HistogramBarChart({
  max,
  referenceLines,
  histogram,
  bars,
  legendHeight,
  isHistogramLoading,
}) {
  const theme = useTheme();
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const [legend, setLegend] = useState([]);
  const [referenceLinesComponents, setReferenceLinesComponents] = useState([]);
  const [barComponents, setBarComponents] = useState([]);
  const [legendToggle, setLegendToggle] = useState({});

  useEffect(() => {
    setLegend([]);

    if (Histogram.isHistogramInitialized(histogram)) {
      referenceLines.forEach(
        ({ color, label, id, inactive, canHide, order }) => {
          const item = new LegendItem({
            value: label,
            type: "line",
            color,
            id,
            canHide,
            inactive,
            order,
          });

          setLegend((prevState) => [...prevState, item]);
        }
      );
    }

    if (bars.length > 1) {
      bars.forEach(({ color, label, id, canHide, inactive, order }) => {
        const item = new LegendItem({
          value: label,
          type: "rect",
          color,
          id,
          canHide,
          inactive,
          order,
        });
        setLegend((prevState) => [...prevState, item]);
      });
    } else {
      const { color, label, id, canHide, inactive, order } = bars[0];
      const item = new LegendItem({
        value: label,
        type: "rect",
        color,
        id,
        canHide,
        inactive,
        order,
      });
      setLegend((prevState) => [...prevState, item]);
    }

    setLegend((prevState) => {
      prevState.sort((a, b) => a.order - b.order);
      return prevState;
    });
  }, [bars, histogram, referenceLines]);

  useEffect(() => {
    setLegendToggle(
      legend.reduce((acc, cur) => {
        acc[cur.id] = cur.inactive;
        return acc;
      }, {})
    );
  }, [legend, setLegendToggle]);

  useEffect(() => {
    setReferenceLinesComponents([]);

    if (Histogram.isHistogramInitialized(histogram)) {
      setReferenceLinesComponents(
        referenceLines
          .map(({ color, value, id, position }) => {
            const bin = Histogram.findBinByValue(histogram, value);

            return (
              <ReferenceLine
                key={id}
                // Gambi, não achei nenhum prop que deixasse esconder esse valor
                x={!legendToggle[id] && bin ? bin.x : null}
                stroke={color}
                strokeDasharray="6 6"
                label={{
                  position:
                    value > max.x / 2 ? `${position}Right` : `${position}Left`,
                  value: formatCurrency(value, language),
                  fontSize: "1rem",
                }}
                ifOverflow="hidden"
                isFront
              />
            );
          })
          .filter((item) => !_.isEmpty(item))
      );
    }
  }, [histogram, language, legendToggle, max.x, referenceLines]);

  useEffect(() => {
    setBarComponents([]);

    if (bars.length > 1) {
      setBarComponents(
        bars.map(({ id, color, dimensionName }) => {
          const key = `y.${dimensionName}`;
          return (
            <Bar
              key={key}
              dataKey={key}
              stroke={color}
              fill={color}
              isAnimationActive
              hide={legendToggle[id]}
            />
          );
        })
      );
    } else {
      const { id, color } = bars[0];
      setBarComponents(
        <Bar
          key="y"
          dataKey="y"
          stroke={color}
          fill={color}
          isAnimationActive
          hide={legendToggle[id]}
        />
      );
    }
  }, [bars, legendToggle]);

  const handleOnClick = ({ id, canHide }) => {
    setLegendToggle((prevState) => ({
      ...prevState,
      [id]: canHide ? !prevState[id] : false,
    }));
  };

  const legendFormatter = (value, { id }) => (
    <span
      style={{ color: legendToggle[id] ? "#ccc" : theme.palette.grey[700] }}
    >
      {value}
    </span>
  );

  const ResponsiveContainerWithLoading = useMemo(
    () => withGradusLoading(ResponsiveContainer),
    []
  );

  return (
    <ResponsiveContainerWithLoading isLoading={isHistogramLoading}>
      <BarChart
        data={histogram.bins}
        barGap={0}
        barCategoryGap={1}
        margin={{ bottom: 20 }}
      >
        <XAxis
          dataKey="x"
          type="category"
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
          tickFormatter={(value) => formatTick(value, language)}
          label={{
            value: t("CHARTS_DASHBOARD.SALARY_RANGE"),
            dy: 20,
          }}
        />
        <YAxis
          type="number"
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
          unit="%"
          domain={[
            0,
            _.isNumber(max.y) ? () => Number(max.y + 3).toFixed(0) : max.y,
          ]}
          label={{
            value: t("PERCENTAGE"),
            angle: -90,
            dx: -20,
          }}
        />
        <Tooltip
          formatter={(value) => tooltipFormatter(value, language)}
          labelFormatter={(value) => tooltipLabelFormatter(value, language)}
        />
        <Legend
          payload={legend}
          onClick={handleOnClick}
          verticalAlign="top"
          height={legendHeight}
          formatter={legendFormatter}
        />
        {barComponents}
        {referenceLinesComponents}
      </BarChart>
    </ResponsiveContainerWithLoading>
  );
}

HistogramBarChart.propTypes = {
  max: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    x: PropTypes.any.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    y: PropTypes.any.isRequired,
  }).isRequired,
  referenceLines: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      inactive: PropTypes.bool.isRequired,
      canHide: PropTypes.bool.isRequired,
      order: PropTypes.number.isRequired,
      position: PropTypes.string.isRequired,
    })
  ).isRequired,
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      inactive: PropTypes.bool.isRequired,
      canHide: PropTypes.bool.isRequired,
      order: PropTypes.number.isRequired,
      dimensionName: PropTypes.string,
    })
  ).isRequired,
  histogram: PropTypes.instanceOf(Histogram),
  legendHeight: PropTypes.number.isRequired,
  isHistogramLoading: PropTypes.bool,
};

HistogramBarChart.defaultProps = {
  histogram: null,
  isHistogramLoading: false,
};

export default HistogramBarChart;
