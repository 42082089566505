import React, { Dispatch, SetStateAction, useState } from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Trans, useTranslation } from "react-i18next";
import PayrollUpload from "../../components/PayrollUpload/PayrollUpload";
import ErrorList from "../../components/ErrorList/ErrorList";
import PayrollExampleModal from "./PayrollExampleModal";
import ExcelColumn from "../../components/CreateMapping/ExcelColumn";

export interface PayrollUploadStepProps {
  setComputedCompanySize: Dispatch<SetStateAction<number>>;
  setFile: Dispatch<SetStateAction<File | null>>;
  setColumns: Dispatch<SetStateAction<ExcelColumn[]>>;
  excelErrors: any[];
  securityPolicyConsent: boolean;
  toggleSecurityPolicyConsent: () => void;
}

const PayrollUploadStep = ({
  setComputedCompanySize,
  setFile,
  setColumns,
  excelErrors,
  securityPolicyConsent,
  toggleSecurityPolicyConsent,
}: PayrollUploadStepProps) => {
  const { t } = useTranslation();

  const [showExample, setShowExample] = useState(false);

  return (
    <Stack alignItems="flex-start">
      <Typography variant="h6" sx={{ mb: 1 }}>
        {`2. ${t("PAYROLL")} (`}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          variant="h6"
          component="span"
          onClick={() => setShowExample(true)}
          sx={{
            fontSize: "18px",
            color: "primary.main",
            display: "inline",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline", cursor: "pointer" },
          }}
        >
          {t("EXAMPLE")}
        </Link>
        )
      </Typography>
      <PayrollExampleModal
        isOpen={showExample}
        handleClose={() => setShowExample(false)}
      />
      <Typography variant="body1" style={{ textAlign: "justify" }}>
        {t("COMPARE_CREATE_ANALYSIS_PAGE.PAYROLL_UPLOAD_TEXT_1")}
      </Typography>
      <Typography
        variant="body1"
        style={{ textAlign: "justify" }}
        sx={{ mb: 1 }}
      >
        <Trans
          t={t}
          i18nKey="COMPARE_CREATE_ANALYSIS_PAGE.PAYROLL_UPLOAD_TEXT_2"
        >
          <Link href="/#/privacy-policy" target="_blank" />
        </Trans>
      </Typography>
      <PayrollUpload
        setComputedCompanySize={setComputedCompanySize}
        setFile={setFile}
        setColumns={setColumns}
        securityPolicyConsent={securityPolicyConsent}
        toggleSecurityPolicyConsent={toggleSecurityPolicyConsent}
      />
      <ErrorList containerSx={{ mb: 2 }} excelErrors={excelErrors} />
    </Stack>
  );
};

export default PayrollUploadStep;
