import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useSystemConfigurationsStore from "../../stores/useSystemConfigurationsStore";

const FontYearRais = () => {
  const { t } = useTranslation();
  const configurations = useSystemConfigurationsStore(
    (state) => state.configurations
  );

  return (
    <Typography
      style={{
        display: "flex",
        fontSize: "13px",
        alignSelf: "start",
      }}
    >
      {t("FONT_RAIS", { yearRais: configurations.yearRais })}
    </Typography>
  );
};

export default FontYearRais;
