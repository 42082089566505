import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import userApi from "../apis/userApi";
import ModulesDTO from "../dtos/ModulesDTO";
import { handleApiError } from "../utils/ErrorUtils";
import AlertContext from "../contexts/AlertContext";

export default function useAvailableModules() {
  const { fireAlert } = useContext(AlertContext);
  const { t } = useTranslation();

  const [availableModules, setAvailableModules] = useState(new ModulesDTO({}));

  const callApi = useCallback(async () => {
    try {
      const response = await userApi.findAvailableModules();
      setAvailableModules(new ModulesDTO(response.data));
    } catch (e) {
      handleApiError(e, fireAlert, t);
    }
  }, [fireAlert, t]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return availableModules;
}
