export default class ExcelColumn {
  label: string;

  type: "number" | "string" | string;

  constructor(label: string, type: "number" | "string" | string) {
    this.label = label;
    this.type = type;
  }
}
