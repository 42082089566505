import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/pro-light-svg-icons";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import TableConfigurationModal from "./tableConfigurationModal/TableConfigurationModal";
import GradusModal from "../GradusModal/GradusModal";

export default function ShowColumnsTable({ storeName }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Stack direction="row-reverse" sx={{ mr: "5%", mb: 1 }}>
        <IconButton
          type="button"
          onClick={handleOpen}
          data-testid="choose-column-visualization"
          sx={{
            border: "0.2px solid rgba(0, 0, 0, 0.5)",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        >
          <FontAwesomeIcon
            icon={faSlidersH}
            style={{
              color: "black",
              opacity: 0.35,
              width: "24px",
              height: "24px",
              marginTop: "5px",
            }}
          />
        </IconButton>
      </Stack>
      <GradusModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        handleClose={handleClose}
        closeAfterTransition
        containerStyle={{
          p: 1,
          width: "816px",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "#353639",
            fontSize: "2rem",
            textAlign: "center",
            py: 2,
            fontWeight: "bold",
          }}
        >
          {t("CHOOSE_COLUMNS_MODAL.TITLE")}
        </Typography>
        <TableConfigurationModal storeName={storeName} />
      </GradusModal>
    </>
  );
}

ShowColumnsTable.propTypes = {
  storeName: PropTypes.string.isRequired,
};
