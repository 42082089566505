import _ from "lodash";
import React from "react";
import Grid from "@mui/material/Grid";
import JobDataFilter from "./FilterGroups/JobDataFilter";
import GeographicDataFilter from "./FilterGroups/GeographicDataFilter";
import useFilterStore from "../../../stores/FilterStore/useFilterStore";
import useFilterOptionsStore from "../../../stores/FilterOptionsStore/useFilterOptionsStore";
import CompanyDataFilter from "./FilterGroups/CompanyDataFilter";
import CompanySizeFilter from "./FilterGroups/CompanySizeFilter";
import RelaxationSelector from "./RelaxationSelector";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import FilterOptionDTO from "../../../dtos/FilterOptionDTO";
import MarketFilter from "../../../dtos/MarketFilter";
import BaseFilter from "../../../dtos/BaseFilter";
import useRelaxationSelectorStore from "../../../stores/useRelaxationSelectorStore";

export default function MarketFilters({
  isCompare,
  analysis,
}: {
  isCompare: boolean;
  analysis: AnalysisPresenter;
}) {
  const {
    marketOptions,
    getMarketCompanyChainedFilters,
    getMarketJobChainedFilters,
    getMarketGeographyChainedFilters,
  } = useFilterOptionsStore((state) => ({
    marketOptions: state.marketOptions,
    getMarketCompanyChainedFilters: state.getMarketCompanyChainedFilters,
    getMarketJobChainedFilters: state.getMarketJobChainedFilters,
    getMarketGeographyChainedFilters: state.getMarketGeographyChainedFilters,
  }));
  const { updateModalSelectedMarketFilters, modalSelectedMarketFilters } =
    useFilterStore((state) => ({
      updateModalSelectedMarketFilters: state.updateModalSelectedMarketFilters,
      modalSelectedMarketFilters: state.modalSelectedMarketFilters,
    }));

  const setCompanySizes = async (companySizes: FilterOptionDTO[]) => {
    await updateModalSelectedMarketFilters(
      { companySizes },
      isCompare,
      analysis.getId()
    );
  };
  const setCompanyDataFilters = async (values: Partial<MarketFilter>) => {
    const newFilters = await updateModalSelectedMarketFilters(
      values,
      isCompare,
      analysis.getId()
    );
    await getMarketCompanyChainedFilters(newFilters);
  };
  const setJobDataFilters = async (values: Partial<BaseFilter>) => {
    const newFilters = await updateModalSelectedMarketFilters(
      values,
      isCompare,
      analysis.getId()
    );
    await getMarketJobChainedFilters(newFilters);
  };
  const setGeographicDataFilters = async (values: Partial<BaseFilter>) => {
    const newFilters = await updateModalSelectedMarketFilters(
      values,
      isCompare,
      analysis.getId()
    );
    await getMarketGeographyChainedFilters(newFilters);
  };

  const isRelaxed = useRelaxationSelectorStore((state) => state.isRelaxed);

  return (
    <>
      <Grid container>
        <Grid item xs={5}>
          <CompanySizeFilter
            options={
              _.isEmpty(marketOptions) ? [] : marketOptions?.companySizes
            }
            companySizes={modalSelectedMarketFilters?.companySizes}
            setCompanySizes={setCompanySizes}
          />
        </Grid>
        {isCompare && (
          <Grid item xs={7}>
            <RelaxationSelector />
          </Grid>
        )}
      </Grid>
      <CompanyDataFilter
        setSelectedFilters={setCompanyDataFilters}
        selectedFilters={modalSelectedMarketFilters}
        options={marketOptions}
      />
      {isCompare && isRelaxed ? null : (
        <>
          <JobDataFilter
            setSelectedFilters={setJobDataFilters}
            selectedFilters={modalSelectedMarketFilters}
            options={marketOptions}
            isMarketTab
          />
          <GeographicDataFilter
            setSelectedFilters={setGeographicDataFilters}
            selectedFilters={modalSelectedMarketFilters}
            options={marketOptions}
            isMarketTab
          />
        </>
      )}
    </>
  );
}
