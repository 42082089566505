import { Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import RenameField from "../Modals/ListAnalysisModal/RenameField";
import withHideOnPresentationMode from "../../hocs/withHideOnPresentationMode";
import AnalysisPresenter from "../../dtos/AnalysisPresenter";

export default function RenameComponent({
  isRenaming,
  setIsRenaming,
  analysis,
  handleRenameAnalysis,
}: {
  isRenaming: boolean;
  setIsRenaming: Dispatch<SetStateAction<boolean>>;
  analysis: AnalysisPresenter;
  handleRenameAnalysis: (newName: string) => void;
}) {
  const { t } = useTranslation();

  if (isRenaming) {
    const HiddenOnPresentationMode = withHideOnPresentationMode(RenameField);
    return (
      <HiddenOnPresentationMode
        // FIXME: como tipar HOCs corretamente? Não estou conseguindo entender
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setIsRenaming={setIsRenaming}
        id={analysis.getId()}
        name={analysis.getName()}
        renameFn={handleRenameAnalysis}
        sx={{ mr: 2 }}
      />
    );
  }

  return (
    <Typography variant="h5">
      {analysis.getName() || `${t("LOADING")}...`}
    </Typography>
  );
}
