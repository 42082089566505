import React, { useState } from "react";
import BaseChartHeader from "../BaseComponents/BaseChartHeader";
import ChartOptions from "../ChartOptions/ChartOptions";
import Chart from "../Chart";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import SelectJobFieldSubfilter from "../ChartOptions/SelectJobFieldSubfilter";

export default function ChartHeader({
  title,
  analysis,
  chart,
  isCompare,
}: {
  title: string;
  analysis: AnalysisPresenter;
  chart: Chart;
  isCompare: boolean;
}) {
  const [isChartOptionsModalOpen, setIsChartOptionsModalOpen] = useState(false);

  return (
    <BaseChartHeader
      title={title}
      rightComponent={
        <ChartOptions
          isOpen={isChartOptionsModalOpen}
          setIsOpen={setIsChartOptionsModalOpen}
          chart={chart}
          analysis={analysis}
          isCompare={isCompare}
        >
          <SelectJobFieldSubfilter chart={chart} isCompare={isCompare} />
        </ChartOptions>
      }
    />
  );
}
