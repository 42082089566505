import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GradusButton from "../../components/Buttons/GradusButton";
import PasswordTextField from "../../components/PasswordTextField/PasswordTextField";
import sessionApi from "../../apis/sessionApi";
import AuthenticationWrapper from "./AuthenticationWrapper";
import AlertContext from "../../contexts/AlertContext";
import NewPasswordRules from "./NewPasswordRules";
import { useNewPasswordErrors } from "../../hooks/useNewPasswordErrors";
import GradusLoading from "../../components/GradusComponents/GradusLoading";
import GradusSupportButton from "../../components/Buttons/GradusSupportButton";

function RecoverPasswordContent() {
  const navigate = useNavigate();
  const { fireAlert } = useContext(AlertContext);

  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const token = useSearchParams()[0].get("token");
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function retrieveUser() {
      try {
        const response = await sessionApi.getPasswordUpdateDetails({
          token,
        });
        setUser(response.data);
      } catch (error) {
        fireAlert(error.response.data, true, 8000);
        navigate("/login", { replace: true });
      }
    }

    retrieveUser();
  }, [fireAlert, navigate, token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await sessionApi.updatePassword({ token, newPassword });
      fireAlert(t("ALERTS.SUCCESSFULLY_UPDATED_PASSWORD"));
      navigate("/login", { replace: true });
    } catch (error) {
      fireAlert(error.response.data, true);
      setNewPassword("");
      setPasswordConfirmation("");
    } finally {
      setIsLoading(false);
    }
  };
  const errors = useNewPasswordErrors({
    user,
    newPassword,
    passwordConfirmation,
  });
  const hasErrors = useMemo(
    () => errors.some(([, isValid]) => !isValid),
    [errors]
  );

  if (isLoading) {
    return <GradusLoading />;
  }

  return (
    <Box>
      <Grid item>
        <Typography
          component="h1"
          variant="h5 "
          sx={{ color: "primary.main", fontWeight: "bold", fontSize: "20px" }}
        >
          {t("UPDATE_PASSWORD")}
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 1 }}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <PasswordTextField
                id="new-password"
                onChange={(event) => setNewPassword(event.target.value)}
                type="password"
                label={t("NEW_PASSWORD")}
                required
              />
              <PasswordTextField
                id="new-password-confirmation"
                onChange={(event) =>
                  setPasswordConfirmation(event.target.value)
                }
                type="password"
                label={t("REPEAT_NEW_PASSWORD")}
                required
              />
              <NewPasswordRules errors={errors} />
            </Grid>
            <Grid item>
              <GradusSupportButton
                onClick={() => navigate("/login", { replace: true })}
                sx={{ mr: 1, width: "auto" }}
              >
                {t("GO_BACK")}
              </GradusSupportButton>
              <GradusButton
                sx={{ width: "auto" }}
                disabled={hasErrors}
                variant="primary"
                colorVariant="primary"
                buttonProps={{ type: "submit" }}
              >
                {t("REDEFINE_PASSWORD")}
              </GradusButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Box>
  );
}

export default function RecoverPassword() {
  return (
    <AuthenticationWrapper width="280px" height="425px">
      <RecoverPasswordContent />
    </AuthenticationWrapper>
  );
}
