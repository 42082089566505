import React from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import PrivacyPolicy from "./PrivacyPolicy";
import ActionButton from "../../components/Actions/ActionButton";

const IconWrapper = styled("div")(() => ({
  "& img": {
    height: "60px",
  },
}));

const ExitButtonWrapper = styled("div")(() => ({
  position: "absolute",
  top: "100px",
  right: "150px",
}));

const PrivacyPolicyWrapper = styled("div")(() => ({
  marginTop: "60px",
}));

export default function PrivacyPolicyNoLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/login");
  }

  return (
    <>
      <AppBar position="absolute">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <IconWrapper>
              <img
                src="/images/Gradus_Analytics_Marca_RGB_Negativa.svg"
                alt={t("GRADUS_LOGO_ALT")}
              />
            </IconWrapper>
          </Box>
          <LanguageSelector colorVariant="light" isNotAuthenticated />
        </Toolbar>
      </AppBar>
      <ExitButtonWrapper>
        <ActionButton 
          icon={light("xmark")}
          title={t("EXIT")}
          onClick={handleClose}
        />
      </ExitButtonWrapper>
      <PrivacyPolicyWrapper>
        <PrivacyPolicy/>
      </PrivacyPolicyWrapper>
      
    </>
  );
};