import React from "react";
import Typography from "@mui/material/Typography";
import GradusTooltip from "../GradusTooltip";
import StringUtils from "../../../utils/StringUtils";
import MarketFilter from "../../../dtos/MarketFilter";
import PayrollFilter from "../../../dtos/PayrollFilter";

export default function TruncatedFilter({
  filters,
}: {
  filters: MarketFilter | PayrollFilter | undefined;
}) {
  return (
    <GradusTooltip title={filters?.toString() || ""}>
      <Typography>{StringUtils.truncateFilters(filters)}</Typography>
    </GradusTooltip>
  );
}
