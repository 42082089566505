import { alpha } from "@mui/material";
import React, { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import HistogramBarChart from "../HistogramBarChart";
import BaseChartContainer from "../BaseComponents/BaseChartContainer";
import useMetricsStore from "../../../stores/useMetricsStore";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import Chart from "../Chart";
import ChartHeader from "./ChartHeader";
import useChartLoadingStore from "../../../stores/ChartLoadingStore/useChartLoadingStore";

export default function EmployeesBySalaryRangeUnified({ title, analysis }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { marketMetrics, companyMetrics } = useMetricsStore((state) => ({
    marketMetrics: state.marketMetrics,
    companyMetrics: state.companyMetrics,
  }));
  const { getChart, maxX, charts } = useChartStore((state) => ({
    getChart: state.getChart,
    maxX: state.maxX,
    maxY: state.maxY,
    charts: state.charts,
  }));

  const { [Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH]: isLoading } =
    useChartLoadingStore();

  const chart = useMemo(
    () => getChart(charts, Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH),
    [charts, getChart]
  );

  return (
    <BaseChartContainer size={12}>
      <ChartHeader
        title={title}
        analysis={analysis}
        chart={Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH}
        isCompare
      />
      <HistogramBarChart
        isHistogramLoading={isLoading}
        max={{
          x: maxX,
          y: "auto",
        }}
        referenceLines={[
          {
            color: theme.palette.primary.main,
            value: _.isNumber(companyMetrics.average)
              ? companyMetrics.average
              : 0,
            label: t("CHARTS_DASHBOARD.COMPANY_AVERAGE"),
            id: "MED01",
            inactive: false,
            canHide: true,
            order: 2,
            position: "insideTop",
          },
          {
            color: alpha(theme.palette.grey[700], 0.5),
            value: _.isNumber(marketMetrics.average)
              ? marketMetrics.average
              : 0,
            label: t("CHARTS_DASHBOARD.MARKET_AVERAGE"),
            id: "MED02",
            inactive: false,
            canHide: true,
            order: 4,
            position: "inside",
          },
        ]}
        bars={[
          {
            color: theme.palette.primary.main,
            label: `${t(
              "CHARTS_DASHBOARD.EMPLOYEE_PERCENTAGE_BY_SALARY_RANGE"
            )} - ${t("COMPANY")}`,
            id: "ID01",
            inactive: false,
            canHide: true,
            order: 1,
            dimensionName: "company",
          },
          {
            color: alpha(theme.palette.grey[700], 0.5),
            label: `${t(
              "CHARTS_DASHBOARD.EMPLOYEE_PERCENTAGE_BY_SALARY_RANGE"
            )} - ${t("MARKET")}`,
            id: "ID02",
            inactive: false,
            canHide: true,
            order: 3,
            dimensionName: "market",
          },
        ]}
        histogram={chart}
        legendHeight={50}
      />
    </BaseChartContainer>
  );
}

EmployeesBySalaryRangeUnified.propTypes = {
  title: PropTypes.string.isRequired,
  analysis: PropTypes.instanceOf(AnalysisPresenter).isRequired,
};
