import React, { useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import GradusModal from "../../components/GradusComponents/GradusModal/GradusModal";
import { GradusTable } from "../../components/GradusComponents/GradusTable/GradusTable";
import GradusTableColumn from "../../components/GradusComponents/GradusTable/GradusTableColumn";
import GradusButton from "../../components/Buttons/GradusButton";
import useDownloadFile from "../../hooks/useDownloadFile";
import payrollExampleApi from "../../apis/payrollExampleApi";
import withGradusLoading from "../../hocs/withGradusLoading";

const PayrollExampleModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  const columns = useMemo(
    () => [
      new GradusTableColumn({
        headerLabel: t("SALARY", { lng: language }),
        field: "salary",
        hasFilter: false,
        order: 0,
      }),
      new GradusTableColumn({
        field: "jobOccupation",
        headerLabel: t("OCCUPATION", { lng: language }),
        hasFilter: false,
        order: 1,
      }),
      new GradusTableColumn({
        field: "county",
        headerLabel: `${t("COUNTY", { lng: language })} (${t("OPTIONAL", {
          lng: language,
        })})`,
        hasFilter: false,
        order: 2,
      }),
      new GradusTableColumn({
        field: "state",
        headerLabel: `${t("STATE", { lng: language })} (${t("OPTIONAL", {
          lng: language,
        })})`,
        hasFilter: false,
        order: 3,
      }),
      new GradusTableColumn({
        field: "customColumn",
        headerLabel: `${t("CUSTOM_COLUMN", { lng: language })} 1 (${t(
          "OPTIONAL",
          { lng: language }
        )})`,
        hasFilter: false,
        order: 4,
      }),
      new GradusTableColumn({
        field: "customColumn",
        headerLabel: `${t("CUSTOM_COLUMN", { lng: language })} 2 (${t(
          "OPTIONAL",
          { lng: language }
        )})`,
        hasFilter: false,
        order: 5,
      }),
      new GradusTableColumn({
        field: "customColumn",
        headerLabel: `${t("CUSTOM_COLUMN", { lng: language })} 3 (${t(
          "OPTIONAL",
          { lng: language }
        )})`,
        hasFilter: false,
        order: 6,
      }),
      new GradusTableColumn({
        field: "customColumn",
        headerLabel: `${t("CUSTOM_COLUMN", { lng: language })} 4 (${t(
          "OPTIONAL",
          { lng: language }
        )})`,
        hasFilter: false,
        order: 7,
      }),
    ],
    [language, t]
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownloadButtonClick = useDownloadFile(
    setIsLoading,
    () => payrollExampleApi.getPayrollExample(),
    "template",
    "xlsx",
    _.noop
  );

  const ButtonWithLoading = withGradusLoading(GradusButton);

  return (
    <GradusModal
      open={isOpen}
      handleClose={handleClose}
      containerStyle={{
        pt: 3,
        pb: 1,
        px: 6,
        marginTop: "80px",
      }}
    >
      <Typography textAlign="left" variant="h5" sx={{ mb: 1 }}>
        {t("PAYROLL_EXAMPLE")}
      </Typography>
      <GradusTable
        columns={columns}
        rows={[
          {
            id: "row1",
            salary: 10500.4,
            jobOccupation: 252305,
            county: "São Carlos",
            state: "São Paulo",
            customColumn: t("VALUE"),
          },
        ]}
        storeName="payroll-example"
        hasCheckbox={false}
        hasPagination={false}
        hasColumnSelector={false}
      />
      <Stack direction="row-reverse" mt={2}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ButtonWithLoading
          variant="primary"
          colorVariant="primary"
          onClick={handleDownloadButtonClick}
          buttonProps={{
            style: { width: "auto" },
          }}
          isLoading={isLoading}
        >
          {t("DOWNLOAD_TEMPLATE")}
        </ButtonWithLoading>
      </Stack>
    </GradusModal>
  );
};

export default PayrollExampleModal;
