import MarketFilter from "../MarketFilter";
import AnalysisPresenter from "../AnalysisPresenter";

export default class ConsulteAnalysisPresenter implements AnalysisPresenter {
  private readonly id: number;

  private readonly name: string;

  private readonly marketFilters: MarketFilter;

  constructor({ id = 0, name = "", marketFilters = new MarketFilter({}) }) {
    this.id = id;
    this.name = name;
    this.marketFilters = new MarketFilter(marketFilters || {});
  }

  getId(): number {
    return this.id;
  }

  getMarketFilters(): MarketFilter {
    return this.marketFilters;
  }

  getName(): string {
    return this.name;
  }
}
