import CompareAnalysisSavedFiltersPresenter from "../CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersPresenter";
import AnalysisPresenter from "../AnalysisPresenter";
import MarketFilter from "../MarketFilter";
import PayrollFilter from "../PayrollFilter";

export default class CompareAnalysisPresenter implements AnalysisPresenter {
  private readonly id: number;

  private readonly name: string;

  private readonly activeFilter: CompareAnalysisSavedFiltersPresenter;

  private readonly consentSecurityPolicy: boolean;

  constructor({
    id = 0,
    name = "",
    consentSecurityPolicy = false,
    activeFilter = new CompareAnalysisSavedFiltersPresenter({}),
  }: {
    id?: number;
    name?: string;
    consentSecurityPolicy?: boolean;
    activeFilter?: CompareAnalysisSavedFiltersPresenter;
  }) {
    this.id = id;
    this.name = name;
    this.activeFilter = new CompareAnalysisSavedFiltersPresenter({
      ...activeFilter,
    });
    this.consentSecurityPolicy = consentSecurityPolicy;
  }

  getId(): number {
    return this?.id;
  }

  getMarketFilters(): MarketFilter {
    return new MarketFilter(this.getActiveFilter()?.getMarketFilters() || {});
  }

  getCompanyFilters(): PayrollFilter {
    return new PayrollFilter(this.getActiveFilter()?.getCompanyFilters() || {});
  }

  getName(): string {
    return this.name;
  }

  getActiveFilterId(): number {
    return this.getActiveFilter()?.getId() || 0;
  }

  getActiveFilter(): CompareAnalysisSavedFiltersPresenter {
    return this.activeFilter;
  }
}
