import React, { useContext, useMemo, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GradusButton from "../../components/Buttons/GradusButton";
import userApi from "../../apis/userApi";
import AuthenticationWrapper from "./AuthenticationWrapper";
import AlertContext from "../../contexts/AlertContext";
import withGradusLoading from "../../hocs/withGradusLoading";

function ForgotPasswordContent() {
  const { fireAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await userApi.recoverPassword({ usernameOrEmail });
      fireAlert(t("ALERTS.LINK_FOR_PASSWORD_RESET_MESSAGE"), false, 15000);
      navigate("/login", { replace: true });
    } catch (error) {
      fireAlert(t("ALERTS.USERNAME_OR_EMAIL_NOT_FOUND"), true);
    } finally {
      setIsLoading(false);
    }
  };

  const BoxWithLoading = useMemo(() => withGradusLoading(Box), []);

  return (
    <BoxWithLoading isLoading={isLoading}>
      <Grid item>
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: "primary.main", fontWeight: "bold" }}
        >
          {t("REDEFINE_PASSWORD")}
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                placeholder={t("LOGIN_PAGE.USERNAME_OR_EMAIL")}
                fullWidth
                name="usernameOrEmail"
                variant="outlined"
                required
                autoFocus
                onChange={(event) => setUsernameOrEmail(event.target.value)}
              />
            </Grid>
            <Grid item>
              <GradusButton
                variant="primary"
                colorVariant="primary"
                buttonProps={{ type: "submit" }}
                sx={{ width: 232 }}
              >
                {t("REDEFINE_PASSWORD")}
              </GradusButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BoxWithLoading>
  );
}

export default function ForgotPassword() {
  return (
    <AuthenticationWrapper height="150px">
      <ForgotPasswordContent />
    </AuthenticationWrapper>
  );
}
