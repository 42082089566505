import FilterOptionDTO from "../FilterOptionDTO";
import PayrollConfigurationDTO from "./PayrollConfigurationDTO";
import CompanySize from "../../models/CompanySize";

export interface ParameterByPosition {
  [key: number]: string | null;
}

export default class ConfigurationView {
  id: number;

  name: string;

  isUsed: boolean;

  companySize: FilterOptionDTO | null;

  cnaes: FilterOptionDTO[];

  salaryColumn: string | null;

  jobOccupationColumn: string | null;

  countyColumn: string | null;

  stateColumn: string | null;

  col1Name: string | null;

  col2Name: string | null;

  col3Name: string | null;

  col4Name: string | null;

  constructor({
    id,
    name,
    isUsed,
    companySize,
    cnaes,
    salaryColumn,
    jobOccupationColumn,
    countyColumn,
    stateColumn,
    col1Name,
    col2Name,
    col3Name,
    col4Name,
  }: ConfigurationView) {
    this.id = id;
    this.name = name;
    this.isUsed = isUsed;
    this.companySize = companySize;
    this.cnaes = cnaes;
    this.salaryColumn = salaryColumn;
    this.jobOccupationColumn = jobOccupationColumn;
    this.countyColumn = countyColumn;
    this.stateColumn = stateColumn;
    this.col1Name = col1Name;
    this.col2Name = col2Name;
    this.col3Name = col3Name;
    this.col4Name = col4Name;
  }

  static fromDTO(dto: PayrollConfigurationDTO): ConfigurationView {
    return new ConfigurationView({
      ...dto,
      companySize: CompanySize.getOptionByCompanySize(dto.companySize),
    });
  }

  static buildDefault(computedCompanySize: number) {
    return new ConfigurationView({
      id: -1,
      name: "",
      isUsed: false,
      companySize: CompanySize.getOptionByCompanySize(computedCompanySize),
      cnaes: [],
      salaryColumn: null,
      jobOccupationColumn: null,
      countyColumn: null,
      stateColumn: null,
      col1Name: null,
      col2Name: null,
      col3Name: null,
      col4Name: null,
    });
  }
}
