import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import GradusIcon from "../../GradusComponents/GradusIcon";
import Chart from "../Chart";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import ChartOptionsModal from "./ChartOptionsModal";

export default function ChartOptions({
  isOpen,
  setIsOpen,
  chart,
  children,
  isCompare,
  analysis,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  chart: Chart;
  children: ReactNode;
  isCompare: boolean;
  analysis: AnalysisPresenter;
}) {
  const { t } = useTranslation();

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} data-testid="chart-options">
        <GradusIcon
          title={t("CHART_OPTIONS")}
          icon={light("circle-ellipsis-vertical")}
        />
      </IconButton>
      <ChartOptionsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        chart={chart}
        isCompare={isCompare}
        analysis={analysis}
      >
        {children}
      </ChartOptionsModal>
    </>
  );
}
