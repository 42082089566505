import React from "react";
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import TextField from "../TextField/TextField";

// eslint-disable-next-line react/prop-types
const PasswordInput = ({ InputProps, ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <MuiTextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Visualizar senha"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  );
};

const PasswordTextField = (props) => (
  <TextField {...props} components={{ input: PasswordInput }} />
);
export default PasswordTextField;
