import createSingletonStore from "../stores/createSingletonStore";

const KEY = "ngStorage-token";

const { useStore: useToken, setState } = createSingletonStore(
  JSON.parse(localStorage.getItem(KEY) || '""') || null
);

export { useToken };

export function setToken(token) {
  if (token) {
    localStorage.setItem(KEY, JSON.stringify(token));
    setState(token);
  } else {
    localStorage.removeItem(KEY);
    setState(null);
  }
}

export function getToken() {
  const token = localStorage.getItem(KEY);
  const state = useToken.getState();
  if (token === null && state !== token) {
    setToken(null);
    return null;
  }
  const parsedToken = JSON.parse(token);
  if (token !== JSON.stringify(state)) {
    setToken(parsedToken);
  }
  return parsedToken;
}
