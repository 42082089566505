import { styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const ExitButton = styled(IconButton)(({ theme }) => ({
  right: theme.spacing(1),
  top: theme.spacing(0.5),
  zIndex: 100,
}));

export default ExitButton;
