export default class GlobalParameters {
  correctionIndex: number;

  constructor({ correctionIndex }: { correctionIndex?: number }) {
    this.correctionIndex = correctionIndex || 0;
  }

  static from(newParameters: GlobalParameters) {
    return new GlobalParameters({
      correctionIndex: newParameters.correctionIndex,
    });
  }
}
