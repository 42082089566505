import React, { useCallback } from "react";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import useDashboardVisualOptionsStore from "../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import GradusIconButton from "../GradusComponents/GradusIconButton";

export default function PresentationModeButton() {
  const { isPresentationMode, setIsPresentationMode } =
    useDashboardVisualOptionsStore((state) => ({
      setIsPresentationMode: state.setIsPresentationMode,
      isPresentationMode: state.isPresentationMode,
    }));

  const { t } = useTranslation();

  const handleExitPresentationMode = useCallback(() => {
    setIsPresentationMode(false);
    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }, [setIsPresentationMode]);

  const handleEnterPresentationMode = useCallback(() => {
    setIsPresentationMode(true);
    if (
      !(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      )
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    }
  }, [setIsPresentationMode]);

  return (
    <GradusIconButton
      onClick={
        isPresentationMode
          ? handleExitPresentationMode
          : handleEnterPresentationMode
      }
      title={
        isPresentationMode
          ? t("DASHBOARD_HEADER.EXIT_PRESENTATION_MODE")
          : t("DASHBOARD_HEADER.ENTER_PRESENTATION_MODE")
      }
      icon={
        isPresentationMode
          ? light("arrow-down-left-and-arrow-up-right-to-center")
          : light("arrow-up-right-and-arrow-down-left-from-center")
      }
    />
  );
}
