export default class SalaryAverageAndQuantity {
  average: number;

  quantity: number;

  constructor({
    average = 0,
    quantity = 0,
  }: {
    average?: number;
    quantity?: number;
  }) {
    this.average = average;
    this.quantity = quantity;
  }
}
