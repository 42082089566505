import _ from "lodash";
import FilterOptionDTO from "../../../dtos/FilterOptionDTO";

enum Subchart {
  HIGHER_AVERAGE = 1,
  HIGHER_COSTS,
  COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE,
  COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE,
}

const subchartById: Record<Subchart, FilterOptionDTO> = {
  [Subchart.HIGHER_AVERAGE]: new FilterOptionDTO({
    id: Subchart.HIGHER_AVERAGE,
    name: `CHARTS_DASHBOARD.HIGHER_AVERAGE`,
  }),
  [Subchart.HIGHER_COSTS]: new FilterOptionDTO({
    id: Subchart.HIGHER_COSTS,
    name: `CHARTS_DASHBOARD.HIGHER_COSTS`,
  }),
  [Subchart.COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE]: new FilterOptionDTO({
    id: Subchart.COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE,
    name: `CHARTS_DASHBOARD.COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE`,
  }),
  [Subchart.COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE]: new FilterOptionDTO({
    id: Subchart.COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE,
    name: `CHARTS_DASHBOARD.COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE`,
  }),
};

export function getAll() {
  const subcharts: Subchart[] = [
    Subchart.HIGHER_AVERAGE,
    Subchart.HIGHER_COSTS,
    Subchart.COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE,
    Subchart.COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE,
  ];
  return subcharts;
}

export function getAllFilterOption() {
  return _.chain(subchartById).values().sortBy("id").value();
}

export function defaultSubchart() {
  return subchartById[Subchart.HIGHER_AVERAGE];
}

export default Subchart;
