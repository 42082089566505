import React from "react";
import { Trans, useTranslation } from "react-i18next";
import PrivacyPolicyPage from "./PrivacyPolicy.style";

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <PrivacyPolicyPage data-testid="privacy-policy">
      <h1>{t("PRIVACY_POLICY.TITLE")}</h1>
      <h2>{t("PRIVACY_POLICY.OBJECTIVES")}</h2>
      <p>{t("PRIVACY_POLICY.OBJECTIVES_PARAGRAPH")}</p>

      <h3>{t("PRIVACY_POLICY.ABOUT_THE_COMPANY_AND_ITS_CONTRACTS")}</h3>
      <p>
        {t("PRIVACY_POLICY.ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_1")}
      </p>
      <p>
        {t("PRIVACY_POLICY.ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_2")}
      </p>

      <h3>
        {t(
          "PRIVACY_POLICY.WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM"
        )}
      </h3>
      <p>
        {t(
          "PRIVACY_POLICY.WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_1"
        )}
      </p>
      <p>
        {t(
          "PRIVACY_POLICY.WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_2"
        )}
      </p>
      <p>
        {t(
          "PRIVACY_POLICY.WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_3"
        )}
      </p>
      <p>
        {t(
          "PRIVACY_POLICY.WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_4"
        )}
      </p>

      <h3>{t("PRIVACY_POLICY.WHY_DO_WE_COLLECT_PERSONAL_DATA")}</h3>
      <p>{t("PRIVACY_POLICY.WHY_DO_WE_COLLECT_PERSONAL_DATA_PARAGRAPH_1")}</p>
      <ul>
        <Trans
          i18nKey="PRIVACY_POLICY.WHY_DO_WE_COLLECT_PERSONAL_DATA_LIST"
          components={{ li: <li /> }}
        />
      </ul>

      <h3>{t("PRIVACY_POLICY.WHICH_DATA_DO_WE_COLLECT")}</h3>
      <ul>
        <Trans
          i18nKey="PRIVACY_POLICY.WHICH_DATA_DO_WE_COLLECT_LIST"
          components={{ li: <li /> }}
        />
      </ul>

      <h3>{t("PRIVACY_POLICY.FOR_HOW_LONG_DO_WE_STORE_THE_DATA")}</h3>
      <p>{t("PRIVACY_POLICY.FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_1")}</p>
      <p>{t("PRIVACY_POLICY.FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_2")}</p>

      <h3>{t("PRIVACY_POLICY.REGARDING_DATA_TRANSFER")}</h3>
      <p>{t("PRIVACY_POLICY.REGARDING_DATA_TRANSFER_PARAGRAPH_1")}</p>

      <h3>{t("PRIVACY_POLICY.WHAT_ARE_THE_USER_RIGHTS")}</h3>
      <ul>
        <Trans
          i18nKey="PRIVACY_POLICY.WHAT_ARE_THE_USER_RIGHTS_LIST"
          components={{ li: <li /> }}
        />
      </ul>

      <h3>{t("PRIVACY_POLICY.WHAT_ARE_THE_USER_RIGHTS")}</h3>
      <p>{t("PRIVACY_POLICY.WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_1")}</p>
      <p>{t("PRIVACY_POLICY.WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_2")}</p>

      <h3>
        {t(
          "PRIVACY_POLICY.REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY"
        )}
      </h3>
      <p>
        {t(
          "PRIVACY_POLICY.REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_1"
        )}
      </p>
      <p>
        {t(
          "PRIVACY_POLICY.REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_2"
        )}
      </p>
    </PrivacyPolicyPage>
  );
}
