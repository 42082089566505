import { styled } from "@mui/material/styles";
import _ from "lodash";
import { alpha, useTheme } from "@mui/material";
import * as React from "react";
import { DefaultTFuncReturn } from "i18next";
import GradusTooltip from "../GradusComponents/GradusTooltip";
import InfoIcon from "../InfoIcon/InfoIcon";

const MetricsCard = styled("div")({
  display: "flex",
  flexDirection: "column",
  fontSize: "14px",
  fontWeight: "bold",
});

const NumericInfo = styled("div")({
  display: "inline-flex",
  justifyContent: "space-evenly",
  fontSize: "22px",
});

const DefaultMetricsCard = ({
  label,
  marketValue,
  companyValue,
  tooltip,
  isCompare,
}: {
  label: string | DefaultTFuncReturn;
  marketValue?: number | string;
  companyValue?: number | string;
  tooltip?: any;
  isCompare: boolean;
}) => {
  const theme = useTheme();

  let tooltipComponent = (
    <span style={{ display: "inline-flex", alignItems: "baseline" }}>
      {label}
      {!_.isEmpty(tooltip) && (
        <InfoIcon
          style={{
            marginLeft: theme.spacing(0.5),
            cursor: "help",
          }}
        />
      )}
    </span>
  );

  if (!_.isEmpty(tooltip)) {
    tooltipComponent = (
      <GradusTooltip title={tooltip} arrow={false} placement="top">
        {tooltipComponent}
      </GradusTooltip>
    );
  }

  return (
    <MetricsCard>
      {tooltipComponent}
      <NumericInfo>
        <span
          style={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          {isCompare ? (
            <>
              <span
                style={{
                  color: theme.palette.primary.main,
                  paddingRight: theme.spacing(1),
                }}
              >
                {companyValue}
              </span>
              <span
                style={{ fontSize: "1rem", paddingRight: theme.spacing(1) }}
              >
                X
              </span>
            </>
          ) : null}
          <span
            style={{
              color: alpha(theme.palette.grey[700], 0.5),
            }}
          >
            {marketValue}
          </span>
        </span>
      </NumericInfo>
    </MetricsCard>
  );
};

DefaultMetricsCard.defaultProps = {
  marketValue: "-",
  companyValue: "-",
  tooltip: "",
};

export default DefaultMetricsCard;
