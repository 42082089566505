import api from "./api";
import CompareAnalysisPresenter from "../dtos/CompareAnalysis/CompareAnalysisPresenter";
import CompareAnalysisUpdateDTO from "../dtos/CompareAnalysis/CompareAnalysisUpdateDTO";
import CompareAnalysisRenameDTO from "../dtos/CompareAnalysis/CompareAnalysisRenameDTO";

const baseUrl = "/api/v1/compare-analysis";

const compareAnalysisApi = {
  createAnalysis: (
    file: File,
    payrollConfigurationId: number,
    name: string,
    securityPolicyConsent: boolean
  ) => {
    const json = JSON.stringify({
      name,
      payrollConfigurationId,
      securityPolicyConsent,
    });
    const analysis = new Blob([json], {
      type: "application/json",
    });

    const data = new FormData();
    data.append("analysis", analysis);
    data.append("file", file, file.name);

    return api.post(baseUrl, data);
  },

  getAll: () =>
    new Promise<CompareAnalysisPresenter[]>((resolve, reject) => {
      api.get(baseUrl).then((response) => {
        const allAnalysis = response.data.map(
          (analysis: CompareAnalysisPresenter) =>
            new CompareAnalysisPresenter({ ...analysis })
        );
        return resolve(allAnalysis);
      }, reject);
    }),

  deleteAnalysis: (deleteId: number) => api.delete(`${baseUrl}/${deleteId}`),

  renameAnalysis: async (
    newName: string,
    updateId: number
  ): Promise<string> => {
    const response = await api.put(
      `${baseUrl}/rename`,
      new CompareAnalysisRenameDTO(updateId, newName)
    );
    return response.data;
  },

  updateAnalysis: (
    analysis: CompareAnalysisUpdateDTO
  ): Promise<CompareAnalysisPresenter> =>
    new Promise<CompareAnalysisPresenter>((resolve, reject) => {
      api
        .put(baseUrl, analysis)
        .then(
          (response) => resolve(new CompareAnalysisPresenter(response.data)),
          reject
        );
    }),

  get: (analysisId: number): Promise<CompareAnalysisPresenter> =>
    new Promise<CompareAnalysisPresenter>((resolve, reject) => {
      api.get(`${baseUrl}/${analysisId}`).then((response) => {
        resolve(new CompareAnalysisPresenter(response.data));
      }, reject);
    }),

  changeFilter: async (analysisId: number, filterId: number) =>
    api.put(`${baseUrl}/${analysisId}/change-filter/${filterId}`),
};

export default compareAnalysisApi;
