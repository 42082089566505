import Container from "@mui/material/Container";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import CompareDashboardHeader from "../../components/Dashboard/CompareDashboardHeader";
import MetricsBoard from "../../components/MetricsBoard/MetricsBoard";
import { handleApiError } from "../../utils/ErrorUtils";
import AlertContext from "../../contexts/AlertContext";
import ChartsOrganization from "../../components/Charts/ChartsOrganization";
import AppliedFiltersList from "../../components/AppliedFilters/AppliedFiltersList";
import withGradusLoading from "../../hocs/withGradusLoading";
import useDownloadFile from "../../hooks/useDownloadFile";
import reportsApi from "../../apis/reportsApi";
import useDashboardVisualOptionsStore from "../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import useCompareAnalysisStore from "../../stores/useCompareAnalysisStore";
import useMetricsStore from "../../stores/useMetricsStore";
import useChartParametersStore from "../../stores/ChartParametersStore/useChartParametersStore";
import raisRawDataApi from "../../apis/raisRawDataApi";
import CompareGetChartDTO from "../../dtos/chartGenerate/CompareGetChartDTO";
import BaseFilter from "../../dtos/BaseFilter";

const CompareDashboard = () => {
  const { fireAlert } = useContext(AlertContext);

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const { id: analysisId } = useParams();
  const [showFilters, setShowFilters] = useState(false);

  const [isDownloadingAnalysisReport, setIsDownloadingAnalysisReport] =
    useState(false);
  const [isDownloadingRaisRawCSV, setIsDownloadingRaisRawCSV] = useState(false);

  const { selectedChartOptions } = useDashboardVisualOptionsStore((state) => ({
    selectedChartOptions: state.selectedChartOptions,
  }));
  const { globalParameters } = useChartParametersStore();

  const { isLoadingCompareMetrics } = useMetricsStore();

  const { initDashboard, renameAnalysis, analysis } = useCompareAnalysisStore(
    (state) => ({
      initDashboard: state.initDashboard,
      renameAnalysis: state.renameAnalysis,
      analysis: state.analysis,
    })
  );

  const handleDownloadAnalysisReport = useDownloadFile(
    setIsDownloadingAnalysisReport,
    () =>
      reportsApi.generateCompareAnalysisReport(
        analysis,
        _.map(selectedChartOptions, "id"),
        globalParameters.applied.correctionIndex
      ),
    analysis.getName(),
    "xlsx",
    () => fireAlert(t("ALERTS.GENERATING_REPORT"), false, 5000)
  );

  const handleDownloadRaisRawCSV = useDownloadFile(
    setIsDownloadingRaisRawCSV,
    () =>
      raisRawDataApi.getRaisRawCSV(
        new CompareGetChartDTO({
          filterId: analysis.getActiveFilterId(),
          correctionIndex: globalParameters.applied.correctionIndex,
          shouldUseNewJobFilters: false,
          newJobFilters: new BaseFilter({}),
          analysisId: analysis.getId(),
        })
      ),
    analysis.getName(),
    "csv",
    () => fireAlert(t("ALERTS.GENERATING_REPORT"), false, 5000)
  );

  const handleRenameAnalysis = useCallback(
    async (newName: string) => {
      try {
        await renameAnalysis(newName, Number(analysisId));
        fireAlert(t("ALERTS.SUCCESSFULLY_RENAMED_ANALYSIS", { lng: language }));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      }
    },
    [analysisId, fireAlert, language, renameAnalysis, t]
  );

  // Os gráficos estavam piscando ao usar useEffect
  useLayoutEffect(() => {
    (async () => {
      try {
        await initDashboard(Number(analysisId));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      }
    })();
  }, [analysisId, fireAlert, initDashboard, t]);

  const ContainerWithLoading = useMemo(() => withGradusLoading(Container), []);

  return (
    <Container sx={{ mb: 5, mt: 1 }}>
      <CompareDashboardHeader
        analysis={analysis}
        renameAnalysis={handleRenameAnalysis}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
        downloadAnalysisReport={handleDownloadAnalysisReport}
        isDownloadingAnalysisReport={isDownloadingAnalysisReport}
        downloadRaisRawCSV={handleDownloadRaisRawCSV}
        isDownloadingRaisRawCSV={isDownloadingRaisRawCSV}
      />

      <AppliedFiltersList
        key={String(analysis)}
        isShown={showFilters}
        isCompare
        companyFilter={{
          filter: analysis.getCompanyFilters(),
          title: t("COMPANY"),
        }}
        marketFilter={{
          filter: analysis.getMarketFilters(),
          title: t("MARKET"),
        }}
      />

      <Divider sx={{ mt: 2.5, mb: 3, mx: 3 }} />

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ContainerWithLoading
        isLoading={isLoadingCompareMetrics()}
        gradusLoadingContainerSx={{ mb: 2, mt: 2 }}
        disableGutters
      >
        <MetricsBoard isCompare />
        <ChartsOrganization analysis={analysis} isCompare />
      </ContainerWithLoading>
    </Container>
  );
};

export default CompareDashboard;
