import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTheme } from "@mui/material/styles";
import GradusSupportButton from "./GradusSupportButton";

export default function ExpandToggleButton({
  handleToggle,
  show,
  buttonTitle,
  buttonSx,
}) {
  const theme = useTheme();
  return (
    <GradusSupportButton
      sx={{
        fontSize: 12,
        color: "grey.700",
        display: "flex",
        justifyContent: "center",
        ...buttonSx,
      }}
      onClick={() => handleToggle()}
    >
      {buttonTitle}
      <FontAwesomeIcon
        icon={show ? solid("angle-up") : solid("angle-down")}
        style={{ fontSize: 16, marginLeft: theme.spacing(0.5) }}
      />
    </GradusSupportButton>
  );
}

ExpandToggleButton.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttonSx: PropTypes.object,
};

ExpandToggleButton.defaultProps = {
  buttonSx: {},
};
