import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import useFilterStore from "../../../../stores/FilterStore/useFilterStore";
import withHideOnCondition from "../../../../hocs/withHideOnCondition";
import GradusIcon from "../../../GradusComponents/GradusIcon";
import { formatInteger } from "../../../../utils/NumberFormatUtils";

function CompanyAndMarketLine({
  company,
  market,
  isCompare,
}: {
  company: string;
  market: string;
  isCompare: boolean;
}) {
  const theme = useTheme();

  return (
    <Typography sx={{ fontWeight: "bold" }}>
      {isCompare && (
        <>
          <span style={{ color: theme.palette.primary.main }}>{company}</span>
          <span
            style={{
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            }}
          >
            x
          </span>
        </>
      )}
      <span style={{ color: alpha(theme.palette.grey[700], 0.5) }}>
        {market}
      </span>
    </Typography>
  );
}

function InsufficientData({ isInsufficient }: { isInsufficient: boolean }) {
  const theme = useTheme();

  const { t } = useTranslation();

  if (!isInsufficient) {
    return null;
  }

  return (
    <GradusIcon
      title={t("FILTER_MODAL.INSUFFICIENT_DATA_DISCLAIMER")}
      icon={regular("triangle-exclamation")}
      iconStyle={{
        color: theme.palette.warning.main,
        marginRight: theme.spacing(1),
      }}
      placement="top-start"
    />
  );
}

export default function FilterResultsCounter({
  isCompare,
}: {
  isCompare: boolean;
}) {
  const theme = useTheme();

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const {
    arePreviewsLoading,
    getCompanyQuantityPreview,
    getMarketQuantityPreview,
    areSelectedMarketFiltersInsufficient,
    filterResultPreview,
  } = useFilterStore((state) => ({
    arePreviewsLoading: state.arePreviewsLoading,
    getCompanyQuantityPreview: state.getCompanyQuantityPreview,
    getMarketQuantityPreview: state.getMarketQuantityPreview,
    areSelectedMarketFiltersInsufficient:
      state.areSelectedMarketFiltersInsufficient,
    filterResultPreview: state.filterResultPreview,
  }));

  const CountLoading = React.memo(
    withHideOnCondition({
      ComponentA: CircularProgress,
      ComponentB: Typography,
      shouldShowComponentA: arePreviewsLoading(filterResultPreview, isCompare),
    })
  );

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography sx={{ textAlign: "center" }}>
        <InsufficientData
          isInsufficient={areSelectedMarketFiltersInsufficient}
        />
        {t("FILTER_MODAL.FILTERING_RESULT")}
        <GradusIcon
          title={t("FILTER_MODAL.FILTERING_RESULT_TOOLTIP")}
          icon={solid("question-circle")}
          iconStyle={{
            fontSize: "15px",
            color: alpha(theme.palette.grey[700], 0.3),
            marginLeft: theme.spacing(1),
          }}
          placement="right-start"
        />
      </Typography>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CountLoading
        componentAProps={{
          sx: {
            fontWeight: "bold",
          },
        }}
        componentBProps={{ size: "24px" }}
      >
        <CompanyAndMarketLine
          isCompare={isCompare}
          company={formatInteger(
            getCompanyQuantityPreview(filterResultPreview),
            language
          )}
          market={formatInteger(
            getMarketQuantityPreview(filterResultPreview),
            language
          )}
        />
      </CountLoading>
      <CompanyAndMarketLine
        isCompare={isCompare}
        company={t("COMPANY")}
        market={t("MARKET")}
      />
    </Stack>
  );
}
