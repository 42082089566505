import React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1),
  "& div > .Mui-selected": {
    color: "white",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "6px",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "70%",
    backgroundColor: "white",
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
}));

export default StyledTabs;
