enum Chart {
  EMPLOYEES_BY_SALARY_RANGE_COMPANY = 1,
  EMPLOYEES_BY_SALARY_RANGE_MARKET,
  EMPLOYEES_BY_SALARY_RANGE_BOTH,
  SALARY_RANGE_BY_STATE,
  SALARY_RANGE_BY_COMPANY_SIZE,
  SALARY_AVERAGE_BY_OCCUPATION,
}

export function getChartById(id: number) {
  switch (id) {
    case Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY.valueOf():
      return Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY;

    case Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET.valueOf():
      return Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET;

    case Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH.valueOf():
      return Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH;

    case Chart.SALARY_RANGE_BY_STATE.valueOf():
      return Chart.SALARY_RANGE_BY_STATE;

    case Chart.SALARY_RANGE_BY_COMPANY_SIZE.valueOf():
      return Chart.SALARY_RANGE_BY_COMPANY_SIZE;

    case Chart.SALARY_AVERAGE_BY_OCCUPATION.valueOf():
      return Chart.SALARY_AVERAGE_BY_OCCUPATION;

    default:
      return null;
  }
}

export function getAll() {
  const charts: Chart[] = [
    Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY,
    Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET,
    Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH,
    Chart.SALARY_RANGE_BY_STATE,
    Chart.SALARY_RANGE_BY_COMPANY_SIZE,
    Chart.SALARY_AVERAGE_BY_OCCUPATION,
  ];
  return charts;
}

export default Chart;
