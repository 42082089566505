import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
  color: "white",
  fontWeight: "bold",
}));

export default StyledTab;
