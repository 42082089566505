/* eslint-disable react/prop-types */

import React from "react";
import useDashboardVisualOptionsStore from "../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";

function withHideOnPresentationMode(Component) {
  const WithHideOnPresentationMode = ({ forwardedRef, ...props }) => {
    const { isPresentationMode } = useDashboardVisualOptionsStore((state) => ({
      isPresentationMode: state.isPresentationMode,
    }));
    if (isPresentationMode) {
      return null;
    }
    return <Component ref={forwardedRef} {...props} />;
  };

  return React.forwardRef(({ ...props }, ref) => (
    <WithHideOnPresentationMode forwardedRef={ref} {...props} />
  ));
}

export default withHideOnPresentationMode;
