export default class FilterOptionDTO {
  id: number;

  name: string;

  constructor({ id = -1, name = "" }: { id?: number; name?: string }) {
    this.id = id;
    this.name = name;
  }

  isEmpty() {
    return this.id < 0 || this.name === "";
  }
}
