import { Grid, Paper } from "@mui/material";
import React, { ReactNode } from "react";
import FontYearRais from "../../FontYearRais/FontYearRais";

export default function BaseChartContainer({
  children,
  size,
}: {
  children: ReactNode;
  size: number;
}) {
  return (
    <Grid item xs={size} md={size} lg={size} xl={size}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: size === 6 ? "350px" : "500px",
        }}
      >
        {children}
        <FontYearRais />
      </Paper>
    </Grid>
  );
}
