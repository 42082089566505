/* eslint-disable jsx-a11y/label-has-associated-control,
jsx-a11y/anchor-is-valid,
jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

// FIXME: arrumar os problemas do eslint-disable!
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAlphaDown,
  faSortAlphaUpAlt,
  faSortNumericDown,
  faSortNumericUpAlt,
} from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";

export default function SortTableItems({
  isNumericValue,
  sortAscOrder,
  sortDescOrder,
}) {
  return (
    <div>
      <label>Ordenar: </label>
      {isNumericValue === false ? (
        <>
          <a onClick={sortAscOrder}>
            <FontAwesomeIcon
              icon={faSortAlphaDown}
              style={{ color: "black" }}
            />
          </a>
          <span style={{ color: "black" }}> | </span>
          <a onClick={sortDescOrder}>
            <FontAwesomeIcon
              icon={faSortAlphaUpAlt}
              style={{ color: "black" }}
            />
          </a>
        </>
      ) : (
        <>
          <a onClick={sortAscOrder}>
            <FontAwesomeIcon
              icon={faSortNumericDown}
              style={{ color: "black" }}
            />
          </a>
          <span style={{ color: "black" }}> | </span>
          <a onClick={sortDescOrder}>
            <FontAwesomeIcon
              icon={faSortNumericUpAlt}
              style={{ color: "black" }}
            />
          </a>
        </>
      )}
    </div>
  );
}

SortTableItems.propTypes = {
  isNumericValue: PropTypes.bool,
  sortAscOrder: PropTypes.func.isRequired,
  sortDescOrder: PropTypes.func.isRequired,
};

SortTableItems.defaultProps = {
  isNumericValue: false,
};
