import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import _ from "lodash";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ColumnInput from "./ColumnInput";
import ConfigurationView from "../../dtos/PayrollConfiguration/ConfigurationView";
import ExcelColumn from "./ExcelColumn";

export default function SelectParameterMapping({
  columns,
  tabInfo,
  setTabInfo,
  disabled,
}: {
  columns: ExcelColumn[];
  tabInfo: ConfigurationView;
  setTabInfo: Dispatch<SetStateAction<ConfigurationView>>;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const [hasCounty, setHasCounty] = useState(!_.isEmpty(tabInfo.countyColumn));

  const isColumnBeingUsed = useCallback(
    (column: ExcelColumn) =>
      [
        tabInfo.salaryColumn,
        tabInfo.jobOccupationColumn,
        tabInfo.countyColumn,
        tabInfo.stateColumn,
        tabInfo.col1Name,
        tabInfo.col2Name,
        tabInfo.col3Name,
        tabInfo.col4Name,
      ].includes(column.label),
    [
      tabInfo.countyColumn,
      tabInfo.jobOccupationColumn,
      tabInfo.salaryColumn,
      tabInfo.stateColumn,
      tabInfo.col1Name,
      tabInfo.col2Name,
      tabInfo.col3Name,
      tabInfo.col4Name,
    ]
  );

  const numericColumnInputProps = useMemo(
    () => ({
      isColumnBeingUsed,
      setTabInfo,
      columns: columns.filter(({ type }) => type === "number"),
    }),
    [columns, isColumnBeingUsed, setTabInfo]
  );
  const textualColumnInputProps = useMemo(
    () => ({
      isColumnBeingUsed,
      setTabInfo,
      columns: columns.filter(({ type }) => type === "string"),
    }),
    [columns, isColumnBeingUsed, setTabInfo]
  );
  const customColumnInputProps = useMemo(
    () => ({
      isColumnBeingUsed,
      setTabInfo,
      columns,
    }),
    [columns, isColumnBeingUsed, setTabInfo]
  );

  return (
    <Grid
      sx={{ width: 400 }}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <ColumnInput
        label={t("SALARY")}
        tabInfo={tabInfo}
        tabInfoField="salaryColumn"
        disabled={disabled}
        tooltipText={
          (disabled
            ? t(
                "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
              )
            : "") as string
        }
        required
        {...numericColumnInputProps}
      />
      <ColumnInput
        label={t("OCCUPATION_CBO")}
        tabInfo={tabInfo}
        tabInfoField="jobOccupationColumn"
        disabled={disabled}
        tooltipText={
          (disabled
            ? t(
                "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
              )
            : t("CREATE_MAPPING_MODAL.CBO_EXPLANATION")) as string
        }
        required
        {...numericColumnInputProps}
      />
      <ColumnInput
        label={t("COUNTY")}
        tabInfo={tabInfo}
        tabInfoField="countyColumn"
        {...textualColumnInputProps}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={(_event, newValue: ExcelColumn) => {
          const isRemovingCountyColumn = _.isEmpty(newValue);
          setTabInfo({
            ...tabInfo,
            countyColumn: newValue && newValue.label,
            stateColumn: isRemovingCountyColumn ? null : tabInfo.stateColumn,
          });
          setHasCounty(!isRemovingCountyColumn);
        }}
        disabled={disabled}
        tooltipText={
          (disabled
            ? t(
                "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
              )
            : "") as string
        }
      />
      {hasCounty && (
        <ColumnInput
          label={t("STATE")}
          tabInfo={tabInfo}
          tabInfoField="stateColumn"
          disabled={disabled}
          tooltipText={
            (disabled
              ? "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
              : "") as string
          }
          required={hasCounty}
          {...textualColumnInputProps}
        />
      )}
      <ColumnInput
        key="parameter-1"
        label={`${t("CUSTOM_COLUMN")} 1`}
        tabInfo={tabInfo}
        required={false}
        tabInfoField="col1Name"
        disabled={disabled}
        tooltipText={
          (disabled
            ? "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
            : "") as string
        }
        {...customColumnInputProps}
      />
      <ColumnInput
        key="parameter-2"
        label={`${t("CUSTOM_COLUMN")} 2`}
        tabInfo={tabInfo}
        required={false}
        tabInfoField="col2Name"
        disabled={disabled}
        tooltipText={
          (disabled
            ? "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
            : "") as string
        }
        {...customColumnInputProps}
      />
      <ColumnInput
        key="parameter-3"
        label={`${t("CUSTOM_COLUMN")} 3`}
        tabInfo={tabInfo}
        required={false}
        tabInfoField="col3Name"
        disabled={disabled}
        tooltipText={
          (disabled
            ? "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
            : "") as string
        }
        {...customColumnInputProps}
      />
      <ColumnInput
        key="parameter-4"
        label={`${t("CUSTOM_COLUMN")} 4`}
        tabInfo={tabInfo}
        required={false}
        tabInfoField="col4Name"
        disabled={disabled}
        tooltipText={
          (disabled
            ? "MAPPINGS_TABLE.IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING"
            : "") as string
        }
        {...customColumnInputProps}
      />
    </Grid>
  );
}

SelectParameterMapping.defaultProps = {
  disabled: false,
};
