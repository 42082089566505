import _ from "lodash";
import BaseFilter from "./BaseFilter";

export default class PayrollFilter extends BaseFilter {
  constructor({ col1 = [], col2 = [], col3 = [], col4 = [], ...baseFilters }) {
    super(baseFilters);
    this.col1 = col1;
    this.col2 = col2;
    this.col3 = col3;
    this.col4 = col4;
  }

  toString() {
    return [
      BaseFilter.prototype.toString.call(this),
      this.parameterFiltersToString(),
    ]
      .filter((filter) => !_.isEmpty(filter))
      .join(", ");
  }

  // eslint-disable-next-line class-methods-use-this
  parameterFiltersToString() {
    // FIXME
    // return _.reduce(
    //   this.parameters,
    //   (acc, current) => {
    //     if (!_.isEmpty(current)) {
    //       if (_.isArray(current.values)) {
    //         acc.push(current.values.join(", "));
    //       } else {
    //         acc.push(current.join(", "));
    //       }
    //     }
    //     return acc;
    //   },
    //   []
    // ).join(", ");
    return [];
  }

  onlyWithIds() {
    return new PayrollFilter({
      ...BaseFilter.prototype.onlyWithIds.call(this),
      col1: this.col1,
      col2: this.col2,
      col3: this.col3,
      col4: this.col4,
    });
  }

  customColumnsFilters() {
    const filters = {};
    if (!_.isEmpty(this.col1)) {
      filters.col1 = this.col1;
    }
    if (!_.isEmpty(this.col2)) {
      filters.col2 = this.col2;
    }
    if (!_.isEmpty(this.col3)) {
      filters.col3 = this.col3;
    }
    if (!_.isEmpty(this.col4)) {
      filters.col4 = this.col4;
    }
    return filters;
  }
}
