export default class MarketCompanyWithDifference<T> {
  market: T | object;

  company: T | object;

  difference: number;

  constructor({
    market = {},
    company = {},
    difference = 0,
  }: {
    market?: object | T;
    company?: object | T;
    difference?: number;
  }) {
    this.market = market;
    this.company = company;
    this.difference = difference;
  }
}
