import MarketFilter from "../MarketFilter";
import PayrollFilter from "../PayrollFilter";

export default class CompareAnalysisSavedFiltersCreateDTO {
  getCompareAnalysisId(): number {
    return this.compareAnalysisId;
  }

  getMarketFilters(): MarketFilter {
    return this.marketFilters;
  }

  getCompanyFilters(): PayrollFilter {
    return this.companyFilters;
  }

  private readonly compareAnalysisId: number;

  private readonly marketFilters: MarketFilter;

  private readonly companyFilters: PayrollFilter;

  constructor(
    compareAnalysisId: number,
    marketFilters: MarketFilter,
    companyFilters: PayrollFilter
  ) {
    this.compareAnalysisId = compareAnalysisId;
    this.marketFilters = new MarketFilter(marketFilters || {});
    this.companyFilters = new PayrollFilter(companyFilters || {});
  }
}
