import { create } from "zustand";
import { devtools } from "zustand/middleware";
import ConsulteAnalysisPresenter from "../dtos/ConsulteAnalysis/ConsulteAnalysisPresenter";
import useChartStore from "./ChartStore/useChartStore";
import consulteAnalysisApi from "../apis/consulteAnalysisApi";
import useFilterStore from "./FilterStore/useFilterStore";
import ConsulteAnalysisUpdateDTO from "../dtos/ConsulteAnalysis/ConsulteAnalysisUpdateDTO";
import useFilterOptionsStore from "./FilterOptionsStore/useFilterOptionsStore";

export type ConsulteAnalysisStoreProps = {
  analysis: ConsulteAnalysisPresenter;

  updateAnalysis: () => void;
  getAnalysis: (analysisId: number) => Promise<ConsulteAnalysisPresenter>;
  getAll: () => Promise<ConsulteAnalysisPresenter[]>;
  renameAnalysis: (newName: string, analysisId: number) => Promise<string>;
  deleteAnalysis: (analysisId: number) => void;

  initDashboard: (analysisId: number) => void;
  reloadDashboard: () => void;
  reset: () => void;
};

const initialState = {
  analysis: new ConsulteAnalysisPresenter({}),
};

const useConsulteAnalysisStore = create<ConsulteAnalysisStoreProps>()(
  devtools(
    (set, get) => ({
      ...initialState,

      initDashboard: async (analysisId) => {
        const { getAnalysis, reset } = get();
        const { initCharts } = useChartStore.getState();
        const { initOptions } = useFilterOptionsStore.getState();

        reset();
        const analysis = await getAnalysis(analysisId);
        await initCharts(false, analysis);
        await initOptions(analysis);
      },

      reloadDashboard: () => {
        const { initDashboard, analysis } = get();
        initDashboard(analysis.getId());
      },

      reset: () => {
        set(initialState);
      },

      updateAnalysis: () =>
        new Promise<void>((resolve, reject) => {
          const { applyFilters } = useFilterStore.getState();
          const { appliedMarketFilters } = applyFilters();

          const { analysis, reloadDashboard } = get();

          consulteAnalysisApi
            .updateAnalysis(
              ConsulteAnalysisUpdateDTO.fromPresenter(
                new ConsulteAnalysisPresenter({
                  ...analysis,
                  marketFilters: appliedMarketFilters,
                })
              )
            )
            .then((newAnalysis) => {
              set({
                analysis: newAnalysis,
              });
              return resolve();
            })
            .catch(reject)
            .finally(reloadDashboard);
        }),
      getAnalysis: (analysisId) =>
        new Promise<ConsulteAnalysisPresenter>((resolve, reject) => {
          consulteAnalysisApi
            .get(analysisId)
            .then((analysis) => {
              set({
                analysis,
              });
              return resolve(analysis);
            })
            .catch(reject);
        }),
      getAll: () => consulteAnalysisApi.getAll(),
      renameAnalysis: async (newName, analysisId) =>
        consulteAnalysisApi.renameAnalysis(analysisId, newName),
      deleteAnalysis: (analysisId) =>
        consulteAnalysisApi.deleteAnalysis(analysisId),
      createAnalysis: () => consulteAnalysisApi.createAnalysis(),
    }),
    { name: "ConsulteAnalysisStore" }
  )
);

export default useConsulteAnalysisStore;
