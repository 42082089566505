import api from "./api";

const baseUrl = "/api/v2/sessions";

const sessionApi = {
  logout: () => api.delete(`${baseUrl}`),
  updatePassword: ({ token, newPassword }) =>
    api.post(`${baseUrl}/password_update`, { token, newPassword }),
  getPasswordUpdateDetails: ({ token }) =>
    api.post(`${baseUrl}/password_update_details`, { token }),
  twoFactor: ({ token, publicToken }) =>
    api.post(`${baseUrl}/two_factor`, { token, publicToken }),
  getReCaptchaPublicKey: () => api.get(`${baseUrl}/re_captcha`),
  getCurrentSession: () => api.get(`${baseUrl}/current`),
};

export default sessionApi;
