import FilterOptionDTO from "../FilterOptionDTO";

export default class PayrollConfigurationDTO {
  id: number;

  name: string;

  salaryColumn: string;

  countyColumn: string;

  stateColumn: string;

  jobOccupationColumn: string;

  isUsed: boolean;

  companySize: number;

  col1Name: string | null;

  col2Name: string | null;

  col3Name: string | null;

  col4Name: string | null;

  cnaes: FilterOptionDTO[];

  constructor({
    id = -1,
    name = "",
    salaryColumn = "",
    countyColumn = "",
    stateColumn = "",
    jobOccupationColumn = "",
    isUsed = false,
    companySize = 0,
    col1Name = null,
    col2Name = null,
    col3Name = null,
    col4Name = null,
    cnaes = [],
  }: {
    id?: number;
    name?: string;
    salaryColumn?: string;
    countyColumn?: string;
    stateColumn?: string;
    jobOccupationColumn?: string;
    isUsed?: boolean;
    companySize?: number;
    col1Name?: string | null;
    col2Name?: string | null;
    col3Name?: string | null;
    col4Name?: string | null;
    cnaes?: FilterOptionDTO[];
  }) {
    this.id = id;
    this.name = name;
    this.salaryColumn = salaryColumn;
    this.countyColumn = countyColumn;
    this.stateColumn = stateColumn;
    this.jobOccupationColumn = jobOccupationColumn;
    this.isUsed = isUsed;
    this.companySize = companySize;
    this.col1Name = col1Name;
    this.col2Name = col2Name;
    this.col3Name = col3Name;
    this.col4Name = col4Name;
    this.cnaes = cnaes;
  }
}
