import React, { useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import { BaseButtonProps } from "./GradusButtonBase";

const GradusPrimaryButton = ({
  onClick,
  children,
  sx,
  isSecondary,
  buttonProps,
}: BaseButtonProps) => {
  const color = useMemo<string>(
    () => (isSecondary ? "secondary" : "primary"),
    [isSecondary]
  );
  return (
    <IconButton
      size="small"
      {...buttonProps}
      sx={{
        alignSelf: "center",
        fontSize: 15,
        px: 2,
        color: "white",
        bgcolor: `${color}.main`,
        py: "4.5px",
        lineHeight: 1.5,
        border: 1,
        borderRadius: "10px",
        fontWeight: "bold",
        height: "31px",
        width: "167px",
        ...sx,
      }}
      title=""
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

GradusPrimaryButton.defaultProps = {
  onClick: _.noop,
  isSecondary: false,
  sx: {},
  children: null,
  buttonProps: {},
};

export default GradusPrimaryButton;
