import Typography from "@mui/material/Typography";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Paper, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import PayrollUploadStep from "./PayrollUploadStep";
import PageContainer from "../../components/PageContainer/PageContainer";
import compareAnalysisApi from "../../apis/compareAnalysisApi";
import AlertContext from "../../contexts/AlertContext";
import GradusLoading from "../../components/GradusComponents/GradusLoading";
import { handleApiError } from "../../utils/ErrorUtils";
import SelectMappingStep from "./SelectMappingStep";
import AnalysisNameStep from "./AnalysisNameStep";
import ExcelColumn from "../../components/CreateMapping/ExcelColumn";

const CreateAnalysisPage = () => {
  const navigate = useNavigate();

  const { fireAlert } = useContext(AlertContext);

  const { t } = useTranslation();

  const [computedCompanySize, setComputedCompanySize] = useState<number>(1);
  const [file, setFile] = useState<File | null>(null);
  const [columns, setColumns] = useState<ExcelColumn[]>([]);
  const [excelErrors, setExcelErrors] = useState<string[]>([]);
  const [isCreatingAnalysis, setIsCreatingAnalysis] = useState<boolean>(false);
  const [securityPolicyConsent, setSecurityPolicyConsent] =
    useState<boolean>(false);
  const [analysisName, setAnalysisName] = useState<string>("");

  const applyMapping = useCallback(
    async (applyConfigurationId: number) => {
      try {
        setExcelErrors([]);
        setIsCreatingAnalysis(true);

        const response = await compareAnalysisApi.createAnalysis(
          file as File,
          applyConfigurationId,
          analysisName,
          securityPolicyConsent
        );

        fireAlert(t("ALERTS.SUCCESSFULLY_CREATED_ANALYSIS"));
        navigate(`/compare/${response.data}`);
      } catch (error: any) {
        if (_.isArrayLike(error.response?.data)) {
          setExcelErrors(error.response.data);
        } else if (_.isArrayLike(error.response?.data?.errors)) {
          setExcelErrors(error.response.data.errors);
        } else {
          handleApiError(error, fireAlert, t);
        }
      } finally {
        setIsCreatingAnalysis(false);
      }
    },
    [file, analysisName, securityPolicyConsent, fireAlert, t, navigate]
  );

  const canSelectMapping = useMemo(
    () => !_.isEmpty(analysisName) && securityPolicyConsent && !_.isEmpty(file),
    [analysisName, file, securityPolicyConsent]
  );

  if (isCreatingAnalysis) {
    return (
      <PageContainer>
        <Typography
          variant="h5"
          textAlign="left"
          fontWeight="bold"
          sx={{ mb: 2 }}
        >
          {t("NEW_ANALYSIS")}
        </Typography>
        <GradusLoading />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Typography
        variant="h5"
        textAlign="left"
        fontWeight="bold"
        sx={{ mb: 2 }}
      >
        {t("NEW_ANALYSIS")}
      </Typography>
      <Stack gap={2}>
        <Paper sx={{ p: 2, width: "100%" }}>
          <AnalysisNameStep
            analysisName={analysisName}
            setAnalysisName={setAnalysisName}
          />
        </Paper>
        <Paper sx={{ p: 2 }}>
          <PayrollUploadStep
            setComputedCompanySize={setComputedCompanySize}
            setFile={setFile}
            setColumns={setColumns}
            excelErrors={excelErrors}
            securityPolicyConsent={securityPolicyConsent}
            toggleSecurityPolicyConsent={() =>
              setSecurityPolicyConsent((prevState) => !prevState)
            }
          />
        </Paper>
        <Paper sx={{ p: 2 }}>
          <SelectMappingStep
            canSelectMapping={canSelectMapping}
            applyMapping={applyMapping}
            columns={columns}
            computedCompanySize={computedCompanySize}
          />
        </Paper>
      </Stack>
    </PageContainer>
  );
};

export default CreateAnalysisPage;
