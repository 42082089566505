export default class MarketCompanyTuple<T> {
  market: T | object;

  company: T | object;

  constructor({
    market = {},
    company = {},
  }: {
    market?: T | object;
    company?: T | object;
  }) {
    this.market = market;
    this.company = company;
  }
}
