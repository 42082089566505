import * as XLSX from "xlsx";
import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import CompanySize from "../../models/CompanySize";
import ExcelColumn from "../CreateMapping/ExcelColumn";

function handlePayrollUpload(
  files: File[],
  setFile: Dispatch<SetStateAction<File | null>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setColumns: Dispatch<SetStateAction<ExcelColumn[]>>,
  setComputedCompanySize: Dispatch<SetStateAction<number>>
) {
  setFile(null);
  setIsLoading(true);
  setColumns([]);

  const reader = new FileReader();
  reader.onload = async (e: ProgressEvent<FileReader>) => {
    const readData: XLSX.WorkBook = XLSX.read(e?.target?.result, {
      type: "binary",
    });
    const worksheet: XLSX.WorkSheet = readData.Sheets[readData.SheetNames[0]];
    const dataParse: never[] = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
    });

    setComputedCompanySize(CompanySize.getCompanySize(dataParse.length));

    // FIXME: provavelmente dá problema quando o usuário passar um excel sem nenhuma ou somente 1 linha
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const filteredNonEmptyNames: [string, string | undefined][] = _.zip(
      dataParse[0],
      dataParse[1]
    ).filter(([name]) => !_.isEmpty(name));
    filteredNonEmptyNames.sort(([nameA], [nameB]) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nameA.toLowerCase().localeCompare(nameB.toLowerCase())
    );

    const columnWithNameAndType: ExcelColumn[] = filteredNonEmptyNames.map(
      ([label, value]) => {
        if (_.isFinite(Number(value))) {
          return new ExcelColumn(label, "number");
        }
        return new ExcelColumn(label, "string");
      }
    );

    setColumns(columnWithNameAndType);
    setIsLoading(false);
  };
  reader.readAsBinaryString(files[0]);
  setFile(files[0]);
}

export default handlePayrollUpload;
