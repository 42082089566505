import Autocomplete from "@mui/material/Autocomplete";
import { AutocompleteProps, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { DefaultTFuncReturn } from "i18next";
import GradusTooltip from "../GradusComponents/GradusTooltip";
import InfoIcon from "../InfoIcon/InfoIcon";
import ConfigurationView from "../../dtos/PayrollConfiguration/ConfigurationView";
import ExcelColumn from "./ExcelColumn";

export default function ColumnInput({
  label,
  columns,
  isColumnBeingUsed,
  tabInfo,
  setTabInfo,
  tabInfoField,
  required,
  tooltipText,
  ...props
}: Partial<AutocompleteProps<ExcelColumn, any, any, any>> & {
  label: string | DefaultTFuncReturn;
  columns: ExcelColumn[];
  isColumnBeingUsed: (option: ExcelColumn) => boolean;
  tabInfo: ConfigurationView;
  setTabInfo: Dispatch<SetStateAction<ConfigurationView>>;
  tabInfoField: string;
  required?: boolean;
  tooltipText?: string | DefaultTFuncReturn;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={6}>
        <p
          style={{
            display: "inline-flex",
            alignContent: "baseline",
          }}
        >
          {label}
          {!_.isEmpty(tooltipText) && (
            <GradusTooltip title={tooltipText}>
              <span>
                <InfoIcon
                  style={{ alignSelf: "center", marginLeft: theme.spacing(1) }}
                />
              </span>
            </GradusTooltip>
          )}
        </p>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          disablePortal
          size="small"
          noOptionsText={t("NO_OPTIONS")}
          options={columns}
          getOptionDisabled={isColumnBeingUsed}
          sx={{ width: 300, ml: 2 }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(_event, newValue: ExcelColumn) => {
            setTabInfo({
              ...tabInfo,
              [tabInfoField]: newValue && newValue.label,
            });
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={tabInfo[tabInfoField]}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("SELECT_COLUMN")}
              required={required}
            />
          )}
          loading={columns.length <= 0}
          loadingText={`${t("LOADING")}...`}
          {...props}
        />
      </Grid>
    </>
  );
}

ColumnInput.defaultProps = {
  required: false,
  tooltipText: "",
};
