import React, {useEffect, useMemo, useState} from "react";
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import PropTypes from "prop-types";
import {alpha} from "@mui/material";
import _ from "lodash";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {formatCurrency, formatCurrencyAbbreviate,} from "../../../utils/NumberFormatUtils";
import withGradusLoading from "../../../hocs/withGradusLoading";
import StringUtils from "../../../utils/StringUtils";
import BaseChartContainer from "../BaseComponents/BaseChartContainer";
import Chart from "../Chart";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import SalaryAverageAndQuantity from "../../../dtos/SalaryAverageAndQuantity";
import useChartLoadingStore from "../../../stores/ChartLoadingStore/useChartLoadingStore";
import ChartHeader from "./ChartHeader";

// TODO: mudar para TS
export default function SalaryAverageByField({
  label,
  title,
  isCompare,
  fieldAxisProps,
  children,
  tooltipTitleFormatter,
  chart,
  analysis,
}) {
  const theme = useTheme();

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const { getChart, charts } = useChartStore((state) => ({
    getChart: state.getChart,
    charts: state.charts,
  }));

  const { [chart]: isLoading } = useChartLoadingStore();

  const [data, setData] = useState([]);

  const marketData = useMemo(
    () => getChart(charts, chart).market,
    [chart, charts, getChart]
  );
  const companyData = useMemo(
    () => getChart(charts, chart).company,
    [chart, charts, getChart]
  );

  useEffect(() => {
    const keys = _.uniq([..._.keys(marketData), ..._.keys(companyData)]);

    const mappedData = _.chain(keys)
      .map((key) => {
        const res = {
          x: t(key, { lng: language }),
          y: {
            market: new SalaryAverageAndQuantity({}),
            company: new SalaryAverageAndQuantity({}),
          },
        };
        if (_.has(marketData, key)) {
          res.y.market = marketData[key];
        }
        if (_.has(companyData, key)) {
          res.y.company = companyData[key];
        }
        return res;
      })
      .value();

    setData(mappedData);

    return () => {
      setData([]);
    };
  }, [t, language, marketData, companyData, isCompare]);

  const tooltipFormatter = (value, type, { payload: { y } }) => {
    const formattedValue = formatCurrency(value, language);
    return [
      <span
        style={{
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <strong>{`${t("SALARY_AVERAGE")}: `}</strong>
        {formattedValue}
        <br />
        <strong>{`${t("EMPLOYEES")}: `}</strong>
        {Number(
          type === t("COMPANY") ? y.company.quantity : y.market.quantity
        ).toLocaleString(language)}
      </span>,
    ];
  };

  const legendFormatter = (value, { inactive }) => (
    <span
      style={{
        color: inactive ? "#ccc" : theme.palette.grey[700],
        fontFamily: theme.typography.fontFamily,
      }}
    >
      {tooltipTitleFormatter(value)}
    </span>
  );

  const barCategoryGap = data.length > 10 ? 2 : 5;

  const ResponsiveContainerWithLoading = withGradusLoading(ResponsiveContainer);

  return (
    <BaseChartContainer size={6}>
      <ChartHeader
        title={title}
        analysis={analysis}
        chart={chart}
        isCompare={isCompare}
      />
      <ResponsiveContainerWithLoading isLoading={isLoading}>
        <BarChart
          barGap={1}
          data={data}
          barCategoryGap={barCategoryGap}
          margin={{ bottom: 15 }}
        >
          <XAxis
            tickFormatter={(value) => StringUtils.truncateString(value, 80)}
            {...fieldAxisProps}
            dataKey="x"
            type="category"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            label={{
              value: label,
              dy: 20,
            }}
          />
          <YAxis
            type="number"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            tickFormatter={(value) => formatCurrencyAbbreviate(value, language)}
            label={{
              value: t("CHARTS_DASHBOARD.AVERAGE_SALARY"),
              angle: -90,
              dx: -20,
            }}
          />
          <Tooltip formatter={tooltipFormatter} />
          {isCompare ? (
            <Bar
              name={t("COMPANY")}
              dataKey="y.company.average"
              key="yCompany"
              fill={theme.palette.primary.main}
              isAnimationActive
            />
          ) : null}
          <Bar
            name={t("MARKET")}
            dataKey="y.market.average"
            key="yMarket"
            fill={alpha(theme.palette.grey[700], 0.5)}
            isAnimationActive={false}
          />
          <Legend verticalAlign="top" formatter={legendFormatter} />
        </BarChart>
      </ResponsiveContainerWithLoading>
      {children}
    </BaseChartContainer>
  );
}

SalaryAverageByField.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  chart: PropTypes.instanceOf(Chart).isRequired,
  // FIXME: isso aqui só vai dar para resolver quando mudar para TS, pois o que deveria esperar de tipo é a interface AnalysisPresenter que não é possível importar do TS para o JS
  // eslint-disable-next-line react/forbid-prop-types
  analysis: PropTypes.object.isRequired,
  isCompare: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  gridProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  fieldAxisProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  cardSx: PropTypes.object,
  children: PropTypes.node,
  tooltipTitleFormatter: PropTypes.func,
};

SalaryAverageByField.defaultProps = {
  isCompare: false,
  gridProps: {},
  fieldAxisProps: {},
  cardSx: {},
  children: null,
  tooltipTitleFormatter: (value) => value,
};
