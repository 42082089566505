import ListItemIcon from "@mui/material/ListItemIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import PropTypes from "prop-types";
import { ListItemButton } from "@mui/material";
import TooltipedListItemButton from "./TooltipedListItemButton";

export default function ListItem({
  isDrawerOpen,
  title,
  icon,
  ...listItemProps
}) {
  if (isDrawerOpen) {
    return (
      <ListItemButton {...listItemProps} sx={{ ml: 2, ...listItemProps?.sx }}>
        <ListItemIcon>
          <FontAwesomeIcon icon={icon} />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    );
  }

  return (
    <TooltipedListItemButton
      {...listItemProps}
      title={title}
      sx={{ justifyContent: "center", mx: 0, ...listItemProps?.sx }}
    >
      <FontAwesomeIcon icon={icon} />
    </TooltipedListItemButton>
  );
}

ListItem.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
};
