import Box from "@mui/material/Box";
import React from "react";
import PropTypes from "prop-types";
import StyledTabs from "./StyledTabs";
import StyledTab from "./StyledTab";

export default function GradusTabs({ tab, setTab, defaultTab, labels }) {
  return (
    <Box sx={{ mb: 1 }}>
      <StyledTabs
        value={tab || defaultTab}
        onChange={(_event, newTab) => setTab(newTab)}
        variant="fullWidth"
        scrollButtons="auto"
      >
        {labels.map((label) => (
          <StyledTab label={label} key={label} />
        ))}
      </StyledTabs>
    </Box>
  );
}

GradusTabs.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  defaultTab: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GradusTabs.defaultProps = {
  defaultTab: 0,
};
