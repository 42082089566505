import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import SystemDrawer from "./BasePage/SystemDrawer";
import SystemAppBar from "./BasePage/SystemAppBar";
import Footer from "../components/Footer/Footer";

const openDrawerWidth = 170;
const closedDrawerWidth = 130;

export default function BasePage() {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ display: "flex", boxSizing: "border-box" }}>
      <CssBaseline />
      <SystemDrawer
        isOpen={open}
        setIsOpen={setOpen}
        openWidth={openDrawerWidth}
        closedWidth={closedDrawerWidth}
      />
      <SystemAppBar
        isDrawerOpen={open}
        closedDrawerWidth={closedDrawerWidth}
        openDrawerWidth={openDrawerWidth}
      />
      <Box
        component="main"
        sx={{
          boxSizing: "border-box",
          backgroundColor: "white",
          flexGrow: 1,
          minHeight: "100vh",
          paddingBottom: "50px",
          position: "relative",
        }}
      >
        <Toolbar />
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
}
