import React, { useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import RenameField from "../../ListAnalysisModal/RenameField";
import ConfirmationDialog from "../../../ConfirmationDialog/ConfirmationDialog";
import { GradusTable } from "../../../GradusComponents/GradusTable/GradusTable";
import GradusTableColumn from "../../../GradusComponents/GradusTable/GradusTableColumn";
import TruncatedFilter from "../../../GradusComponents/GradusTable/TruncatedFilter";
import { ActionButtonType } from "../../../Actions/ActionButtonFactory";
import Actions from "../../../Actions/Actions";
import CompareAnalysisSavedFiltersPresenter from "../../../../dtos/CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersPresenter";
import useCompareAnalysisStore from "../../../../stores/useCompareAnalysisStore";

export default function CompareAnalysisSavedFiltersTable({
  options,
  handleDelete,
  handleRename,
  handleApply,
}: {
  options: CompareAnalysisSavedFiltersPresenter[];
  handleDelete: (id: number) => void;
  handleRename: (newName: string, id: number) => void;
  handleApply: (id: number) => void;
}) {
  const { analysis } = useCompareAnalysisStore();

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  const [renamingId, setRenamingId] = useState<number>(-1);
  const [deletingRow, setDeletingRow] =
    useState<CompareAnalysisSavedFiltersPresenter | null>(null);

  const columns = useMemo(
    () => [
      new GradusTableColumn<CompareAnalysisSavedFiltersPresenter>({
        field: "name",
        headerLabel: t("NAME", { lng: language }),
        order: 1,
        render: (row) => {
          if (row.getId() === renamingId) {
            return (
              <RenameField
                id={row?.getId() || -1}
                name={row.getName()}
                renameFn={handleRename}
                setIsRenaming={(isRenaming) => {
                  setRenamingId(isRenaming ? row?.getId() || -1 : -1);
                }}
              />
            );
          }
          return row.getName();
        },
      }),
      new GradusTableColumn<CompareAnalysisSavedFiltersPresenter>({
        field: "marketFilters",
        headerLabel: t("MARKET_FILTERS", { lng: language }),
        order: 2,
        render: (row) => <TruncatedFilter filters={row.getMarketFilters()} />,
        hasFilter: false,
      }),
      new GradusTableColumn<CompareAnalysisSavedFiltersPresenter>({
        field: "companyFilters",
        headerLabel: t("COMPANY_FILTERS", { lng: language }),
        order: 3,
        render: (row) => <TruncatedFilter filters={row.getCompanyFilters()} />,
        hasFilter: false,
      }),
      new GradusTableColumn<CompareAnalysisSavedFiltersPresenter>({
        field: "id",
        headerLabel: t("ACTIONS", { lng: language }),
        order: 4,
        align: "center",
        headerAlign: "center",
        render: (row) => {
          const isFilterActive = row.getId() === analysis.getActiveFilterId();

          return (
            // FIXME: Parece que ele não está aceitando uma definição de props
            //  como tipo neste componente, ver como melhorar isso
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Actions
              actions={[
                {
                  type: ActionButtonType.OPEN,
                  buttonProps: {
                    onClick: () => handleApply(row?.getId() || -1),
                  },
                },
                {
                  type: ActionButtonType.RENAME,
                  buttonProps: {
                    onClick: () => setRenamingId(row?.getId() || -1),
                  },
                },
                {
                  type: ActionButtonType.DELETE,
                  buttonProps: {
                    onClick: () => setDeletingRow(row),
                    disabled: isFilterActive,
                  },
                  overrideDefaultButtonProps: {
                    title: isFilterActive
                      ? t(
                          "FILTER_MODAL.IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_FILTER",
                          { lng: language }
                        )
                      : t("DELETE", { lng: language }),
                  },
                },
              ]}
            />
          );
        },
        hasFilter: false,
      }),
    ],
    [analysis, handleApply, handleRename, language, renamingId, t]
  );

  return (
    <>
      <GradusTable
        containerSx={{ mt: 2 }}
        rows={options}
        columns={columns}
        storeName="compare-analysis-saved-filters"
      />
      <ConfirmationDialog
        open={!_.isEmpty(deletingRow)}
        handleAccept={() => {
          if (deletingRow?.getId()) {
            handleDelete(deletingRow?.getId() || -1);
            setDeletingRow(null);
          }
        }}
        handleCancel={() => {
          setDeletingRow(null);
        }}
        title={t("REMOVE_FILTER_DIALOG.TITLE")}
        content={t("REMOVE_FILTER_DIALOG.CONFIRMATION_MESSAGE", {
          filterName: deletingRow?.getName() || "",
        })}
        acceptButtonText={t("DELETE")}
      />
    </>
  );
}
