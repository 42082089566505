import Container from "@mui/material/Container";
import React, { CircularProgress, SxProps } from "@mui/material";

const GradusLoading = ({ containerSx }: { containerSx?: SxProps }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Container
    disableGutters
    sx={{
      height: "100%",
      width: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      ...containerSx,
    }}
  >
    <CircularProgress />
  </Container>
);

GradusLoading.defaultProps = {
  containerSx: {},
};

export default GradusLoading;
