import React, { Dispatch, SetStateAction, useState } from "react";
import { InputAdornment, SxProps, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RenameField({
  id,
  name,
  renameFn,
  setIsRenaming,
  sx,
}: {
  id: number;
  name: string;
  renameFn: (newName: string, id: number) => void;
  setIsRenaming: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps;
}) {
  const [newName, setNewName] = useState(name);

  const handleClear = () => {
    setNewName(name);
    setIsRenaming(false);
  };

  const handleRename = () => {
    renameFn(newName, id);
    setIsRenaming(false);
  };

  return (
    <TextField
      type="text"
      size="small"
      value={newName}
      onChange={(event) => setNewName(event.target.value)}
      sx={{ width: "100%", ...sx }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleRename}>
              <FontAwesomeIcon icon={light("check")} />
            </IconButton>
            <IconButton onClick={handleClear}>
              <FontAwesomeIcon icon={light("xmark")} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

RenameField.defaultProps = {
  sx: {},
};
