import * as React from "react";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CompareMenu from "./CompareMenu";
import ConsulteMenu from "./ConsulteMenu";
import useAvailableModules from "../../hooks/useAvailableModules";
import ListItem from "./ListItem";

const MainListItemsWrapper = styled(List, {
  shouldForwardProp: (prop) => prop !== "isDrawerOpen",
})(() => ({
  "& svg": {
    color: "white",
    width: "26px",
    height: "26px",
    minWidth: "26px",
  },
  "& .MuiListItemIcon-root": {
    minWidth: "42px",
  },
  "& .MuiListItemText-primary": {
    fontWeight: "bold",
    fontSize: "12px",
    color: "white",
  },
  "& .Mui-selected": {
    backgroundColor: "rgba(69, 107, 176, 0.5)",
  },
}));

export default function SidebarList({ isDrawerOpen }) {
  const { isCompareAvailable, isConsulteAvailable } = useAvailableModules();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <MainListItemsWrapper isDrawerOpen={isDrawerOpen} sx={{ py: 0 }}>
      <ListItem
        icon={light("house-chimney")}
        isDrawerOpen={isDrawerOpen}
        title={t("HOME")}
        selected={pathname === "/"}
        component={RouterLink}
        to="/"
        sx={{ mx: 0 }}
      />
      <Divider />
      <CompareMenu
        isDrawerOpen={isDrawerOpen}
        isCompareAvailable={isCompareAvailable}
      />
      <Divider />
      <ConsulteMenu
        isDrawerOpen={isDrawerOpen}
        isConsulteAvailable={isConsulteAvailable}
      />
    </MainListItemsWrapper>
  );
}

SidebarList.propTypes = { isDrawerOpen: PropTypes.bool.isRequired };
