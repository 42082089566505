import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { FormEventHandler, memo } from "react";
import { Trans, useTranslation } from "react-i18next";

interface PolicyConsentButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  checked: boolean;
}

const PolicyConsentButton = ({
  onClick,
  disabled,
  checked,
}: PolicyConsentButtonProps) => {
  const { t } = useTranslation();

  const handleSubmit: FormEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };

  return (
    <FormGroup onSubmit={handleSubmit}>
      <FormControlLabel
        control={
          <Checkbox onClick={onClick} disabled={disabled} checked={checked} />
        }
        label={
          <Typography>
            <Trans
              t={t}
              i18nKey="COMPARE_CREATE_ANALYSIS_PAGE.POLICY_CONSENT_BUTTON"
            >
              <Link href="/#/privacy-policy" target="_blank" />
            </Trans>
          </Typography>
        }
      />
    </FormGroup>
  );
};

PolicyConsentButton.defaultProps = {
  disabled: false,
};

export default memo(PolicyConsentButton);
