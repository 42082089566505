import Stack from "@mui/material/Stack";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AlertContext from "../../../../contexts/AlertContext";
import PayrollFilters from "../PayrollFilters";
import useFilterStore from "../../../../stores/FilterStore/useFilterStore";
import FilterTabFooter from "../Footer/FilterTabFooter";
import FilterTabHeader from "../Header/FilterTabHeader";
import useCompareAnalysisStore from "../../../../stores/useCompareAnalysisStore";
import { handleApiError } from "../../../../utils/ErrorUtils";
import AnalysisPresenter from "../../../../dtos/AnalysisPresenter";

export default function PayrollFilterTab({
  closeModal,
  analysis,
}: {
  closeModal: () => void;
  analysis: AnalysisPresenter;
}) {
  const { updateAnalysis } = useCompareAnalysisStore((state) => ({
    updateAnalysis: state.updateAnalysis,
  }));
  const { fireAlert } = useContext(AlertContext);

  const { setDefaultPayrollFilters } = useFilterStore((state) => ({
    setDefaultPayrollFilters: state.setDefaultPayrollFilters,
  }));

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const handleFilter = async () => {
    try {
      await updateAnalysis();
      fireAlert(
        t("ALERTS.SUCCESSFULLY_APPLIED_FILTER", { lng: language }),
        false
      );
      closeModal();
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  };

  return (
    <Stack direction="column" spacing={3}>
      <FilterTabHeader isOtherTabMarket />
      <PayrollFilters />
      <FilterTabFooter
        setDefaultFilters={() => setDefaultPayrollFilters(analysis.getId())}
        handleFilter={handleFilter}
        isCompare
      />
    </Stack>
  );
}
