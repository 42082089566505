import Stack from "@mui/material/Stack";
import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AlertContext from "../../../../contexts/AlertContext";
import MarketFilters from "../MarketFilters";
import useFilterStore from "../../../../stores/FilterStore/useFilterStore";
import FilterTabFooter from "../Footer/FilterTabFooter";
import useConsulteAnalysisStore from "../../../../stores/useConsulteAnalysisStore";
import { handleApiError } from "../../../../utils/ErrorUtils";

function ConsulteMarketFilterTab({ closeModal }: { closeModal: () => void }) {
  const { fireAlert } = useContext(AlertContext);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { analysis, updateAnalysis } = useConsulteAnalysisStore((state) => ({
    analysis: state.analysis,
    updateAnalysis: state.updateAnalysis,
  }));
  const { setDefaultMarketFilters } = useFilterStore((state) => ({
    setDefaultMarketFilters: state.setDefaultMarketFilters,
  }));

  const handleFilter = useCallback(async () => {
    try {
      await updateAnalysis();
      fireAlert(
        t("ALERTS.SUCCESSFULLY_APPLIED_FILTER", { lng: language }),
        false
      );
      closeModal();
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  }, [closeModal, fireAlert, language, t, updateAnalysis]);

  return (
    <Stack direction="column" spacing={3}>
      <MarketFilters analysis={analysis} isCompare={false} />
      <FilterTabFooter
        setDefaultFilters={() =>
          setDefaultMarketFilters(false, analysis.getId())
        }
        handleFilter={handleFilter}
        saveButtonTitle="APPLY_FILTERS"
        isCompare={false}
      />
    </Stack>
  );
}

ConsulteMarketFilterTab.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ConsulteMarketFilterTab;
