import _ from "lodash";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import useFilterOptionsStore from "../../../../stores/FilterOptionsStore/useFilterOptionsStore";
import useFilterStore from "../../../../stores/FilterStore/useFilterStore";
import useCompareAnalysisStore from "../../../../stores/useCompareAnalysisStore";
import { AutocompleteChip } from "../../../GradusComponents/GradusAutocomplete";

export default function CustomParametersFilters() {
  const { t } = useTranslation();

  const {
    updateModalSelectedPayrollFilters,
    modalSelectedPayrollFilters,
    currentConfiguration,
  } = useFilterStore((state) => ({
    updateModalSelectedPayrollFilters: state.updateModalSelectedPayrollFilters,
    modalSelectedPayrollFilters: state.modalSelectedPayrollFilters,
    currentConfiguration: state.currentConfiguration,
  }));
  const { companyOptions, getPayrollCustomColumnsChainedFilters } =
    useFilterOptionsStore((state) => ({
      companyOptions: state.companyOptions,
      getPayrollCustomColumnsChainedFilters:
        state.getPayrollCustomColumnsChainedFilters,
    }));
  const { analysis } = useCompareAnalysisStore((state) => ({
    analysis: state.analysis,
  }));

  const handleSelectOption = async (newValues, columnName) => {
    const newFilters = await updateModalSelectedPayrollFilters(
      { [columnName]: newValues },
      analysis.getId()
    );
    await getPayrollCustomColumnsChainedFilters(newFilters, analysis.getId());
  };

  return (
    <Container disableGutters>
      <Typography variant="h6">{t("CUSTOM_COLUMNS")}</Typography>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        {[1, 2, 3, 4]
          .map((col) => {
            const columnIdentifier = `col${col}`;
            if (companyOptions[columnIdentifier].length <= 0) {
              return null;
            }

            const customColumnName =
              currentConfiguration[`${columnIdentifier}Name`];
            return (
              <Autocomplete
                noOptionsText={t("NO_OPTIONS")}
                multiple
                disablePortal
                size="small"
                value={modalSelectedPayrollFilters[columnIdentifier]}
                onChange={(_event, newValues) => {
                  handleSelectOption(newValues, columnIdentifier);
                }}
                sx={{ pt: 1 }}
                options={companyOptions[columnIdentifier]}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={_.isEqual}
                renderOption={(props, name) => (
                  <li {...props} key={`${columnIdentifier}-${name}`}>
                    {name}
                  </li>
                )}
                renderTags={(values) => <AutocompleteChip values={values} />}
                renderInput={(params) => (
                  <TextField {...params} label={customColumnName} />
                )}
              />
            );
          })
          .filter((value) => value !== null)}
      </Box>
    </Container>
  );
}
