class LegendItem {
  private value: string;

  private type: "line" | "rect";

  private color: string;

  private id: string;

  private readonly canHide: boolean;

  private inactive: boolean;

  private order: number;

  constructor({
    value,
    type,
    color,
    id,
    canHide,
    inactive,
    order,
  }: {
    value: string;
    type: "line" | "rect";
    color: string;
    id: string;
    canHide: boolean;
    inactive: boolean;
    order: number;
  }) {
    this.value = value;
    this.type = type;
    this.color = color;
    this.id = id;
    this.canHide = canHide;
    this.inactive = inactive;
    this.order = order;
  }
}

export default LegendItem;
