/* eslint-disable array-callback-return */
import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import { Typography } from "@mui/material";
import { useGradusTableStore } from "./GradusTableStore";

function CollapsedTableRow({ row, columns, hasCheckbox, storeName }) {
  const { collapseCheckedRows, checkRow } = useGradusTableStore(storeName);

  const tableCellsValues = columns.map((column) => (
    <TableCell
      key={`${column.field}-${row.id}`}
      data-testid={`cell-${column.headerLabel}-${row[column.field]}`}
      align={column.align}
      style={{
        ...(column.collapsedCellStyle ? column.collapsedCellStyle : ""),
        borderWidth: "0px",
      }}
    >
      {column.renderCollapse(row)}
    </TableCell>
  ));

  return (
    <TableRow sx={{ bgcolor: "#F0F0F0" }}>
      {hasCheckbox && (
        <TableCell
          style={{
            borderWidth: "0px",
            float: "right",
          }}
        >
          <Checkbox
            sx={{
              "&.Mui-checked": {
                color: "#456bb0",
              },
              "&.MuiCheckbox-root": {
                marginLeft: "10px",
              },
            }}
            checked={collapseCheckedRows[row.id] === true}
            onChange={(_event, checked) => checkRow(row.id, checked, true)}
          />
        </TableCell>
      )}
      <TableCell
        style={{
          borderWidth: "0px",
        }}
      />
      {tableCellsValues}
    </TableRow>
  );
}

CollapsedTableRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerLabel: PropTypes.string.isRequired,
    })
  ).isRequired,
  storeName: PropTypes.string.isRequired,
  hasCheckbox: PropTypes.bool,
};

CollapsedTableRow.defaultProps = {
  hasCheckbox: false,
};

export default function CollapsableTableRow({
  row,
  columns,
  hasCollapse,
  hasCheckbox,
  storeName,
}) {
  const [collapse, setCollapse] = useState(false);

  const { checkRow, checkedRows, collapseField } = useGradusTableStore(
    storeName,
    (state) => ({
      checkRow: state.checkRow,
      checkedRows: state.checkedRows,
      collapseField: state.collapseField,
    })
  );

  const isObject = (val) => val instanceof Object;

  const tableCellsValues = useMemo(
    () =>
      columns
        .filter((element) => element !== undefined)
        .map((column) => {
          let renderedColumn = column.render(row);
          if (!isObject(renderedColumn)) {
            if (!_.isElement(renderedColumn)) {
              const sx = column.shortenTextWithEllipsis
                ? {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }
                : {};
              renderedColumn = (
                <Typography title={renderedColumn} component="p" sx={sx}>
                  {renderedColumn}
                </Typography>
              );
            }
          }

          return (
            <TableCell
              key={column.field}
              data-testid={`cell-${column.headerLabel}-${row[column.field]}`}
              align={column.align}
              style={{
                ...(column.cellStyle ? column.cellStyle : {}),
                borderLeftWidth: "0px",
                borderRightWidth: "0px",
                maxWidth: "160px",
              }}
            >
              {renderedColumn}
            </TableCell>
          );
        }),
    [columns, row]
  );

  const hasCollapseRows = !_.isEmpty(_.get(row, collapseField));

  const onCheckboxChange = useCallback(
    (_event, checked) => {
      checkRow(row.id, checked);
      _.get(row, collapseField, []).forEach((collapseRow) => {
        checkRow(collapseRow.id, checked, true);
      });
    },
    [checkRow, collapseField, row]
  );

  return (
    <>
      <TableRow className="main-row">
        {hasCheckbox && (
          <TableCell
            align="center"
            style={{
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              maxWidth: "160px",
            }}
            data-testid="btn-checkbox-table"
          >
            <Checkbox
              checked={checkedRows[row.id] === true}
              onChange={onCheckboxChange}
            />
          </TableCell>
        )}
        {hasCollapse && (
          <TableCell
            className="expand-cell"
            style={{
              width: "20px",
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              maxWidth: "160px",
            }}
            align="center"
            data-testid="expand-cell-group"
          >
            {hasCollapseRows && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setCollapse((prevState) => !prevState)}
              >
                <FontAwesomeIcon
                  icon={collapse ? faChevronUp : faChevronDown}
                />
              </IconButton>
            )}
          </TableCell>
        )}
        {tableCellsValues}
      </TableRow>
      {collapse &&
        hasCollapseRows &&
        _.get(row, collapseField).map((collapseRow) => (
          <CollapsedTableRow
            key={`collapsed-${collapseRow.id}`}
            row={collapseRow}
            columns={columns}
            hasCheckbox={hasCheckbox}
            storeName={storeName}
          />
        ))}
    </>
  );
}

CollapsableTableRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerLabel: PropTypes.string.isRequired,
    })
  ).isRequired,
  storeName: PropTypes.string.isRequired,
  hasCollapse: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
};

CollapsableTableRow.defaultProps = {
  hasCollapse: false,
  hasCheckbox: false,
};
