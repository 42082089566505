import { HashRouter, Route, Routes } from "react-router-dom";
import React from "react";
import BasePage from "./BasePage";
import Home from "./Home/Home";
import CompareDashboard from "./Dashboards/CompareDashboard";
import ConsulteDashboard from "./Dashboards/ConsulteDashboard";
import Login from "./Login/Login";
import PrivateRoute from "./PrivateRoute";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import CreateAnalysisPage from "./CreateAnalysisPage/CreateAnalysisPage";
import ForgotPassword from "./Login/ForgotPassword";
import TwoFactor from "./Login/TwoFactor";
import RecoverPassword from "./Login/RecoverPassword";
import PrivacyPolicyNoLogin from "./PrivacyPolicy/PrivacyPolicyNoLogin";

export default function BaseRouter() {
  return (
    <HashRouter>
      <Routes onUpdate={() => window.scrollTo({ top: 0 })} >
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<BasePage />}>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<Home />} />
            <Route
              path="/compare/create-analysis"
              element={<CreateAnalysisPage />}
            />
            <Route path="/compare/:id" element={<CompareDashboard />} />
            <Route path="/consulte/:id" element={<ConsulteDashboard />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="privacy-policy-no-login" element={<PrivacyPolicyNoLogin />} />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="recover_password" element={<RecoverPassword />} />
        <Route path="two_factor" element={<TwoFactor />} />
      </Routes>
    </HashRouter>
  );
}
