import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Title from "../../Title/Title";

export default function BaseChartHeader({
  title,
  leftComponent,
  rightComponent,
}: {
  title: string;
  leftComponent?: ReactElement;
  rightComponent?: ReactElement;
}) {
  return (
    <Grid container>
      <Grid item xs={1}>
        <Stack direction="row" justifyContent="flex-start">
          {leftComponent}
        </Stack>
      </Grid>
      <Grid item xs={10}>
        <Title>{title}</Title>
      </Grid>
      <Grid item xs={1}>
        <Stack direction="row" justifyContent="flex-end">
          {rightComponent}
        </Stack>
      </Grid>
    </Grid>
  );
}

BaseChartHeader.defaultProps = {
  leftComponent: null,
  rightComponent: null,
};
