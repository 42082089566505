import React from "react";
import PropTypes from "prop-types";
import { Container, FormControl, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GradusButton from "../../components/Buttons/GradusButton";
import PasswordTextField from "../../components/PasswordTextField/PasswordTextField";
import userRepository from "../../apis/userApi";
import AlertContext from "../../contexts/AlertContext";
import { useNewPasswordErrors } from "../../hooks/useNewPasswordErrors";
import NewPasswordRules from "../Login/NewPasswordRules";
import { useUser } from "../../stores/useUserStore";
import GradusModal from "../../components/GradusComponents/GradusModal/GradusModal";

export default function UpdatePasswordModal({ open, handleClose }) {
  const { fireAlert } = React.useContext(AlertContext);
  const [oldPassword, setOldPassword] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [passwordConfirmation, setPasswordConfirmation] = React.useState();
  const user = useUser();
  const errors = useNewPasswordErrors({
    user,
    newPassword,
    passwordConfirmation,
  });
  const hasErrors = React.useMemo(
    () => errors.some(([, isValid]) => !isValid),
    [errors]
  );
  const { t } = useTranslation();

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    try {
      await userRepository.updatePassword({
        oldPassword,
        newPassword,
      });
      fireAlert(t("ALERTS.SUCCESSFULLY_UPDATED_PASSWORD"));
      handleClose();
    } catch (error) {
      fireAlert(error.response.data, true);
    }
  };

  return (
    <GradusModal
      open={open}
      handleClose={handleClose}
      containerStyle={{ width: "40%" }}
    >
      <Container>
        <Typography
          variant="h5"
          component="h1"
          sx={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("CHANGE_PASSWORD")}
        </Typography>
        <form onSubmit={handleUpdatePassword}>
          <FormControl sx={{ width: "100%", mt: 2 }}>
            <Stack spacing={1}>
              <PasswordTextField
                onChange={(event) => setOldPassword(event.target.value)}
                type="password"
                label={t("PASSWORD_MODAL.CURRENT_PASSWORD")}
                required
              />
              <PasswordTextField
                onChange={(event) => setNewPassword(event.target.value)}
                type="password"
                label={t("NEW_PASSWORD")}
                required
              />
              <PasswordTextField
                onChange={(event) =>
                  setPasswordConfirmation(event.target.value)
                }
                type="password"
                label={t("REPEAT_NEW_PASSWORD")}
                required
              />
            </Stack>
            <NewPasswordRules errors={errors} />
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <GradusButton
                variant="support"
                colorVariant="primary"
                onClick={handleClose}
              >
                {t("CANCEL")}
              </GradusButton>
              <GradusButton
                variant="primary"
                colorVariant="primary"
                disabled={hasErrors}
                buttonProps={{ type: "submit" }}
              >
                {t("APPLY")}
              </GradusButton>
            </Stack>
          </FormControl>
        </form>
      </Container>
    </GradusModal>
  );
}

UpdatePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
