import Stack from "@mui/material/Stack";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import payrollConfigurationsApi from "../../apis/payrollConfigurationsApi";
import ParameterConfigurationPreview from "./ParameterConfigurationPreview";
import AlertContext from "../../contexts/AlertContext";
import SearchBar from "../SearchBar/SearchBar";
import CreateMappingModal from "../CreateMapping/CreateMappingModal";
import UpdateMappingModal from "../CreateMapping/UpdateMappingModal";
import MappingsTable from "./MappingsTable";
import { handleApiError } from "../../utils/ErrorUtils";
import PayrollConfigurationDTO from "../../dtos/PayrollConfiguration/PayrollConfigurationDTO";
import GradusPrimaryButton from "../Buttons/GradusPrimaryButton";
import ConfigurationView from "../../dtos/PayrollConfiguration/ConfigurationView";
import ExcelColumn from "../CreateMapping/ExcelColumn";

interface SelectMappingProps {
  computedCompanySize: number;
  columns: ExcelColumn[];
  applyMapping: (applyConfigurationId: number) => void;
}

const SelectMapping = ({
  computedCompanySize,
  columns,
  applyMapping,
}: SelectMappingProps) => {
  const theme = useTheme();
  const { fireAlert } = useContext(AlertContext);
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const [options, setOptions] = useState<PayrollConfigurationDTO[]>(() => []);
  const [searchedOptions, setSearchedOptions] = useState<
    PayrollConfigurationDTO[]
  >(() => options);
  const [isCreateMappingsModalOpen, setIsCreateMappingsModalOpen] =
    useState<boolean>(false);
  const [toUpdateMapping, setToUpdateMapping] = useState<
    PayrollConfigurationDTO | null | undefined
  >(null);
  const [shownConfiguration, setShownConfiguration] =
    useState<ConfigurationView | null>(null);

  const configurationPreviewRef = useRef<null | HTMLDivElement>(null);

  const updateOptions = useCallback(async () => {
    try {
      const configurations: PayrollConfigurationDTO[] =
        await payrollConfigurationsApi.getAll();
      setOptions(configurations);
      setSearchedOptions(configurations);
      setShownConfiguration(null);
    } catch (error) {
      fireAlert(error, true);
    }
  }, [fireAlert, setShownConfiguration]);

  const deleteMapping = useCallback(
    async (deleteId: number) => {
      try {
        await payrollConfigurationsApi.deleteConfiguration(deleteId);
        if (deleteId === shownConfiguration?.id) {
          setShownConfiguration(null);
        }
        fireAlert(t("ALERTS.SUCCESSFULLY_REMOVED_MAPPING", { lng: language }));
        await updateOptions();
      } catch (error) {
        handleApiError(error, fireAlert, t);
      }
    },
    [
      fireAlert,
      t,
      language,
      setShownConfiguration,
      shownConfiguration?.id,
      updateOptions,
    ]
  );

  const duplicateMapping = useCallback(
    async (duplicateId: number) => {
      try {
        await payrollConfigurationsApi.duplicateConfiguration(duplicateId);
        fireAlert(
          t("ALERTS.SUCCESSFULLY_DUPLICATED_MAPPING", { lng: language })
        );
        await updateOptions();
      } catch (error) {
        handleApiError(error, fireAlert, t);
      }
    },
    [fireAlert, t, language, updateOptions]
  );

  const updateMapping = useCallback(
    (updateId: number) => {
      setToUpdateMapping(
        _.find(
          searchedOptions,
          (configuration) => configuration.id === updateId
        )
      );
    },
    [searchedOptions]
  );

  useEffect(() => {
    updateOptions();
    return () => {
      setShownConfiguration(null);
    };
  }, [setShownConfiguration, updateOptions]);

  // useEffect(() => {
  //   if (!_.isEmpty(shownConfiguration)) {
  //     configurationPreviewRef?.current?.scrollIntoView();
  //   }
  // }, [shownConfiguration]);

  return (
    <Stack gap={1}>
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <GradusPrimaryButton
          onClick={() => setIsCreateMappingsModalOpen(true)}
          sx={{ width: "auto" }}
        >
          <FontAwesomeIcon
            icon={solid("plus")}
            style={{ marginRight: theme.spacing(1) }}
          />
          {t("CREATE_MAPPING")}
        </GradusPrimaryButton>
        <SearchBar
          key="mappingsSearchBar"
          options={options}
          setSearchedOptions={setSearchedOptions}
          parametersToSearch={["name"]}
          placeholder={t(
            "COMPARE_CREATE_ANALYSIS_PAGE.SELECT_MAPPING_SEARCH_PLACEHOLDER"
          )}
        />
      </Stack>
      <MappingsTable
        options={searchedOptions}
        deleteFunction={deleteMapping}
        applyFunction={applyMapping}
        duplicateFunction={duplicateMapping}
        updateFunction={updateMapping}
        shownConfiguration={shownConfiguration}
        setShownConfiguration={setShownConfiguration}
        configurationPreviewRef={configurationPreviewRef}
      />
      <Container
        disableGutters
        sx={{ my: !_.isEmpty(shownConfiguration) ? 1 : 0 }}
      >
        <div
          style={{ display: _.isEmpty(shownConfiguration) ? "none" : "block" }}
        >
          <ParameterConfigurationPreview configuration={shownConfiguration} />
          <div ref={configurationPreviewRef} />
        </div>
      </Container>
      <CreateMappingModal
        columns={columns}
        computedCompanySize={computedCompanySize}
        isOpen={isCreateMappingsModalOpen}
        handleClose={() => setIsCreateMappingsModalOpen(false)}
        onSuccessfulSave={() => updateOptions()}
      />
      <UpdateMappingModal
        columns={columns}
        isOpen={!_.isEmpty(toUpdateMapping)}
        handleClose={() => setToUpdateMapping(null)}
        mapping={toUpdateMapping as PayrollConfigurationDTO}
        onSuccessfulUpdate={() => updateOptions()}
      />
    </Stack>
  );
};

export default memo(SelectMapping);
