import React from "react";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { getAllFilterOption } from "../SalaryAverageByOccupation/Subchart";
import Chart from "../Chart";
import FilterOptionDTO from "../../../dtos/FilterOptionDTO";
import useChartParametersStore from "../../../stores/ChartParametersStore/useChartParametersStore";

export default function SelectOccupationChartType({ chart }: { chart: Chart }) {
  const theme = useTheme();

  const { t } = useTranslation();

  const { [chart]: chartParameters, updateSelected } = useChartParametersStore(
    (state) => ({
      [chart]: state[chart],
      updateSelected: state.updateSelected,
    })
  );

  const handleChange = (newSubchart: FilterOptionDTO) => {
    updateSelected(chart, { subchart: newSubchart });
  };

  return (
    <FormGroup>
      <FormLabel sx={{ mb: 1 }}>
        {t("OCCUPATIONS_CHART_OPTIONS_MODAL.SELECT_CHART_INFORMATION")}
      </FormLabel>
      <FormControl>
        <Autocomplete
          disablePortal
          disableClearable
          renderInput={(params) => <TextField {...params} />}
          renderOption={(renderOptionProps, { id, name }) => (
            <li {...renderOptionProps} key={id}>
              {t(name)}
            </li>
          )}
          value={chartParameters.selected.subchart}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => t(option.name)}
          options={getAllFilterOption()}
          onChange={(_event, newSubchart) => handleChange(newSubchart)}
          size="small"
          sx={{
            overflow: "auto",
            pt: 1,
            maxHeight: "100px",
            width: theme.spacing(40),
          }}
        />
      </FormControl>
    </FormGroup>
  );
}
