import FilterOptionDTO from "./FilterOptionDTO";

export default class ChartOptionDTO {
  id: number;

  name: string;

  subcharts: FilterOptionDTO[];

  constructor({
    id = -1,
    name = "",
    subcharts = [],
  }: {
    id?: number;
    name?: string;
    subcharts?: FilterOptionDTO[];
  }) {
    this.id = id;
    this.name = name;
    this.subcharts = subcharts;
  }
}
