import MarketFilter from "../MarketFilter";

export default class CompareAnalysisRelaxedFiltersPresenter {
  compareAnalysisSavedFilterId: number | null;

  effectiveFilters: MarketFilter;

  isActive: boolean;

  constructor({
    compareAnalysisSavedFilterId = null,
    effectiveFilters = new MarketFilter({}),
    isActive = false,
  }: {
    compareAnalysisSavedFilterId?: number | null;
    effectiveFilters?: MarketFilter;
    isActive?: boolean;
  }) {
    this.compareAnalysisSavedFilterId = compareAnalysisSavedFilterId;
    this.effectiveFilters = new MarketFilter({ ...effectiveFilters });
    this.isActive = isActive;
  }
}
