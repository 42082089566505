import { useTranslation } from "react-i18next";
import React, { CSSProperties, useContext } from "react";
import userApi from "../../apis/userApi";
import { handleApiError } from "../../utils/ErrorUtils";
import AlertContext from "../../contexts/AlertContext";

export interface LocaleButtonProps {
  locale: "en" | "pt" | "es";
  buttonStyle?: CSSProperties;
  isNotAuthenticated?: boolean;
}

const LocaleButton = ({
  locale,
  buttonStyle,
  isNotAuthenticated,
}: LocaleButtonProps) => {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language, changeLanguage },
  } = useTranslation();

  const { fireAlert } = useContext(AlertContext);

  const handleChangeLocale = async () => {
    try {
      if (!isNotAuthenticated) {
        await userApi.changeLocale(locale);
      }
      await changeLanguage(locale);
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  };

  return (
    <button
      type="button"
      style={{
        padding: 0,
        margin: 0,
        background: "none",
        border: 0,
        fontWeight: language === locale ? "bold" : "unset",
        textDecoration: language === locale ? "underline" : "unset",
        textUnderlineOffset: "1px",
        cursor: "pointer",
        ...buttonStyle,
      }}
      onClick={handleChangeLocale}
    >
      {locale.split("-")[0]}
    </button>
  );
};

LocaleButton.defaultProps = {
  buttonStyle: {},
  isNotAuthenticated: false,
};

export default LocaleButton;
