import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TooltipProps } from "@mui/material";
import { DefaultTFuncReturn } from "i18next";
import GradusTooltip from "./GradusTooltip";

export default function GradusIcon({
  title,
  icon,
  iconStyle,
  placement,
}: {
  title: string | DefaultTFuncReturn;
  icon: IconDefinition;
  iconStyle?: CSSProperties;
  placement?: TooltipProps["placement"];
}) {
  return (
    <GradusTooltip
      placement={placement}
      style={{ fontSize: "20px" }}
      title={title}
    >
      <FontAwesomeIcon icon={icon} style={iconStyle} />
    </GradusTooltip>
  );
}

GradusIcon.defaultProps = {
  iconStyle: {},
  placement: "bottom",
};
