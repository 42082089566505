import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import sessionApi from "../../apis/sessionApi";
import UpdatePasswordModal from "../../pages/Home/UpdatePasswordModal";
import { resetUser, useUser } from "../../stores/useUserStore";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: "unset",
  fontWeight: "bold",
  ":hover": {
    backgroundColor: "inherit",
  },
}));

export default function AccountMenu() {
  const user = useUser();
  const theme = useTheme();
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await sessionApi.logout();
    resetUser();
    navigate("/login", { replace: true });
  };

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "baseline", textAlign: "center" }}
      >
        <LanguageSelector />
        <MenuButton
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          endIcon={<ExpandMoreIcon />}
        >
          {user?.name}
        </MenuButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => setOpenUpdatePasswordModal(true)}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={solid("key")}
              color={theme.palette.primary.main}
            />
          </ListItemIcon>
          {t("CHANGE_PASSWORD")}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={solid("arrow-right-from-bracket")}
              color={theme.palette.primary.main}
            />
          </ListItemIcon>
          {t("LOGOUT")}
        </MenuItem>
      </Menu>
      {openUpdatePasswordModal && (
        <UpdatePasswordModal
          open={openUpdatePasswordModal}
          handleClose={() => setOpenUpdatePasswordModal(false)}
        />
      )}
    </>
  );
}
