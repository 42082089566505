import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { useUser } from "../../stores/useUserStore";

export default function Footer() {
  const year = new Date().getFullYear();
  const { t } = useTranslation();

  const FooterLink = styled(Link)(() => ({
    color: "#353639",
    textDecoration:  'none',
    cursor: "pointer",
    ":hover": {
      textDecoration: 'underline',
    },
  }));

  return (
    <footer
      style={{
        display: "flex",
        position: "absolute",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
        height: "37px",
        padding: "9px",
        boxSizing: "border-box",
        background: "#D9D9D9",
        color: "#353639",
        bottom: "0",
        fontFamily: "Nunito Sans, sans serif",
      }}
    >
      <div style={{ fontSize: "14px" }}>
        <span>{t("FOOTER", { year })}</span>
        <FooterLink
          href={useUser() ? "#/privacy-policy" : "#/privacy-policy-no-login"}
          /* TODO: Alternativa para abrir a tela de politicas de privacidades
          ERROR: You are trying to block a POP navigation to a location that was not created by @remix-run/router */
          target="_blank"
          rel="noreferrer"
        >
          {t("PRIVACY_POLICY.FOOTER_LINK")}
        </FooterLink>
      </div>
    </footer>
  );
}
