export default {
  common: {
    FOOTER: "©{{year}} Gradus Tech - All rights reserved. ",
    CHANGE_PASSWORD: "Change password",
    LOGOUT: "Logout",
    EXIT: "EXIT",
    CANCEL: "Cancel",
    APPLY: "Apply",
    NAME: "Name",
    MARKET_FILTERS: "Market filters",
    COMPANY_FILTERS: "Company filters",
    MY_FILTERS: "My filters",
    ACTIONS: "Actions",
    HOME: "Home",
    CREATE: "Create",
    SALARY: "Salary",
    OCCUPATION_CBO: "Occupation (CBO)",
    COMPANY_SIZE_LABEL: "Company size",
    COMPANY_CNAES: "Company's CNAEs",
    COUNTY: "County",
    STATE: "State",
    REGION: "Region",
    INTERMEDIATE_GEOGRAPHIC_REGION: "Intermediate Geographic Region",
    INTERMEDIATE_REGION: "Intermediate region",
    IMMEDIATE_GEOGRAPHIC_REGION: "Immediate Geographic Region",
    IMMEDIATE_REGION: "Immediate region",
    GEOGRAPHY: "Geography",
    CUSTOM_COLUMN: "Custom column",
    CUSTOM_COLUMNS: "Custom columns",
    CREATE_ANALYSIS: "Create analysis",
    NEW_ANALYSIS: "New analysis",
    ANALYSIS: "All analysis",
    ANALYSIS_NAME: "Analysis name",
    COMPARE: "Compare",
    CONSULT: "Consult",
    PAYROLL: "Payroll",
    EXAMPLE: "example",
    MAPPING: "Mapping",
    SELECT_COLUMN: "Select the column",
    CREATE_MAPPING: "Create mapping",
    APPLY_MAPPING: "Apply mapping",
    EDIT_MAPPING: "Edit mapping",
    DUPLICATE_MAPPING: "Duplicate mapping",
    REMOVE_MAPPING: "Remove mapping",
    HIDE_MAPPING: "Hide mapping",
    SHOW_MAPPING: "Show mapping",
    MAPPING_NAME: "Mapping name",
    FILL_IN_THE_PREVIOUS_FIELDS_BEFORE_CONTINUING:
      "Fill in the previous fields before continuing",
    UPLOAD_FIELD_PLACEHOLDER: "Upload or drag your file here",
    NO_MAPPINGS_HAVE_BEEN_FOUND: "No mappings have been found",
    SELECT_ALL: "Select all",
    NO_OPTIONS: "No options",
    LOADING: "Loading",
    COMPANY: "Company",
    MARKET: "Market",
    DOWNLOAD_REPORT: "Download report",
    DOWNLOAD_OCCUPATIONS_REPORT: "Download occupations report",
    DOWNLOAD_RAIS_RAW_CSV: "EN-Baixar CSV dos dados filtrados",
    PROCESSING_THE_REPORT: "Processing the report",
    NO_RESULTS: "No results",
    PERCENTAGE: "Percentage",
    OPTIONS: "Options",
    CHART_OPTIONS: "Chart options",
    TOTAL_ESTIMATE_COST: "Estimated total cost",
    EMPLOYEES: "Employees",
    SALARY_AVERAGE: "Salary average",
    TOTAL_COST: "Total cost",
    DIFFERENCE_IN_RELATION_TO_MARKET: "Difference compared to the market",
    SIZE: "Size",
    METRICS: "Metrics",
    CHARTS: "Charts",
    REMUNERATION_AVERAGE: "Average remuneration",
    "10TH_PERCENTILE": "10th percentile",
    REMUNERATION_MEDIAN: "Median remuneration",
    "90TH_PERCENTILE": "90th percentile",
    EMPLOYEE_TOTAL_QUANTITY: "Total number of employees",
    BACK_TO_DEFAULTS: "Back to defaults",
    BUY_COMPARE_MODULE:
      "Get the comparison module now and get a complete analysis of your salary attractiveness compared to the market. Contact us by phone (11) 3167-2542.",
    BUY_CONSULT_MODULE:
      "Get the consult module now and get a complete analysis of your salary attractiveness compared to the market. Contact us by phone (11) 3167-2542.",
    SAVED_FILTERS: "Saved filters",
    NEW_FILTER: "New filter",
    OPEN: "Open",
    RENAME: "Rename",
    DELETE: "Delete",
    APPLY_AND_SAVE: "Apply and save",
    RELAX_AND_SAVE: "Relax and save",
    CLEAR: "Clear",
    LARGE_GROUP: "Large group",
    PRIMARY_SUBGROUP: "Primary subgroup",
    SUBGROUP: "Subgroup",
    FAMILY: "Family",
    OCCUPATION: "Occupation",
    ROLE: "Role",
    JOB: "Job",
    COMPANY_SECTOR: "Company sector",
    COMPANY_SECTORS: "Company sectors",
    SECTION: "Section",
    DIVISION: "Division",
    GROUP: "Group",
    CLASS: "Class",
    SUBCLASS: "Subclass",
    COMPANY_SIZE: "Company size",
    GRADUS_LOGO_ALT: "Gradus Tech logo",
    PASSWORD: "Password",
    ACCESS: "Access",
    REDEFINE_PASSWORD_FIRST_ACCESS: "Redefine password/First access",
    USER: "User",
    REDEFINE_PASSWORD: "Redefine password",
    UPDATE_PASSWORD: "Update password",
    NEW_PASSWORD: "New password",
    REPEAT_NEW_PASSWORD: "Repeat the new password",
    GO_BACK: "Go back",
    NO_FILTER_APPLIED: "No applied filters.",
    GEOGRAPHIC_DATA: "Geographic data",
    CREATE_NEW_FILTER: "Create new filter",
    SYSTEM_FIELD: "System field",
    SPREADSHEET_FIELD: "Spreadsheet field",
    ACCEPT: "Accept",
    FONT_RAIS: "Font: Last RAIS update ({{yearRais}})",
    APPLY_FILTERS: "Apply filters",
    DUPLICATE: "Duplicate",
    OPTIONAL: "Optional",
    VALUE: "Value",
    PAYROLL_EXAMPLE: "Payroll example",
    EDIT: "Edit",
    SAVE: "Save",
    ALL: "All",
    DOWNLOAD_TEMPLATE: "Download template",
    ALERTS: {
      SUCCESSFULLY_UPDATED_PASSWORD: "Password updated successfully",
      SUCCESSFULLY_REMOVED_ANALYSIS: "Analysis removed successfully",
      SUCCESSFULLY_RENAMED_ANALYSIS: "Analysis renamed successfully",
      SUCCESSFULLY_CREATED_ANALYSIS: "Analysis created successfully",
      SUCCESSFULLY_SAVED_ANALYSIS: "Analysis saved successfully",
      SUCCESSFULLY_CREATED_MAPPING: "Mapping created successfully",
      SUCCESSFULLY_REMOVED_MAPPING: "Mapping removed successfully",
      SUCCESSFULLY_DUPLICATED_MAPPING: "Mapping duplicated successfully",
      SUCCESSFULLY_EDITED_MAPPING: "Mapping edited successfully",
      GENERATING_REPORT:
        "Request sent! Generating the report may take a few minutes depending on your filters.",
      GENERATING_TEMPLATE: "Template generated successfully",
      SUCCESSFULLY_CHANGED_METRICS: "Metrics changed successfully",
      SUCCESSFULLY_CHANGED_CHARTS: "Charts changed successfully",
      SUCCESSFULLY_APPLIED_PARAMETERS: "Parameters changed successfully",
      SUCCESSFULLY_APPLIED_FILTER: "Filter applied successfully",
      SUCCESSFULLY_REMOVED_FILTER: "Filter removed successfully",
      SUCCESSFULLY_RENAMED_FILTER: "Filter renamed successfully",
      SUCCESSFULLY_CREATED_FILTER: "Filter created successfully",
      SUCCESSFULLY_COPIED_FILTER: "Filters copied successfully",
      USERNAME_OR_EMAIL_NOT_FOUND: "Username or email not found",
      LINK_FOR_PASSWORD_RESET_MESSAGE:
        "A password reset link will be sent to the registered email. Wait a few moments to receive the message with the procedure instructions. If you have not received the email, please also check your spam folder.",
      UNKNOWN_ERROR_PLEASE_CONTACT_SUPPORT:
        "There was a problem making the request to the server. Unexpected error, contact support.",
      DENIED_ACCESS: "Denied access",
      SUCCESSFULLY_APPLIED_OPTIONS: "Options applied successfully",
    },
    PASSWORD_MODAL: {
      CURRENT_PASSWORD: "Current password",
      YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST:
        "Your password must contain at least",
    },
    PASSWORD_REQUIREMENTS: {
      HAS_MINIMUM_CHARACTERS_LENGTH: "8 characters",
      HAS_AT_LEAST_ONE_NUMBER: "1 number",
      HAS_AT_LEAST_ONE_UPPER_CASE: "1 capital character",
      HAS_AT_LEAST_ONE_LOWER_CASE: "1 lowercase character",
      HAS_AT_LEAST_ONE_SPECIAL_CHARACTER: "1 special character",
      PASSWORD_EQUALS_CONFIRMATION: "Password confirmation",
      HAS_NOT_USER_DETAILS: "No usage of name and login",
      HAS_NO_SEQUENCES: "Must not have sequences",
    },
    HOME_PAGE: {
      COMPARE_TEXT:
        "Perform a complete analysis of your salary attractiveness compared to the market, based on the information on your payroll.",
      CONSULT_TEXT:
        "Filter and consult market salaries according to your needs.",
    },
    LIST_ANALYSIS_MODAL: {
      COMPARE_TITLE: "Analysis - Compare",
      CONSULT_TITLE: "Analysis - Consult",
      NO_ANALYSIS_FOUND: "No analysis found",
      SEARCH_BAR_PLACEHOLDER: "Ex.: Analysis 1",
    },
    COMPARE_CREATE_ANALYSIS_PAGE: {
      CREATE_AN_UNIQUE_NAME: "Create an unique name to identify your analysis.",
      PAYROLL_UPLOAD_TEXT_1:
        "In this step you will enter your payroll and create a mapping to define the parameters of the analysis or select a mapping already saved previously.",
      PAYROLL_UPLOAD_TEXT_2:
        "In accordance with our <0>information security policy</0> we request that data such as name, CPF, address, gender, age group and any sensitive information that identifies the employee be excluded from the spreadsheet prior to upload.",
      POLICY_CONSENT_BUTTON:
        "I declare that I have read the <0>information security policy</0> and am aware of the data that should or should not be sent to the system.",
      SELECT_MAPPING_TEXT:
        "Select the desired mapping to apply to the entered payroll, as long as it has the same columns as the previously saved mapping.",
      SELECT_MAPPING_SEARCH_PLACEHOLDER: "Ex.: Mapping 1",
    },
    MAPPINGS_TABLE: {
      IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_MAPPING:
        "It is not possible to delete mappings that are being used in some analysis",
      IT_IS_NOT_POSSIBLE_TO_EDIT_AN_ALREADY_USED_MAPPING:
        "It is not possible to edit mappings that are being used in some analysis",
      IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING:
        "It is not possible to edit sheet fields in mappings that are being used in some analysis",
    },
    CREATE_MAPPING_MODAL: {
      DESCRIPTION:
        "Mapping is a setting that links your payroll fields to system ones.",
      CBO_EXPLANATION:
        "Brazilian classification of occupations. It is the registration number used as a reference for administrative records of activities.",
    },
    COMPANY_SIZE_ENUM: {
      NO_EMPLOYEES: "No employees",
      UPTO_4: "Upto 4 employees",
      FROM_5_TO_9: "From 5 to 9 employees",
      FROM_10_TO_19: "From 10 to 19 employees",
      FROM_20_TO_49: "From 20 to 49 employees",
      FROM_50_TO_99: "From 50 to 99 employees",
      FROM_100_TO_249: "From 100 to 249 employees",
      FROM_250_TO_499: "From 250 to 499 employees",
      FROM_500_TO_999: "From 500 to 999 employees",
      FROM_1000: "From 1000 employees",
    },
    METRICS_BOARD: {
      MONETARY_VALUES_TOOLTIP:
        "All amounts referring to remuneration are in R$ thousand",
      ABOVE_AVERAGE:
        "The basket in question is <0>{{diff}} above the market average</0> for a similar basket in the selected sample.",
      BELOW_AVERAGE:
        "The basket in question is <0>{{diff}} below the market average</0> for a similar basket in the selected sample.",
      AT_AVERAGE:
        "The basket in question is <0>equal to the market average</0> for a similar basket in the selected sample.",
      "10TH_PERCENTILE_TOOLTIP":
        "10% of professionals have a salary lower than or equal to this amount, while the remaining 90% receive equal or higher remuneration",
      MEDIAN_TOOLTIP:
        "Half of the professionals have a salary less than or equal to this amount, while the other half receive a salary equal to or greater",
      "90TH_PERCENTILE_TOOLTIP":
        "90% of professionals have a salary lower than or equal to this amount, while the remaining 10% receive equal or higher remuneration",
    },
    DASHBOARD_HEADER: {
      APPLIED_FILTERS: "Applied filters",
      EDIT_OR_CREATE_NEW_FILTERS: "Edit or create new filters",
      CUSTOMIZE_INFORMATIONS: "Customize informations",
      SAVE_ANALYSIS: "Save analysis",
      NO_MODIFICATIONS_TO_SAVE: "No modifications to save",
      RENAME_ANALYSIS: "Rename analysis",
      ENTER_PRESENTATION_MODE: "Presentation mode",
      EXIT_PRESENTATION_MODE: "Exit presentation mode",
    },
    CHARTS_DASHBOARD: {
      MARKET_AVERAGE: "Average - Market",
      COMPANY_AVERAGE: "Average - Company",
      EMPLOYEE_PERCENTAGE_BY_SALARY_RANGE: "% of employees by salary range",
      SALARY_RANGE: "Salarial range (R$ thousand)",
      AVERAGE_SALARY: "Average salary (R$ thousand)",
      EMPLOYEES_BY_SALARY_RANGE_COMPANY: "Employees by salary range - Company",
      EMPLOYEES_BY_SALARY_RANGE_MARKET: "Employees by salary range - Market",
      EMPLOYEES_BY_SALARY_RANGE_BOTH: "Employees by salary range - Unified",
      SALARY_RANGE_BY_STATE: "Average salary by state",
      SALARY_RANGE_BY_COMPANY_SIZE: "Average salary by company size",
      SALARY_AVERAGE_BY_OCCUPATION:
        "Occupations with the highest average salary",
      HIGHER_AVERAGE: "Occupations with the highest average salary",
      HIGHER_COSTS: "Occupations with the highest payroll cost",
      COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE:
        "Occupations with a higher average salary than the market",
      COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE:
        "Occupations with a lower average salary than the market",
      AVERAGE_SALARY_BY_OCCUPATION: "Average salary by occupation",
      OCCUPATIONS_CHART_TOOLTIP:
        "Total market cost is calculated by multiplying the market average by the number of employees in your company.",
    },
    OCCUPATIONS_CHART_OPTIONS_MODAL: {
      SELECT_CHART_INFORMATION: "Select the chart information type",
    },
    PARAMETERIZATION_MODAL: {
      CHART_PARAMETERIZATION: "Chart parameterization",
      METRICS_ORGANIZATION: "Metrics organization",
      CHARTS_ORGANIZATION: "Charts organization",
      INDEX_APPLICATION: "Index application",
      METRICS_TAB_DESCRIPTION:
        "Choose which metrics will be shown in the metrics bar. They will be shown in the order they are selected.",
      CHARTS_TAB_DESCRIPTION:
        "Choose which graphics will be shown. They will be shown in the order they are selected.",
      APPLY_SALARY_CORRECTION_INDEX: "Apply salary correction index",
      SALARY_CORRECTION_INDEX_TOOLTIP:
        "The index can be used to correct discrepancies in market data, as the data source is the last updated RAIS. The selected value will be applied to all monetary values referring to the market, including in reports.",
      CUT_SALARIES_ABOVE_PERCENTILE: "Cut salaries above the percentile",
    },
    FILTER_MODAL: {
      FILTER_EXAMPLE: "Ex.: Filter 1",
      IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_FILTER:
        "It is not possible to remove an already in use filter",
      COPY_FILTER_FROM_CURRENT_TO_OTHER:
        "Copies the selected filters in the current tab to the {{otherTabName}} one",
      COPY_FILTER_FROM_OTHER_TO_CURRENT:
        "Copy the filters selected in the {{otherTabName}} tab to the current one",
      FILTERING_RESULT: "Filtering result",
      FILTERING_RESULT_TOOLTIP:
        "Employees' quantity that will be returned by filtering using the selected filters",
      INSUFFICIENT_DATA_DISCLAIMER:
        "Market data is insufficient for meaningful comparison, consider applying more comprehensive filters.",
      CBO_TOOLTIP:
        "Positions are specified by a number called CBO (Brazilian Classification of Occupations). The CBO is made up of 5 parts: <list><item>The first digit indicates the <bold>large group</bold>.</item><item>The second digit indicates the <bold>primary subgroup</bold> .</item><item>The third digit indicates the <bold>subgroup</bold>.</item><item>The fourth digit indicates the <bold>family</bold>.</item><item> The last two numbers of the code indicate the <bold>occupation</bold>.</item></list>",
      GEOGRAPHY_TOOLTIP:
        "The geography consists of five pieces of information: <list><item>The <bold>region</bold> refers to each of the federative units of Brazil.</item><item>The <bold>state</bold> refers to each Brazilian state (São Paulo, Santa Catarina, etc.).</item><item>The <bold>intermediate geographic region</bold> is a grouping of immediate geographic regions.</item><item>The <bold>immediate geographic region</bold> is a grouping of municipalities.</item><item>The <bold>counties</bold> is the smallest autonomous unit of the Federation.</item></list>",
      CNAE_TOOLTIP:
        "The company's sectors are specified by a number called CNAE (National Classification of Economic Activities). The CNAE is made up of 5 parts:<list><item>The first digit indicates the <bold>section</bold>.</item><item>The second digit indicates the <bold>division</bold>.< /item><item>The third digit indicates the <bold>group</bold>.</item><item>The fourth digit and its check digit (the fifth) indicate the <bold>class</bold>.< /item><item>The last two numbers of the code after the check digit indicate the <bold>subclass</bold>.</item></list>",
    },
    REMOVE_FILTER_DIALOG: {
      TITLE: "Removing filter",
      CONFIRMATION_MESSAGE:
        "Do you really want to remove the filter '{{filterName}}'?",
    },
    LOGIN_PAGE: {
      PLEASE_TICK_IM_NOT_A_ROBOT: 'Please tick "I\'m not a robot"',
      INCORRECT_USERNAME_OR_PASSWORD: "Incorrect username or password",
      USERNAME_OR_EMAIL: "Username or email",
    },
    GRADUS_TABLE: {
      ROWS_PER_PAGE: "Rows per page",
      DISPLAYED_ROWS: "{{from}}-{{to}} of {{count}} rows",
    },
    CONFIRMATION_DIALOG: {
      CONFIRM_ACTION: "Confirm action",
      DO_YOU_REALLY_WANT_TO_EXECUTE_THE_SELECTED_ACTION:
        "Do you really want to execute the selected action?",
    },
    PRIVACY_POLICY: {
      TITLE: "Privacy Policy",
      OBJECTIVES: "Objectives",
      OBJECTIVES_PARAGRAPH:
        "This policy aims to clarify for the user how the personal data used in the Gradus Remuneratio system are treated and for what purpose they are collected. In addition, it is also the purpose of this policy to inform how the user can exercise their rights related to their data.",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS:
        "About the company and its contracts",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_1:
        "Gradus is a consulting, technology and analytics company that has over 20 years of experience in management consulting and transforms proven methodologies into solutions that dramatically increase the efficiency of our clients.",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_2:
        "Doubts, information about how we treat personal data, to request the data present in the systems, or to request the deletion of your data, contact us by e-mail at dpo@gradustech.com.br. For any other questions, please use suporte@gradustech.com.br or call (11) 3167-2542.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM:
        "Which data the user should not include in the system",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_1:
        "In order for the analysis in Remuneratio to be operational, effective and present the comparison between the client's information and the market, the client's payroll is requested to be uploaded in the Compare Module.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_2:
        "Aiming at greater security of the customer's personal data, we request that data such as name, CPF, address, gender, age range and any sensitive information or information that identifies the employee to be excluded from the spreadsheet prior to upload.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_3:
        "In any case, after defining the mapping of which information will be used in the comparison, the system only keeps the data selected by the user, with the sole purpose of accessing the information in its analysis.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_4:
        "Therefore, Gradus does not store any personal data present in the payroll that allows associating the remuneration information with the individual.",
      WHY_DO_WE_COLLECT_PERSONAL_DATA: "Why do we collect personal data",
      WHY_DO_WE_COLLECT_PERSONAL_DATA_PARAGRAPH_1:
        "User personal data is collected exclusively for use by Gradus to allow:",
      WHY_DO_WE_COLLECT_PERSONAL_DATA_LIST:
        "<li>The use of the system, in order to allow users to explore its functionalities, such as the Compare Module</li><li>Ensure the security of user data</li><li>Investigate and fix issues encountered by users or Gradus staff</li><li>Create new features and improve existing ones in order to evolve the user experience</li><li>Allow communication of updates, support services, alerts and notifications to the user</li><li>Comply with legal and contractual obligations</li>",
      WHICH_DATA_DO_WE_COLLECT: "Which data do we collect",
      WHICH_DATA_DO_WE_COLLECT_LIST:
        "<li>Data present in the payroll entered by the user, including remuneration, positions, location, company size, CNAE and other parameters that the user deems appropriate to compose the analysis</li><li>Usage data: information is collected about your navigation on our website, which contents and reports were accessed</li><li>Technical information: all accesses per user to the platform are recorded, such as date and time of access, IP address, geolocation, type of operating system, browser version and language preference</li><li>Information saved directly by the user in the System, such as saved mappings and analyses, are stored for the sole purpose of accessing the information by the user</li>",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA: "For how long do we store the data",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_1:
        "The collected data is stored as long as necessary to ensure the fulfillment of the purposes for which it was collected or to comply with legal and contractual obligations.",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_2:
        "As mentioned, the data that is not selected in the information mapping step will be excluded from the system when the parameterization is applied. The data that are mapped and the analyzes saved can be deleted by the user directly in the system.",
      REGARDING_DATA_TRANSFER: "Regarding data transfer",
      REGARDING_DATA_TRANSFER_PARAGRAPH_1:
        "All data collected is used only by Gradus and the customer without sharing with third parties, with the exception of basic services for customer service (CRM, e-mail and training), navigation data analysis services and the infrastructure of the systems that are located in the United States.",
      WHAT_ARE_THE_USER_RIGHTS: "What are the user rights",
      WHAT_ARE_THE_USER_RIGHTS_LIST:
        "<li>Confirm the existence of personal data processing</li><li>Access your personal data</li><li>Correct incomplete, inaccurate or outdated personal data</li><li>Anonymization, blocking or deletion of personal data that is unnecessary, excessive or treated in violation of the LGPD</li><li>Portability of personal data to another product or service provider</li><li>Deletion of processed data with your consent</li><li>Obtaining information about the public and private entities with which the controller shared personal data</li><li>Obtaining information on the possibility of not consenting to the processing of personal data and on the consequences of the refusal</li>",
      WHAT_SECURITY_MEASURES_ARE_TAKEN: "What security measures are taken",
      WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_1:
        "The security measures used by Gradus, such as backup, firewall and antivirus, are carried out to ensure that user data is always protected, so that only authorized members of the Gradus team have direct access to the systems infrastructure.",
      WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_2:
        "In addition to the entire security structure implemented by Gradus, massive security tests are also carried out by renowned third-party audits.",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY:
        "Regarding revision and updates on the access control policy",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_1:
        "The document is valid from 05/02/2022 and if any changes occur to the privacy policy, the information contained in this document will be updated.",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_2:
        "The Privacy Policy must be reviewed and updated, at least annually, with the intention of keeping in line with business rules, best market practices, laws, regulations and other aspects.",
      FOOTER_LINK: "Privacy Policy",
    },
    BACKEND_MESSAGES: {
      ANALYSIS_DOES_NOT_EXIST: "Analysis does not exist",
      FIELD_NAME_IS_REQUIRED: "Th field Name is required",
      FIELD_SALARY_IS_REQUIRED: "Th field Salary is required",
      FIELD_JOB_IS_REQUIRED: "Th field Job (CBO) is required",
      FIELD_COMPANY_IS_REQUIRED: "Th field CNAE is required",
      FIELD_STATE_IS_REQUIRED_WHEN_MAPPING_COUNTIES:
        "The field State is required when mapping the field County",
      FIELD_CONFIGURATION_IS_REQUIRED: "The field Configuração is required",
      FIELD_COMPANY_SIZE_IS_REQUIRED: "O The field Company Size is required",
      INVALID_VALUE_FOR_COMPANY_SIZE:
        "Invalid value for the field Company Size",
      INVALID_VALUE_FOR_CONFIGURATION:
        "Invalid value for the field Configuration",
      YOU_SHOULD_ACCEPT_THE_INFORMATION_SECURITY_POLICY_TO_USE_THE_SYSTEM:
        "You should accept the information secury policy to use the system",
      FILTER_NAME_IS_REQUIRED: "The filter name is required",
      FILTER_DOES_NOT_EXIST: "The filter does not exist",
      FILTER_SHOULD_BE_RELATED_TO_AN_ANALYSIS:
        "The filter should be related to an analysis",
    },
    GENERAL: {
      CANCEL: "Cancel",
    },
    GRADUS_DOUBLE_LIST_FIELD_SELECTOR: {
      TO_INCLUDE: "To include",
      TO_EXCLUDE: "To exclude",
      DROP_THE_DESIRED_ITEM_HERE: "Drop the desired item here",
    },
    RELAXATION_SELECTOR: {
      FILTERS_RELAXATION: "Filters relaxation",
      EXCLUDED_FILTERS: "Filters excluded from relaxation",
      INCLUDED_FILTERS: "Filters included in relaxation",
      EXPLANATION_TOOLTIP:
        "The relaxation seeks, for each occupation and county pair filtered from the payroll, to increase the statistical significance of the market sample for the minimum of {{quantity}} employees by expanding the geography filter",
      RELAXATION_NOT_AVAILABLE_FOR_PAYROLLS_WITHOUT_COUNTIES:
        "The relaxation is not available for payrolls without counties",
    },
    COMPANY_SIZE_FILTER_TAG: {
      BETWEEN_X_AND_Y_EMPLOYEES: "Between {{from}} and {{to}} employees",
      UPTO_X_EMPLOYEES: "Upto {{to}} employees",
      FROM_X_EMPLOYEE: "From {{from}} employee",
      FROM_X_EMPLOYEES: "From {{from}} employees",
      ANY_NUMBER_OF_EMPLOYEES: "Any number of employees",
    },
  },
};
