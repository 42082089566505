import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import _ from "lodash";
import useFilterStore from "../../../stores/FilterStore/useFilterStore";
import InfoIcon from "../../InfoIcon/InfoIcon";
import GradusTooltip from "../../GradusComponents/GradusTooltip";
import useSystemConfigurationsStore from "../../../stores/useSystemConfigurationsStore";
import useCompareAnalysisStore from "../../../stores/useCompareAnalysisStore";
import useRelaxationSelectorStore from "../../../stores/useRelaxationSelectorStore";
import { useGetByAnalysisId } from "../../../apis/payrollConfigurationsApi";

export default function RelaxationSelector() {
  const { changeRelaxationState } = useFilterStore((state) => ({
    changeRelaxationState: state.changeRelaxationState,
  }));

  const { analysis } = useCompareAnalysisStore();

  const { data: payrollConfiguration } = useGetByAnalysisId(analysis.getId());

  const { isRelaxed, updateRelaxationStatus } = useRelaxationSelectorStore(
    (state) => ({
      isRelaxed: state.isRelaxed,
      updateRelaxationStatus: state.updateRelaxationStatus,
    })
  );

  const configurations = useSystemConfigurationsStore(
    (state) => state.configurations
  );

  const { t } = useTranslation();

  return (
    <Container disableGutters>
      <Stack direction="row" sx={{ mb: 1, ml: 0 }} alignItems="center">
        <GradusTooltip
          title={t("RELAXATION_SELECTOR.EXPLANATION_TOOLTIP", {
            quantity:
              configurations.statisticallyInsignificantQuantityThreshold,
          })}
          placement="top-end"
        >
          <span>
            <InfoIcon icon={solid("question-circle")} />
          </span>
        </GradusTooltip>
        <Typography variant="h6" sx={{ ml: 1 }}>
          {t("RELAXATION_SELECTOR.FILTERS_RELAXATION")}
        </Typography>
        <GradusTooltip
          title={
            _.isEmpty(payrollConfiguration?.countyColumn)
              ? t(
                  "RELAXATION_SELECTOR.RELAXATION_NOT_AVAILABLE_FOR_PAYROLLS_WITHOUT_COUNTIES"
                )
              : ""
          }
        >
          <span>
            <Switch
              checked={isRelaxed}
              onChange={(_event, checked) => {
                updateRelaxationStatus(checked);
                changeRelaxationState(analysis.getId());
              }}
              disabled={_.isEmpty(payrollConfiguration?.countyColumn)}
              data-testid="relaxation-switch"
            />
          </span>
        </GradusTooltip>
      </Stack>
    </Container>
  );
}
