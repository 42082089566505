import api from "./api";

const BASE_URL = "/api/v1/user";

const userApi = {
  findAvailableModules: () => api.get(`${BASE_URL}/available-modules`),
  recoverPassword: ({ usernameOrEmail }: { usernameOrEmail: string }) =>
    api.post(`${BASE_URL}/password/email`, { usernameOrEmail }),
  updatePassword: ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => api.post(`${BASE_URL}/password_update`, { oldPassword, newPassword }),
  changeLocale: (locale: "en" | "es" | "pt") =>
    api.post(`${BASE_URL}/locale/${locale}`),
  getLocale: () => api.get(`${BASE_URL}/locale`),
};

export default userApi;
