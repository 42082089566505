import React from "react";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";

export default function ErrorList({ excelErrors, containerSx }) {
  if (excelErrors.length <= 0) {
    return null;
  }

  return (
    <Container
      sx={{
        ...containerSx,
        maxHeight: "200px",
        border: "1px solid red",
        pr: 1,
        overflow: "auto",
        textAlign: "left",
      }}
    >
      <ul>
        {excelErrors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </Container>
  );
}

ErrorList.propTypes = {
  excelErrors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  containerSx: PropTypes.object,
};

ErrorList.defaultProps = {
  containerSx: {},
};
