import React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import LocaleButton from "../Buttons/LocaleButton";

const LanguageSelector = ({
  colorVariant,
  isNotAuthenticated,
}: {
  colorVariant?: "dark" | "light";
  isNotAuthenticated?: boolean;
}) => {
  const theme = useTheme();

  const color = colorVariant === "dark" ? theme.palette.grey[700] : "white";

  return (
    <Stack direction="row" mr={2}>
      <LocaleButton
        locale="en"
        buttonStyle={{ color }}
        isNotAuthenticated={isNotAuthenticated}
      />
      <Divider
        orientation="vertical"
        sx={{ ml: 1, mr: 1, bgcolor: color }}
        flexItem
      />
      <LocaleButton
        locale="es"
        buttonStyle={{ color }}
        isNotAuthenticated={isNotAuthenticated}
      />
      <Divider
        orientation="vertical"
        sx={{ ml: 1, mr: 1, bgcolor: color }}
        flexItem
      />
      <LocaleButton
        locale="pt"
        buttonStyle={{ color }}
        isNotAuthenticated={isNotAuthenticated}
      />
    </Stack>
  );
};

LanguageSelector.defaultProps = {
  colorVariant: "dark",
  isNotAuthenticated: false,
};

export default LanguageSelector;
