import { StateCreator } from "zustand";
import {
  MarketFilterOptionsSlice,
  PayrollFilterOptionsSlice,
} from "./filterOptionsStoreUtils";
import PayrollFilter from "../../dtos/PayrollFilter";
import filterOptionApi from "../../apis/filterOptionApi";
import CompareAnalysisPresenter from "../../dtos/CompareAnalysis/CompareAnalysisPresenter";

const initialState = {
  companyOptions: new PayrollFilter({}),
};

const createPayrollFilterOptionSlice: StateCreator<
  PayrollFilterOptionsSlice & MarketFilterOptionsSlice,
  [],
  [],
  PayrollFilterOptionsSlice
> = (set, get) => ({
  ...initialState,

  setCompanyOptions: (companyOptions) =>
    set((state) => ({ ...state, companyOptions })),

  _initPayrollOptions: async (analysis: CompareAnalysisPresenter) => {
    await get().getCompanyChainedFilters(
      analysis.getCompanyFilters(),
      analysis.getId()
    );
  },

  getPayrollJobChainedFilters: async (
    filters: PayrollFilter,
    analysisId: number
  ) => {
    const response = await filterOptionApi.getJobChainedFiltersByAnalysis(
      analysisId,
      filters.onlyWithIds()
    );

    set(({ companyOptions, ...state }) => {
      const chainedFilters = new PayrollFilter(response.data).jobFilters();
      const newCompanyOptions = new PayrollFilter({
        ...companyOptions,
        ...chainedFilters,
      });
      return { ...state, companyOptions: newCompanyOptions };
    });
  },
  getPayrollGeographyChainedFilters: async (
    filters: PayrollFilter,
    analysisId: number
  ) => {
    const response =
      await filterOptionApi.getGeographicChainedFiltersByAnalysis(
        analysisId,
        filters.onlyWithIds()
      );

    set(({ companyOptions, ...state }) => {
      const chainedFilters = new PayrollFilter(
        response.data
      ).geographicFilters();
      const newCompanyOptions = new PayrollFilter({
        ...companyOptions,
        ...chainedFilters,
      });
      return { ...state, companyOptions: newCompanyOptions };
    });
  },
  getPayrollCustomColumnsChainedFilters: async (
    filters: PayrollFilter,
    analysisId: number
  ) => {
    const response =
      await filterOptionApi.getCustomColumnChainedFiltersByAnalysis(
        analysisId,
        filters.onlyWithIds()
      );

    set(({ companyOptions, ...state }) => {
      const chainedFilters = new PayrollFilter(
        response.data
      ).customColumnsFilters();
      const newCompanyOptions = new PayrollFilter({
        ...companyOptions,
        ...chainedFilters,
      });
      return { ...state, companyOptions: newCompanyOptions };
    });
  },

  getCompanyChainedFilters: async (appliedFilters, analysisId) => {
    const filters = appliedFilters.onlyWithIds();
    const chainedFilters =
      await filterOptionApi.getCompanyConsolidatedChainedFilters(
        filters,
        analysisId
      );
    const companyOptions = new PayrollFilter(chainedFilters.data);
    set({ companyOptions });
  },
});

export default createPayrollFilterOptionSlice;
