import MarketFilter from "../MarketFilter";
import PayrollFilter from "../PayrollFilter";
import CompareAnalysisPresenter from "./CompareAnalysisPresenter";

export default class CompareAnalysisUpdateDTO {
  private readonly id: number;

  private readonly name: string;

  private readonly marketFilters: MarketFilter;

  private readonly companyFilters: PayrollFilter;

  private readonly activeFilterId: number;

  private readonly isRelaxed: boolean;

  constructor(
    id: number,
    name: string,
    marketFilters: MarketFilter,
    companyFilters: PayrollFilter,
    activeFilterId: number,
    isRelaxed = false
  ) {
    this.id = id;
    this.name = name;
    this.marketFilters = marketFilters;
    this.companyFilters = companyFilters;
    this.activeFilterId = activeFilterId;
    this.isRelaxed = isRelaxed;
  }

  static fromPresenter(analysis: CompareAnalysisPresenter) {
    const activeFilter = analysis.getActiveFilter();

    return new CompareAnalysisUpdateDTO(
      analysis.getId(),
      analysis.getName(),
      analysis.getMarketFilters().onlyWithIds(),
      analysis.getCompanyFilters().onlyWithIds(),
      analysis.getActiveFilterId(),
      activeFilter?.getRelaxedFilter().isActive
    );
  }
}
