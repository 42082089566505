import React, { Dispatch, SetStateAction } from "react";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import RenameComponent from "./RenameComponent";
import ExpandToggleButton from "../Buttons/ExpandToggleButton";
import PresentationModeButton from "./PresentationModeButton";
import FilterModal from "../Modals/FilterModal/FilterModal";
import ParameterModal from "../Modals/ParameterModal/ParameterModal";
import GradusIconButton from "../GradusComponents/GradusIconButton";
import withHideOnPresentationMode from "../../hocs/withHideOnPresentationMode";
import useMetricsStore from "../../stores/useMetricsStore";
import ConsulteAnalysisPresenter from "../../dtos/ConsulteAnalysis/ConsulteAnalysisPresenter";

export default function ConsulteDashboardHeader({
  analysis,
  renameAnalysis,
  setShowFilters,
  showFilters,
}: {
  analysis: ConsulteAnalysisPresenter;
  renameAnalysis: (newName: string) => void;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  showFilters: boolean;
}) {
  const { t } = useTranslation();

  const { isLoadingConsulteMetrics } = useMetricsStore((state) => ({
    isLoadingConsulteMetrics: state.isLoadingConsulteMetrics,
  }));

  const HiddenOnPresentationMode = withHideOnPresentationMode(GradusIconButton);

  const [isRenaming, setIsRenaming] = React.useState(false);
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [openParameterModal, setOpenParameterModal] = React.useState(false);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          my: 0,
        }}
      >
        <RenameComponent
          isRenaming={isRenaming}
          setIsRenaming={setIsRenaming}
          handleRenameAnalysis={renameAnalysis}
          analysis={analysis}
        />

        <span style={{ display: "flex" }}>
          <ExpandToggleButton
            show={showFilters}
            handleToggle={() => setShowFilters(!showFilters)}
            buttonTitle={t("DASHBOARD_HEADER.APPLIED_FILTERS")}
          />

          <Divider orientation="vertical" sx={{ ml: 2, mr: 1 }} flexItem />

          <GradusIconButton
            onClick={() => setOpenFilterModal(true)}
            title={t("DASHBOARD_HEADER.EDIT_OR_CREATE_NEW_FILTERS")}
            icon={light("filter")}
            disabled={isLoadingConsulteMetrics()}
          />
          <GradusIconButton
            onClick={() => setOpenParameterModal(true)}
            title={t("DASHBOARD_HEADER.CUSTOMIZE_INFORMATIONS")}
            icon={light("sliders")}
          />
          <HiddenOnPresentationMode
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => setIsRenaming(true)}
            title={t("DASHBOARD_HEADER.RENAME_ANALYSIS")}
            icon={light("pencil")}
          />

          <Divider orientation="vertical" sx={{ mx: 1 }} flexItem />

          <PresentationModeButton />
        </span>
      </Container>

      <FilterModal
        isOpen={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        analysis={analysis}
        isCompare={false}
      />
      <ParameterModal
        open={openParameterModal}
        close={() => setOpenParameterModal(false)}
        analysis={analysis}
        isCompare={false}
      />
    </>
  );
}
