import { AxiosResponse } from "axios";
import api from "../api";
import Metrics from "../../models/Metrics";
import MarketFilter from "../../dtos/MarketFilter";
import ConsulteGetMetricDTO from "../../dtos/chartGenerate/ConsulteGetMetricDTO";
import CompareGetMetricDTO from "../../dtos/chartGenerate/CompareGetMetricDTO";
import CompareGetMetricsWithRelaxationDTO from "../../dtos/chartGenerate/CompareGetMetricsWithRelaxationDTO";
import PayrollFilter from "../../dtos/PayrollFilter";

const CONSULTE_BASE_URL = "/api/v1/consulte/employee/metrics";
const COMPARE_BASE_URL = "/api/v1/compare/employee/metrics";

const mapResponseToMetrics = (response: AxiosResponse) =>
  new Metrics(response.data);

const employeeMetricsApi = {
  getConsulteMetrics: async (
    filters: MarketFilter,
    correctionIndex: number
  ) => {
    const response = await api.post(
      CONSULTE_BASE_URL,
      new ConsulteGetMetricDTO(filters.onlyWithIds(), correctionIndex)
    );
    return mapResponseToMetrics(response);
  },

  getCompareMetricsByFilterId: async (
    filterId: number,
    correctionIndex: number,
    analysisId: number
  ) => {
    const response = await api.post(
      COMPARE_BASE_URL,
      new CompareGetMetricDTO(filterId, correctionIndex, analysisId)
    );
    return mapResponseToMetrics(response);
  },

  getCompareMetrics: async (
    marketFilters: MarketFilter,
    companyFilters: PayrollFilter,
    shouldRelax: boolean,
    correctionIndex: number,
    analysisId: number
  ): Promise<Metrics> => {
    const response = await api.post(
      `${COMPARE_BASE_URL}/by-filter`,
      new CompareGetMetricsWithRelaxationDTO(
        marketFilters.onlyWithIds(),
        companyFilters.onlyWithIds(),
        shouldRelax,
        correctionIndex,
        analysisId
      )
    );
    return mapResponseToMetrics(response);
  },
};

export default employeeMetricsApi;
