import { Alert, Snackbar } from "@mui/material";
import React, { ReactNode, useMemo, useState } from "react";
import AlertContext from "../../contexts/AlertContext";

export type FireAlertFn = (
  alertMessage: string | ReactNode,
  hasErrors?: boolean,
  timeout?: number
) => void;

export default function SystemAlert({ children }: { children?: ReactNode }) {
  const [hasReturnedError, setHasReturnedError] = useState<boolean>(false);
  const [message, setMessage] = useState<string | ReactNode>("");
  const [open, setOpen] = useState<boolean>(false);
  const defaultTimeout = 2000;

  const fireAlert: FireAlertFn = (
    alertMessage,
    hasErrors = false,
    timeout = defaultTimeout
  ) => {
    setHasReturnedError(hasErrors);
    setMessage(alertMessage);
    setOpen(true);
    setTimeout(() => setOpen(false), timeout);
  };

  const contextValue = useMemo(() => ({ fireAlert }), []);

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={defaultTimeout}
      >
        {/* FIXME: precisamos criar um ErrorBoundary ao invés de fazer esse tipo de tratamento */}
        <Alert severity={hasReturnedError ? "error" : "success"}>
          {message}
        </Alert>
      </Snackbar>
      <AlertContext.Provider value={contextValue}>
        {children}
      </AlertContext.Provider>
    </>
  );
}

SystemAlert.defaultProps = {
  children: null,
};
