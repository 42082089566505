import { TextField, TextFieldProps } from "@mui/material";
import React, { useMemo } from "react";

const GradusTextField = ({ required, label, ...props }: TextFieldProps) => {
  const labelWithOptional = useMemo(
    () => (required ? label : `${label} (opcional)`),
    [required, label]
  );

  return (
    <TextField
      variant="outlined"
      type="text"
      size="small"
      label={labelWithOptional}
      required={required}
      InputLabelProps={{ required: false }}
      {...props}
    />
  );
};

GradusTextField.defaultProps = {
  required: false,
  props: {},
};

export default GradusTextField;
