import React from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Grid, Paper, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

const IconWrapper = styled("div")(() => ({
  "& img": {
    height: "60px",
  },
}));

export default function AuthenticationWrapper({ width, height, children }) {
  const { t } = useTranslation();

  return (
    <div>
      <AppBar position="absolute">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <IconWrapper>
              <img
                src="/images/Gradus_Analytics_Marca_RGB_Negativa.svg"
                alt={t("GRADUS_LOGO_ALT")}
              />
            </IconWrapper>
          </Box>
          <LanguageSelector colorVariant="light" isNotAuthenticated />
        </Toolbar>
      </AppBar>
      <Grid
        container
        direction="row"
        alignContent="center"
        justifyContent="center"
        paddingTop="64px"
        style={{
          backgroundColor: "#F9F9F9",
          backgroundImage: "url(/images/logo-gradus.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "calc(100vh - 37px)",
        }}
      >
        <Grid item>
          <Grid
            item
            container
            direction="column"
            spacing={2}
            className="login-form"
          >
            <Paper
              className="login-background"
              sx={{
                width,
                minHeight: height,
                display: "flex",
                alignItems: "center",
                p: 2,
              }}
            >
              {children}
            </Paper>
          </Grid>
        </Grid>
        <Grid item />
      </Grid>
      <Footer />
    </div>
  );
}

AuthenticationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

AuthenticationWrapper.defaultProps = {
  width: "232px",
  height: "200px",
};
