import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import GradusPrimaryButton from "../../Buttons/GradusPrimaryButton";
import GradusSupportButton from "../../Buttons/GradusSupportButton";
import { handleApiError } from "../../../utils/ErrorUtils";
import AlertContext from "../../../contexts/AlertContext";
import InfoIcon from "../../InfoIcon/InfoIcon";
import GradusTooltip from "../../GradusComponents/GradusTooltip";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import useChartParametersStore from "../../../stores/ChartParametersStore/useChartParametersStore";
import GradusPercentageInput from "../../GradusComponents/GradusPercentageInput";

export default function IndexApplicationTab({
  isCompare,
  analysis,
  isModalOpen,
}: {
  isCompare: boolean;
  analysis: AnalysisPresenter;
  isModalOpen: boolean;
}) {
  const { fireAlert } = useContext(AlertContext);

  const {
    globalParameters,
    applyGlobalSelected,
    updateGlobalSelected,
    resetGlobalSelected,
    revertGlobalSelectedToApplied,
  } = useChartParametersStore((state) => ({
    globalParameters: state.globalParameters,
    applyGlobalSelected: state.applyGlobalSelected,
    updateGlobalSelected: state.updateGlobalSelected,
    resetGlobalSelected: state.resetGlobalSelected,
    revertGlobalSelectedToApplied: state.revertGlobalSelectedToApplied,
  }));
  const updateCharts = useChartStore((state) => state.updateCharts);

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const handleSaveButtonClick = () => {
    try {
      applyGlobalSelected();
      updateCharts(isCompare, analysis);
      fireAlert(t("ALERTS.SUCCESSFULLY_APPLIED_PARAMETERS", { lng: language }));
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  };

  const handleBackToDefaultButtonClick = () => {
    resetGlobalSelected();
  };

  const handleChange = (newValue: number) => {
    updateGlobalSelected({ correctionIndex: newValue });
  };

  return (
    <Container sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container>
        <Grid item sm={6} md={6} lg={6} xl={6}>
          <FormGroup>
            <FormControl>
              <FormLabel htmlFor="correction-index">
                {`${t(
                  "PARAMETERIZATION_MODAL.APPLY_SALARY_CORRECTION_INDEX"
                )}:`}
              </FormLabel>
              <Stack direction="row" gap={1} alignItems="center">
                <GradusPercentageInput
                  id="correction-index"
                  value={globalParameters.selected.correctionIndex}
                  setValue={handleChange}
                  canExceedHundred
                />
                <GradusTooltip
                  title={t(
                    "PARAMETERIZATION_MODAL.SALARY_CORRECTION_INDEX_TOOLTIP"
                  )}
                  placement="top-end"
                >
                  <span>
                    <InfoIcon icon={solid("question-circle")} />
                  </span>
                </GradusTooltip>
              </Stack>
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>

      <Box
        my={2}
        sx={{
          display: "flex",
          justifyItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <GradusPrimaryButton
          onClick={handleSaveButtonClick}
          sx={{ my: 0, mx: "10px" }}
        >
          {t("APPLY")}
        </GradusPrimaryButton>

        <GradusSupportButton
          onClick={handleBackToDefaultButtonClick}
          sx={{ ml: "10px" }}
        >
          {t("BACK_TO_DEFAULTS")}
        </GradusSupportButton>
      </Box>
    </Container>
  );
}
