import { create } from "zustand";

export default function createSingletonStore(
  defValue,
  createStore = () => create(() => defValue)
) {
  const useStore = createStore();
  function setState(newValue) {
    return useStore.setState(newValue, true);
  }
  function getState() {
    return useStore.getState();
  }
  function resetState() {
    setState(defValue);
  }
  return { useStore, getState, setState, resetState };
}
