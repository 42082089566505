import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import AlertContext from "../contexts/AlertContext";
import { handleApiError } from "../utils/ErrorUtils";

export default function useDownloadFile(
  setIsDownloading,
  apiFn,
  filename,
  fileExtension,
  alertOnClickFn
) {
  const { fireAlert } = useContext(AlertContext);
  const { t } = useTranslation();

  return useCallback(async () => {
    setIsDownloading(true);
    try {
      alertOnClickFn();
      const response = await apiFn();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${filename}-${new Date().toISOString()}.${fileExtension}`
      );
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
    } catch (e) {
      handleApiError(e, fireAlert, t);
    } finally {
      setIsDownloading(false);
    }
  }, [
    alertOnClickFn,
    apiFn,
    fileExtension,
    filename,
    fireAlert,
    setIsDownloading,
    t,
  ]);
}
