import api from "../api";
import ConsulteGetChartDTO from "../../dtos/chartGenerate/ConsulteGetChartDTO";
import HistogramBinDTO from "../../dtos/HistogramBinDTO";
import SalaryAverageAndQuantity from "../../dtos/SalaryAverageAndQuantity";

const BASE_URL = "/api/v1/consulte/employee/chart";

const consulteEmployeeChartApi = {
  getEmployeeCountBySalaryRange: (
    dto: ConsulteGetChartDTO
  ): Promise<HistogramBinDTO[]> =>
    new Promise<HistogramBinDTO[]>((resolve, reject) => {
      api
        .post(`${BASE_URL}/count-by-salary-range`, dto)
        .then((response) => {
          const histogramBins = response.data.map(
            (bin: HistogramBinDTO) => new HistogramBinDTO({ ...bin })
          );
          return resolve(histogramBins);
        })
        .catch(reject);
    }),

  getEmployeeAverageSalaryByState: (
    dto: ConsulteGetChartDTO
  ): Promise<{ [key: string]: SalaryAverageAndQuantity }> =>
    new Promise<{ [key: string]: SalaryAverageAndQuantity }>(
      (resolve, reject) => {
        api
          .post(`${BASE_URL}/average-salary-by-state`, dto)
          .then((response) => {
            const map: { [key: string]: SalaryAverageAndQuantity } = {};
            const responseData = response.data;
            Object.keys(responseData).forEach((key) => {
              map[key] = new SalaryAverageAndQuantity({ ...responseData[key] });
            });
            return resolve(map);
          })
          .catch(reject);
      }
    ),

  getEmployeeAverageSalaryByCompanySize: (
    dto: ConsulteGetChartDTO
  ): Promise<{ [key: string]: SalaryAverageAndQuantity }> =>
    new Promise<{ [key: string]: SalaryAverageAndQuantity }>(
      (resolve, reject) => {
        api
          .post(`${BASE_URL}/average-salary-by-company-size`, dto)
          .then((response) => {
            const map: { [key: string]: SalaryAverageAndQuantity } = {};
            const responseData = response.data;
            Object.keys(responseData).forEach((key) => {
              map[key] = new SalaryAverageAndQuantity({ ...responseData[key] });
            });
            return resolve(map);
          })
          .catch(reject);
      }
    ),
};

export default consulteEmployeeChartApi;
