import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GradusTableColumn from "../../GradusComponents/GradusTable/GradusTableColumn";
import ConsulteAnalysisPresenter from "../../../dtos/ConsulteAnalysis/ConsulteAnalysisPresenter";
import RenameField from "./RenameField";
import { GradusTable } from "../../GradusComponents/GradusTable/GradusTable";
import ConfirmationDialog from "../../ConfirmationDialog/ConfirmationDialog";
import Actions from "../../Actions/Actions";
import TruncatedFilter from "../../GradusComponents/GradusTable/TruncatedFilter";
import { ActionButtonType } from "../../Actions/ActionButtonFactory";

export default function ConsulteAnalysisTable({
  options,
  handleDeleteAnalysis,
  handleOpenAnalysis,
  handleRenameAnalysis,
}: {
  options: ConsulteAnalysisPresenter[];
  handleDeleteAnalysis: (id: number) => void;
  handleOpenAnalysis: (id: number | null) => void;
  handleRenameAnalysis: (newName: string, id: number) => void;
}) {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  const [renamingId, setRenamingId] = useState<number>(-1);
  const [deletingId, setDeletingId] = useState<number>(-1);

  const columns = useMemo(
    () => [
      new GradusTableColumn<ConsulteAnalysisPresenter>({
        field: "name",
        headerLabel: t("NAME", { lng: language }),
        order: 1,
        render: (row) => {
          if (row.getId() === renamingId) {
            return (
              <RenameField
                id={row.getId()}
                name={row.getName()}
                renameFn={handleRenameAnalysis}
                setIsRenaming={(isRenaming) => {
                  setRenamingId(isRenaming ? row.getId() : -1);
                }}
              />
            );
          }
          return row.getName();
        },
        hasFilter: true,
      }),
      new GradusTableColumn<ConsulteAnalysisPresenter>({
        field: "marketFilters",
        headerLabel: t("MARKET_FILTERS", { lng: language }),
        order: 2,
        render: (row) => <TruncatedFilter filters={row.getMarketFilters()} />,
        hasFilter: false,
      }),
      new GradusTableColumn<ConsulteAnalysisPresenter>({
        field: "id",
        headerLabel: t("ACTIONS", { lng: language }),
        order: 3,
        align: "center",
        headerAlign: "center",
        render: (row) => (
          // FIXME: Parece que ele não está aceitando uma definição de props
          //  como tipo neste componente, ver como melhorar isso
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Actions
            actions={[
              {
                type: ActionButtonType.OPEN,
                buttonProps: { onClick: () => handleOpenAnalysis(row.getId()) },
              },
              {
                type: ActionButtonType.RENAME,
                buttonProps: { onClick: () => setRenamingId(row.getId()) },
              },
              {
                type: ActionButtonType.DELETE,
                buttonProps: { onClick: () => setDeletingId(row.getId()) },
              },
            ]}
          />
        ),
        hasFilter: false,
      }),
    ],
    [handleOpenAnalysis, handleRenameAnalysis, language, renamingId, t]
  );

  return (
    <>
      <GradusTable
        containerSx={{ mt: 2 }}
        storeName="consulte-analysis-table"
        rows={options}
        columns={columns}
      />
      <ConfirmationDialog
        open={deletingId > -1}
        handleAccept={() => {
          handleDeleteAnalysis(deletingId);
          setDeletingId(-1);
        }}
        handleCancel={() => {
          setDeletingId(-1);
        }}
      />
    </>
  );
}
