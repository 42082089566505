export default class CompareGetMetricDTO {
  filterId: number;

  correctionIndex: number;

  analysisId: number;

  constructor(filterId: number, correctionIndex: number, analysisId: number) {
    this.filterId = filterId;
    this.correctionIndex = correctionIndex;
    this.analysisId = analysisId;
  }
}
