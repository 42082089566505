import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

const PageContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
}));

PageContainer.defaultProps = {
  disableGutters: true,
};

export default PageContainer;
