import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Chart from "../Chart";
import useChartParametersStore from "../../../stores/ChartParametersStore/useChartParametersStore";
import GradusPercentageInput from "../../GradusComponents/GradusPercentageInput";

export default function CapPercentileApplier({ chart }: { chart: Chart }) {
  const { t } = useTranslation();

  const { updateSelected, [chart]: chartParameters } = useChartParametersStore(
    (state) => ({
      updateSelected: state.updateSelected,
      [chart]: state[chart],
    })
  );

  const handleChange = useCallback(
    (newValue: number) => {
      updateSelected(chart, {
        capPercentile: newValue,
      });
    },
    [chart, updateSelected]
  );

  return (
    <FormGroup>
      <FormControl>
        <FormLabel htmlFor="cap-percentile" sx={{ mb: 1 }}>
          {`${t("PARAMETERIZATION_MODAL.CUT_SALARIES_ABOVE_PERCENTILE")}:`}
        </FormLabel>
        <GradusPercentageInput
          id="cap-percentile"
          value={chartParameters.selected.capPercentile}
          setValue={handleChange}
        />
      </FormControl>
    </FormGroup>
  );
}
