import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Divider from "@mui/material/Divider";
import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";
import _ from "lodash";
import withHideOnPresentationMode from "../../hocs/withHideOnPresentationMode";
import SidebarList from "../../components/SidebarList/SidebarList";
import LogoImage from "./LogoImage";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    !_.includes(["open", "openWidth", "closedWidth", "animated"], prop),
})(({ theme, open, openWidth, closedWidth, animated }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: openWidth,
    ...(animated && {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    overflow: "hidden",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      ...(animated && {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }),
      width: closedWidth,
    }),
  },
}));

function SystemDrawer({ isOpen, setIsOpen, openWidth, closedWidth, animated }) {
  const theme = useTheme();
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      openWidth={openWidth}
      closedWidth={closedWidth}
      animated={animated}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isOpen ? "flex-start" : "center",
        }}
        style={{ minHeight: "56px" }}
        disableGutters
      >
        <LogoImage isDrawerOpen={isOpen} />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          style={{ position: "absolute", right: 2, top: 0, bottom: 0 }}
          onClick={toggleDrawer}
        >
          <FontAwesomeIcon
            style={{
              fontSize: "16px",
              marginLeft: theme.spacing(isOpen ? 2.5 : 2),
            }}
            icon={isOpen ? solid("angle-left") : solid("angle-right")}
          />
        </IconButton>
      </Toolbar>
      <Divider />
      <SidebarList isDrawerOpen={isOpen} />
      <Divider />
    </Drawer>
  );
}

SystemDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  openWidth: PropTypes.number.isRequired,
  closedWidth: PropTypes.number.isRequired,
  animated: PropTypes.bool,
};

SystemDrawer.defaultProps = {
  animated: false,
};

export default withHideOnPresentationMode(React.memo(SystemDrawer));
