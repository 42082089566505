import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { errorTranslationKeys } from "../../hooks/useNewPasswordErrors";

const Container = styled("div")(() => ({
  marginTop: "20px",
  marginBottom: "10px",

  "& .new-rules-subtitle": {
    fontSize: "16px",
    fontWeight: "650",
  },
  "& .fa-circle-xmark, .fa-circle-check": {
    fontSize: "14px",
    width: "16px",
  },
  "& .fa-circle-xmark": {
    color: "#e15c5c",
  },
  "& .fa-circle-check": {
    color: "#b4cd4d",
  },
  "& .requirement": {
    marginTop: "8px",
    fontSize: "12px",
    textAlign: "left",
    marginLeft: "16px",
  },
}));

export default function NewPasswordRules({ errors }) {
  const { t } = useTranslation();

  return (
    <Container>
      <span className="new-rules-subtitle">
        {`${t("PASSWORD_MODAL.YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST")}: `}
      </span>

      {errors.map(([errorName, isValid]) => (
        <div key={errorName} className="requirement">
          {isValid ? (
            <FontAwesomeIcon icon={solid("circle-check")} />
          ) : (
            <FontAwesomeIcon icon={solid("circle-xmark")} />
          )}
          <span>
            {t(`PASSWORD_REQUIREMENTS.${errorTranslationKeys[errorName]}`)}
          </span>
        </div>
      ))}
    </Container>
  );
}

NewPasswordRules.propTypes = {
  errors: PropTypes.arrayOf((propValue, key) => {
    if (typeof propValue[key][0] !== "string") {
      throw new Error(`Invalid prop errors[${key}][0]`);
    }
    if (typeof propValue[key][1] !== "boolean") {
      throw new Error(`Invalid prop errors[${key}][1]`);
    }
  }).isRequired,
};
