import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import ConsulteMarketFilterTab from "./ConsulteMarketFilterTab";

export default function ConsulteFilterModal({ handleClose }) {
  return (
    <Container disableGutters>
      <ConsulteMarketFilterTab closeModal={handleClose} />
    </Container>
  );
}

ConsulteFilterModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
