import Typography from "@mui/material/Typography";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import GradusTextField from "../../components/GradusComponents/GradusTextField";

interface AnalysisNameStepProps {
  analysisName: string;
  setAnalysisName: Dispatch<SetStateAction<string>>;
}

const AnalysisNameStep = ({
  analysisName,
  setAnalysisName,
}: AnalysisNameStepProps) => {
  const { t } = useTranslation();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setAnalysisName(event.target.value),
    [setAnalysisName]
  );

  return (
    <Stack alignItems="start">
      <Typography variant="h6" textAlign="left" sx={{ mb: 1 }}>
        {`1. ${t("CREATE_ANALYSIS")}`}
      </Typography>
      <Typography variant="body1" textAlign="justify" sx={{ mb: 1 }}>
        {t("COMPARE_CREATE_ANALYSIS_PAGE.CREATE_AN_UNIQUE_NAME")}
      </Typography>
      <GradusTextField
        key="analysisName"
        label={t("ANALYSIS_NAME")}
        value={analysisName}
        onChange={handleChange}
        sx={{ mr: 1, width: "100%", mb: 1 }}
        required
      />
    </Stack>
  );
};

export default AnalysisNameStep;
