import React, { useContext, useMemo, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GradusButton from "../../components/Buttons/GradusButton";
import sessionApi from "../../apis/sessionApi";
import AuthenticationWrapper from "./AuthenticationWrapper";
import AlertContext from "../../contexts/AlertContext";
import withGradusLoading from "../../hocs/withGradusLoading";
import { useToken } from "../../hooks/useToken";

function RecoverPasswordContent() {
  const navigate = useNavigate();
  const { fireAlert } = useContext(AlertContext);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const publicToken = useToken();

  if (publicToken === "") {
    navigate("/login", { replace: true });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await sessionApi.twoFactor({
        publicToken,
        token: twoFactorCode,
      });
      if (response.data?.token) {
        if (response.data.type === "AUTH") {
          localStorage.setItem("remuneratio-bearer-token", response.data.token);
          navigate("/", {
            replace: true,
          });
        }
      }
    } catch (error) {
      if (error.response?.status === 400) {
        fireAlert("Código inválido", true, 8000);
        navigate("login/", {
          replace: true,
        });
      }
    }
  };

  const BoxWithLoading = useMemo(() => withGradusLoading(Box), []);

  return (
    <BoxWithLoading isLoading={isLoading}>
      <Grid item>
        <Typography
          component="h1"
          variant="h5 "
          sx={{ color: "primary.main", fontWeight: "bold", fontSize: "20px" }}
        >
          Código de acesso recebido por email
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 5 }}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                id="access-code"
                onChange={(event) => setTwoFactorCode(event.target.value)}
                label="Código de acesso"
                required
                InputLabelProps={{ required: false }}
              />
            </Grid>
            <Grid item sx={{ mt: 5 }}>
              <GradusButton
                variant="support"
                colorVariant="primary"
                sx={{ mr: 1, width: "auto" }}
                onClick={() => navigate("/login")}
              >
                Voltar
              </GradusButton>
              <GradusButton
                sx={{ width: "auto" }}
                variant="primary"
                colorVariant="primary"
                type="submit"
              >
                Validar
              </GradusButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BoxWithLoading>
  );
}

export default function RecoverPassword() {
  return (
    <AuthenticationWrapper width="280px" height="210px">
      <RecoverPasswordContent />
    </AuthenticationWrapper>
  );
}
