import _, { ceil } from "lodash";
import Metrics from "../models/Metrics";
import HistogramBinDTO from "../dtos/HistogramBinDTO";

export default class Histogram {
  /**
   *
   * @param bins {HistogramBinDTO[]}
   * @param metrics {Metrics}
   * @param classInterval {number}
   * @param capPercentile {number}
   */
  constructor({
    bins = [],
    metrics = new Metrics({}),
    classInterval = 500,
    capPercentile = 100,
  }) {
    this.originalBins = bins;
    this.bins = bins;
    this.metrics = metrics;
    this.classInterval = classInterval;
    this.capPercentile = capPercentile;
    this.isInitialized = false;
  }

  calculateHistogram() {
    if (this.capPercentile < 100) {
      this.cutUpperBinsByPercentile(this.capPercentile);
    } else {
      this.aggregateBins();
    }

    return this;
  }

  aggregateBins() {
    this.minSalary = 0;
    this.amplitude = _.isEmpty(this.bins)
      ? this.metrics.max
      : Histogram.getMaxXBin(this).supBound;
    this.nbins = ceil(this.amplitude / this.classInterval, 0);

    const newBins = [];
    for (let i = 0; i < this.nbins; i += 1) {
      const infBound = i * this.classInterval;
      const supBound = (i + 1) * this.classInterval;
      newBins.push({
        infBound,
        supBound,
        x: `${infBound}, ${supBound}`,
        y: 0,
      });
    }

    this.bins.forEach((bin) => {
      const newBin = _.find(
        newBins,
        (value) =>
          value.infBound <= bin.infBound && value.supBound >= bin.supBound
      );
      newBin.y += bin.y;
    });

    this.bins = newBins;

    this.isInitialized = true;

    this.calculateBiggestBin();
    this.fromCountToPercentage();
  }

  static findBinByValue(histogram, value) {
    return (
      Object.values(histogram.bins).find(
        ({ infBound, supBound }) => infBound <= value && value <= supBound
      ) || histogram.bins[0]
    );
  }

  calculateBiggestBin() {
    this.biggestBin = _.isEmpty(this.bins)
      ? new HistogramBinDTO({ infBound: 0, supBound: 500, x: "0, 500", y: 0 })
      : this.bins[0];
    if (Histogram.isHistogramInitialized(this)) {
      this.bins.forEach((bin) => {
        if (this.biggestBin.y < bin.y) {
          this.biggestBin = bin;
        }
      });
    }
  }

  /**
   * Converte a instância de contagem de elementos em cada bin para a porcentagem de cada bin em relação ao total
   * @return {Histogram}
   */
  fromCountToPercentage() {
    const totalEmployees = _.sumBy(this.bins, "y");

    const convertToPercentage = (value, total) =>
      total || total !== 0 ? 100 * (value / total) : 0;

    this.bins = this.bins.map((value) => ({
      ...value,
      ...{
        y: convertToPercentage(value.y, totalEmployees),
      },
    }));

    this.biggestBin = {
      ...this.biggestBin,
      ...{ y: convertToPercentage(this.biggestBin.y, totalEmployees) },
    };

    return this;
  }

  cutUpperBinsByPercentile(percentile) {
    this.capPercentile = percentile;

    if (percentile >= 100) {
      this.bins = this.originalBins;
    } else {
      const cutThreshold = Math.floor(
        this.metrics.quantity * (percentile / 100.0)
      );

      let accumulatedValue = 0;
      let shouldRemoveNextBins = false;
      this.bins = this.originalBins
        .map((bin) => {
          if (shouldRemoveNextBins) {
            return null;
          }

          accumulatedValue += bin.y;
          if (accumulatedValue >= cutThreshold) {
            accumulatedValue = cutThreshold;
            shouldRemoveNextBins = true;
            return { ...bin, y: accumulatedValue + bin.y - cutThreshold };
          }

          return bin;
        })
        .filter((bin) => bin !== null);
    }

    this.aggregateBins();
    return this;
  }

  static getMaxXBin(histogram) {
    return histogram.bins[histogram.bins.length - 1];
  }

  // TODO: este método funciona, porém ele deixa a variável originalBins errada
  /**
   *
   * @param histA {Histogram}
   * @param histB {Histogram}
   * @param labelA {string} nome da propriedade que os valores de A terão
   * @param labelB {string} nome da propriedade que os valores de B terão
   */
  static mergeHistograms(histA, histB, labelA, labelB) {
    const isHistABiggerThanB = histA.bins.length > histB.bins.length;

    const biggerHist = isHistABiggerThanB ? histA : histB;
    const biggerHistLabel = isHistABiggerThanB ? labelA : labelB;
    const biggerHistByInfBound = _.groupBy(
      biggerHist.bins,
      (bin) => bin.infBound
    );

    const smallerHist = isHistABiggerThanB ? histB : histA;
    const smallerHistLabel = isHistABiggerThanB ? labelB : labelA;
    const smallerHistByInfBound = _.groupBy(
      smallerHist.bins,
      (bin) => bin.infBound
    );

    // TODO: daria para dar um new Histogram() e passar os bins gerados pelo
    //  algoritmo abaixo, mas antes é preciso generalizar a classe para receber
    //  bin.y como Number ou Object
    const mergedHist = _.cloneDeep(biggerHist);
    mergedHist.biggestBin = null;

    mergedHist.bins = Object.keys(biggerHistByInfBound).map((key) => {
      const biggerBin = biggerHistByInfBound[key][0];
      const smallerBin = _.has(smallerHistByInfBound, key)
        ? smallerHistByInfBound[key][0]
        : { ..._.cloneDeep(biggerBin), y: 0 };

      const mergedBin = {
        infBound: biggerBin.infBound,
        supBound: biggerBin.supBound,
        x: biggerBin.x,
        y: {
          [smallerHistLabel]: smallerBin.y,
          [biggerHistLabel]: biggerBin.y,
        },
      };

      if (
        _.isEmpty(mergedHist.biggestBin) ||
        _.max([
          mergedHist.biggestBin.y[smallerHistLabel],
          mergedHist.biggestBin.y[biggerHistLabel],
        ]) <
          _.max([mergedBin.y[smallerHistLabel], mergedBin.y[biggerHistLabel]])
      ) {
        mergedHist.biggestBin = mergedBin;
      }

      return mergedBin;
    });

    return mergedHist;
  }

  static isHistogramInitialized(histogram) {
    return !_.isEmpty(histogram) && histogram.isInitialized;
  }

  static isHistogramEmpty(histogram) {
    return (
      !Histogram.isHistogramInitialized(histogram) || _.isEmpty(histogram.bins)
    );
  }
}
