import _ from "lodash";
import BaseFilter from "./BaseFilter";
import StringUtils from "../utils/StringUtils";
import {OPTIONS} from "../models/CompanySize";

// TODO: dá para melhorar ainda mais separando em CompanyDataFilter
export default class MarketFilter extends BaseFilter {
  constructor({
    sections = [],
    divisions = [],
    groups = [],
    classes = [],
    subclasses = [],
    companySizes = [],
    ...baseFilters
  }) {
    super(baseFilters);
    this.sections = sections;
    this.divisions = divisions;
    this.groups = groups;
    this.classes = classes;
    this.subclasses = subclasses;
    this.companySizes = companySizes;
  }

  toString() {
    return [
      BaseFilter.prototype.toString.call(this),
      this.companyFiltersToString(),
      this.companySizesToString(),
    ]
      .filter((filter) => !_.isEmpty(filter))
      .join(", ");
  }

  companyFilters() {
    return {
      subclasses: this.subclasses,
      classes: this.classes,
      groups: this.groups,
      divisions: this.divisions,
      sections: this.sections,
    };
  }

  companyFiltersToString() {
    return StringUtils.firstNonEmptyArrayToString([
      this.subclasses,
      this.classes,
      this.groups,
      this.divisions,
      this.sections,
    ]);
  }

  companySizesToString() {
    return StringUtils.firstNonEmptyArrayToString([this.companySizes]);
  }

  onlyWithIds() {
    const range = this.companySizes.map(({ id }) => id);
    let companySizes = [];
    // Caso contenha todos os tamanhos de empresa, então mandamos o valor vazio
    if (range.length < OPTIONS.length) {
      companySizes = _.range(_.min(range), _.max(range) + 1);
    }
    return new MarketFilter({
      ...BaseFilter.prototype.onlyWithIds.call(this),
      sections: this.sections.map(({ id }) => id),
      divisions: this.divisions.map(({ id }) => id),
      groups: this.groups.map(({ id }) => id),
      classes: this.classes.map(({ id }) => id),
      subclasses: this.subclasses.map(({ id }) => id),
      companySizes,
    });
  }

  hasCompanySizeFilter() {
    return !_.isEmpty(this.companySizes);
  }

  hasCompanyFilter() {
    return (
      !_.isEmpty(this.subclasses) ||
      !_.isEmpty(this.classes) ||
      !_.isEmpty(this.groups) ||
      !_.isEmpty(this.divisions) ||
      !_.isEmpty(this.sections)
    );
  }

  isEmpty() {
    return (
      !this.hasCompanyFilter() &&
      !this.hasCompanySizeFilter() &&
      !this.hasJobFilter() &&
      !this.hasGeographyFilter()
    );
  }
}
