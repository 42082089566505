import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GradusModal from "../../GradusComponents/GradusModal/GradusModal";
import TabPanel from "../../TabPanel/TabPanel";
import OrganizationTab from "./OrganizationTab";
import useDashboardVisualOptionsStore from "../../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import GradusTabs from "../../GradusComponents/Tabs/GradusTabs";
import IndexApplicationTab from "./IndexApplicationTab";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import useMetricsStore from "../../../stores/useMetricsStore";
import useChartParametersStore from "../../../stores/ChartParametersStore/useChartParametersStore";

const ParameterModal = ({
  open,
  close,
  isCompare,
  analysis,
}: {
  open: boolean;
  close: () => void;
  analysis: AnalysisPresenter;
  isCompare: boolean;
}) => {
  const [tab, setTab] = useState(0);
  const {
    chartOptions,
    defaultChartOptions,
    selectedChartOptions,
    metricsOptions,
    defaultMetricsOptions,
    selectedMetricsOptions,
    getChartsOptions,
    getChartsDefaultOptions,
    setSelectedChartOptions,
    setSelectedMetricsOptions,
  } = useDashboardVisualOptionsStore((state) => ({
    chartOptions: state.chartOptions,
    defaultChartOptions: state.defaultChartOptions,
    selectedChartOptions: state.selectedChartOptions,
    metricsOptions: state.metricsOptions,
    defaultMetricsOptions: state.defaultMetricsOptions,
    selectedMetricsOptions: state.selectedMetricsOptions,
    getChartsOptions: state.getChartsOptions,
    getChartsDefaultOptions: state.getChartsDefaultOptions,
    setSelectedChartOptions: state.setSelectedChartOptions,
    setSelectedMetricsOptions: state.setSelectedMetricsOptions,
  }));

  const { updateCharts } = useChartStore((state) => ({
    updateCharts: state.updateCharts,
  }));

  const { getMetrics } = useMetricsStore((state) => ({
    getMetrics: state.getMetrics,
  }));

  const { revertGlobalSelectedToApplied } = useChartParametersStore(
    (state) => ({
      revertGlobalSelectedToApplied: state.revertGlobalSelectedToApplied,
    })
  );

  const { t } = useTranslation();

  useEffect(() => {
    getChartsOptions(isCompare);
    getChartsDefaultOptions(isCompare);
  }, [getChartsDefaultOptions, getChartsOptions, isCompare]);

  const handleClose = () => {
    revertGlobalSelectedToApplied();
    close();
  };

  return (
    <GradusModal
      open={open}
      handleClose={handleClose}
      containerStyle={{ width: "70%" }}
    >
      <GradusTabs
        labels={[
          t("PARAMETERIZATION_MODAL.METRICS_ORGANIZATION"),
          t("PARAMETERIZATION_MODAL.CHARTS_ORGANIZATION"),
          t("PARAMETERIZATION_MODAL.INDEX_APPLICATION"),
        ]}
        tab={tab}
        setTab={setTab}
      />
      <TabPanel value={tab} index={0}>
        <OrganizationTab
          options={metricsOptions}
          defaultOptions={defaultMetricsOptions}
          selectedOptions={selectedMetricsOptions}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setSelectedOptions={setSelectedMetricsOptions}
          label={t("METRICS")}
          description={t("PARAMETERIZATION_MODAL.METRICS_TAB_DESCRIPTION")}
          onSaveAlertSuccessMessage={t("ALERTS.SUCCESSFULLY_CHANGED_METRICS")}
          onSave={() => getMetrics(isCompare, analysis)}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <OrganizationTab
          options={chartOptions}
          defaultOptions={defaultChartOptions}
          selectedOptions={selectedChartOptions}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setSelectedOptions={setSelectedChartOptions}
          label={t("CHARTS")}
          description={t("PARAMETERIZATION_MODAL.METRICS_TAB_DESCRIPTION")}
          onSaveAlertSuccessMessage={t("ALERTS.SUCCESSFULLY_CHANGED_CHARTS")}
          onSave={() => updateCharts(isCompare, analysis)}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <IndexApplicationTab
          isCompare={isCompare}
          analysis={analysis}
          isModalOpen={open}
        />
      </TabPanel>
    </GradusModal>
  );
};

export default ParameterModal;
