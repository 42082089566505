import React, { useCallback, useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import withGradusLoading from "../../../../hocs/withGradusLoading";
import SearchBar from "../../../SearchBar/SearchBar";
import { handleApiError } from "../../../../utils/ErrorUtils";
import AlertContext from "../../../../contexts/AlertContext";
import CompareAnalysisSavedFiltersTable from "./CompareAnalysisSavedFiltersTable";
import GradusPrimaryButton from "../../../Buttons/GradusPrimaryButton";
import useCompareAnalysisStore from "../../../../stores/useCompareAnalysisStore";

export default function CompareAnalysisSavedFiltersTableWithSearch({
  isOpen,
  handleClose,
}) {
  const {
    changeFilter,
    createFilter,
    getAllSavedFilters,
    savedFilterOptions,
    deleteFilter,
    renameFilter,
  } = useCompareAnalysisStore((state) => ({
    changeFilter: state.changeFilter,
    createFilter: state.createFilter,
    getAllSavedFilters: state.getAllSavedFilters,
    savedFilterOptions: state.savedFilterOptions,
    deleteFilter: state.deleteFilter,
    renameFilter: state.renameFilter,
  }));

  const [searchedOptions, setSearchedOptions] = useState(savedFilterOptions);
  const [isLoading, setIsLoading] = useState(false);

  const { fireAlert } = useContext(AlertContext);
  const theme = useTheme();

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const updateOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const newOptions = await getAllSavedFilters();
      setSearchedOptions(newOptions);
    } catch (error) {
      handleApiError(error, fireAlert, t);
    } finally {
      setIsLoading(false);
    }
  }, [fireAlert, getAllSavedFilters, t]);

  const handleApply = useCallback(
    async (activeFilterId) => {
      await changeFilter(activeFilterId);
      fireAlert(
        t("ALERTS.SUCCESSFULLY_APPLIED_FILTER", { lng: language }),
        false
      );
      handleClose();
    },
    [changeFilter, fireAlert, handleClose, language, t]
  );
  const handleDelete = useCallback(
    async (deleteId) => {
      try {
        await deleteFilter(deleteId);
        fireAlert(t("ALERTS.SUCCESSFULLY_REMOVED_FILTER", { lng: language }));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      } finally {
        await updateOptions();
      }
    },
    [deleteFilter, fireAlert, language, t, updateOptions]
  );
  const handleRename = useCallback(
    async (newName, updateId) => {
      try {
        await renameFilter(updateId, newName);
        fireAlert(t("ALERTS.SUCCESSFULLY_RENAMED_FILTER", { lng: language }));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      } finally {
        await updateOptions();
      }
    },
    [fireAlert, language, renameFilter, t, updateOptions]
  );
  const handleCreate = useCallback(async () => {
    try {
      const response = await createFilter();
      await handleApply(response.data);
      handleClose();
      fireAlert(t("ALERTS.SUCCESSFULLY_CREATED_FILTER", { lng: language }));
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  }, [createFilter, fireAlert, handleApply, handleClose, language, t]);

  const TableComponentWithLoading = withGradusLoading(
    searchedOptions.length <= 0
      ? () => <p style={{ textAlign: "center" }}>Nenhum filtro encontrado.</p>
      : () => (
          <CompareAnalysisSavedFiltersTable
            options={searchedOptions}
            handleDelete={handleDelete}
            handleRename={handleRename}
            handleApply={handleApply}
          />
        )
  );

  useEffect(() => {
    if (isOpen) {
      updateOptions();
    }
  }, [isOpen, updateOptions]);

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <GradusPrimaryButton onClick={handleCreate}>
          <FontAwesomeIcon
            icon={solid("plus")}
            style={{
              marginRight: theme.spacing(1),
              justifyContent: "flex-start",
            }}
          />
          {t("NEW_FILTER")}
        </GradusPrimaryButton>
        <SearchBar
          options={savedFilterOptions}
          setSearchedOptions={setSearchedOptions}
          parametersToSearch={["name"]}
          placeholder={t("FILTER_MODAL.FILTER_EXAMPLE")}
        />
      </Stack>
      <TableComponentWithLoading
        isLoading={isLoading}
        gradusLoadingContainerSx={{ mt: 2 }}
      />
    </Stack>
  );
}

CompareAnalysisSavedFiltersTableWithSearch.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

CompareAnalysisSavedFiltersTableWithSearch.defaultProps = {
  isOpen: false,
};
