// FIXME: seguir o padrão dos gráfico de buscar no back as opções ao invés de ter estes objetos aqui
export const METRICS_OPTIONS = [
  { id: "average", name: "REMUNERATION_AVERAGE" },
  {
    id: "p10",
    name: "10TH_PERCENTILE",
  },
  {
    id: "median",
    name: "REMUNERATION_MEDIAN",
  },
  {
    id: "p90",
    name: "90TH_PERCENTILE",
  },
  {
    id: "quantity",
    name: "EMPLOYEE_TOTAL_QUANTITY",
  },
];

export const METRICS_DEFAULT_VALUES = METRICS_OPTIONS.filter(({ id }) =>
  ["average", "p10", "median", "p90", "quantity"].includes(id)
);
