import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import api from "./api";
import PayrollConfigurationDTO from "../dtos/PayrollConfiguration/PayrollConfigurationDTO";
import PayrollConfigurationUpdateDTO from "../dtos/PayrollConfiguration/PayrollConfigurationUpdateDTO";
import PayrollConfigurationCreateDTO from "../dtos/PayrollConfiguration/PayrollConfigurationCreateDTO";

const baseUrl = "/api/v1/payroll-configurations";

const payrollConfigurationsApi = {
  createConfiguration: (payload: PayrollConfigurationCreateDTO) =>
    api.post(baseUrl, payload),

  getAll: (): Promise<PayrollConfigurationDTO[]> =>
    new Promise<PayrollConfigurationDTO[]>((resolve, reject) => {
      api
        .get(baseUrl)
        .then((response) =>
          resolve(
            response.data.map(
              (config: PayrollConfigurationDTO) =>
                new PayrollConfigurationDTO({ ...config })
            )
          )
        )
        .catch(reject);
    }),

  get: (configurationId: number): Promise<PayrollConfigurationDTO> =>
    new Promise<PayrollConfigurationDTO>((resolve, reject) => {
      api
        .get(`${baseUrl}/${configurationId}`)
        .then((response) =>
          resolve(new PayrollConfigurationDTO({ ...response.data }))
        )
        .catch(reject);
    }),

  getByAnalysisId: (analysisId: number): Promise<PayrollConfigurationDTO> =>
    new Promise<PayrollConfigurationDTO>((resolve, reject) => {
      api
        .get(`${baseUrl}/by-analysis/${analysisId}`)
        .then((response) =>
          resolve(new PayrollConfigurationDTO({ ...response.data }))
        )
        .catch(reject);
    }),

  deleteConfiguration: (configurationId: number) =>
    api.delete(`${baseUrl}/${configurationId}`),

  duplicateConfiguration: (configurationId: number) =>
    api.post(`${baseUrl}/duplicate/${configurationId}`),

  updateConfiguration: (
    configuration: PayrollConfigurationUpdateDTO
  ): Promise<AxiosResponse<void>> =>
    api.put(`${baseUrl}/${configuration.id}`, configuration),

  rename: (updateId: number, newName: string): Promise<AxiosResponse<void>> =>
    api.put(`${baseUrl}/${updateId}`, newName),
};

export default payrollConfigurationsApi;

export function useGetByAnalysisId(analysisId: number) {
  return useQuery({
    queryKey: ["payrollConfigurations", "getByAnalysisId", analysisId],
    queryFn: () => payrollConfigurationsApi.getByAnalysisId(analysisId),
  });
}
