import { create } from "zustand";
import { devtools } from "zustand/middleware";
import createGlobalParametersSlice, {
  initialGlobalParametersSliceState,
} from "./createGlobalParametersSlice";
import { ChartParametersStoreProps } from "./types";
import createChartParametersSlice, {
  initialChartParametersSliceState,
} from "./createChartParametersSlice";

const useChartParametersStore = create<ChartParametersStoreProps>()(
  devtools(
    (set, get, store) => ({
      ...createChartParametersSlice(set, get, store),
      ...createGlobalParametersSlice(set, get, store),

      initParameters: () => {
        set({
          ...initialGlobalParametersSliceState,
          ...initialChartParametersSliceState,
        });
      },
    }),
    { name: "ChartParametersStore" }
  )
);

export default useChartParametersStore;
