import { Droppable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Column from "./Column";

const ColumnHeaderVisible = styled.span`
  font-size: 2rem;
  color: rgba(53, 54, 57, 0.5);
`;

export default function DroppableColumn({
  columnHeader,
  elements,
  isDropDisabled,
}) {
  return (
    <Grid
      item
      flexGrow={1}
      flexShrink={0}
      flexBasis={0}
      display="flex"
      alignItems={elements.length <= 0 ? "center" : "flex-start"}
      fontFamily="Nunito Sans, sans serif"
    >
      <Droppable droppableId={columnHeader} isDropDisabled={isDropDisabled}>
        {({ droppableProps, innerRef, placeholder }) => (
          <div
            {...droppableProps}
            ref={innerRef}
            style={{ minHeight: "100px", width: "100%" }}
            data-testid={`draggable-${columnHeader}`}
          >
            {_.compact(elements)
              .filter((element) => !element.isFixed)
              .sort((a, b) => a.order - b.order)
              .map((item, index) => (
                <Column key={item.field} item={item} index={index} />
              ))}
            {elements.length <= 0 && (
              <ColumnHeaderVisible>{columnHeader}</ColumnHeaderVisible>
            )}
            {placeholder}
          </div>
        )}
      </Droppable>
    </Grid>
  );
}

DroppableColumn.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerLabel: PropTypes.string.isRequired,
    })
  ).isRequired,
  columnHeader: PropTypes.string.isRequired,
  isDropDisabled: PropTypes.bool,
};

DroppableColumn.defaultProps = {
  isDropDisabled: false,
};
