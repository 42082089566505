import api from "./api";
import ConsulteAnalysisPresenter from "../dtos/ConsulteAnalysis/ConsulteAnalysisPresenter";
import ConsulteAnalysisUpdateDTO from "../dtos/ConsulteAnalysis/ConsulteAnalysisUpdateDTO";

const baseUrl = "/api/v1/consulte-analysis";

const consulteAnalysisApi = {
  createAnalysis: (marketFilters = {}) =>
    api.post(baseUrl, {
      name: `Análise - ${new Date().toISOString()}`,
      marketFilters,
    }),

  getAll: () =>
    new Promise<ConsulteAnalysisPresenter[]>((resolve, reject) => {
      api.get(baseUrl).then((response) => {
        const allAnalysis = response.data.map(
          (analysis: ConsulteAnalysisPresenter) =>
            new ConsulteAnalysisPresenter({ ...analysis })
        );
        return resolve(allAnalysis);
      }, reject);
    }),

  deleteAnalysis: (deleteId: number) => api.delete(`${baseUrl}/${deleteId}`),

  renameAnalysis: async (updateId: number, newName: string): Promise<string> =>
    api.put(`${baseUrl}/${updateId}/rename`, newName),

  updateAnalysis: (analysis: ConsulteAnalysisUpdateDTO) =>
    new Promise<ConsulteAnalysisPresenter>((resolve, reject) => {
      api
        .put(baseUrl, analysis)
        .then(
          (response) => resolve(new ConsulteAnalysisPresenter(response.data)),
          reject
        );
    }),

  get: (analysisId: number) =>
    new Promise<ConsulteAnalysisPresenter>((resolve, reject) => {
      api
        .get(`${baseUrl}/${analysisId}`)
        .then(
          (response) => resolve(new ConsulteAnalysisPresenter(response.data)),
          reject
        );
    }),
};

export default consulteAnalysisApi;
