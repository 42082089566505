import api from "./api";
import CompareAnalysisPresenter from "../dtos/CompareAnalysis/CompareAnalysisPresenter";

const BASE_URL = "/api/v1/reports";

const reportsApi = {
  generateOccupationsReport: (
    analysis: CompareAnalysisPresenter,
    correctionIndex: number
  ) =>
    api.get(`${BASE_URL}/occupations/${analysis.getId()}`, {
      responseType: "blob",
      params: { correctionIndex },
    }),

  generateCompareAnalysisReport: (
    analysis: CompareAnalysisPresenter,
    charts: number[],
    correctionIndex: number
  ) =>
    api.post(`${BASE_URL}/compare-analysis/${analysis.getId()}`, charts, {
      responseType: "blob",
      params: { correctionIndex },
    }),
};

export default reportsApi;
