// eslint-disable-next-line no-unused-vars
import React from "react";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CompanySize from "../../models/CompanySize";
import PayrollFilter from "../../dtos/PayrollFilter";
import StringUtils from "../../utils/StringUtils";

class FilterRow {
  /**
   *
   * @param label {string}
   * @param value {string | Array<string | FilterOptionDTO>}
   */
  constructor(label, value) {
    this.label = label;
    this.value = value;
    this.stringMaxSize = 20;
    this.chipSx = {
      ml: 1,
      mt: 1,
    };
  }

  getStringChip(value = this.value) {
    return (
      <Chip
        key={value}
        title={value.length > this.stringMaxSize ? value : null}
        label={`${this.label}: ${StringUtils.truncateString(
          value,
          this.stringMaxSize
        )}`}
        sx={this.chipSx}
      />
    );
  }

  getFilterOptionDTOChip(filterOption) {
    const { id, name } = filterOption;
    return (
      <Chip
        key={id}
        title={name.length > this.stringMaxSize ? name : null}
        label={`${this.label}: ${StringUtils.truncateString(
          name,
          this.stringMaxSize
        )}`}
        sx={this.chipSx}
      />
    );
  }

  getChips() {
    if (_.isArray(this.value)) {
      return this.value.map((obj) => {
        if (_.isObject(obj)) {
          return this.getFilterOptionDTOChip(obj);
        }
        return this.getStringChip(obj);
      });
    }
    if (_.isString(this.value)) {
      return this.getStringChip();
    }
    return null;
  }
}

function FilterTagsRow({ rowName, data, sortAscending, oneItemPerLine }) {
  return (
    <Grid
      item
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "baseline",
      }}
    >
      <span
        style={{ textAlign: "left", flex: "0 0 11em" }}
      >{`${rowName}: `}</span>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline",
          flexWrap: "wrap",
          flexDirection: oneItemPerLine ? "column" : "row",
        }}
      >
        {data
          .sort((a, b) => {
            if (sortAscending) {
              return a.label > b.label ? 1 : -1;
            }
            return a.label > b.label ? -1 : 1;
          })
          .map(({ label, value }) => new FilterRow(label, value).getChips())}
      </div>
    </Grid>
  );
}

FilterTagsRow.propTypes = {
  rowName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(FilterRow)).isRequired,
  sortAscending: PropTypes.bool,
  oneItemPerLine: PropTypes.bool,
};

FilterTagsRow.defaultProps = {
  sortAscending: true,
  oneItemPerLine: true,
};

export default function FilterTags({ filters, title }) {
  const { t } = useTranslation();

  const findFirstNonEmpty = (list) =>
    list.find(({ value }) => !_.isEmpty(value));

  const geographicData = findFirstNonEmpty([
    new FilterRow(t("COUNTY"), filters.counties),
    new FilterRow(t("INTERMEDIATE_REGION"), filters.intermediateRegions),
    new FilterRow(t("IMMEDIATE_REGION"), filters.immediateRegions),
    new FilterRow(t("STATE"), filters.geographicStates),
    new FilterRow(t("REGION"), filters.geographicRegions),
  ]);
  const jobData = findFirstNonEmpty([
    new FilterRow(t("OCCUPATION"), filters.occupations),
    new FilterRow(t("FAMILY"), filters.families),
    new FilterRow(t("SUBGROUP"), filters.subgroups),
    new FilterRow(t("PRIMARY_SUBGROUP"), filters.primarySubgroups),
    new FilterRow(t("LARGE_GROUP"), filters.largeGroups),
  ]);
  const companyData = findFirstNonEmpty([
    new FilterRow(t("SUBCLASS"), filters.subclasses),
    new FilterRow(t("CLASS"), filters.classes),
    new FilterRow(t("GROUP"), filters.groups),
    new FilterRow(t("DIVISION"), filters.divisions),
    new FilterRow(t("SECTION"), filters.sections),
  ]);

  const filterTagsComponents = [];
  let dividerCount = 0;
  const addDivider = () => {
    if (filterTagsComponents.length > 0) {
      filterTagsComponents.push(
        <Divider key={`divider-${dividerCount++}`} sx={{ mt: 1 }} />
      );
    }
  };

  if (!_.isEmpty(jobData)) {
    addDivider();
    filterTagsComponents.push(
      <FilterTagsRow key="job" data={[jobData]} rowName={t("ROLE")} />
    );
  }
  if (!_.isEmpty(geographicData)) {
    addDivider();
    filterTagsComponents.push(
      <FilterTagsRow
        key="geographic"
        data={[geographicData]}
        rowName={t("GEOGRAPHIC_DATA")}
      />
    );
  }

  if (!_.isEmpty(companyData)) {
    addDivider();
    filterTagsComponents.push(
      <FilterTagsRow
        key="company"
        data={[companyData]}
        rowName={t("COMPANY")}
      />
    );
  }

  if (_.has(filters, "companySizes") && !_.isEmpty(filters.companySizes)) {
    const value = CompanySize.getFilterTagLabel(
      filters.companySizes.map(({ id }) => id),
      t,
    );
    addDivider();
    filterTagsComponents.push(
      <FilterTagsRow
        key="companySize"
        data={[new FilterRow(t("COMPANY_SIZE"), value)]}
        rowName={t("COMPANY_SIZE")}
        sortAscending={false}
        oneItemPerLine={false}
      />
    );
  }

  if (
    _.has(filters, "parameters") &&
    !_.isEmpty(filters.parameters) &&
    _.some(filters.parameters, ({ values }) => !_.isEmpty(values))
  ) {
    const data = _.chain(filters.parameters)
      .reduce((acc, { values: value, name: label }) => {
        acc.push({ label, value });
        return acc;
      }, [])
      .flatMap()
      .value();
    addDivider();
    filterTagsComponents.push(
      <FilterTagsRow
        key="parameters"
        data={data}
        rowName={t("CUSTOM_COLUMNS")}
        oneItemPerLine
      />
    );
  }

  return (
    <div>
      <Typography variant="body1" sx={{ mb: 0, fontWeight: "bold" }}>
        {title}
      </Typography>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
      >
        {filterTagsComponents.length > 0
          ? filterTagsComponents
          : t("NO_FILTER_APPLIED")}
      </Grid>
    </div>
  );
}

FilterTags.propTypes = {
  filters: PropTypes.shape(PayrollFilter),
  title: PropTypes.string.isRequired,
};

FilterTags.defaultProps = {
  filters: new PayrollFilter({}),
};
