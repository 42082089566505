import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const Title = function Title({ children }) {
  const theme = useTheme();
  return (
    <Typography
      component="h2"
      variant="h6"
      color={theme.palette.grey[700]}
      fontWeight="600"
      gutterBottom
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
