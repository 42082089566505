import _ from "lodash";
import MarketFilter from "../MarketFilter";
import PayrollFilter from "../PayrollFilter";
import CompareAnalysisRelaxedFiltersPresenter from "../CompareAnalysisRelaxedFilters/CompareAnalysisRelaxedFiltersPresenter";

export default class CompareAnalysisSavedFiltersPresenter {
  private readonly id: number | null;

  private readonly name: string;

  private readonly userId: number | null;

  private readonly compareAnalysisId: number | null;

  private readonly marketFilters: MarketFilter;

  private readonly companyFilters: PayrollFilter;

  private readonly relaxedFilter: CompareAnalysisRelaxedFiltersPresenter;

  constructor({
    id = null,
    name = "",
    userId = null,
    compareAnalysisId = null,
    marketFilters = new MarketFilter({}),
    companyFilters = new PayrollFilter({}),
    relaxedFilter = new CompareAnalysisRelaxedFiltersPresenter({}),
  }: {
    id?: number | null;
    name?: string;
    userId?: number | null;
    compareAnalysisId?: number | null;
    marketFilters?: MarketFilter;
    companyFilters?: PayrollFilter;
    relaxedFilter?: CompareAnalysisRelaxedFiltersPresenter;
  }) {
    this.id = id;
    this.name = name;
    this.userId = userId;
    this.compareAnalysisId = compareAnalysisId;
    this.marketFilters = _.isEmpty(marketFilters)
      ? new MarketFilter({})
      : new MarketFilter(marketFilters);
    this.companyFilters = _.isEmpty(companyFilters)
      ? new PayrollFilter({})
      : new PayrollFilter(companyFilters);
    this.relaxedFilter = new CompareAnalysisRelaxedFiltersPresenter({
      ...relaxedFilter,
    });
  }

  getId(): number | null {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  getUserId(): number | null {
    return this.userId;
  }

  getCompareAnalysisId(): number | null {
    return this.compareAnalysisId;
  }

  getMarketFilters(): MarketFilter {
    return this.marketFilters;
  }

  getCompanyFilters(): PayrollFilter {
    return this.companyFilters;
  }

  getRelaxedFilter(): CompareAnalysisRelaxedFiltersPresenter {
    return this.relaxedFilter;
  }
}
