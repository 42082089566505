import React, { useCallback, useContext, useState } from "react";
import Container from "@mui/material/Container";
import {
  Autocomplete,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import GradusPrimaryButton from "../../Buttons/GradusPrimaryButton";
import GradusSupportButton from "../../Buttons/GradusSupportButton";
import AlertContext from "../../../contexts/AlertContext";
import ChartOptionDTO from "../../../dtos/ChartOptionDTO";

export default function OrganizationTab({
  defaultOptions,
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  description,
  onSaveAlertSuccessMessage,
  onSave,
}: {
  defaultOptions: ChartOptionDTO[] | { id: string; name: string }[];
  options: ChartOptionDTO[] | { id: string; name: string }[];
  selectedOptions: ChartOptionDTO[] | { id: string; name: string }[];
  setSelectedOptions: (
    selectedOptions: ChartOptionDTO[] | { id: string; name: string }[]
  ) => void;
  label: string;
  description: string;
  onSaveAlertSuccessMessage: string;
  onSave?: () => void;
}) {
  const [chosenValues, setChosenValues] = useState(selectedOptions);
  const { fireAlert } = useContext(AlertContext);
  const { t } = useTranslation();

  const handleSaveButtonClick = useCallback(() => {
    setSelectedOptions(chosenValues);
    onSave?.();
    fireAlert(onSaveAlertSuccessMessage);
  }, [
    chosenValues,
    fireAlert,
    onSave,
    onSaveAlertSuccessMessage,
    setSelectedOptions,
  ]);

  const handleBackToDefaultButtonClick = useCallback(() => {
    setChosenValues(defaultOptions);
    setSelectedOptions(defaultOptions);
    onSave?.();
    fireAlert(onSaveAlertSuccessMessage);
  }, [
    defaultOptions,
    fireAlert,
    onSave,
    onSaveAlertSuccessMessage,
    setSelectedOptions,
  ]);

  return (
    <Container>
      <Grid container>
        <Grid item sm={12} md={12} lg={12} xl={6}>
          <FormGroup>
            <FormControl>
              <FormLabel htmlFor={`${label}-organization`} sx={{ mb: "10px" }}>
                {description}
              </FormLabel>
              <Autocomplete
                id={`${label}-organization`}
                value={chosenValues as ChartOptionDTO[]}
                options={options as ChartOptionDTO[]}
                multiple
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label={label} />
                )}
                getOptionLabel={(option) => t(option.name)}
                onChange={(_event, newValue) => setChosenValues(newValue)}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>

      <Box
        my={2}
        sx={{
          display: "flex",
          justifyItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <GradusPrimaryButton
          onClick={handleSaveButtonClick}
          sx={{ my: 0, mx: "10px" }}
        >
          {t("APPLY")}
        </GradusPrimaryButton>

        <GradusSupportButton
          onClick={handleBackToDefaultButtonClick}
          sx={{ ml: "10px" }}
        >
          {t("BACK_TO_DEFAULTS")}
        </GradusSupportButton>
      </Box>
    </Container>
  );
}

OrganizationTab.defaultProps = {
  onSave: _.noop,
};
