// Isso aqui é necessário pois está dando pau na análise do TS, fica em loop
// infinito, já que o erro TS2590 parece ter causado isso
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {Dispatch, PropsWithChildren, SetStateAction, useCallback, useEffect, useMemo, useState,} from "react";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {styled} from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import {SxProps, TableFooter} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import {Trans, useTranslation} from "react-i18next";
import _ from "lodash";
import {LabelDisplayedRowsArgs} from "@mui/material/TablePagination/TablePagination";
import CollapsableTableRow from "./CollapsableTableRow";
import CollapsableTableHeader from "./CollapsableTableHeader";
import FilterableHeader from "./FilterableHeader";
import {GradusTableProvider, useGradusTableStore} from "./GradusTableStore";
import ShowColumnsTable from "./ShowColumnsTable";
import GradusTableColumn from "./GradusTableColumn";

const defaultProps = {
  collapseField: "collapseRows",
  containerSx: {},
  tableSx: {},
  hasCheckbox: false,
  hasCollapse: false,
  hasPagination: true,
  hasColumnSelector: false,
  rowsPerPageLimit: 20,
  rowsPerPageOptions: [10, 20, 30],
};

interface GradusTableProps<R> {
  rows: R[];
  columns: GradusTableColumn<R>[];
  storeName: string;
  collapseField?: string;
  containerSx?: SxProps;
  tableSx?: SxProps;
  hasCheckbox?: boolean;
  hasCollapse?: boolean;
  hasPagination?: boolean;
  hasColumnSelector?: boolean;
  rowsPerPageLimit?: number;
  rowsPerPageOptions?: number[];
}

interface GradusTableInsideProps<R> {
  tableSx: SxProps;
  rowsPerPage: number;
  filteredRows: object[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  columns: GradusTableColumn<R>[];
  hasCollapse: boolean;
  hasCheckbox: boolean;
  hasPagination: boolean;
  storeName: string;
  headers: any;
  rowsPerPageOptions: number[];
  setRowsPerPage: Dispatch<SetStateAction<number>>;
}

const Table = styled(MuiTable)(() => ({
  "&": {
    border: "none",
    borderCollapse: "separate",
  },
  "& tr": {
    height: "40px",
    border: "none",
  },
  "& th, & td": {
    padding: "0 10px 0 10px",
    height: "auto",
  },
  "& td": {
    border: "1px solid #DDD",
  },
  "& th:first-of-type": {
    borderRadius: "8px 0 0 0",
  },
  "& th:last-child": {
    borderRadius: "0 8px 0 0",
  },
  "& tr:last-child > td:first-of-type": {
    borderRadius: "0 0 0 8px",
  },
  "& tr:last-child > td:last-child": {
    borderRadius: "0 0 8px 0",
  },
}));

const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => (
  <Trans
    i18nKey="GRADUS_TABLE.DISPLAYED_ROWS"
    values={{ from, to, count }}
    style={{ margin: 0 }}
  />
);

function GradusTableFooter({
  hasPagination,
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
}: {
  hasPagination: boolean;
  count: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  rowsPerPageOptions: number[];
}) {
  const { t } = useTranslation();

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!hasPagination) {
    return null;
  }

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={(_event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          /* TODO: existe um meio de utilizar traduções do próprio MUI nesses labels */
          labelRowsPerPage={t("GRADUS_TABLE.ROWS_PER_PAGE")}
          labelDisplayedRows={labelDisplayedRows}
          style={{ alignItems: "baseline" }}
        />
      </TableRow>
    </TableFooter>
  );
}

function GradusTableInside<R>({
  tableSx,
  rowsPerPage,
  filteredRows,
  page,
  setPage,
  columns,
  hasCollapse,
  hasCheckbox,
  storeName,
  headers,
  rowsPerPageOptions,
  setRowsPerPage,
  hasPagination,
}: PropsWithChildren<GradusTableInsideProps<R>>) {
  const filteredRowsMemoed = useMemo(
    () =>
      (rowsPerPage > 0
        ? filteredRows.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : filteredRows
      ).map((row: any) => (
        <CollapsableTableRow
          columns={columns}
          key={row.id}
          row={row}
          hasCollapse={hasCollapse}
          hasCheckbox={hasCheckbox}
          storeName={storeName}
          data-testid="gradus-table-td"
        />
      )),
    [
      rowsPerPage,
      filteredRows,
      page,
      columns,
      hasCollapse,
      hasCheckbox,
      storeName,
    ]
  );

  return (
    <Table
      data-testid="table-budget-request"
      aria-label="collapsible table"
      stickyHeader
      sx={{
        tableLayout: "auto",
        ...tableSx,
      }}
    >
      <CollapsableTableHeader
        headers={headers}
        hasCollapse={hasCollapse}
        hasCheckbox={hasCheckbox}
        storeName={storeName}
      />
      <TableBody>{filteredRowsMemoed}</TableBody>
      <GradusTableFooter
        rowsPerPageOptions={rowsPerPageOptions}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        page={page}
        hasPagination={hasPagination}
        count={filteredRows.length}
      />
    </Table>
  );
}

function GradusTableWithoutContext<R>({
  rows,
  columns: columnsToShow,
  hasCheckbox,
  hasCollapse,
  hasColumnSelector,
  collapseField,
  containerSx,
  tableSx,
  storeName,
  hasPagination,
  rowsPerPageLimit,
  rowsPerPageOptions,
}: PropsWithChildren<GradusTableProps<R>>) {
  const { initTable, applyFilters, filteredRows, columns } =
    useGradusTableStore<R>(storeName, (state) => ({
      initTable: state.initTable,
      applyFilters: state.applyFilters,
      filteredRows: state.filteredRows,
      columns: state.columns,
    }));

  const [showFilterForColumn, setShowFilterForColumn] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(() =>
    _.isUndefined(rowsPerPageLimit) ? 20 : rowsPerPageLimit
  );

  const onSelectedFilterChange = useCallback(() => {
    applyFilters();
    setPage(0);
  }, [applyFilters]);

  const closeFilter = useCallback(() => {
    setShowFilterForColumn("");
  }, []);

  const headers = useMemo(() => {
    const filtered: GradusTableColumn<R>[] = columns.filter(
      (column: GradusTableColumn<R>) => column !== undefined
    );
    const lastColumn: GradusTableColumn<R> = _.chain(filtered)
      .maxBy("order")
      .defaultTo(null)
      .value();

    return filtered.map((column) => (
      <FilterableHeader
        key={column.field}
        closeFilter={closeFilter}
        onSelectedFilterChange={onSelectedFilterChange}
        showFilterForColumn={showFilterForColumn}
        setShowFilterForColumn={setShowFilterForColumn}
        column={column}
        storeName={storeName}
        isLast={!_.isEmpty(lastColumn) && column.order === lastColumn.order}
      />
    ));
  }, [
    closeFilter,
    columns,
    onSelectedFilterChange,
    showFilterForColumn,
    storeName,
  ]);

  useEffect(() => {
    initTable(rows, columnsToShow, collapseField);
  }, [columnsToShow, rows, initTable, collapseField]);

  return (
    <>
      {hasColumnSelector && <ShowColumnsTable storeName={storeName} />}
      {/* Não consegui nem ignorar o erro (TS2590) nem corrigí-lo */}
      <TableContainer
        sx={{
          overflowY: "auto",
          ...containerSx,
        }}
      >
        <GradusTableInside
          tableSx={tableSx}
          rowsPerPage={rowsPerPage}
          filteredRows={filteredRows}
          page={page}
          setPage={setPage}
          columns={columns}
          hasCollapse={hasCollapse}
          hasCheckbox={hasCheckbox}
          hasPagination={hasPagination}
          storeName={storeName}
          headers={headers}
          rowsPerPageOptions={rowsPerPageOptions}
          setRowsPerPage={setRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

function GradusTable<R>({
  rows,
  columns,
  storeName,
  hasCheckbox,
  hasCollapse,
  collapseField,
  containerSx,
  tableSx,
  hasPagination,
  hasColumnSelector,
  rowsPerPageLimit,
  rowsPerPageOptions,
}: PropsWithChildren<GradusTableProps<R>>) {
  return (
    <GradusTableProvider storeName={storeName}>
      <GradusTableWithoutContext
        columns={columns}
        rows={rows}
        hasCheckbox={hasCheckbox}
        hasCollapse={hasCollapse}
        collapseField={collapseField}
        containerSx={containerSx}
        tableSx={tableSx}
        storeName={storeName}
        hasPagination={hasPagination}
        hasColumnSelector={hasColumnSelector}
        rowsPerPageLimit={rowsPerPageLimit}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </GradusTableProvider>
  );
}

GradusTableWithoutContext.defaultProps = { ...defaultProps };
GradusTable.defaultProps = { ...defaultProps };

export { GradusTable, GradusTableWithoutContext };
