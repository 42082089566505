import React, { useCallback, useMemo } from "react";
import { alpha } from "@mui/material";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import withGradusLoading from "../../../hocs/withGradusLoading";
import StringUtils from "../../../utils/StringUtils";
import {
  formatCurrency,
  formatCurrencyAbbreviate,
  formatInteger,
  formatPercentage,
} from "../../../utils/NumberFormatUtils";
import BaseChartContainer from "../BaseComponents/BaseChartContainer";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import Chart from "../Chart";
import MarketCompanyWithDifference from "../../../stores/ChartStore/MarketCompanyWithDifference";
import SalaryAverageAndQuantityAndCost from "../../../dtos/SalaryAverageAndQuantityAndCost";
import ChartHeader from "./ChartHeader";
import useChartLoadingStore from "../../../stores/ChartLoadingStore/useChartLoadingStore";

export default function SalaryAverageByOccupation() {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const theme = useTheme();

  const { getChart, charts } = useChartStore((state) => ({
    getChart: state.getChart,
    charts: state.charts,
  }));
  const { [Chart.SALARY_AVERAGE_BY_OCCUPATION]: isLoading } =
    useChartLoadingStore();

  const data = useMemo<
    {
      x: string;
      y: MarketCompanyWithDifference<SalaryAverageAndQuantityAndCost>;
    }[]
  >(() => {
    const chartData = getChart(
      charts,
      Chart.SALARY_AVERAGE_BY_OCCUPATION
    ) as unknown as Record<
      string,
      MarketCompanyWithDifference<SalaryAverageAndQuantityAndCost>
    >;
    return Object.keys(chartData).map((key) => ({
      x: key,
      y: chartData[key],
    }));
  }, [charts, getChart]);

  const ResponsiveContainerWithLoading = withGradusLoading(ResponsiveContainer);

  const tooltipFormatter = useCallback(
    (
      _value: any,
      type: string,
      {
        payload: {
          y: { company, market, difference },
        },
      }: any
    ) => {
      if (type === t("COMPANY")) {
        return [
          <>
            <span
              style={{
                fontFamily: theme.typography.fontFamily,
              }}
            >
              <strong>{`${t("SALARY_AVERAGE")}: `}</strong>
              {formatCurrency(company.average, language)}
              <br />
              <strong>{`${t("TOTAL_COST")}: `}</strong>
              {formatCurrency(company.cost, language)}
              <br />
              <strong>{`${t("EMPLOYEES")}: `}</strong>
              {formatInteger(company.quantity, language)}
            </span>
            <br />
            <span
              style={{
                fontFamily: theme.typography.fontFamily,
              }}
            >
              <strong>{`${t("DIFFERENCE_IN_RELATION_TO_MARKET", {
                lng: language,
              })}: `}</strong>
              {formatPercentage(difference, language)}
            </span>
          </>,
        ];
      }

      return [
        <span
          style={{
            fontFamily: theme.typography.fontFamily,
          }}
        >
          <strong>{`${t("SALARY_AVERAGE", { lng: language })}: `}</strong>
          {formatCurrency(market.average, language)}
          <br />
          <strong>{`${t("TOTAL_ESTIMATE_COST", {
            lng: language,
          })}: `}</strong>
          {formatCurrency(market.cost, language)}
          <br />
          <strong>{`${t("EMPLOYEES", { lng: language })}: `}</strong>
          {formatInteger(market.quantity, language)}
        </span>,
      ];
    },
    [language, t, theme.typography.fontFamily]
  );

  const legendFormatter = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (legendValue, { inactive }) => (
      <span
        style={{
          color: inactive ? "#ccc" : "#353639",
          fontFamily: theme.typography.fontFamily,
        }}
      >
        {legendValue}
      </span>
    ),
    [theme.typography.fontFamily]
  );

  return (
    <BaseChartContainer size={12}>
      <ChartHeader isCompare />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ResponsiveContainerWithLoading isLoading={isLoading}>
        <BarChart
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data={data}
          layout="vertical"
          barGap={1}
          barCategoryGap={5}
          margin={{ left: 160, bottom: 15 }}
        >
          <XAxis
            type="number"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            tickFormatter={(value) => formatCurrencyAbbreviate(value, language)}
            label={{
              value: t("CHARTS_DASHBOARD.AVERAGE_SALARY"),
              dy: 20,
            }}
          />
          <YAxis
            dataKey="x"
            type="category"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            tickFormatter={(value) => StringUtils.truncateString(value, 70)}
            label={{
              value: t("OCCUPATION_CBO"),
              angle: -90,
              dx: -180,
            }}
            tick={{
              width: 180,
              textAnchor: "start",
              dx: -180,
            }}
            tickSize={0}
          />
          <Tooltip
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formatter={tooltipFormatter}
            isAnimationActive={false}
          />
          <Bar
            name={t("COMPANY") as string}
            dataKey="y.company.average"
            key="yCompany"
            fill={theme.palette.primary.main}
            barSize={18}
            isAnimationActive={false}
          />
          <Bar
            name={t("MARKET") as string}
            dataKey="y.market.average"
            key="yMarket"
            fill={alpha(theme.palette.grey[700], 0.5)}
            barSize={18}
            isAnimationActive={false}
          />
          <Legend
            verticalAlign="top"
            wrapperStyle={{ top: 0, left: 90 }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formatter={legendFormatter}
          />
        </BarChart>
      </ResponsiveContainerWithLoading>
    </BaseChartContainer>
  );
}
