export default class CompareAnalysisRenameDTO {
  private readonly analysisId: number;

  private readonly name: string;

  constructor(
    analysisId: number,
    name: string,
  ) {
    this.analysisId = analysisId;
    this.name = name;
  }
}
