import React, { useMemo, useState } from "react";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const FileUploader = ({ onUpload }: { onUpload: (files: File[]) => void }) => {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const [inputLabel, setInputLabel] = useState("");

  const translatedPlaceholder = useMemo(
    () => t("UPLOAD_FIELD_PLACEHOLDER", { lng: language }),
    [t, language]
  );

  return (
    <Dropzone
      multiple={false}
      onDrop={(files) => {
        setInputLabel(files[0].name);
        onUpload(files);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps()}
            style={{
              border: "1px solid #C1C1C1",
              padding: "8px",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <input {...getInputProps()} name="file-input" />
            <Stack
              direction="row"
              alignItems="center"
              style={{ color: "rgba(0, 0, 0, 0.6)" }}
            >
              <FontAwesomeIcon
                icon={light("cloud-arrow-up")}
                style={{
                  height: "22px",
                  width: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography variant="h5" component="h5" sx={{ fontSize: "18px" }}>
                {_.isEmpty(inputLabel) ? translatedPlaceholder : inputLabel}
              </Typography>
            </Stack>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileUploader;
