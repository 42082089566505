import _ from "lodash";
import FilterOptionDTO from "../dtos/FilterOptionDTO";

const OPTIONS = [
  new FilterOptionDTO({ id: 1, name: "COMPANY_SIZE_ENUM.NO_EMPLOYEES" }),
  new FilterOptionDTO({ id: 2, name: "COMPANY_SIZE_ENUM.UPTO_4" }),
  new FilterOptionDTO({ id: 3, name: "COMPANY_SIZE_ENUM.FROM_5_TO_9" }),
  new FilterOptionDTO({ id: 4, name: "COMPANY_SIZE_ENUM.FROM_10_TO_19" }),
  new FilterOptionDTO({ id: 5, name: "COMPANY_SIZE_ENUM.FROM_20_TO_49" }),
  new FilterOptionDTO({ id: 6, name: "COMPANY_SIZE_ENUM.FROM_50_TO_99" }),
  new FilterOptionDTO({ id: 7, name: "COMPANY_SIZE_ENUM.FROM_100_TO_249" }),
  new FilterOptionDTO({ id: 8, name: "COMPANY_SIZE_ENUM.FROM_250_TO_499" }),
  new FilterOptionDTO({ id: 9, name: "COMPANY_SIZE_ENUM.FROM_500_TO_999" }),
  new FilterOptionDTO({ id: 10, name: "COMPANY_SIZE_ENUM.FROM_1000" }),
];

export { OPTIONS };

export default class CompanySize {
  static isSmall(size) {
    return Number(size) < 5;
  }

  static isMedium(size) {
    return Number(size) >= 5 && Number(size) <= 7;
  }

  static isBig(size) {
    return Number(size) > 7;
  }

  static getCompanySize(size) {
    if (size <= 0) {
      return 1;
    }
    if (size <= 4) {
      return 2;
    }
    if (size <= 9) {
      return 3;
    }
    if (size <= 19) {
      return 4;
    }
    if (size <= 49) {
      return 5;
    }
    if (size <= 99) {
      return 6;
    }
    if (size <= 249) {
      return 7;
    }
    if (size <= 499) {
      return 8;
    }
    if (size <= 999) {
      return 9;
    }
    return 10;
  }

  /**
   *
   * @param id
   * @return {FilterOptionDTO | null}
   */
  static getOptionByCompanySize(id) {
    const found = _.find(OPTIONS, (option) => option.id === id);
    if (!_.isEmpty(found)) {
      return found;
    }
    return OPTIONS.at(0);
  }

  static getOptionMaxAndMinEmployeesById(id) {
    switch (id) {
      case 1:
        return { max: 0 };
      case 2:
        return { min: 1, max: 4 };
      case 3:
        return { min: 5, max: 9 };
      case 4:
        return { min: 10, max: 19 };
      case 5:
        return { min: 20, max: 49 };
      case 6:
        return { min: 50, max: 99 };
      case 7:
        return { min: 100, max: 249 };
      case 8:
        return { min: 250, max: 499 };
      case 9:
        return { min: 500, max: 999 };
      default:
        return { min: 1000 };
    }
  }

  static getFilterTagLabel(ids, t) {
    const maxCompanySize = CompanySize.getOptionMaxAndMinEmployeesById(
      _.max(ids)
    );
    const minCompanySize = CompanySize.getOptionMaxAndMinEmployeesById(
      _.min(ids)
    );

    const hasMin = _.has(minCompanySize, "min");
    const hasMax = _.has(maxCompanySize, "max");

    if (hasMin && hasMax) {
      return t("COMPANY_SIZE_FILTER_TAG.BETWEEN_X_AND_Y_EMPLOYEES", {
        from: minCompanySize.min,
        to: maxCompanySize.max,
      });
    }
    if (!hasMin && hasMax) {
      return t("COMPANY_SIZE_FILTER_TAG.UPTO_X_EMPLOYEES", {
        to: maxCompanySize.max,
      });
    }
    if (hasMin && !hasMax) {
      return t(
        `COMPANY_SIZE_FILTER_TAG.${
          minCompanySize.min === 1 ? "FROM_X_EMPLOYEE" : "FROM_X_EMPLOYEES"
        }`,
        { from: minCompanySize.min }
      );
    }
    return t("COMPANY_SIZE_FILTER_TAG.ANY_NUMBER_OF_EMPLOYEES");
  }

  static getMinimum() {
    return OPTIONS[0];
  }

  static getMaximum() {
    return OPTIONS[OPTIONS.length - 1];
  }
}
