import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Trans, useTranslation } from "react-i18next";
import Metrics from "../../models/Metrics";
import { formatCurrency } from "../../utils/NumberFormatUtils";

export default function CompareSummaryBoard({ marketMetrics, companyMetrics }) {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const companyAverage = _.isNumber(companyMetrics.average)
    ? companyMetrics.average
    : 0;
  const marketAverage = _.isNumber(marketMetrics.average)
    ? marketMetrics.average
    : 0;

  const diff = companyAverage - (marketAverage || 0);
  const formattedDiff = formatCurrency(Math.abs(diff), language);

  if (diff > 0) {
    return (
      <span style={{ textAlign: "justify", width: "100%" }}>
        <Trans t={t} i18nKey="METRICS_BOARD.ABOVE_AVERAGE">
          <strong>{{ diff: formattedDiff }}</strong>
        </Trans>
      </span>
    );
  }
  if (diff < 0) {
    return (
      <span style={{ textAlign: "justify", width: "100%" }}>
        <Trans t={t} i18nKey="METRICS_BOARD.BELOW_AVERAGE">
          <strong>{{ diff: formattedDiff }}</strong>
        </Trans>
      </span>
    );
  }
  return (
    <span style={{ textAlign: "justify", width: "100%" }}>
      <Trans t={t} i18nKey="METRICS_BOARD.AT_AVERAGE">
        <strong />
      </Trans>
    </span>
  );
}

CompareSummaryBoard.propTypes = {
  marketMetrics: PropTypes.instanceOf(Metrics).isRequired,
  companyMetrics: PropTypes.instanceOf(Metrics).isRequired,
};
