import React from "react";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import ActionButton, { ActionButtonProps } from "./ActionButton";

export enum ActionButtonType {
  OPEN,
  RENAME,
  DUPLICATE,
  DELETE,
  HIDE_AND_SHOW,
  EDIT,
}

export type DefaultActionButtonProps = "icon" | "title";

export interface ActionButtonFactoryProps {
  type: ActionButtonType;
  buttonProps: Omit<ActionButtonProps, DefaultActionButtonProps>;
  overrideDefaultButtonProps?: Partial<
    Pick<ActionButtonProps, DefaultActionButtonProps>
  >;
}

function ActionButtonFactory({
  type,
  buttonProps,
  overrideDefaultButtonProps,
}: ActionButtonFactoryProps) {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  switch (type) {
    case ActionButtonType.OPEN:
      return (
        <ActionButton
          icon={light("up-right-from-square")}
          title={t("OPEN", { lng: language })}
          {...overrideDefaultButtonProps}
          {...buttonProps}
        />
      );
    case ActionButtonType.RENAME:
      return (
        <ActionButton
          icon={light("pencil")}
          title={t("RENAME", { lng: language })}
          {...overrideDefaultButtonProps}
          {...buttonProps}
        />
      );
    case ActionButtonType.EDIT:
      return (
        <ActionButton
          icon={light("pencil")}
          title={t("EDIT", { lng: language })}
          {...overrideDefaultButtonProps}
          {...buttonProps}
        />
      );
    case ActionButtonType.DUPLICATE:
      return (
        <ActionButton
          icon={light("copy")}
          title={t("DUPLICATE", { lng: language })}
          {...overrideDefaultButtonProps}
          {...buttonProps}
        />
      );
    case ActionButtonType.DELETE:
      return (
        <ActionButton
          icon={light("trash")}
          title={t("DELETE", { lng: language })}
          {...overrideDefaultButtonProps}
          {...buttonProps}
        />
      );
    default:
      // Caso não seja nenhum dos outros tipos, deve-se passar os valores por
      // props para criar um botão customizado. Os casos em que isto é útil são
      // botões com lógicas mais complexas, como troca de ícone e título.
      return (
        <ActionButton
          icon={overrideDefaultButtonProps?.icon || light("bug")}
          title={
            overrideDefaultButtonProps?.title || t("VALUE", { lng: language })
          }
          {...overrideDefaultButtonProps}
          {...buttonProps}
        />
      );
  }
}

ActionButtonFactory.defaultProps = {
  overrideDefaultButtonProps: {},
};

export default ActionButtonFactory;
