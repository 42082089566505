import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  alpha,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import Container from "@mui/material/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradusTooltip from "../../GradusComponents/GradusTooltip";
import InfoIcon from "../../InfoIcon/InfoIcon";
import Chart from "../Chart";
import BaseChartHeader from "../BaseComponents/BaseChartHeader";
import useDashboardVisualOptionsStore from "../../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import reportsApi from "../../../apis/reportsApi";
import useCompareAnalysisStore from "../../../stores/useCompareAnalysisStore";
import AlertContext from "../../../contexts/AlertContext";
import SelectOccupationChartType from "../ChartOptions/SelectOccupationChartType";
import FilterOptionDTO from "../../../dtos/FilterOptionDTO";
import useChartParametersStore from "../../../stores/ChartParametersStore/useChartParametersStore";
import ChartOptionsModal from "../ChartOptions/ChartOptionsModal";
import useDownloadFile from "../../../hooks/useDownloadFile";
import theme from "../../../pages/theme";
import withHideOnPresentationMode from "../../../hocs/withHideOnPresentationMode";

const chart = Chart.SALARY_AVERAGE_BY_OCCUPATION;

function LeftComponent() {
  const { t } = useTranslation();

  return (
    <GradusTooltip
      title={t("CHARTS_DASHBOARD.OCCUPATIONS_CHART_TOOLTIP")}
      placement="top-end"
      data-testid="occupations-chart-tooltip"
    >
      <span>
        <InfoIcon icon={solid("question-circle")} />
      </span>
    </GradusTooltip>
  );
}

function OptionsModal({
  isChartOptionsModalOpen,
  setIsChartOptionsModalOpen,
  isCompare,
}: {
  isChartOptionsModalOpen: boolean;
  setIsChartOptionsModalOpen: Dispatch<SetStateAction<boolean>>;
  isCompare: boolean;
}) {
  const { analysis } = useCompareAnalysisStore();

  return (
    <ChartOptionsModal
      isOpen={isChartOptionsModalOpen}
      setIsOpen={setIsChartOptionsModalOpen}
      chart={chart}
      analysis={analysis}
      isCompare={isCompare}
    >
      <SelectOccupationChartType chart={chart} />
    </ChartOptionsModal>
  );
}

function RightComponent({ isCompare }: { isCompare: boolean }) {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isChartOptionsModalOpen, setIsChartOptionsModalOpen] = useState(false);

  const { fireAlert } = useContext(AlertContext);
  const { t } = useTranslation();

  const { analysis } = useCompareAnalysisStore();
  const { globalParameters } = useChartParametersStore((state) => ({
    globalParameters: state.globalParameters,
  }));

  const generateOccupationsReport = useCallback(
    () =>
      reportsApi.generateOccupationsReport(
        analysis,
        globalParameters.applied.correctionIndex
      ),
    [analysis, globalParameters.applied.correctionIndex]
  );

  const downloadReport = useDownloadFile(
    setIsDownloading,
    generateOccupationsReport,
    analysis.getName(),
    "xlsx",
    () => fireAlert(t("ALERTS.GENERATING_REPORT"), false, 5000)
  );

  const HideOnPresentationModeAction =
    withHideOnPresentationMode(SpeedDialAction);

  const tooltipTitle = useMemo(() => {
    if (isDownloading) {
      return t("PROCESSING_THE_REPORT");
    }
    return t("DOWNLOAD_OCCUPATIONS_REPORT");
  }, [isDownloading, t]);

  return (
    <Container disableGutters sx={{ position: "relative" }}>
      <SpeedDial
        ariaLabel="salary-average-by-occupation-speed-dial"
        direction="down"
        icon={<SpeedDialIcon sx={{ color: "white" }} />}
        FabProps={{
          size: "small",
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
          color: "inherit",
          style: {
            boxShadow: "none",
            backgroundColor: alpha(theme.palette.grey["700"], 0.3),
          },
        }}
        sx={{ position: "absolute", right: 0, top: 0 }}
      >
        <HideOnPresentationModeAction
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltipTitle={tooltipTitle}
          key="download-occupations-report"
          icon={<FontAwesomeIcon icon={regular("cloud-arrow-down")} />}
          disabled={isDownloading}
          onClick={downloadReport}
          sx={{
            "&.Mui-disabled": {
              pointerEvents: "all",
            },
          }}
        />
        <SpeedDialAction
          key="options-action"
          tooltipTitle={t("OPTIONS")}
          icon={<FontAwesomeIcon icon={regular("ellipsis-vertical")} />}
          onClick={() => setIsChartOptionsModalOpen(true)}
        />
      </SpeedDial>
      <OptionsModal
        isChartOptionsModalOpen={isChartOptionsModalOpen}
        setIsChartOptionsModalOpen={setIsChartOptionsModalOpen}
        isCompare={isCompare}
      />
    </Container>
  );
}

export default function ChartHeader({ isCompare }: { isCompare: boolean }) {
  const { t } = useTranslation();

  const { chartOptions } = useDashboardVisualOptionsStore((state) => ({
    chartOptions: state.chartOptions,
  }));

  const {
    updateSelected,
    applySelected,
    [chart]: { applied },
  } = useChartParametersStore((state) => ({
    updateSelected: state.updateSelected,
    applySelected: state.applySelected,
    [chart]: state[chart],
  }));

  const [selectedChart, setSelectedChart] = useState<FilterOptionDTO>(() => {
    if (!applied?.subchart?.isEmpty?.()) {
      return applied.subchart;
    }
    const chartOption = _.find(
      chartOptions,
      (option) =>
        option.name === "CHARTS_DASHBOARD.SALARY_AVERAGE_BY_OCCUPATION"
    );
    const foundSubchart =
      _.find(
        chartOption?.subcharts || [],
        (option) => option.name === "CHARTS_DASHBOARD.HIGHER_AVERAGE"
      ) || new FilterOptionDTO({});

    updateSelected(chart, {
      subchart: foundSubchart,
    });
    applySelected(chart);

    return foundSubchart;
  });

  useEffect(() => {
    setSelectedChart(applied.subchart);
  }, [applied.subchart]);

  return (
    <BaseChartHeader
      title={
        t(
          selectedChart?.name || "CHARTS_DASHBOARD.AVERAGE_SALARY_BY_OCCUPATION"
        ) as string
      }
      leftComponent={<LeftComponent />}
      rightComponent={<RightComponent isCompare={isCompare} />}
    />
  );
}
