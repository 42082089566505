import { create } from "zustand";
import { devtools } from "zustand/middleware";
import Metrics from "../models/Metrics";
import employeeMetricsApi from "../apis/metrics/employeeMetricsApi";
import payrollMetricsApi from "../apis/metrics/payrollMetricsApi";
import CompareAnalysisPresenter from "../dtos/CompareAnalysis/CompareAnalysisPresenter";
import ConsulteAnalysisPresenter from "../dtos/ConsulteAnalysis/ConsulteAnalysisPresenter";
import AnalysisPresenter from "../dtos/AnalysisPresenter";
import useChartParametersStore from "./ChartParametersStore/useChartParametersStore";

export type MetricsProps = {
  marketMetrics: Metrics;
  companyMetrics: Metrics;

  isUpdatingMarketMetrics: boolean;
  isUpdatingCompanyMetrics: boolean;

  getMetrics: (
    isCompare: boolean,
    analysis: AnalysisPresenter
  ) => Promise<void>;
  getCompareMetrics: (analysis: CompareAnalysisPresenter) => Promise<void>;
  getConsulteMetrics: (analysis: ConsulteAnalysisPresenter) => Promise<void>;

  isLoadingCompareMetrics: () => boolean;
  isLoadingConsulteMetrics: () => boolean;

  resetMetrics: () => void;
};

const initialState = {
  marketMetrics: new Metrics({}),
  companyMetrics: new Metrics({}),

  isUpdatingMarketMetrics: true,
  isUpdatingCompanyMetrics: true,
};

const useMetricsStore = create<MetricsProps>()(
  devtools(
    (set, get) => ({
      ...initialState,

      getMetrics: async (isCompare: boolean, analysis: AnalysisPresenter) => {
        const { getCompareMetrics, getConsulteMetrics } = get();

        if (isCompare) {
          await getCompareMetrics(analysis as CompareAnalysisPresenter);
        } else {
          await getConsulteMetrics(analysis as ConsulteAnalysisPresenter);
        }
      },

      getCompareMetrics: async (analysis: CompareAnalysisPresenter) => {
        const { resetMetrics } = get();
        const { globalParameters } = useChartParametersStore.getState();

        resetMetrics();

        let marketMetrics = new Metrics({});
        let companyMetrics = new Metrics({});

        try {
          set({ isUpdatingMarketMetrics: true });
          marketMetrics = await employeeMetricsApi.getCompareMetricsByFilterId(
            analysis.getActiveFilterId(),
            globalParameters.applied.correctionIndex,
            analysis.getId()
          );
        } finally {
          set({ isUpdatingMarketMetrics: false });
        }

        try {
          set({ isUpdatingCompanyMetrics: true });
          companyMetrics = await payrollMetricsApi.getMetrics(
            analysis.getCompanyFilters(),
            analysis.getId()
          );
        } finally {
          set({ isUpdatingCompanyMetrics: false });
        }

        set({ marketMetrics, companyMetrics });
      },

      getConsulteMetrics: async (analysis: ConsulteAnalysisPresenter) => {
        const { resetMetrics } = get();
        const { globalParameters } = useChartParametersStore.getState();

        resetMetrics();

        let marketMetrics = new Metrics({});

        try {
          set({ isUpdatingMarketMetrics: true });
          marketMetrics = await employeeMetricsApi.getConsulteMetrics(
            analysis.getMarketFilters(),
            globalParameters.applied.correctionIndex
          );
        } finally {
          set({ isUpdatingMarketMetrics: false });
        }

        set({ marketMetrics });
      },

      isLoadingCompareMetrics: () => {
        const { isUpdatingCompanyMetrics, isUpdatingMarketMetrics } = get();
        return isUpdatingCompanyMetrics || isUpdatingMarketMetrics;
      },

      isLoadingConsulteMetrics: () => {
        const { isUpdatingMarketMetrics } = get();
        return isUpdatingMarketMetrics;
      },

      resetMetrics: () => {
        set(initialState);
      },
    }),
    { name: "MetricsStore" }
  )
);

export default useMetricsStore;
