/* eslint-disable react/prop-types */

import React from "react";
import GradusLoading from "../components/GradusComponents/GradusLoading";

function withGradusLoading(Component) {
  const WithLoading = ({
    forwardedRef,
    isLoading,
    gradusLoadingContainerSx,
    ...props
  }) => {
    if (isLoading) {
      return (
        <GradusLoading
          containerSx={gradusLoadingContainerSx}
          ref={forwardedRef}
        />
      );
    }
    return <Component ref={forwardedRef} {...props} />;
  };

  return React.forwardRef(
    ({ isLoading, gradusLoadingContainerSx, ...props }, ref) => (
      <WithLoading
        forwardedRef={ref}
        isLoading={isLoading}
        gradusLoadingContainerSx={gradusLoadingContainerSx}
        {...props}
      />
    )
  );
}

export default withGradusLoading;
