import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { GradusTable } from "../GradusComponents/GradusTable/GradusTable";
import GradusTableColumn from "../GradusComponents/GradusTable/GradusTableColumn";
import Actions from "../Actions/Actions";
import { ActionButtonType } from "../Actions/ActionButtonFactory";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import ConfigurationView from "../../dtos/PayrollConfiguration/ConfigurationView";
import PayrollConfigurationDTO from "../../dtos/PayrollConfiguration/PayrollConfigurationDTO";

function MappingsActionButtonsWithoutMemo({
  row,
  shownConfiguration,
  applyFunction,
  updateFunction,
  duplicateFunction,
  deleteFunction,
  handleVisibilityClick,
}: {
  row: ConfigurationView;
  shownConfiguration: ConfigurationView | null;
  applyFunction: (applyId: number) => void;
  updateFunction: (updateId: number) => void;
  duplicateFunction: (duplicateId: number) => void;
  deleteFunction: (row: ConfigurationView) => void;
  handleVisibilityClick: (option: ConfigurationView) => void;
}) {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  const isMappingBeingShown = shownConfiguration?.id === row.id;

  const hideAndShowProps = useMemo(() => {
    if (isMappingBeingShown) {
      return {
        title: t("HIDE_MAPPING", { lng: language }),
        icon: light("eye-slash"),
      };
    }
    return { title: t("SHOW_MAPPING", { lng: language }), icon: light("eye") };
  }, [isMappingBeingShown, language, t]);

  const actions = [
    {
      type: ActionButtonType.OPEN,
      buttonProps: { onClick: () => applyFunction(row.id) },
    },
    {
      type: ActionButtonType.EDIT,
      buttonProps: { onClick: () => updateFunction(row.id) },
    },
    {
      type: ActionButtonType.DUPLICATE,
      buttonProps: { onClick: () => duplicateFunction(row.id) },
    },
    {
      type: ActionButtonType.DELETE,
      buttonProps: {
        onClick: () => deleteFunction(row),
        disabled: row.isUsed,
      },
      overrideDefaultButtonProps: {
        title: row.isUsed
          ? t(
              "FILTER_MODAL.IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_FILTER",
              { lng: language }
            )
          : t("DELETE", { lng: language }),
      },
    },
    {
      type: ActionButtonType.HIDE_AND_SHOW,
      buttonProps: {
        onClick: () => handleVisibilityClick(row),
      },
      overrideDefaultButtonProps: hideAndShowProps,
    },
  ];

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Actions actions={actions} />
  );
}

const MappingsActionButtons = React.memo(MappingsActionButtonsWithoutMemo);

function MappingsTable({
  options,
  deleteFunction,
  applyFunction,
  duplicateFunction,
  updateFunction,
  setShownConfiguration,
  shownConfiguration,
  configurationPreviewRef,
}: {
  options: PayrollConfigurationDTO[];
  deleteFunction: (deleteId: number) => void;
  applyFunction: (applyId: number) => void;
  duplicateFunction: (duplicateId: number) => void;
  updateFunction: (updateId: number) => void;
  setShownConfiguration: Dispatch<SetStateAction<ConfigurationView | null>>;
  shownConfiguration: ConfigurationView | null;
  configurationPreviewRef: MutableRefObject<HTMLDivElement | null>;
}) {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  const handleVisibilityClick = useCallback(
    (option: ConfigurationView) => {
      setShownConfiguration((prevState) => {
        if (prevState?.id === option?.id) {
          return null;
        }
        configurationPreviewRef?.current?.scrollIntoView();
        return option;
      });
    },
    [configurationPreviewRef, setShownConfiguration]
  );

  const [deletingRow, setDeletingRow] = useState<ConfigurationView | null>(
    null
  );

  const handleDelete = useCallback(
    (row: ConfigurationView) => setDeletingRow(row),
    []
  );

  const columns = useMemo(
    () => [
      new GradusTableColumn<ConfigurationView>({
        headerLabel: t("NAME", { lng: language }),
        field: "name",
        order: 0,
        render: (row) => row.name,
      }),
      new GradusTableColumn<ConfigurationView>({
        headerLabel: t("ACTIONS", { lng: language }),
        headerAlign: "center",
        cellStyle: {
          textAlign: "center",
        },
        field: "id",
        order: 1,
        hasFilter: false,
        render: (row) => (
          <MappingsActionButtons
            row={row}
            shownConfiguration={shownConfiguration}
            applyFunction={applyFunction}
            updateFunction={updateFunction}
            duplicateFunction={duplicateFunction}
            deleteFunction={handleDelete}
            handleVisibilityClick={handleVisibilityClick}
          />
        ),
      }),
    ],
    [
      applyFunction,
      duplicateFunction,
      handleDelete,
      handleVisibilityClick,
      language,
      shownConfiguration,
      t,
      updateFunction,
    ]
  );

  const configurationListViews = useMemo(
    () => options.map(ConfigurationView.fromDTO),
    [options]
  );

  if (options.length <= 0) {
    return (
      <p style={{ textAlign: "center" }}>{`${t(
        "NO_MAPPINGS_HAVE_BEEN_FOUND"
      )}.`}</p>
    );
  }

  return (
    <Stack>
      <GradusTable
        rows={configurationListViews}
        columns={columns}
        storeName="mappings-table"
      />
      <ConfirmationDialog
        open={!_.isEmpty(deletingRow)}
        handleAccept={() => {
          if (!_.isEmpty(deletingRow)) {
            deleteFunction(deletingRow.id);
          }
          setDeletingRow(null);
        }}
        handleCancel={() => {
          setDeletingRow(null);
        }}
      />
      <Divider />
    </Stack>
  );
}

export default MappingsTable;
