import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function SearchBar({
  setSearchedOptions,
  options,
  parametersToSearch,
  placeholder,
}) {
  const [searchText, setSearchText] = useState("");

  const search = (event) => {
    const { value } = event.target;
    setSearchText(value);
    if (_.isEmpty(value)) {
      setSearchedOptions(options);
    } else {
      const filtered = options.filter((option) =>
        _.some(parametersToSearch, (parameter) =>
          _.get(option, parameter).toLowerCase().includes(value.toLowerCase())
        )
      );
      setSearchedOptions(filtered);
    }
  };

  return (
    <TextField
      type="search"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FontAwesomeIcon icon={light("magnifying-glass")} />
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      value={searchText}
      onChange={search}
      sx={{ width: "40%", alignSelf: "flex-start" }}
    />
  );
}

SearchBar.propTypes = {
  setSearchedOptions: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  parametersToSearch: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: "Comece a digitar para buscar...",
};
