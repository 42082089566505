import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { DefaultTFuncReturn } from "i18next";
import withGradusTooltip from "../../hocs/withGradusTooltip";

const IconButtonWithTooltip = withGradusTooltip(IconButton, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  defaultTooltipProps: { style: { fontSize: "20px" } },
});

export interface ActionButtonProps {
  title: string | DefaultTFuncReturn;
  icon: IconDefinition;
  onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  disabled?: boolean;
}

export default function ActionButton({
  title,
  icon,
  disabled,
  onClick,
}: ActionButtonProps) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <IconButtonWithTooltip onClick={onClick} disabled={disabled} title={title}>
      <FontAwesomeIcon icon={icon} />
    </IconButtonWithTooltip>
  );
}

ActionButton.defaultProps = {
  disabled: false,
};
