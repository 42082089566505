import React from "react";
import { alpha, Container, Paper } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import CompareSummaryBoard from "./CompareSummaryBoard";
import MetricsOrganization from "./MetricsOrganization";
import GradusTooltip from "../GradusComponents/GradusTooltip";
import InfoIcon from "../InfoIcon/InfoIcon";
import FontYearRais from "../FontYearRais/FontYearRais";
import useMetricsStore from "../../stores/useMetricsStore";

export default function MetricsBoard({ isCompare }: { isCompare?: boolean }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { marketMetrics, companyMetrics } = useMetricsStore();

  return (
    <Container maxWidth="lg" sx={{ mb: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-start">
          <GradusTooltip
            title={t("METRICS_BOARD.MONETARY_VALUES_TOOLTIP")}
            placement="top-end"
          >
            <span>
              <InfoIcon icon={solid("question-circle")} />
            </span>
          </GradusTooltip>
        </Stack>
        <Stack direction="row" justifyContent="space-around">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <MetricsOrganization
            marketMetrics={marketMetrics}
            companyMetrics={companyMetrics}
            isCompare={isCompare}
          />
        </Stack>
        <Container
          disableGutters
          sx={{
            mb: isCompare ? 1 : 0,
            fontSize: "1rem",
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
            fontWeight: "bold",
          }}
        >
          {isCompare && (
            <>
              <span
                style={{
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  marginRight: theme.spacing(1),
                }}
              >
                {t("COMPANY")}
              </span>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                x
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  color: alpha(theme.palette.grey[700], 0.5),
                  marginLeft: theme.spacing(1),
                }}
              >
                {t("MARKET")}
              </span>
            </>
          )}
        </Container>
        {isCompare && (
          <Container disableGutters sx={{ mb: isCompare ? 1 : 0 }}>
            <CompareSummaryBoard
              marketMetrics={marketMetrics}
              companyMetrics={companyMetrics}
            />
          </Container>
        )}
        <FontYearRais />
      </Paper>
    </Container>
  );
}

MetricsBoard.propTypes = {
  isCompare: PropTypes.bool,
};

MetricsBoard.defaultProps = {
  isCompare: false,
};
