import { StateCreator } from "zustand";
import _ from "lodash";
import payrollMetricsApi from "../../apis/metrics/payrollMetricsApi";
import FilterOptionDTO from "../../dtos/FilterOptionDTO";
import PayrollFilter from "../../dtos/PayrollFilter";

import {
  FilterResultPreviewType,
  FilterStoreProps,
  MarketFilterSlice,
  PayrollFilterSlice,
} from "./filterStoreUtils";
import PayrollConfigurationDTO from "../../dtos/PayrollConfiguration/PayrollConfigurationDTO";
import payrollConfigurationsApi from "../../apis/payrollConfigurationsApi";
import useFilterOptionsStore from "../FilterOptionsStore/useFilterOptionsStore";

const initialState = {
  // Filtros salvos no banco
  appliedPayrollFilters: new PayrollFilter({}),

  // Filtros que estão somente no modal de filtragem, sem estarem salvos no banco
  modalSelectedPayrollFilters: new PayrollFilter({}),

  currentConfiguration: new PayrollConfigurationDTO({}),
};

const createPayrollFilterSlice: StateCreator<
  PayrollFilterSlice & MarketFilterSlice & FilterStoreProps,
  [],
  [],
  PayrollFilterSlice
> = (set, get) => ({
  ...initialState,

  _initializePayrollFilters: async (analysis) => {
    const currentConfiguration = await payrollConfigurationsApi.getByAnalysisId(
      analysis.getId()
    );

    const { getCompanyChainedFilters } = useFilterOptionsStore.getState();
    await getCompanyChainedFilters(
      analysis.getCompanyFilters(),
      analysis.getId()
    );

    set((state) => ({
      ...state,
      appliedPayrollFilters: analysis.getCompanyFilters(),
      modalSelectedPayrollFilters: analysis.getCompanyFilters(),
      currentConfiguration,
    }));
  },

  updateModalSelectedPayrollFilters: async (values, analysisId) => {
    const { modalSelectedPayrollFilters, filterResultPreview } = get();

    const newSelectedFilters = new PayrollFilter({
      ...modalSelectedPayrollFilters,
      ...values,
    });

    set({
      filterResultPreview: {
        ...filterResultPreview,
        company: {
          quantity: 0,
          isLoading: true,
        },
      },
      modalSelectedPayrollFilters: newSelectedFilters,
    });

    const companyMetrics = await payrollMetricsApi.getMetrics(
      newSelectedFilters,
      analysisId
    );

    set({
      filterResultPreview: {
        ...filterResultPreview,
        company: {
          quantity: companyMetrics?.quantity || 0,
          isLoading: false,
        },
      },
    });

    return newSelectedFilters;
  },

  getCompanyQuantityPreview: (filterResultPreview: FilterResultPreviewType) =>
    filterResultPreview?.company.quantity || 0,

  setDefaultPayrollFilters: (analysisId) => {
    const { modalSelectedPayrollFilters, updateModalSelectedPayrollFilters } =
      get();

    const emptyParametersByName = _.reduce(
      modalSelectedPayrollFilters,
      (acc: { [key: string]: FilterOptionDTO[] }, _cur, key: string) => {
        acc[key] = [];
        return acc;
      },
      {}
    );

    updateModalSelectedPayrollFilters(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      {
        ...new PayrollFilter({
          parameters: emptyParametersByName,
        }),
      },
      analysisId
    );
  },
});

export default createPayrollFilterSlice;
