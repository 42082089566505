import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";

function LogoImage({ isDrawerOpen }) {
  const theme = useTheme();

  const GradusAnalyticsLogoImg = useMemo(() => {
    const image = new Image();
    image.src = "/images/Gradus_Analytics_Marca_RGB_Negativa.svg";
    return image;
  }, []);
  const GradusLogoImg = useMemo(() => {
    const image = new Image();
    image.src = "/images/Gradus_Logo_Negativa.svg";
    return image;
  }, []);

  if (isDrawerOpen) {
    return (
      <img
        alt="Gradus Analytics Logo"
        src={GradusAnalyticsLogoImg.src}
        style={{ width: "120px", marginLeft: theme.spacing(2) }}
      />
    );
  }

  return (
    <img
      alt="Gradus Analytics Logo"
      src={GradusLogoImg.src}
      style={{ width: "40px" }}
    />
  );
}

LogoImage.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default React.memo(LogoImage);
