import { ListItemButton } from "@mui/material";
import withGradusTooltip from "../../hocs/withGradusTooltip";

const TooltipedListItemButton = withGradusTooltip(ListItemButton, {
  defaultTooltipProps: {
    placement: "right",
    style: {
      fontSize: "24px",
    },
  },
});

export default TooltipedListItemButton;
