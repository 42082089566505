import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { DefaultTFuncReturn } from "i18next";
import GradusPrimaryButton from "../Buttons/GradusPrimaryButton";
import GradusSupportButton from "../Buttons/GradusSupportButton";

export default function ConfirmationDialog({
  title,
  content,
  acceptButtonText,
  cancelButtonText,
  open,
  handleAccept,
  handleCancel,
}: {
  open: boolean;
  handleAccept: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  handleCancel: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  title?: string | DefaultTFuncReturn;
  content?: string | DefaultTFuncReturn;
  acceptButtonText?: string | DefaultTFuncReturn;
  cancelButtonText?: string | DefaultTFuncReturn;
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle>{t(title as string)}</DialogTitle>
      <DialogContent>{t(content as string)}</DialogContent>
      <DialogActions>
        <GradusPrimaryButton onClick={handleAccept}>
          {t(acceptButtonText as string)}
        </GradusPrimaryButton>
        <GradusSupportButton onClick={handleCancel}>
          {t(cancelButtonText as string)}
        </GradusSupportButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.defaultProps = {
  title: "CONFIRMATION_DIALOG.CONFIRM_ACTION",
  content:
    "CONFIRMATION_DIALOG.DO_YOU_REALLY_WANT_TO_EXECUTE_THE_SELECTED_ACTION",
  acceptButtonText: "ACCEPT",
  cancelButtonText: "CANCEL",
};
