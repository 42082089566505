import { Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import GradusPrimaryButton from "../../components/Buttons/GradusPrimaryButton";
import GradusTooltip from "../../components/GradusComponents/GradusTooltip";

export default function ModuleCard({
  title,
  text,
  buttonText,
  handleClick,
  isAvailable,
  isCompare,
}) {
  const { t } = useTranslation();

  const tooltipTitle = isCompare
    ? t("BUY_COMPARE_MODULE")
    : t("BUY_CONSULT_MODULE");

  return (
    <Paper
      sx={{
        height: "250px",
        py: 4,
        px: 3,
        display: "flex",
        flexDirection: "column",
        justifyItems: "space-between",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Typography variant="h2">{title}</Typography>
        <p>{text}</p>
      </div>
      <GradusTooltip title={isAvailable ? "" : tooltipTitle}>
        <span>
          <GradusPrimaryButton
            onClick={handleClick}
            buttonProps={{
              disabled: !isAvailable,
            }}
          >
            {buttonText}
          </GradusPrimaryButton>
        </span>
      </GradusTooltip>
    </Paper>
  );
}

ModuleCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool,
  isCompare: PropTypes.bool,
};

ModuleCard.defaultProps = {
  isAvailable: false,
  isCompare: false,
};
