import _ from "lodash";
import StringUtils from "../utils/StringUtils";
import TableField from "../utils/TableField";

export default class BaseFilter {
  constructor({
    geographicRegions = [],
    geographicStates = [],
    immediateRegions = [],
    intermediateRegions = [],
    counties = [],
    largeGroups = [],
    primarySubgroups = [],
    subgroups = [],
    families = [],
    occupations = [],
  }) {
    this.geographicRegions = geographicRegions;
    this.geographicStates = geographicStates;
    this.immediateRegions = immediateRegions;
    this.intermediateRegions = intermediateRegions;
    this.counties = counties;
    this.occupations = occupations;
    this.families = families;
    this.subgroups = subgroups;
    this.primarySubgroups = primarySubgroups;
    this.largeGroups = largeGroups;
  }

  toString() {
    return [this.geographicFiltersToString(), this.jobFiltersToString()]
      .filter((filter) => !_.isEmpty(filter))
      .join(", ");
  }

  geographicFilters() {
    return {
      counties: this.counties,
      intermediateRegions: this.intermediateRegions,
      immediateRegions: this.immediateRegions,
      geographicStates: this.geographicStates,
      geographicRegions: this.geographicRegions,
    };
  }

  geographicFiltersToString() {
    return StringUtils.firstNonEmptyArrayToString([
      this.counties,
      this.intermediateRegions,
      this.immediateRegions,
      this.geographicStates,
      this.geographicRegions,
    ]);
  }

  jobFilters() {
    return {
      occupations: this.occupations,
      families: this.families,
      subgroups: this.subgroups,
      primarySubgroups: this.primarySubgroups,
      largeGroups: this.largeGroups,
    };
  }

  jobFiltersToString() {
    return StringUtils.firstNonEmptyArrayToString([
      this.occupations,
      this.families,
      this.subgroups,
      this.primarySubgroups,
      this.largeGroups,
    ]);
  }

  onlyWithIds() {
    return new BaseFilter({
      geographicRegions: this.geographicRegions.map(({ id }) => id),
      geographicStates: this.geographicStates.map(({ id }) => id),
      immediateRegions: this.immediateRegions.map(({ id }) => id),
      intermediateRegions: this.intermediateRegions.map(({ id }) => id),
      counties: this.counties.map(({ id }) => id),
      largeGroups: this.largeGroups.map(({ id }) => id),
      primarySubgroups: this.primarySubgroups.map(({ id }) => id),
      subgroups: this.subgroups.map(({ id }) => id),
      families: this.families.map(({ id }) => id),
      occupations: this.occupations.map(({ id }) => id),
    });
  }

  hasJobFilter() {
    return (
      !_.isEmpty(this.occupations) ||
      !_.isEmpty(this.families) ||
      !_.isEmpty(this.subgroups) ||
      !_.isEmpty(this.primarySubgroups) ||
      !_.isEmpty(this.largeGroups)
    );
  }

  hasGeographyFilter() {
    return (
      !_.isEmpty(this.counties) ||
      !_.isEmpty(this.immediateRegions) ||
      !_.isEmpty(this.intermediateRegions) ||
      !_.isEmpty(this.geographicStates) ||
      !_.isEmpty(this.geographicRegions)
    );
  }

  getOptionsByField(field) {
    return this[field];
  }

  getMostGranularJobFilterValues() {
    if (!_.isEmpty(this.occupations)) {
      return { field: TableField.occupation, values: this.occupations };
    }
    if (!_.isEmpty(this.families)) {
      return { field: TableField.family, values: this.families };
    }
    if (!_.isEmpty(this.subgroups)) {
      return { field: TableField.subgroup, values: this.subgroups };
    }
    if (!_.isEmpty(this.primarySubgroups)) {
      return {
        field: TableField.primarySubgroup,
        values: this.primarySubgroups,
      };
    }
    if (!_.isEmpty(this.largeGroups)) {
      return { field: TableField.largeGroup, values: this.largeGroups };
    }
    return { field: TableField.occupation, values: [] };
  }

  isEmpty() {
    return !this.hasGeographyFilter() && !this.hasJobFilter();
  }

  static addValuesToMostGranularJobFilter(field, filter, values) {
    switch (field) {
      case TableField.occupation:
        return new BaseFilter({ occupations: values });
      case TableField.family:
        return new BaseFilter({ families: values });
      case TableField.subgroup:
        return new BaseFilter({ subgroups: values });
      case TableField.primarySubgroup:
        return new BaseFilter({ primarySubgroups: values });
      case TableField.largeGroup:
        return new BaseFilter({ largeGroups: values });
      default:
        return new BaseFilter({});
    }
  }
}
