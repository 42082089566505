import React from "react";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import withGradusTooltip from "../../hocs/withGradusTooltip";

export default function UnavailableListHeader({
  isDrawerOpen,
  icon,
  itemTitle,
  tooltipText,
}) {
  const WrappedItemButton = withGradusTooltip(MuiListItemButton, {
    defaultTooltipProps: { title: tooltipText, placement: "right" },
  });

  if (isDrawerOpen) {
    return (
      <WrappedItemButton disabled>
        <ListItemIcon>
          <FontAwesomeIcon icon={icon} />
        </ListItemIcon>
        <ListItemText primary={itemTitle} />
      </WrappedItemButton>
    );
  }
  return (
    <WrappedItemButton
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      disabled
    >
      <ListItemIcon>
        <FontAwesomeIcon icon={icon} />
      </ListItemIcon>
      <ListItemText primary={itemTitle} />
    </WrappedItemButton>
  );
}

UnavailableListHeader.propTypes = {
  itemTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string.isRequired,
};
