import { StateCreator } from "zustand";
import compareAnalysisSavedFiltersApi from "../apis/compareAnalysisSavedFiltersApi";
import CompareAnalysisSavedFiltersPresenter from "../dtos/CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersPresenter";
import CompareAnalysisSavedFiltersCreateDTO from "../dtos/CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersCreateDTO";
import MarketFilter from "../dtos/MarketFilter";
import PayrollFilter from "../dtos/PayrollFilter";
import CompareAnalysisPresenter from "../dtos/CompareAnalysis/CompareAnalysisPresenter";

export interface CompareAnalysisSavedFiltersStoreState {
  savedFilterOptions: CompareAnalysisSavedFiltersPresenter[];

  initSavedFilters: (analysisId: CompareAnalysisPresenter) => void;

  getSavedFilterById: (
    id: number
  ) => Promise<CompareAnalysisSavedFiltersPresenter>;
  getAllSavedFiltersByAnalysisId: (
    analysisId: number
  ) => Promise<CompareAnalysisSavedFiltersPresenter[]>;
  createFilterByDto: (
    analysisId: number,
    marketFilters: MarketFilter,
    companyFilters: PayrollFilter
  ) => Promise<number>;
  deleteFilter: (filterId: number) => void;
}

const initialSavedFiltersSliceState = {
  savedFilterOptions: [],
};

const createCompareAnalysisSavedFiltersSlice: StateCreator<
  CompareAnalysisSavedFiltersStoreState
> = (set, get) => ({
  ...initialSavedFiltersSliceState,

  initSavedFilters: async (analysis) => {
    const { getAllSavedFiltersByAnalysisId } = get();

    await getAllSavedFiltersByAnalysisId(analysis.getId());
  },

  getAllSavedFiltersByAnalysisId: async (analysisId) => {
    const newOptions = await compareAnalysisSavedFiltersApi.getAllByAnalysisId(
      analysisId
    );
    set(() => ({ savedFilterOptions: newOptions }));
    return newOptions;
  },

  getSavedFilterById: async (filterId) =>
    compareAnalysisSavedFiltersApi.getById(filterId),

  createFilterByDto: (analysisId, marketFilters, companyFilters) =>
    compareAnalysisSavedFiltersApi.createFilter(
      new CompareAnalysisSavedFiltersCreateDTO(
        analysisId,
        marketFilters.onlyWithIds(),
        companyFilters.onlyWithIds()
      )
    ),

  deleteFilter: (filterId) =>
    compareAnalysisSavedFiltersApi.deleteFilter(filterId),
});

export { initialSavedFiltersSliceState };

export default createCompareAnalysisSavedFiltersSlice;
