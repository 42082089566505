import { create } from "zustand";
import { devtools } from "zustand/middleware";
import CompareAnalysisPresenter from "../dtos/CompareAnalysis/CompareAnalysisPresenter";
import compareAnalysisApi from "../apis/compareAnalysisApi";
import CompareAnalysisUpdateDTO from "../dtos/CompareAnalysis/CompareAnalysisUpdateDTO";
import useFilterStore from "./FilterStore/useFilterStore";
import CompareAnalysisSavedFiltersPresenter from "../dtos/CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersPresenter";
import CompareAnalysisRelaxedFiltersPresenter from "../dtos/CompareAnalysisRelaxedFilters/CompareAnalysisRelaxedFiltersPresenter";
import useChartStore from "./ChartStore/useChartStore";
import createCompareAnalysisSavedFiltersSlice, {
  CompareAnalysisSavedFiltersStoreState,
  initialSavedFiltersSliceState,
} from "./createCompareAnalysisSavedFiltersSlice";
import compareAnalysisSavedFiltersApi from "../apis/compareAnalysisSavedFiltersApi";
import useChartParametersStore from "./ChartParametersStore/useChartParametersStore";
import useFilterOptionsStore from "./FilterOptionsStore/useFilterOptionsStore";
import useRelaxationSelectorStore from "./useRelaxationSelectorStore";

export interface CompareAnalysisStoreProps
  extends CompareAnalysisSavedFiltersStoreState {
  analysis: CompareAnalysisPresenter;

  updateAnalysis: () => void;
  getAnalysis: (analysisId: number) => Promise<CompareAnalysisPresenter>;
  getAll: () => Promise<CompareAnalysisPresenter[]>;
  renameAnalysis: (newName: string, analysisId: number) => void;
  deleteAnalysis: (deleteId: number) => void;

  initDashboard: (analysisId: number) => void;
  reloadDashboard: () => void;
  reset: () => void;

  changeFilter: (newActiveFilterId: number | null) => void;
  createFilter: () => void;
  getAllSavedFilters: () => Promise<CompareAnalysisSavedFiltersPresenter[]>;
  renameFilter: (filterId: number, newName: string) => void;
}

const initialState = {
  analysis: new CompareAnalysisPresenter({}),
  originalAnalysis: new CompareAnalysisPresenter({}),
  ...initialSavedFiltersSliceState,
};

const useCompareAnalysisStore = create<CompareAnalysisStoreProps>()(
  devtools(
    (set, get, store) => ({
      ...initialState,
      ...createCompareAnalysisSavedFiltersSlice(set, get, store),

      initDashboard: async (analysisId) => {
        const { getAnalysis, reset, initSavedFilters } = get();
        const { initCharts } = useChartStore.getState();
        const { initParameters } = useChartParametersStore.getState();
        const { initOptions } = useFilterOptionsStore.getState();

        reset();
        initParameters();
        const analysis = await getAnalysis(analysisId);
        await initSavedFilters(analysis);
        await initCharts(true, analysis);
        await initOptions(analysis);
      },

      reloadDashboard: () => {
        const { initDashboard, analysis } = get();
        initDashboard(analysis.getId());
      },

      reset: () => {
        set(initialState);
        // const { resetChartParameters } =
        //   useDashboardVisualOptionsStore.getState();
        // resetChartParameters();
      },

      updateAnalysis: async () => {
        const { applyFilters } = useFilterStore.getState();
        const { isRelaxed } = useRelaxationSelectorStore.getState();
        const { appliedPayrollFilters, appliedMarketFilters } = applyFilters();

        const { analysis: analysisToBeUpdated, reloadDashboard } = get();

        const prevActiveFilter = analysisToBeUpdated.getActiveFilter();
        const prevRelaxedFilter = prevActiveFilter?.getRelaxedFilter();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newRelaxedFilter = new CompareAnalysisRelaxedFiltersPresenter({
          ...prevRelaxedFilter,
          isActive: isRelaxed,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newActiveFilter = new CompareAnalysisSavedFiltersPresenter({
          ...prevActiveFilter,
          companyFilters: appliedPayrollFilters,
          marketFilters: appliedMarketFilters,
          relaxedFilter: newRelaxedFilter,
        });
        const newAnalysis = new CompareAnalysisPresenter({
          ...analysisToBeUpdated,
          activeFilter: newActiveFilter,
        });

        const toUpdate = CompareAnalysisUpdateDTO.fromPresenter(newAnalysis);
        const analysis = await compareAnalysisApi.updateAnalysis(toUpdate);
        set({ analysis });

        reloadDashboard();
      },

      getAnalysis: (analysisId) =>
        new Promise<CompareAnalysisPresenter>((resolve, reject) => {
          compareAnalysisApi
            .get(analysisId)
            .then((analysis) => {
              set({ analysis });
              return resolve(analysis);
            })
            .catch(reject);
        }),

      getAll: async () => compareAnalysisApi.getAll(),

      renameAnalysis: async (newName, analysisId) => {
        const returnedNewName = await compareAnalysisApi.renameAnalysis(
          newName,
          analysisId
        );

        const analysis = new CompareAnalysisPresenter({
          ...get().analysis,
          name: returnedNewName,
        });

        set({ analysis });
      },

      deleteAnalysis: async (deleteId) =>
        compareAnalysisApi.deleteAnalysis(deleteId),

      changeFilter: async (newActiveFilterId) => {
        const { analysis, reloadDashboard } = get();

        if (newActiveFilterId) {
          await compareAnalysisApi.changeFilter(
            analysis.getId(),
            newActiveFilterId
          );
        }
        await reloadDashboard();
      },

      createFilter: async () => {
        const { analysis, changeFilter, createFilterByDto } = get();

        const newActiveFilterId = await createFilterByDto(
          analysis.getId(),
          analysis.getMarketFilters(),
          analysis.getCompanyFilters()
        );

        changeFilter(newActiveFilterId);
      },

      getAllSavedFilters: () => {
        const { getAllSavedFiltersByAnalysisId, analysis } = get();
        return getAllSavedFiltersByAnalysisId(analysis.getId());
      },

      renameFilter: async (filterId, newName) => {
        const { analysis } = get();
        return compareAnalysisSavedFiltersApi.renameFilter(
          filterId,
          analysis.getId(),
          newName
        );
      },
    }),
    { name: "CompareAnalysisStore" }
  )
);

export default useCompareAnalysisStore;
