import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import Stack from "@mui/material/Stack";
import FileUploader from "../FileUploader/FileUploader";
import PolicyConsentButton from "../PolicyConsentButton/PolicyConsentButton";
import GradusLoading from "../GradusComponents/GradusLoading";
import handlePayrollUpload from "./handlePayrollUpload";
import ExcelColumn from "../CreateMapping/ExcelColumn";

interface PayrollUploadProps {
  setComputedCompanySize: Dispatch<SetStateAction<number>>;
  setFile: Dispatch<SetStateAction<File | null>>;
  setColumns: Dispatch<SetStateAction<ExcelColumn[]>>;
  securityPolicyConsent: boolean;
  toggleSecurityPolicyConsent: () => void;
}

const PayrollUpload = ({
  setComputedCompanySize,
  setFile,
  setColumns,
  securityPolicyConsent,
  toggleSecurityPolicyConsent,
}: PayrollUploadProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = useCallback(
    (files: File[]) =>
      handlePayrollUpload(
        files,
        setFile,
        setIsLoading,
        setColumns,
        setComputedCompanySize
      ),
    [setColumns, setComputedCompanySize, setFile]
  );

  return (
    <Stack gap={1}>
      <FileUploader onUpload={handleUpload} />
      {isLoading && <GradusLoading />}
      <PolicyConsentButton
        checked={securityPolicyConsent}
        onClick={() => toggleSecurityPolicyConsent()}
      />
    </Stack>
  );
};

export default PayrollUpload;
