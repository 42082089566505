import { alpha } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import HistogramBarChart from "../HistogramBarChart";
import BaseChartContainer from "../BaseComponents/BaseChartContainer";
import AnalysisPresenter from "../../../dtos/AnalysisPresenter";
import useMetricsStore from "../../../stores/useMetricsStore";
import useChartStore from "../../../stores/ChartStore/useChartStore";
import Chart from "../Chart";
import ChartHeader from "./ChartHeader";
import useChartLoadingStore from "../../../stores/ChartLoadingStore/useChartLoadingStore";

export default function EmployeesBySalaryRangeMarket({
  isCompare,
  title,
  analysis,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { marketMetrics, companyMetrics } = useMetricsStore((state) => ({
    marketMetrics: state.marketMetrics,
    companyMetrics: state.companyMetrics,
  }));

  const { [Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET]: isLoading } =
    useChartLoadingStore();

  const { getChart, maxX, maxY, charts } = useChartStore((state) => ({
    getChart: state.getChart,
    maxX: state.maxX,
    maxY: state.maxY,
    charts: state.charts,
  }));

  const referenceLines = [
    {
      color: alpha(theme.palette.grey[700], 0.5),
      value: _.isNumber(marketMetrics.average) ? marketMetrics.average : 0,
      label: t("CHARTS_DASHBOARD.MARKET_AVERAGE"),
      id: "MED01",
      inactive: false,
      canHide: true,
      order: 2,
      position: "inside",
    },
  ];

  if (isCompare) {
    referenceLines.push({
      color: theme.palette.primary.main,
      value: _.isNumber(companyMetrics.average) ? companyMetrics.average : 0,
      label: t("CHARTS_DASHBOARD.COMPANY_AVERAGE"),
      id: "MED02",
      inactive: true,
      canHide: true,
      order: 3,
      position: "insideTop",
    });
  }

  return (
    <BaseChartContainer size={6}>
      <ChartHeader
        title={title}
        analysis={analysis}
        chart={Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET}
        isCompare={false}
      />
      <HistogramBarChart
        isHistogramLoading={isLoading}
        max={{ x: maxX, y: maxY }}
        referenceLines={referenceLines}
        bars={[
          {
            color: alpha(theme.palette.grey[700], 0.5),
            label: t("CHARTS_DASHBOARD.EMPLOYEE_PERCENTAGE_BY_SALARY_RANGE"),
            id: "ID01",
            inactive: false,
            canHide: false,
            order: 1,
          },
        ]}
        histogram={getChart(charts, Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET)}
        legendHeight={50}
      />
    </BaseChartContainer>
  );
}

EmployeesBySalaryRangeMarket.propTypes = {
  isCompare: PropTypes.bool,
  title: PropTypes.string.isRequired,
  analysis: PropTypes.objectOf(AnalysisPresenter).isRequired,
};

EmployeesBySalaryRangeMarket.defaultProps = {
  isCompare: false,
};
