/* eslint-disable prefer-regex-literals */
import { useMemo } from "react";
import _ from "lodash";

function normalizeText(text) {
  if (typeof text === "string") {
    return _.deburr(text).toLowerCase();
  }
  return text;
}

export function getForbiddenParts({ name, username } = {}) {
  return _(username?.split?.(/\./) || [])
    .concat(name?.split?.(/\s/) || [])
    .map(normalizeText)
    .filter((string) => string.length >= 3)
    .uniq()
    .value();
}

function hasSequence(password) {
  // prettier-ignore
  return [
    // raising
    '012', '123', '234', '345', '456', '567', '678', '789', '890',
    // decreasing
    '098', '987', '876', '765', '654', '543', '432', '321', '210'
  ].some((string) => password.includes(string));
}

export const validators = {
  hasMinimumCharactersLength({ newPassword }) {
    if (newPassword) {
      return newPassword.length >= 8;
    }
    return false;
  },
  hasAtLeastOneNumber({ newPassword }) {
    const pattern = new RegExp("(?=.*?[0-9])");
    return pattern.test(newPassword);
  },
  hasAtLeastOneUpperCase({ newPassword }) {
    if (!newPassword) {
      return false;
    }

    const pattern = new RegExp("(?=.*?[A-Z])");
    return pattern.test(newPassword);
  },
  hasAtLeastOneLowerCase({ newPassword }) {
    if (!newPassword) {
      return false;
    }

    const pattern = new RegExp("(?=.*?[a-z])");
    return pattern.test(newPassword);
  },
  hasAtLeastOneSpecialCharacter({ newPassword }) {
    const pattern = new RegExp("(?=.*?[^0-9a-zA-Z]+)");
    return pattern.test(newPassword);
  },
  passwordEqualsConfirmation({ newPassword, passwordConfirmation }) {
    return newPassword && newPassword === passwordConfirmation;
  },
  hasNotUserDetails({ newPassword, user }) {
    if (!user || !newPassword) {
      return false;
    }

    const normalizedPassword = normalizeText(newPassword);
    return !getForbiddenParts(user).some((forbiddenPart) =>
      normalizedPassword.includes(forbiddenPart)
    );
  },
  hasNoSequences({ newPassword }) {
    if (!newPassword) {
      return false;
    }

    return !hasSequence(newPassword);
  },
};

export const errorTranslationKeys = {
  hasMinimumCharactersLength: "HAS_MINIMUM_CHARACTERS_LENGTH",
  hasAtLeastOneNumber: "HAS_AT_LEAST_ONE_NUMBER",
  hasAtLeastOneUpperCase: "HAS_AT_LEAST_ONE_UPPER_CASE",
  hasAtLeastOneLowerCase: "HAS_AT_LEAST_ONE_LOWER_CASE",
  hasAtLeastOneSpecialCharacter: "HAS_AT_LEAST_ONE_SPECIAL_CHARACTER",
  passwordEqualsConfirmation: "PASSWORD_EQUALS_CONFIRMATION",
  hasNotUserDetails: "HAS_NOT_USER_DETAILS",
  hasNoSequences: "HAS_NO_SEQUENCES",
};

export function useNewPasswordErrors({
  user,
  newPassword,
  passwordConfirmation,
}) {
  return useMemo(
    () =>
      Object.entries(validators).map(([validatorName, isValid]) => [
        validatorName,
        Boolean(isValid({ user, newPassword, passwordConfirmation })),
      ]),
    [user, newPassword, passwordConfirmation]
  );
}
