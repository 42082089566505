import React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useGradusTableStore } from "./GradusTableStore";

export default function CollapsableTableHeader({
  headers,
  storeName,
  hasCollapse,
  hasCheckbox,
}) {
  const { checkAllRows } = useGradusTableStore(storeName);

  return (
    <TableHead>
      <TableRow>
        {hasCheckbox && (
          <TableCell
            sx={{
              fontWeight: "bold",
              backgroundColor: "#456bb0",
              color: "#FFF",
              height: "35px",
            }}
            align="center"
          >
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "white",
                },
              }}
              onChange={(_event, checked) => checkAllRows(checked)}
            />
          </TableCell>
        )}
        {hasCollapse && (
          <TableCell
            sx={{
              fontWeight: "bold",
              backgroundColor: "#456bb0",
              color: "#FFF",
              height: "35px",
            }}
          />
        )}
        {headers}
      </TableRow>
    </TableHead>
  );
}

CollapsableTableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  storeName: PropTypes.string.isRequired,
  hasCollapse: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
};

CollapsableTableHeader.defaultProps = {
  hasCollapse: false,
  hasCheckbox: false,
};
