import styled from "@emotion/styled";

const PrivacyPolicyPage = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamily};
  justify-content: center;
  padding-right: 20%;
  padding-left: 20%;
  padding-bottom: 20vh;
  margin: auto;

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  h1 {
    font-size: 40pt;
    text-align: center;
  }

  h2 {
    font-size: 20pt;
  }

  h3 {
    font-size: 16pt;
  }

  p {
    font-size: 16px;
    text-align: justify;
    line-height: 24px;
    margin: 0;
    text-indent: 20pt;
  }

  li {
    text-align: justify;
    font-size: 16px;
    line-height: 24px;
  }
`;

export default PrivacyPolicyPage;
