import React, { useCallback } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import DroppableColumn from "./DroppableColumn";
import {
  selectColumnsFont,
  selectColumnsFontBackground,
} from "./TableConfigurationModal.style";
import { useGradusTableStore } from "../GradusTableStore";
import GradusTableColumn from "../GradusTableColumn";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return _.chain(result)
    .uniqBy("field")
    .map(
      (column, order) =>
        new GradusTableColumn({
          ...column,
          order: column.isFixed ? column.order : order,
        })
    )
    .value();
};

const sortColumns = (fixedColumns, notFixedColumns) => {
  let columnIndex = 0;
  const newColumns = [];
  const newOrderNotFixedColumns = notFixedColumns.map(
    (value, order) =>
      new GradusTableColumn({
        ...value,
        order,
      })
  );
  fixedColumns
    .map((column) => column.order)
    .sort()
    .forEach((order) => {
      while (columnIndex < order && !_.isEmpty(newOrderNotFixedColumns)) {
        const element = newOrderNotFixedColumns.shift();
        newColumns.push(
          new GradusTableColumn({ ...element, order: columnIndex })
        );
        columnIndex += 1;
      }

      newColumns.push(fixedColumns.shift());
      columnIndex += 1;
    });

  return _.sortBy(
    _.isEmpty(newColumns) ? newOrderNotFixedColumns : newColumns,
    "order"
  );
};

export default function TableConfigurationModal({ storeName }) {
  const { t } = useTranslation();
  const { columns, setColumns, hiddenColumns, setHiddenColumns } =
    useGradusTableStore(storeName, (state) => ({
      columns: state.columns,
      setColumns: state.setColumns,
      hiddenColumns: state.hiddenColumns,
      setHiddenColumns: state.setHiddenColumns,
    }));

  const getList = useCallback(
    (sourceDroppableId) =>
      sourceDroppableId === t("CHOOSE_COLUMNS_MODAL.SECTION_VISIBLE_INFO")
        ? _.compact(columns)
        : hiddenColumns,
    [hiddenColumns, columns, t]
  );

  function addToDestinationList(destination, result, movedElement) {
    destination.splice(result.destination.index, 0, movedElement);
    return [...destination];
  }

  function updateShowColumns(result) {
    const source = getList(result.source.droppableId);
    const destination = getList(result.destination.droppableId);
    const [fixedColumnsSource, notFixedColumnsSource] = _.partition(
      source,
      "isFixed"
    );
    const [fixedColumnsDestination, notFixedColumnsDestination] = _.partition(
      destination,
      "isFixed"
    );

    const movedElement = notFixedColumnsSource[result.source.index];
    _.pullAt(notFixedColumnsSource, [result.source.index]);
    const newSource = sortColumns(fixedColumnsSource, notFixedColumnsSource);
    const newDestinationList = sortColumns(
      fixedColumnsDestination,
      addToDestinationList(notFixedColumnsDestination, result, movedElement)
    );

    if (
      result.destination.droppableId ===
      t("CHOOSE_COLUMNS_MODAL.SECTION_VISIBLE_INFO")
    ) {
      setColumns(_.compact(newDestinationList));
      setHiddenColumns(newSource);
    } else {
      setColumns(newSource);
      setHiddenColumns(newDestinationList);
    }
  }

  function updateOrder(result) {
    const source = getList(result.source.droppableId);
    const [fixedColumns, notFixedColumns] = _.partition(source, "isFixed");
    const newOrderedNotFixedColumns = reorder(
      notFixedColumns,
      result.source.index,
      result.destination.index
    );
    const consolidated = sortColumns(fixedColumns, newOrderedNotFixedColumns);

    if (
      result.source.droppableId ===
      t("CHOOSE_COLUMNS_MODAL.SECTION_INVISIBLE_INFO")
    ) {
      setHiddenColumns(consolidated);
    } else {
      setColumns(consolidated);
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const isJustChangingOrder =
      result.source.droppableId === result.destination.droppableId;
    if (isJustChangingOrder) {
      updateOrder(result);
    } else {
      updateShowColumns(result);
    }
  };

  return (
    <Container sx={{ fontFamily: "Nunito Sans, sans serif" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container direction="row" width="100%" gap={2} pt={1} pb={2}>
          <DroppableColumn
            elements={columns}
            className={selectColumnsFontBackground}
            columnHeader={t("CHOOSE_COLUMNS_MODAL.SECTION_VISIBLE_INFO")}
          />
          <DroppableColumn
            className={selectColumnsFont}
            elements={hiddenColumns}
            columnHeader={t("CHOOSE_COLUMNS_MODAL.SECTION_INVISIBLE_INFO")}
            isDropDisabled={
              columns.filter((column) => !column.isFixed).length <= 1
            }
          />
        </Grid>
      </DragDropContext>
    </Container>
  );
}

TableConfigurationModal.propTypes = {
  storeName: PropTypes.string.isRequired,
};
