import { ReactNode, useEffect } from "react";
import useSystemConfigurationsStore from "../stores/useSystemConfigurationsStore";

function SystemInitializationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { getConfigurations } = useSystemConfigurationsStore((state) => ({
    getConfigurations: state.getConfigurations,
  }));

  useEffect(() => {
    getConfigurations();
  }, [getConfigurations]);

  return children;
}

export default SystemInitializationContextProvider;
