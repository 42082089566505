import { StateCreator } from "zustand";
import Chart, { getAll } from "../../components/Charts/Chart";
import ChartParameters from "./ChartParameters";
import { ChartParametersSliceState, ChartState } from "./types";

const initialChartParametersSliceState = getAll().reduce((acc, chart) => {
  acc[chart] = {
    applied: new ChartParameters({}),
    selected: new ChartParameters({}),
  };
  return acc;
}, {} as Record<Chart, ChartState<ChartParameters>>);

const createChartParametersSlice: StateCreator<ChartParametersSliceState> = (
  set,
  get
) => ({
  ...initialChartParametersSliceState,

  updateSelected: (chart: Chart, newParameters: Partial<ChartParameters>) => {
    const chartState = get()[chart];
    const selected = new ChartParameters({
      ...chartState.selected,
      ...newParameters,
    });

    set({
      [chart]: {
        ...chartState,
        selected,
      },
    });
  },

  applySelected: (chart: Chart) => {
    const { selected } = get()[chart];
    set({
      [chart]: {
        applied: new ChartParameters({ ...selected }),
        selected,
      },
    });
  },

  resetSelected: (chart: Chart) => {
    const chartParameters = get()[chart];

    set({
      [chart]: {
        applied: chartParameters.applied,
        selected: new ChartParameters({}),
      },
    });
  },

  revertSelectedToApplied: (chart: Chart) => {
    const { applied } = get()[chart];
    set({
      [chart]: {
        applied,
        selected: new ChartParameters({ ...applied }),
      },
    });
  },
});

export { initialChartParametersSliceState };

export default createChartParametersSlice;
