import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";

const fontFamily = [
  "Nunito Sans",
  "Roboto",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

const GRADUS_BLUE = "#456bb0";
const GRADUS_GREEN = "#9ccf69";
const GRADUS_GREY = "#353639";

const theme = createTheme({
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: alpha(GRADUS_BLUE, 0.5),
          },
          "&:hover": {
            backgroundColor: alpha(GRADUS_BLUE, 0.1),
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: alpha(GRADUS_BLUE, 0.5),
          },
          "&:hover": {
            backgroundColor: alpha(GRADUS_BLUE, 0.1),
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 4,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCollapse: {
      defaultProps: {
        easing: 3000,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
  },
  palette: {
    grey: {
      700: GRADUS_GREY,
    },
    primary: { main: GRADUS_BLUE },
    secondary: { main: GRADUS_GREEN },
    warning: { main: "#EEA31F" },
    indigo: {
      300: "#7986cb",
      500: "#3f51b5",
    },
  },
  typography: {
    fontFamily,
    h1: {
      fontWeight: "bold",
      fontSize: "40pt",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "20pt",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "16pt",
    },
    body1: {
      fontSize: "16px",
      color: GRADUS_GREY,
      fontFamily,
    },
  },
});

export default theme;
