import api from "./api";
import CompareAnalysisSavedFiltersPresenter from "../dtos/CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersPresenter";
import CompareAnalysisSavedFiltersCreateDTO from "../dtos/CompareAnalysisSavedFilters/CompareAnalysisSavedFiltersCreateDTO";

const BASE_URL = "/api/v1/compare-analysis-saved-filters";

const compareAnalysisSavedFiltersApi = {
  getAllByAnalysisId: (
    compareAnalysisId: number
  ): Promise<CompareAnalysisSavedFiltersPresenter[]> =>
    new Promise((resolve, reject) => {
      api
        .get(`${BASE_URL}/all/${compareAnalysisId}`)
        .then((response) => {
          const options = response.data.map(
            (option: CompareAnalysisSavedFiltersPresenter) =>
              new CompareAnalysisSavedFiltersPresenter({ ...option })
          );
          resolve(options);
        })
        .catch(reject);
    }),

  getById: (id: number) =>
    new Promise<CompareAnalysisSavedFiltersPresenter>((resolve, reject) => {
      api
        .get(`${BASE_URL}/${id}`)
        .then((response) => {
          resolve(new CompareAnalysisSavedFiltersPresenter(response.data));
        })
        .catch(reject);
    }),

  createFilter: (dto: CompareAnalysisSavedFiltersCreateDTO): Promise<number> =>
    new Promise((resolve, reject) => {
      api
        .post(BASE_URL, dto)
        .then((response) => resolve(response.data))
        .catch(reject);
    }),

  renameFilter: (
    filterId: number,
    compareAnalysisId: number,
    newName: string
  ) => api.put(`${BASE_URL}/${compareAnalysisId}/rename/${filterId}`, newName),

  deleteFilter: (filterId: number) => api.delete(`${BASE_URL}/${filterId}`),
};

export default compareAnalysisSavedFiltersApi;
