import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./locales";

const i18nConfig = {
  resources,
  defaultNS: "common",
  fallbackLng: "pt",
};

i18next.use(initReactI18next).init(i18nConfig);

export default i18next;
