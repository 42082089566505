import BaseFilter from "../../dtos/BaseFilter";
import FilterOptionDTO from "../../dtos/FilterOptionDTO";
import { defaultSubchart } from "../../components/Charts/SalaryAverageByOccupation/Subchart";

export default class ChartParameters {
  capPercentile: number;

  shouldUseNewJobFilters: boolean;

  newJobFilters: BaseFilter;

  subchart: FilterOptionDTO;

  constructor({
    capPercentile,
    shouldUseNewJobFilters,
    newJobFilters,
    subchart,
  }: {
    capPercentile?: number;
    shouldUseNewJobFilters?: boolean;
    newJobFilters?: BaseFilter;
    subchart?: FilterOptionDTO;
  }) {
    this.capPercentile = capPercentile || 100;
    this.shouldUseNewJobFilters = shouldUseNewJobFilters || false;
    this.newJobFilters = newJobFilters || new BaseFilter({});
    this.subchart = subchart || defaultSubchart();
  }
}
