import IconButton from "@mui/material/IconButton";
import React, { MouseEventHandler } from "react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { SxProps } from "@mui/material";
import { DefaultTFuncReturn } from "i18next";
import GradusIcon from "./GradusIcon";

export default function GradusIconButton({
  onClick,
  title,
  icon,
  sx,
  disabled,
  ...props
}: {
  onClick: MouseEventHandler;
  title: string | DefaultTFuncReturn;
  icon: IconDefinition;
  disabled?: boolean;
  sx?: SxProps;
}) {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        fontSize: "20px",
        "&.Mui-disabled": {
          pointerEvents: "all",
        },
        ...sx,
      }}
      {...props}
      disabled={disabled}
    >
      <GradusIcon title={title} icon={icon} />
    </IconButton>
  );
}

GradusIconButton.defaultProps = {
  sx: {},
  disabled: false,
};
