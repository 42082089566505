import { Slider } from "@mui/material";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import FilterOptionDTO from "../../../../dtos/FilterOptionDTO";
import GradusTooltip from "../../../GradusComponents/GradusTooltip";
import CompanySize from "../../../../models/CompanySize";

type FilterNameById = { [key: number]: string };

function ValueLabelComponent({
  children,
  value,
  options,
}: {
  children: ReactElement;
  value: number;
  options: FilterOptionDTO[];
}) {
  const [title, setTitle] = useState<string>("");
  const [titles, setTitles] = useState<FilterNameById>({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (options.length > 0) {
      const mappedTitles = _.reduce(
        options,
        (acc: FilterNameById, option: FilterOptionDTO) => {
          acc[option.id] = t(option.name, { lng: i18n.language });
          return acc;
        },
        {}
      );
      setTitles(mappedTitles);
    }
  }, [options, t, i18n.language]);

  useEffect(() => {
    setTitle(_.has(titles, value) ? titles[value] : "");
  }, [value, titles]);

  return (
    <GradusTooltip enterTouchDelay={0} placement="top" title={title}>
      {children}
    </GradusTooltip>
  );
}

export default function CompanySizeFilter({
  options,
  companySizes,
  setCompanySizes,
}: {
  options: FilterOptionDTO[];
  companySizes: FilterOptionDTO[];
  setCompanySizes: (values: FilterOptionDTO[]) => void;
}) {
  const [rangeSelected, setRangeSelected] = useState<number[]>([1, 10]);

  useEffect(() => {
    if (_.isEmpty(companySizes)) {
      setRangeSelected([1, 10]);
    }
    const ids = companySizes.map((filterOption) => filterOption.id);
    const min = _.min(ids) || CompanySize.getMinimum().id;
    const max = _.max(ids) || CompanySize.getMaximum().id;
    setRangeSelected([min, max]);
  }, [companySizes]);

  const handleOnMouseUp = () => {
    let newCompanySizes: FilterOptionDTO[] = [];
    const min = CompanySize.getMinimum().id;
    const max = CompanySize.getMaximum().id;

    if (!rangeSelected.includes(min) || !rangeSelected.includes(max)) {
      newCompanySizes = options.filter((filterOption) => {
        if (_.isArray(rangeSelected)) {
          return rangeSelected.includes(filterOption.id);
        }
        return _.isEqual(rangeSelected, filterOption.id);
      });
    }

    setCompanySizes(newCompanySizes);
  };

  const marks = useMemo(
    () =>
      options.map(({ id }) => ({
        value: id,
      })),
    [options]
  );

  const { t } = useTranslation();

  return (
    <Container disableGutters>
      <Typography variant="h6">{t("COMPANY_SIZE")}</Typography>
      <Slider
        value={rangeSelected}
        min={1}
        max={10}
        step={1}
        onMouseUp={handleOnMouseUp}
        onChange={(_event, companySizesIds) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setRangeSelected(companySizesIds)
        }
        marks={marks}
        valueLabelDisplay="on"
        components={{
          ValueLabel: ValueLabelComponent,
        }}
        slotProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          valueLabel: { options },
        }}
        sx={{ mx: 2, width: "70%" }}
      />
    </Container>
  );
}
