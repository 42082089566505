import PropTypes from "prop-types";
import React from "react";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GradusModal from "../../GradusComponents/GradusModal/GradusModal";
import CompareAnalysisTableWithSearch from "./CompareAnalysisTableWithSearch";
import ConsulteAnalysisTableWithSearch from "./ConsulteAnalysisTableWithSearch";

export default function ListAnalysisModal({ handleClose, open, isCompare }) {
  const { t } = useTranslation();
  const titleTranslationKey = isCompare
    ? "LIST_ANALYSIS_MODAL.COMPARE_TITLE"
    : "LIST_ANALYSIS_MODAL.CONSULT_TITLE";

  return (
    <GradusModal
      handleClose={handleClose}
      open={open}
      containerStyle={{ width: "90%" }}
    >
      <Typography variant="h5">{t(titleTranslationKey)}</Typography>

      <Divider sx={{ mb: 2, mt: 2 }} />

      {isCompare ? (
        <CompareAnalysisTableWithSearch handleClose={handleClose} open={open} />
      ) : (
        <ConsulteAnalysisTableWithSearch
          handleClose={handleClose}
          open={open}
        />
      )}
    </GradusModal>
  );
}

ListAnalysisModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isCompare: PropTypes.bool,
};

ListAnalysisModal.defaultProps = {
  isCompare: false,
};
