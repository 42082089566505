import { StateCreator } from "zustand/esm";
import GlobalParameters from "./GlobalParameters";
import { GlobalParametersSliceState } from "./types";

const initialGlobalParametersSliceState = {
  globalParameters: {
    applied: new GlobalParameters({}),
    selected: new GlobalParameters({}),
  },
};

const createGlobalParametersSlice: StateCreator<GlobalParametersSliceState> = (
  set,
  get
) => ({
  ...initialGlobalParametersSliceState,

  updateGlobalSelected: (newParameters: Partial<GlobalParameters>) => {
    const globalParametersState = get().globalParameters;
    const selected = new GlobalParameters({
      ...globalParametersState.selected,
      ...newParameters,
    });

    set({
      globalParameters: {
        ...globalParametersState,
        selected,
      },
    });
  },

  applyGlobalSelected: () => {
    const { selected } = get().globalParameters;
    const newGlobalParameters = {
      applied: GlobalParameters.from(selected),
      selected,
    };
    set({
      globalParameters: newGlobalParameters,
    });
    return newGlobalParameters;
  },

  resetGlobalSelected: () => {
    const { globalParameters } = get();
    const newGlobalParameters = {
      ...globalParameters,
      selected: new GlobalParameters({}),
    };
    set({
      globalParameters: newGlobalParameters,
    });
    return newGlobalParameters;
  },

  revertGlobalSelectedToApplied: () => {
    const { applied } = get().globalParameters;
    const newGlobalParameters = {
      selected: GlobalParameters.from(applied),
      applied,
    };
    set({
      globalParameters: newGlobalParameters,
    });
  },
});

export { initialGlobalParametersSliceState };

export default createGlobalParametersSlice;
