export default {
  common: {
    FOOTER: "©{{year}} Gradus Tech - Todos los derechos reservados. ",
    CHANGE_PASSWORD: "Cambiar contraseña",
    LOGOUT: "Cerrar sesión",
    EXIT: "Cerrar",
    CANCEL: "Cancelar",
    APPLY: "Aplicar",
    NAME: "Nombre",
    MARKET_FILTERS: "Filtros de mercado",
    COMPANY_FILTERS: "Filtros de empresa",
    MY_FILTERS: "Mis filtros",
    ACTIONS: "Acciones",
    HOME: "Home",
    CREATE: "Crear",
    SALARY: "Salario",
    OCCUPATION_CBO: "Ocupación (CBO)",
    COMPANY_SIZE_LABEL: "Tamaño de la empresa",
    COMPANY_CNAES: "CNAEs de la empresa",
    COUNTY: "Municipio",
    STATE: "Estado",
    REGION: "Región",
    INTERMEDIATE_GEOGRAPHIC_REGION: "Región Geográfica Intermedia",
    INTERMEDIATE_REGION: "Región intermedia",
    IMMEDIATE_GEOGRAPHIC_REGION: "Región Geográfica Inmediata",
    IMMEDIATE_REGION: "Región inmediata",
    GEOGRAPHY: "Geografía",
    CUSTOM_COLUMN: "Columna personalizada",
    CUSTOM_COLUMNS: "Columnas personalizadas",
    CREATE_ANALYSIS: "Crear análisis",
    NEW_ANALYSIS: "Nuevo análisis",
    ANALYSIS: "Todos análisis",
    ANALYSIS_NAME: "Nombre del análisis",
    COMPARE: "Compare",
    CONSULT: "Consulte",
    PAYROLL: "Nómina de sueldos",
    EXAMPLE: "ejemplo",
    MAPPING: "Mapeo",
    SELECT_COLUMN: "Seleccionar columna",
    CREATE_MAPPING: "Crear mapeo",
    APPLY_MAPPING: "Aplicar mapeo",
    EDIT_MAPPING: "Editar mapeo",
    DUPLICATE_MAPPING: "Duplicar mapeo",
    REMOVE_MAPPING: "Eliminar mapeo",
    HIDE_MAPPING: "Esconder mapeo",
    SHOW_MAPPING: "Mostrar mapeo",
    MAPPING_NAME: "Nombre del mapeo",
    FILL_IN_THE_PREVIOUS_FIELDS_BEFORE_CONTINUING:
      "Complete los campos anteriores antes de continuar",
    UPLOAD_FIELD_PLACEHOLDER: "Cargue o arrastre su archivo aquí",
    NO_MAPPINGS_HAVE_BEEN_FOUND: "No se encontró ningún mapeo",
    NO_OPTIONS: "Ninguna opción",
    SELECT_ALL: "Seleccionar todo",
    LOADING: "Cargando",
    COMPANY: "Empresa",
    MARKET: "Mercado",
    DOWNLOAD_REPORT: "Descargar informe",
    DOWNLOAD_OCCUPATIONS_REPORT: "Descargar informe de ocupación completo",
    DOWNLOAD_RAIS_RAW_CSV: "ES-Baixar CSV dos dados filtrados",
    PROCESSING_THE_REPORT: "Procesando el informe",
    NO_RESULTS: "Sin resultados",
    PERCENTAGE: "Porcentaje",
    OPTIONS: "Opciones",
    CHART_OPTIONS: "Opciones de gráfico",
    TOTAL_ESTIMATE_COST: "Costo total estimado",
    EMPLOYEES: "Empleados",
    SALARY_AVERAGE: "Salario promedio",
    TOTAL_COST: "Costo total",
    DIFFERENCE_IN_RELATION_TO_MARKET: "Diferencia en relación al mercado",
    SIZE: "Tamaño",
    METRICS: "Métricas",
    CHARTS: "Gráficos",
    REMUNERATION_AVERAGE: "Remuneración media",
    "10TH_PERCENTILE": "Percentil 10",
    REMUNERATION_MEDIAN: "Mediana de remuneración",
    "90TH_PERCENTILE": "Percentil 90",
    EMPLOYEE_TOTAL_QUANTITY: "Número total de empleados",
    BACK_TO_DEFAULTS: "Volver al patrón",
    BUY_COMPARE_MODULE:
      "Obtenga el módulo de comparación ahora y obtenga un análisis completo de su atractivo salarial en comparación con el mercado. Contáctenos por teléfono (11) 3167-2542.",
    BUY_CONSULT_MODULE:
      "Obtenga el módulo de consulta ahora y obtenga un análisis completo de su atractivo salarial en comparación con el mercado. Contáctenos por teléfono (11) 3167-2542.",
    SAVED_FILTERS: "Filtros guardados",
    NEW_FILTER: "Nuevo filtro",
    OPEN: "Abrir",
    RENAME: "Rebautizar",
    DELETE: "Eliminar",
    APPLY_AND_SAVE: "Aplicar y guardar",
    RELAX_AND_SAVE: "Relajarse y guardar",
    CLEAR: "Limpio",
    LARGE_GROUP: "Gran grupo",
    PRIMARY_SUBGROUP: "Subgrupo principal",
    SUBGROUP: "Subgrupo",
    FAMILY: "Familia",
    OCCUPATION: "Ocupación",
    ROLE: "Cargo",
    JOB: "Cargo",
    COMPANY_SECTOR: "Sector de la empresa",
    COMPANY_SECTORS: "Sectores de la empresa",
    SECTION: "Sección",
    DIVISION: "División",
    GROUP: "Grupo",
    CLASS: "Clase",
    SUBCLASS: "Subclase",
    COMPANY_SIZE: "Tamaño de la empresa",
    GRADUS_LOGO_ALT: "Logo de Gradus Tech",
    PASSWORD: "Contraseña",
    ACCESS: "Acceder",
    REDEFINE_PASSWORD_FIRST_ACCESS: "Restablecer contraseña/Primer acceso",
    USER: "Usuario",
    REDEFINE_PASSWORD: "Restablecer contraseña",
    UPDATE_PASSWORD: "Actualizar contraseña",
    NEW_PASSWORD: "Nueva contraseña",
    REPEAT_NEW_PASSWORD: "Repita la nueva contraseña",
    GO_BACK: "Volver",
    NO_FILTER_APPLIED: "Ningún filtro aplicado.",
    GEOGRAPHIC_DATA: "Datos geográficos",
    CREATE_NEW_FILTER: "Crear nuevo filtro",
    SYSTEM_FIELD: "Campo del sistema",
    SPREADSHEET_FIELD: "Columna de la pantaja",
    ACCEPT: "Aceptar",
    FONT_RAIS: "Fuente: última actualización de RAIS ({{yearRais}})",
    APPLY_FILTERS: "Aplicar filtros",
    DUPLICATE: "Duplicar",
    OPTIONAL: "Opcional",
    VALUE: "Valor",
    PAYROLL_EXAMPLE: "Ejemplo de nómina",
    EDIT: "Editar",
    SAVE: "Guardar",
    ALL: "Todos",
    DOWNLOAD_TEMPLATE: "Descargar template",
    ALERTS: {
      SUCCESSFULLY_UPDATED_PASSWORD: "Contraseña actualizada con éxito",
      SUCCESSFULLY_REMOVED_ANALYSIS: "Análisis eliminado con éxito",
      SUCCESSFULLY_RENAMED_ANALYSIS: "Análisis renombrado con éxito",
      SUCCESSFULLY_CREATED_ANALYSIS: "Análisis creado con éxito",
      SUCCESSFULLY_SAVED_ANALYSIS: "Análisis guardado con éxito",
      SUCCESSFULLY_CREATED_MAPPING: "Mapeo creado con éxito",
      SUCCESSFULLY_REMOVED_MAPPING: "Mapeo eliminado con éxito",
      SUCCESSFULLY_DUPLICATED_MAPPING: "Mapeo duplicado con éxito",
      SUCCESSFULLY_EDITED_MAPPING: "Mapeo editado con éxito",
      GENERATING_REPORT:
        "¡Solicitud enviada! La generación del informe puede tardar unos minutos, según sus filtros.",
      GENERATING_TEMPLATE: "Template generado con éxito",
      SUCCESSFULLY_CHANGED_METRICS: "Métricas actualizadas con éxito",
      SUCCESSFULLY_CHANGED_CHARTS: "Gráficos actualizados con éxito",
      SUCCESSFULLY_APPLIED_PARAMETERS: "Parámetros actualizados con éxito",
      SUCCESSFULLY_APPLIED_FILTER: "Filtro aplicado con éxito",
      SUCCESSFULLY_REMOVED_FILTER: "Filtro eliminado con éxito",
      SUCCESSFULLY_RENAMED_FILTER: "Filtro renombrado con éxito",
      SUCCESSFULLY_CREATED_FILTER: "Filtro creado con éxito",
      SUCCESSFULLY_COPIED_FILTER: "Filtros copiados con éxito",
      USERNAME_OR_EMAIL_NOT_FOUND:
        "Nombre de usuario o correo electrónico no encontrado",
      LINK_FOR_PASSWORD_RESET_MESSAGE:
        "Se enviará un enlace de restablecimiento de contraseña al correo electrónico registrado. Espere unos momentos para recibir el mensaje con las instrucciones del procedimiento. Si no ha recibido el correo electrónico, verifique también su carpeta de correo no deseado.",
      UNKNOWN_ERROR_PLEASE_CONTACT_SUPPORT:
        "Hubo un problema al realizar la solicitud al servidor. Error inesperado, póngase en contacto con el soporte.",
      DENIED_ACCESS: "Acceso denegado",
      SUCCESSFULLY_APPLIED_OPTIONS: "Opciones aplicadas con éxito",
    },
    PASSWORD_MODAL: {
      CURRENT_PASSWORD: "Contraseña actual",
      YOUR_PASSWORD_MUST_CONTAIN_AT_LEAST:
        "Su contraseña debe contener al menos",
    },
    PASSWORD_REQUIREMENTS: {
      HAS_MINIMUM_CHARACTERS_LENGTH: "8 caracteres",
      HAS_AT_LEAST_ONE_NUMBER: "1 número",
      HAS_AT_LEAST_ONE_UPPER_CASE: "1 caractere capital",
      HAS_AT_LEAST_ONE_LOWER_CASE: "1 caractere diminuto",
      HAS_AT_LEAST_ONE_SPECIAL_CHARACTER: "1 caractere especial",
      PASSWORD_EQUALS_CONFIRMATION: "Confirmación de contraseña",
      HAS_NOT_USER_DETAILS: "Ausencia de nombre e de login",
      HAS_NO_SEQUENCES: "No debe tener secuencias",
    },
    HOME_PAGE: {
      COMPARE_TEXT:
        "Realiza un análisis completo de tu atractivo salarial frente al mercado, en base a la información de tu nómina de sueldos.",
      CONSULT_TEXT:
        "Filtra y consulta los salarios del mercado según tus necesidades.",
    },
    LIST_ANALYSIS_MODAL: {
      COMPARE_TITLE: "Análisis - Compare",
      CONSULT_TITLE: "Análisis - Consulte",
      NO_ANALYSIS_FOUND: "No se encontró ningún análisis",
      SEARCH_BAR_PLACEHOLDER: "Ex.: Análisis 1",
    },
    COMPARE_CREATE_ANALYSIS_PAGE: {
      CREATE_AN_UNIQUE_NAME:
        "Cree un nombre único para identificar su análisis.",
      PAYROLL_UPLOAD_TEXT_1:
        "En este paso ingresará su nómina y creará un mapeo para definir los parámetros del análisis o seleccionará un mapeo ya guardado previamente.",
      PAYROLL_UPLOAD_TEXT_2:
        "De acuerdo con nuestra <0>política de seguridad de la información</0>, solicitamos que datos como nombre, CPF, dirección, sexo, grupo de edad y cualquier información confidencial que identifique al empleado se excluyan de la hoja de cálculo antes de cargarla.",
      POLICY_CONSENT_BUTTON:
        "Declaro que he leído la <0>política de seguridad de la información</0> y que estoy al tanto de los datos que deben o no enviarse al sistema.",
      SELECT_MAPPING_TEXT:
        "Seleccione lo mapeo deseada para aplicar a la nómina ingresada, siempre que tenga las mismas columnas que la asignación guardada anteriormente.",
      SELECT_MAPPING_SEARCH_PLACEHOLDER: "Ex.: Mapeo 1",
    },
    MAPPINGS_TABLE: {
      IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_MAPPING:
        "No se pueden eliminar los mapeos que se utilizan en alguno análisis",
      IT_IS_NOT_POSSIBLE_TO_EDIT_AN_ALREADY_USED_MAPPING:
        "No se pueden editar los mapeos que se utilizan en alguno análisis",
      IT_IS_NOT_POSSIBLE_TO_EDIT_SOME_FIELDS_OF_AN_ALREADY_USED_MAPPING:
        "No se pueden editar campos de hoja en mapeos que se utilizan en algunos análisis",
    },
    CREATE_MAPPING_MODAL: {
      DESCRIPTION:
        "El mapeo es una configuración que vincula sus campos de nómina a los campos del sistema.",
      CBO_EXPLANATION:
        "Clasificación brasileña de ocupaciones. Es el número de registro que se utiliza como referencia para los registros administrativos de actividades.",
    },
    COMPANY_SIZE_ENUM: {
      NO_EMPLOYEES: "Ningún empleado",
      UPTO_4: "Hasta 4 empleados",
      FROM_5_TO_9: "De 5 a 9 empleados",
      FROM_10_TO_19: "De 10 a 19 empleados",
      FROM_20_TO_49: "De 20 a 49 empleados",
      FROM_50_TO_99: "De 50 a 99 empleados",
      FROM_100_TO_249: "De 100 a 249 empleados",
      FROM_250_TO_499: "De 250 a 499 empleados",
      FROM_500_TO_999: "De 500 a 999 empleados",
      FROM_1000: "A partir de 1000 empleados",
    },
    METRICS_BOARD: {
      MONETARY_VALUES_TOOLTIP:
        "Todos los valores referentes a la remuneración están en R$ mil",
      ABOVE_AVERAGE:
        "La cesta en cuestión está <0>{{diff}} por encima de la media del mercado</0> para una cesta similar en la muestra seleccionada.",
      BELOW_AVERAGE:
        "La cesta en cuestión está <0>{{diff}} por debajo de la media del mercado</0> para una cesta similar en la muestra seleccionada.",
      AT_AVERAGE:
        "La cesta en cuestión es <0>igual a la media del mercado</0> para una cesta similar en la muestra seleccionada.",
      "10TH_PERCENTILE_TOOLTIP":
        "El 10% de los profesionales tienen un salario inferior o igual a esta cuantía, mientras que el 90% restante percibe una remuneración igual o superior",
      MEDIAN_TOOLTIP:
        "La mitad de los profesionales tienen un salario inferior o igual a esta cantidad, mientras que la otra mitad percibe un salario igual o superior",
      "90TH_PERCENTILE_TOOLTIP":
        "El 90% de los profesionales tienen un salario inferior o igual a esta cuantía, mientras que el 10% restante percibe una remuneración igual o superior",
    },
    DASHBOARD_HEADER: {
      APPLIED_FILTERS: "Filtros aplicados",
      EDIT_OR_CREATE_NEW_FILTERS: "Editar o crear nuevos filtros",
      CUSTOMIZE_INFORMATIONS: "Personalizar información",
      SAVE_ANALYSIS: "Guardar análisis",
      NO_MODIFICATIONS_TO_SAVE: "No hay cambios para guardar",
      RENAME_ANALYSIS: "Renombrar análisis",
      ENTER_PRESENTATION_MODE: "Modo de presentación",
      EXIT_PRESENTATION_MODE: "Salir del modo de presentación",
    },
    CHARTS_DASHBOARD: {
      MARKET_AVERAGE: "Promedio - Mercado",
      COMPANY_AVERAGE: "Promedio - Empresa",
      EMPLOYEE_PERCENTAGE_BY_SALARY_RANGE: "% de empleados por rango salarial",
      SALARY_RANGE: "Rango salarial (R$ mil)",
      AVERAGE_SALARY: "Salario promedio (R$ mil)",
      EMPLOYEES_BY_SALARY_RANGE_COMPANY:
        "Empleados por rango salarial - Empresa",
      EMPLOYEES_BY_SALARY_RANGE_MARKET:
        "Empleados por rango salarial - Mercado",
      EMPLOYEES_BY_SALARY_RANGE_BOTH:
        "Empleados por rango salarial - Unificado",
      SALARY_RANGE_BY_STATE: "Salario promedio por estado",
      SALARY_RANGE_BY_COMPANY_SIZE: "Salario promedio por tamaño de empresa",
      SALARY_AVERAGE_BY_OCCUPATION:
        "Ocupaciones con el salario promedio más alto",
      HIGHER_AVERAGE: "Ocupaciones con mayor salario promedio",
      HIGHER_COSTS: "Ocupaciones con mayor costo en la hoja",
      COMPANY_AVERAGE_HIGHER_THAN_MARKET_AVERAGE:
        "Ocupaciones con un salario medio superior al del mercado",
      COMPANY_AVERAGE_LOWER_THAN_MARKET_AVERAGE:
        "Ocupaciones con un salario medio inferior al del mercado",
      AVERAGE_SALARY_BY_OCCUPATION: "Salario promedio por ocupación",
      OCCUPATIONS_CHART_TOOLTIP:
        "El costo total del mercado se calcula multiplicando el promedio del mercado por el número de empleados en su empresa.",
    },
    OCCUPATIONS_CHART_OPTIONS_MODAL: {
      SELECT_CHART_INFORMATION: "Seleccione el tipo de información del gráfico",
    },
    PARAMETERIZATION_MODAL: {
      CHART_PARAMETERIZATION: "Parametrización de gráficos",
      METRICS_ORGANIZATION: "Organización de métricas",
      CHARTS_ORGANIZATION: "Organización de gráficos",
      INDEX_APPLICATION: "Aplicación de índices",
      METRICS_TAB_DESCRIPTION:
        "Elija qué métricas se mostrarán en la barra de métricas. Estarán en el orden en que fueron seleccionados.",
      CHARTS_TAB_DESCRIPTION:
        "Elija qué gráficos se mostrarán. Estarán en el orden en que fueron seleccionados.",
      APPLY_SALARY_CORRECTION_INDEX: "Aplicar índice de corrección salarial",
      SALARY_CORRECTION_INDEX_TOOLTIP:
        "El índice se puede utilizar para corregir discrepancias en los datos del mercado, ya que la fuente de datos es la última actualización de RAIS. El valor seleccionado se aplicará a todos los valores monetarios que se refieran al mercado, incluso en los informes.",
      CUT_SALARIES_ABOVE_PERCENTILE:
        "Recortar los salarios por encima del percentil",
    },
    FILTER_MODAL: {
      FILTER_EXAMPLE: "Ex.: Filtro 1",
      IT_IS_NOT_POSSIBLE_TO_REMOVE_AN_ALREADY_USED_FILTER:
        "No se pueden eliminar filtros en uso",
      COPY_FILTER_FROM_CURRENT_TO_OTHER:
        "Copie los filtros seleccionados en la pestaña actual a la pestaña {{otherTabName}}",
      COPY_FILTER_FROM_OTHER_TO_CURRENT:
        "Copie los filtros seleccionados en la pestaña {{otherTabName}} a la pestaña actual",
      FILTERING_RESULT: "Resultado de filtrado",
      FILTERING_RESULT_TOOLTIP:
        "Número de empleados que se devolverán al filtrar usando los filtros seleccionados",
      INSUFFICIENT_DATA_DISCLAIMER:
        "Los datos de mercado son insuficientes para una comparación significativa, considere aplicar filtros más completos.",
      CBO_TOOLTIP:
        "Las posiciones son especificadas por un número llamado CBO (Clasificación Brasileña de Ocupaciones). La CBO se compone de 5 partes: <list><item>El primer dígito indica el <bold>gran grupo</bold>.</item><item>El segundo dígito indica el <bold>subgrupo principal</bold>.</item><item>El tercer dígito indica el <bold>subgrupo</bold>.</item><item>El cuarto dígito indica la <bold>familia</bold>.</item><item>Los dos últimos números del código indican la <bold>ocupación</bold>.</item></list>",
      GEOGRAPHY_TOOLTIP:
        "La geografía consta de cinco piezas de información: <list><item>La <bold>región</bold> se refiere a cada una de las unidades federativas de Brasil.</item><item>El <bold>estado</bold> se refiere a cada estado brasileño (São Paulo, Santa Catarina, etc.).</item><item>La <bold>región geográfica intermedia</bold> es una agrupación de regiones geográficas inmediatas.</item><item>La <bold>región geográfica inmediata</bold> es una agrupación de municipios.</item><item>El <bold>municipio</bold> es la unidad autónoma más pequeña de la Federación.</item></list>",
      CNAE_TOOLTIP:
        "Los sectores de la empresa están especificados por un número denominado CNAE (Clasificación Nacional de Actividades Económicas). El CNAE se compone de 5 partes:<list><item>El primer dígito indica la <bold>sección</bold>.</item><item>El segundo dígito indica la <bold>división</bold>. < /item><item>El tercer dígito indica el <bold>grupo</bold>.</item><item>El cuarto dígito y su dígito de control (el quinto) indican la <bold>clase</bold>. < /item><item>Los dos últimos números del código después del dígito de control indican la <bold>subclase</bold>.</item></list>",
    },
    REMOVE_FILTER_DIALOG: {
      TITLE: "Eliminando filtro",
      CONFIRMATION_MESSAGE:
        "¿Realmente desea eliminar el filtro '{{filterName}}'?",
    },
    LOGIN_PAGE: {
      PLEASE_TICK_IM_NOT_A_ROBOT: 'Marque "No soy un robot"',
      INCORRECT_USERNAME_OR_PASSWORD: "Usuario o contraseña incorrectos",
      USERNAME_OR_EMAIL: "Usuario o correo electrónico",
    },
    GRADUS_TABLE: {
      ROWS_PER_PAGE: "Líneas por página",
      DISPLAYED_ROWS: "{{from}}-{{to}} de {{count}} líneas",
    },
    CONFIRMATION_DIALOG: {
      CONFIRM_ACTION: "Confirmar la acción",
      DO_YOU_REALLY_WANT_TO_EXECUTE_THE_SELECTED_ACTION:
        "¿Realmente desea realizar la acción seleccionada?",
    },
    PRIVACY_POLICY: {
      TITLE: "Política de privacidad",
      OBJECTIVES: "Metas",
      OBJECTIVES_PARAGRAPH:
        "Esta política tiene como objetivo aclarar al usuario cómo se tratan los datos personales utilizados en el sistema Gradus Remuneratio y con qué finalidad se recopilan.Además, también es objeto de esta política informarle cómo el usuario puede ejercer sus derechos relacionados con sus datos.",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS: "Sobre la empresa y sus contratos",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_1:
        "Gradus es una empresa de consultoría, tecnología y análisis que cuenta con más de 20 años de experiencia en consultoría de gestión y transforma metodologías probadas en soluciones que aumentan drásticamente la eficiencia de nuestros clientes.",
      ABOUT_THE_COMPANY_AND_ITS_CONTRACTS_PARAGRAPH_2:
        "Dudas, información sobre cómo tratamos los datos personales, para solicitar los datos presentes en los sistemas o para solicitar la eliminación de sus datos, contáctenos por correo electrónico a dpo@gradustech.com.br. Para cualquier otra consulta, utilice suporte@gradustech.com.br o llame al (11) 3167-2542.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM:
        "Qué datos no debe incluir el usuario en el sistema",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_1:
        "Para que el análisis en Remuneratio sea operativo, eficiente y presente la comparación entre la información del cliente y el mercado, se solicita cargar la nómina del cliente en el Módulo de Comparación.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_2:
        "Con el fin de lograr una mayor seguridad de los datos personales del cliente, solicitamos que datos como el nombre, el CPF, la dirección, el sexo, el rango de edad y cualquier información confidencial o que identifique al empleado se excluyan de la hoja de cálculo antes de cargarlos.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_3:
        "En todo caso, luego de definir el mapeo de qué información se utilizará en la comparación, el sistema sólo conserva los datos seleccionados por el usuario, con el único fin de acceder a la información por parte del mismo en su análisis.",
      WHICH_DATA_THE_USER_SHOULD_NOT_INCLUDE_IN_THE_SYSTEM_PARAGRAPH_4:
        "Por lo tanto, Gradus no almacena ningún dato personal presente en la nómina que permita asociar la información de remuneración con el individuo.",
      WHY_DO_WE_COLLECT_PERSONAL_DATA: "Por qué recopilamos datos personales",
      WHY_DO_WE_COLLECT_PERSONAL_DATA_PARAGRAPH_1:
        "Los datos personales del usuario se recopilan exclusivamente para uso de Gradus para permitir:",
      WHY_DO_WE_COLLECT_PERSONAL_DATA_LIST:
        "<li>El uso del sistema, con el fin de permitir a los usuarios explorar sus funcionalidades, como el Módulo de Comparación</li><li>Garantizar la seguridad de los datos de los usuarios</li><li>Investigar y solucionar problemas encontrados por usuarios o el equipo de Gradus</li><li>Crear nuevas funciones y mejorar las existentes para evolucionar la experiencia del usuario</li><li>Permitir la comunicación de actualizaciones, servicios de soporte, alertas y notificaciones de usuario</li> <li>Cumplir con las obligaciones legales y contractuales</li>",
      WHICH_DATA_DO_WE_COLLECT: "Qué datos recopilamos",
      WHICH_DATA_DO_WE_COLLECT_LIST:
        "<li>Datos presentes en la nómina ingresada por el usuario, incluyendo remuneración, cargos, ubicación, tamaño de la empresa, CNAE y otros parámetros que el usuario considere oportunos para componer el análisis</li><li>Datos de uso: se recopila información sobre su navegación en nuestra web, a qué contenidos e informes se ha accedido</li><li>Información técnica: se registran todos los accesos por usuario a la plataforma, como fecha y hora de acceso, dirección IP, geolocalización, tipo de sistema operativo, versión del navegador y preferencia de idioma</li><li>La información guardada directamente por el usuario en el Sistema, como mapas y análisis guardados, se almacenan con el único propósito de acceder a la información por parte del usuario</li>",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA:
        "¿Cuánto tiempo almacenamos los datos?",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_1:
        "Los datos recopilados se conservan durante el tiempo necesario para garantizar el cumplimiento de los fines para los que fueron recopilados o para cumplir con las obligaciones legales y contractuales.",
      FOR_HOW_LONG_DO_WE_STORE_THE_DATA_PARAGRAPH_2:
        "Como se mencionó, los datos que no se seleccionen en el paso de mapeo de información serán excluidos del sistema cuando se aplique la parametrización.Los datos mapeados y los análisis guardados pueden ser excluidos por el usuario directamente en el sistema.",
      REGARDING_DATA_TRANSFER: "En cuanto a la transferencia de datos",
      REGARDING_DATA_TRANSFER_PARAGRAPH_1:
        "Todos los datos recopilados son utilizados únicamente por Gradus y por el cliente sin compartirlos con terceros, a excepción de los servicios básicos de atención al cliente (CRM, correo electrónico y capacitación), los servicios de análisis de datos de navegación y la infraestructura de los sistemas que se encuentran en los Estados Unidos.",
      WHAT_ARE_THE_USER_RIGHTS: "¿Cuáles son los derechos de usuario?",
      WHAT_ARE_THE_USER_RIGHTS_LIST:
        "<li>Confirmar la existencia de tratamiento de sus datos personales</li><li>Acceder a sus datos personales</li><li>Corregir datos personales incompletos, inexactos o desactualizados</li><li>Anonimización, bloqueo o eliminación de datos personales innecesarios, excesivos o tratados en violación de la LGPD</li><li>Portabilidad de datos personales a otro proveedor de productos o servicios</li><li>Eliminación de datos tratados con su consentimiento</li> <li >Obtener información sobre las entidades públicas y privadas con las que el responsable del tratamiento haya compartido datos personales</li><li>Obtener información sobre la posibilidad de no consentir el tratamiento de datos personales y las consecuencias negativas</li>",
      WHAT_SECURITY_MEASURES_ARE_TAKEN:
        "¿Qué medidas de seguridad se han tomado?",
      WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_1:
        "Las medidas de seguridad utilizadas por Gradus, como respaldo, firewall y antivirus, se llevan a cabo para garantizar que los datos de los usuarios estén siempre protegidos, de modo que solo los miembros autorizados del equipo de Gradus tengan acceso directo a la infraestructura de los sistemas.",
      WHAT_SECURITY_MEASURES_ARE_TAKEN_PARAGRAPH_2:
        "Además de toda la estructura de seguridad implementada por Gradus, también se realizan pruebas de seguridad masivas por auditorías de terceros de renombre.",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY:
        "Revisar y actualizar la política de control de acceso",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_1:
        "El documento es válido desde el 02/05/2022 y si se producen cambios en la política de privacidad, la información contenida en este documento se actualizará.",
      REGARDING_REVISION_AND_UPDATES_ON_THE_ACCESS_CONTROL_POLICY_PARAGRAPH_2:
        "La Política de Privacidad debe ser revisada y actualizada, al menos anualmente, con la intención de adecuarse a las reglas de negocio, mejores prácticas del mercado, leyes, reglamentos y otros aspectos.",
      FOOTER_LINK: "Política de privacidad",
    },
    BACKEND_MESSAGES: {
      ANALYSIS_DOES_NOT_EXIST: "El analisis no existe",
      FIELD_NAME_IS_REQUIRED: "El campo Nombre es obligatorio",
      FIELD_SALARY_IS_REQUIRED: "El campo Salario es obligatorio",
      FIELD_JOB_IS_REQUIRED: "El campo Cargo (CBO) es obligatorio",
      FIELD_COMPANY_IS_REQUIRED: "El campo CNAE es obligatorio",
      FIELD_STATE_IS_REQUIRED_WHEN_MAPPING_COUNTIES:
        "El campo Estado es obligatorio al mapear el campo Municipio",
      FIELD_CONFIGURATION_IS_REQUIRED: "El campo Configuración es obligatorio",
      FIELD_COMPANY_SIZE_IS_REQUIRED:
        "El campo Tamaño de la Empresa es obligatorio",
      INVALID_VALUE_FOR_COMPANY_SIZE:
        "Valor no válido para o campo Tamanho da Empresa",
      INVALID_VALUE_FOR_CONFIGURATION:
        "Valor no válido para el campo Tamaño de la empresa",
      YOU_SHOULD_ACCEPT_THE_INFORMATION_SECURITY_POLICY_TO_USE_THE_SYSTEM:
        "Debe aceptar la política de seguridad de la información para utilizar el sistema",
      FILTER_NAME_IS_REQUIRED: "El nombre del filtro es obligatorio",
      FILTER_DOES_NOT_EXIST: "El filtro no existe",
      FILTER_SHOULD_BE_RELATED_TO_AN_ANALYSIS:
        "El filtro debe estar vinculado a un análisis",
    },
    GENERAL: {
      CANCEL: "Cancelar",
    },
    GRADUS_DOUBLE_LIST_FIELD_SELECTOR: {
      TO_INCLUDE: "Incluidos",
      TO_EXCLUDE: "Excluidos",
      DROP_THE_DESIRED_ITEM_HERE: "Arrastre el elemento deseado aquí",
    },
    RELAXATION_SELECTOR: {
      FILTERS_RELAXATION: "Relajación de filtros",
      EXCLUDED_FILTERS: "Filtros excluidos de la relajación",
      INCLUDED_FILTERS: "Filtros incluidos de la relajación",
      EXPLANATION_TOOLTIP:
        "La relajación busca, para cada par de ocupación y municipio filtrado en la nómina, aumentar la significación estadística de la muestra de mercado para el mínimo de {{quantity}} empleados mediante la expansión del filtro geográfico",
      RELAXATION_NOT_AVAILABLE_FOR_PAYROLLS_WITHOUT_COUNTIES:
        "La relajación no está disponible para las nóminas sin municipios",
    },
    COMPANY_SIZE_FILTER_TAG: {
      BETWEEN_X_AND_Y_EMPLOYEES: "Entre {{from}} y {{to}} empleados",
      UPTO_X_EMPLOYEES: "Hasta {{to}} empleados",
      FROM_X_EMPLOYEE: "A partir de {{from}} empleado",
      FROM_X_EMPLOYEES: "A partir de {{from}} empleados",
      ANY_NUMBER_OF_EMPLOYEES: "Cualquier número de empleados",
    },
  },
};
