import Typography from "@mui/material/Typography";
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Stack } from "@mui/material";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import BaseFilter from "../../../../dtos/BaseFilter";
import GradusAutocomplete from "../../../GradusComponents/GradusAutocomplete";
import GradusTooltip from "../../../GradusComponents/GradusTooltip";
import InfoIcon from "../../../InfoIcon/InfoIcon";
import FilterOptionDTO from "../../../../dtos/FilterOptionDTO";

const TooltipText = () => {
  const theme = useTheme();

  return (
    <div style={{ padding: theme.spacing(1) }}>
      <Typography sx={{ color: "white" }}>
        <Trans
          i18nKey="FILTER_MODAL.GEOGRAPHY_TOOLTIP"
          components={{ list: <ul />, item: <li />, bold: <strong /> }}
        />
      </Typography>
    </div>
  );
};

function GeographicDataFilter({
  options,
  selectedFilters,
  setSelectedFilters,
  isMarketTab,
}: {
  options: BaseFilter;
  selectedFilters: BaseFilter;
  setSelectedFilters: (values: Partial<BaseFilter>) => void;
  isMarketTab?: boolean;
}) {
  const { t } = useTranslation();

  const handleAddCountiesButton = () => {
    setSelectedFilters({
      geographicRegions: [],
      geographicStates: [],
      intermediateRegions: [],
      immediateRegions: [],
      counties: options.counties,
    });
  };

  return (
    <Container disableGutters>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h6">{t("GEOGRAPHY")}</Typography>
        <GradusTooltip title={<TooltipText />} placement="right">
          <span>
            <InfoIcon icon={solid("question-circle")} />
          </span>
        </GradusTooltip>
      </Stack>
      <Grid container justifyContent="space-between">
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("REGION")}
            options={options.geographicRegions}
            selectedOptions={selectedFilters?.geographicRegions || []}
            setSelectedOptions={(geographicRegions: FilterOptionDTO[]) => {
              setSelectedFilters({
                geographicRegions,
                geographicStates: [],
                intermediateRegions: [],
                immediateRegions: [],
                counties: [],
              });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("STATE")}
            options={options.geographicStates}
            selectedOptions={selectedFilters?.geographicStates || []}
            setSelectedOptions={(geographicStates: FilterOptionDTO[]) => {
              setSelectedFilters({
                geographicStates,
                intermediateRegions: [],
                immediateRegions: [],
                counties: [],
              });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("INTERMEDIATE_GEOGRAPHIC_REGION")}
            options={options.intermediateRegions}
            selectedOptions={selectedFilters?.intermediateRegions || []}
            setSelectedOptions={(intermediateRegions: FilterOptionDTO[]) => {
              setSelectedFilters({
                intermediateRegions,
                immediateRegions: [],
                counties: [],
              });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("IMMEDIATE_GEOGRAPHIC_REGION")}
            options={options.immediateRegions}
            selectedOptions={selectedFilters?.immediateRegions || []}
            setSelectedOptions={(immediateRegions: FilterOptionDTO[]) => {
              setSelectedFilters({ immediateRegions, counties: [] });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
          />
        </Grid>
        <Grid item width="215px">
          <GradusAutocomplete
            label={t("COUNTY")}
            options={options.counties}
            selectedOptions={selectedFilters?.counties || []}
            setSelectedOptions={(counties: FilterOptionDTO[]) => {
              setSelectedFilters({ counties });
            }}
            isOptionEqualToValue={(
              option: FilterOptionDTO,
              value: FilterOptionDTO
            ) => option.id === value.id}
            getOptionLabel={(option: FilterOptionDTO) => option.name}
            handleSelectAll={handleAddCountiesButton}
            addSelectAll={!isMarketTab}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

GeographicDataFilter.defaultProps = {
  isMarketTab: false,
};

export default GeographicDataFilter;
