import React, { useCallback, useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import SelectParameterMapping from "./SelectParameterMapping";
import payrollConfigurationsApi from "../../apis/payrollConfigurationsApi";
import companySubclassApi from "../../apis/companySubclassApi";
import StringUtils from "../../utils/StringUtils";
import AlertContext from "../../contexts/AlertContext";
import CompanySize, { OPTIONS } from "../../models/CompanySize";
import GradusTextField from "../GradusComponents/GradusTextField";
import GradusButton from "../Buttons/GradusButton";
import GradusModal from "../GradusComponents/GradusModal/GradusModal";
import { handleApiError } from "../../utils/ErrorUtils";
import PayrollConfigurationCreateDTO from "../../dtos/PayrollConfiguration/PayrollConfigurationCreateDTO";
import ConfigurationView from "../../dtos/PayrollConfiguration/ConfigurationView";
import FilterOptionDTO from "../../dtos/FilterOptionDTO";
import ExcelColumn from "./ExcelColumn";

export default function CreateMappingModal({
  columns,
  computedCompanySize,
  handleClose,
  isOpen,
  onSuccessfulSave,
}: {
  columns: ExcelColumn[];
  computedCompanySize: number;
  handleClose: () => void;
  isOpen: boolean;
  onSuccessfulSave?: () => void;
}) {
  const { fireAlert } = useContext(AlertContext);
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const [selectedCNAEs, setSelectedCNAEs] = useState<FilterOptionDTO[]>([]);
  const [CNAEs, setCNAEs] = useState<FilterOptionDTO[]>([]);
  const [tabInfo, setTabInfo] = useState<ConfigurationView>(
    ConfigurationView.buildDefault(computedCompanySize)
  );

  const closeModal = useCallback(() => {
    setSelectedCNAEs([]);
    setCNAEs([]);
    setTabInfo(ConfigurationView.buildDefault(computedCompanySize));

    handleClose();
  }, [computedCompanySize, handleClose]);

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      const { value } = event.target;
      if (value.length <= 64) {
        setTabInfo(
          (prevTabInfo) =>
            new ConfigurationView({
              ...prevTabInfo,
              name: value,
            })
        );
      }
    }, []);

  const handleCreateMapping: React.FormEventHandler<HTMLFormElement> =
    useCallback(
      async (event) => {
        event.preventDefault();

        const payload = PayrollConfigurationCreateDTO.fromView(
          new ConfigurationView({
            ...tabInfo,
            cnaes: selectedCNAEs,
          })
        );

        try {
          await payrollConfigurationsApi.createConfiguration(payload);
          closeModal();
          fireAlert(
            t("ALERTS.SUCCESSFULLY_CREATED_MAPPING", { lng: language })
          );
          onSuccessfulSave?.();
        } catch (error) {
          handleApiError(error, fireAlert, t);
        }
      },
      [
        tabInfo,
        selectedCNAEs,
        closeModal,
        fireAlert,
        t,
        language,
        onSuccessfulSave,
      ]
    );

  const handleOpen = useCallback(() => {
    companySubclassApi.getAll().then(({ data }) => {
      setCNAEs(data);
    });
  }, []);

  useEffect(() => {
    setTabInfo(
      (prevState) =>
        new ConfigurationView({
          ...prevState,
          companySize: CompanySize.getOptionByCompanySize(computedCompanySize),
        })
    );
  }, [computedCompanySize]);

  return (
    <GradusModal handleClose={closeModal} open={isOpen} handleOpen={handleOpen}>
      <Stack>
        <Typography variant="h5">{t("CREATE_MAPPING")}</Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {t("CREATE_MAPPING_MODAL.DESCRIPTION")}
        </Typography>
      </Stack>
      <Stack gap={2}>
        <form onSubmit={handleCreateMapping}>
          <Stack direction="row" gap={2}>
            <GradusTextField
              id="payroll-configuration-name"
              label={t("MAPPING_NAME")}
              value={tabInfo.name}
              onChange={handleNameChange}
              sx={{ width: "50%" }}
              required
            />
            <Autocomplete
              disablePortal
              size="small"
              options={OPTIONS}
              getOptionLabel={({ name }) => t(name)}
              noOptionsText={t("NO_OPTIONS")}
              sx={{ width: "50%" }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_event, newValue) => {
                setTabInfo(
                  new ConfigurationView({
                    ...tabInfo,
                    companySize: newValue,
                  })
                );
              }}
              value={tabInfo.companySize}
              renderInput={(params) => (
                <GradusTextField
                  {...params}
                  label={t("COMPANY_SIZE_LABEL")}
                  required
                />
              )}
            />
          </Stack>

          <Autocomplete
            disablePortal
            size="small"
            noOptionsText={t("NO_OPTIONS")}
            value={selectedCNAEs}
            options={CNAEs}
            filterSelectedOptions
            onChange={(_event, selected: FilterOptionDTO[]) =>
              setSelectedCNAEs(selected)
            }
            sx={{ width: "100%", my: 2 }}
            loading={CNAEs.length <= 0}
            loadingText={`${t("LOADING")}...`}
            renderInput={(params) => (
              <GradusTextField
                {...params}
                label={t("COMPANY_CNAES")}
                required
                inputProps={{
                  ...params.inputProps,
                  required: selectedCNAEs.length < 1,
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option: FilterOptionDTO, index) => (
                <Chip
                  variant="outlined"
                  label={`${StringUtils.formatCNAE(option.id)} - ${
                    option.name
                  }`}
                  {...getTagProps({ index })}
                />
              ))
            }
            getOptionLabel={(CNAE) =>
              `${StringUtils.formatCNAE(CNAE.id)} - ${CNAE.name}`
            }
            multiple
          />

          <SelectParameterMapping
            columns={columns}
            tabInfo={tabInfo}
            setTabInfo={setTabInfo}
          />

          <Stack direction="row-reverse" gap={2}>
            <GradusButton
              variant="primary"
              colorVariant="primary"
              buttonProps={{ type: "submit" }}
            >
              {t("CREATE")}
            </GradusButton>
            <GradusButton
              variant="primary"
              colorVariant="primary"
              onClick={closeModal}
            >
              {t("CANCEL")}
            </GradusButton>
          </Stack>
        </form>
      </Stack>
    </GradusModal>
  );
}

CreateMappingModal.defaultProps = {
  onSuccessfulSave: _.noop,
};
