import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import ListAnalysisModal from "../../components/Modals/ListAnalysisModal/ListAnalysisModal";
import CompareModuleCard from "./CompareModuleCard";
import ConsulteModuleCard from "./ConsulteModuleCard";
import useAvailableModules from "../../hooks/useAvailableModules";

export default function Home() {
  const [consulteAnalysisModal, setConsulteAnalysisModal] = useState(false);
  const { isConsulteAvailable, isCompareAvailable } = useAvailableModules();

  return (
    <Container sx={{ mt: 5, mb: 5 }}>
      <Grid container spacing={2}>
        <CompareModuleCard isCompareAvailable={isCompareAvailable} />
        <ConsulteModuleCard
          setConsulteAnalysisModal={setConsulteAnalysisModal}
          isConsulteAvailable={isConsulteAvailable}
        />
        <ListAnalysisModal
          handleClose={() => setConsulteAnalysisModal(false)}
          open={consulteAnalysisModal}
        />
      </Grid>
    </Container>
  );
}
