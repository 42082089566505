import { create } from "zustand";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import chartsOptionsApi from "../../apis/chartsOptionsApi";
import {
  METRICS_DEFAULT_VALUES,
  METRICS_OPTIONS,
} from "../../components/MetricsBoard/Metrics";
import ChartOptionDTO from "../../dtos/ChartOptionDTO";

export type DashboardVisualOptionsProps = {
  // Gráficos que podem ser mostrados no dashboard
  chartOptions: ChartOptionDTO[];
  getChartsOptions: (isCompare: boolean) => Promise<ChartOptionDTO[]>;
  // Gráficos que são mostrados por padrão
  defaultChartOptions: ChartOptionDTO[];
  getChartsDefaultOptions: (isCompare: boolean) => Promise<ChartOptionDTO[]>;
  // Gráficos que foram selecionados e são mostrados na tela
  selectedChartOptions: ChartOptionDTO[];
  setSelectedChartOptions: (selectedChartOptions: ChartOptionDTO[]) => void;

  // Métricas que podem ser mostradas no card
  metricsOptions: { id: string; name: string }[];
  // Métricas que são mostradas por padrão
  defaultMetricsOptions: { id: string; name: string }[];
  // Métricas que foram selecionadas e são mostradas na tela
  selectedMetricsOptions: { id: string; name: string }[];
  setSelectedMetricsOptions: (
    selectedMetricsOptions: { id: string; name: string }[]
  ) => void;

  isPresentationMode: boolean;
  setIsPresentationMode: (isPresentationMode: boolean) => void;
};

const initialState = {
  chartOptions: [],
  defaultChartOptions: [],
  selectedChartOptions: [],

  metricsOptions: METRICS_OPTIONS,
  defaultMetricsOptions: METRICS_DEFAULT_VALUES,
  selectedMetricsOptions: _.cloneDeep(METRICS_DEFAULT_VALUES),

  isPresentationMode: false,
};

const useDashboardVisualOptionsStore = create<DashboardVisualOptionsProps>()(
  devtools(
    (set, get) => ({
      ...initialState,

      setSelectedChartOptions: (selectedChartOptions: ChartOptionDTO[]) => {
        set(() => ({ selectedChartOptions }));
      },

      getChartsOptions: async (isCompare: boolean) => {
        const chartOptions = await chartsOptionsApi.getChartsOptions(isCompare);
        set(() => ({ chartOptions }));
        return chartOptions;
      },
      getChartsDefaultOptions: async (isCompare: boolean) => {
        const defaultChartOptions =
          await chartsOptionsApi.getChartsDefaultOptions(isCompare);
        set(() => ({
          defaultChartOptions,
          selectedChartOptions: defaultChartOptions,
        }));
        return defaultChartOptions;
      },

      setSelectedMetricsOptions: (
        selectedMetricsOptions: { id: string; name: string }[]
      ) => {
        set({ selectedMetricsOptions });
      },

      setIsPresentationMode: (isPresentationMode: boolean) => {
        set({ isPresentationMode });
      },
    }),
    { name: "DashboardVisualOptionsStore" }
  )
);

export default useDashboardVisualOptionsStore;
