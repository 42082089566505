import _ from "lodash";

export default class StringUtils {
  static formatCNAE(id: string | number) {
    return String(id)
      .padStart(7, "0")
      .replace(/(\d{4})(\d)(\d{2})/g, "$1-$2/$3");
  }

  static firstNonEmptyArrayToString(filters: string[][]) {
    const nonEmptyFilter = filters.find((filter) => !_.isEmpty(filter));

    if (nonEmptyFilter) {
      return nonEmptyFilter.join(", ");
    }

    return "";
  }

  static truncateString(name: string, maxSize: number) {
    return name.length > maxSize ? `${name.slice(0, maxSize).trim()}...` : name;
  }

  // TODO: tem que mudar o BaseFilter, o MarketFilter e o PayrollFilter para TS
  //  e criar um arquivo de tipagem. Se colocar o tipo deles aqui, dará ciclo,
  //  pois eles utilizam funções desta classe
  static truncateFilters(filters: any, maxSize = 32) {
    const filtersString = _.isEmpty(filters) ? "" : filters.toString();

    return filtersString.length > maxSize
      ? `${filtersString.slice(0, maxSize - 3)}...`
      : filtersString;
  }
}
