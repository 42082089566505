import { StateCreator } from "zustand";
import MarketFilter from "../../dtos/MarketFilter";
import filterOptionApi from "../../apis/filterOptionApi";
import {
  MarketFilterOptionsSlice,
  PayrollFilterOptionsSlice,
} from "./filterOptionsStoreUtils";
import AnalysisPresenter from "../../dtos/AnalysisPresenter";

const initialState = {
  marketOptions: new MarketFilter({}),
};

const createMarketFilterOptionSlice: StateCreator<
  PayrollFilterOptionsSlice & MarketFilterOptionsSlice,
  [],
  [],
  MarketFilterOptionsSlice
> = (set, get) => ({
  ...initialState,

  setMarketOptions: (marketOptions) =>
    set((state) => ({ ...state, marketOptions })),

  _initMarketOptions: async (analysis: AnalysisPresenter) => {
    await get().getMarketChainedFilters(analysis.getMarketFilters());
  },

  getMarketJobChainedFilters: async (filters: MarketFilter) => {
    const response = await filterOptionApi.getJobChainedFilters(
      filters.onlyWithIds()
    );

    set(({ marketOptions, ...state }) => {
      const chainedFilters = new MarketFilter(response.data).jobFilters();
      const newMarketOptions = new MarketFilter({
        ...marketOptions,
        ...chainedFilters,
      });
      return { ...state, marketOptions: newMarketOptions };
    });
  },

  getMarketCompanySizeChainedFilters: async () => {
    const response = await filterOptionApi.getCompanySizes();

    set(({ marketOptions, ...state }) => {
      const newMarketOptions = new MarketFilter({
        ...marketOptions,
        companySizes: response.data,
      });
      return { ...state, marketOptions: newMarketOptions };
    });
  },

  getMarketCompanyChainedFilters: async (filters: MarketFilter) => {
    const response = await filterOptionApi.getCompanyChainedFilters(
      filters.onlyWithIds()
    );

    set(({ marketOptions, ...state }) => {
      const chainedFilters = new MarketFilter(response.data).companyFilters();
      const newMarketOptions = new MarketFilter({
        ...marketOptions,
        ...chainedFilters,
      });
      return { ...state, marketOptions: newMarketOptions };
    });
  },

  getMarketGeographyChainedFilters: async (filters: MarketFilter) => {
    const response = await filterOptionApi.getGeographicChainedFilters(
      filters.onlyWithIds()
    );

    set(({ marketOptions, ...state }) => {
      const chainedFilters = new MarketFilter(
        response.data
      ).geographicFilters();
      const newMarketOptions = new MarketFilter({
        ...marketOptions,
        ...chainedFilters,
      });
      return { ...state, marketOptions: newMarketOptions };
    });
  },

  getMarketChainedFilters: async (appliedFilters) => {
    const filters = appliedFilters.onlyWithIds();
    const chainedFilters =
      await filterOptionApi.getMarketConsolidatedChainedFilters(filters);
    const marketOptions = new MarketFilter(chainedFilters.data);
    set({ marketOptions });
  },
});

export default createMarketFilterOptionSlice;
