import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import FilterOptionDTO from "../../../dtos/FilterOptionDTO";
import Chart from "../Chart";
import useCompareAnalysisStore from "../../../stores/useCompareAnalysisStore";
import useConsulteAnalysisStore from "../../../stores/useConsulteAnalysisStore";
import useChartParametersStore from "../../../stores/ChartParametersStore/useChartParametersStore";
import BaseFilter from "../../../dtos/BaseFilter";
import useFilterOptionsStore from "../../../stores/FilterOptionsStore/useFilterOptionsStore";

const SelectJobFieldSubfilter = ({
  chart,
  isCompare,
}: {
  chart: Chart;
  isCompare: boolean;
}) => {
  const { t } = useTranslation();

  const { companyOptions, marketOptions } = useFilterOptionsStore((state) => ({
    companyOptions: state.companyOptions,
    marketOptions: state.marketOptions,
  }));

  const defaultOption = useMemo(
    () => new FilterOptionDTO({ id: -1, name: t("ALL") as string }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { updateSelected, [chart]: chartParameters } = useChartParametersStore(
    (state) => ({
      [chart]: state[chart],
      updateSelected: state.updateSelected,
    })
  );

  const compareAnalysis = useCompareAnalysisStore((state) => state.analysis);
  const consulteAnalysis = useConsulteAnalysisStore((state) => state.analysis);

  const [selectedValue, setSelectedValue] = useState(defaultOption);

  const mostGranularJobFilter = useMemo(() => {
    if (isCompare) {
      return companyOptions.getMostGranularJobFilterValues();
    }
    return marketOptions.getMostGranularJobFilterValues();
  }, [companyOptions, isCompare, marketOptions]);

  const jobFilters = useMemo(
    () => ({
      field: mostGranularJobFilter.field,
      values: [defaultOption, ...mostGranularJobFilter.values],
    }),
    [defaultOption, mostGranularJobFilter.field, mostGranularJobFilter.values]
  );

  const handleChange = useCallback(
    (newValue: FilterOptionDTO) => {
      const shouldUseNewJobFilters = !_.isEqual(newValue, defaultOption);

      let newJobFilters = new BaseFilter({});
      if (shouldUseNewJobFilters) {
        newJobFilters = BaseFilter.addValuesToMostGranularJobFilter(
          mostGranularJobFilter.field,
          isCompare
            ? compareAnalysis.getCompanyFilters()
            : consulteAnalysis.getMarketFilters(),
          [newValue]
        );
      }

      updateSelected(chart, { newJobFilters, shouldUseNewJobFilters });
      setSelectedValue(newValue);
    },
    [
      chart,
      compareAnalysis,
      consulteAnalysis,
      defaultOption,
      isCompare,
      mostGranularJobFilter.field,
      updateSelected,
    ]
  );

  useEffect(() => {
    const mostGranularNewJobFilter =
      chartParameters.selected.newJobFilters.getMostGranularJobFilterValues();
    if (mostGranularNewJobFilter.values.length > 0) {
      setSelectedValue(mostGranularNewJobFilter.values[0]);
    } else {
      setSelectedValue(defaultOption);
    }
  }, [chartParameters.selected.newJobFilters, defaultOption]);

  return (
    <FormGroup>
      <FormLabel>{t(jobFilters.field.key)}</FormLabel>
      <FormControl>
        <Autocomplete
          disablePortal
          disableClearable
          renderInput={(params) => <TextField {...params} />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          options={jobFilters.values}
          value={selectedValue}
          onChange={(_event, newValue) => handleChange(newValue)}
          size="small"
          sx={{
            overflow: "auto",
            pt: 1,
            maxHeight: "100px",
            width: "50%",
          }}
          defaultValue={defaultOption}
        />
      </FormControl>
    </FormGroup>
  );
};

export default SelectJobFieldSubfilter;
