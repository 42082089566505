import api from "./api";
import CompareGetChartDTO from "../dtos/chartGenerate/CompareGetChartDTO";

const BASE_URL = "/api/v1/rais-raw-csv";

const raisRawDataApi = {
  getRaisRawCSV: (dto: CompareGetChartDTO) =>
    api.post(BASE_URL, dto, {
      responseType: "blob",
    }),
};

export default raisRawDataApi;
