import * as React from "react";
import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import ListAnalysisModal from "../Modals/ListAnalysisModal/ListAnalysisModal";
import { handleApiError } from "../../utils/ErrorUtils";
import AlertContext from "../../contexts/AlertContext";
import consulteAnalysisApi from "../../apis/consulteAnalysisApi";
import ListItem from "./ListItem";
import ListHeader from "./ListHeader";
import UnavailableListHeader from "./UnavailableListHeader";

export default function ConsulteMenu({ isDrawerOpen, isConsulteAvailable }) {
  const { fireAlert } = useContext(AlertContext);

  const [analysisModalOpen, setAnalysisModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!isConsulteAvailable) {
    return (
      <UnavailableListHeader
        itemTitle={t("CONSULT")}
        icon={light("magnifying-glass-chart")}
        isDrawerOpen={isDrawerOpen}
        tooltipText={t("BUY_CONSULT_MODULE")}
      />
    );
  }

  const handleCreateAnalysis = () => {
    consulteAnalysisApi
      .createAnalysis()
      .then((response) => {
        fireAlert(t("ALERTS.SUCCESSFULLY_CREATED_ANALYSIS"));
        navigate(`/consulte/${response.data}`);
      })
      .catch((error) => {
        handleApiError(error, fireAlert, t);
      });
  };

  return (
    <>
      <ListHeader
        itemTitle={t("CONSULT")}
        icon={light("magnifying-glass-chart")}
        isDrawerOpen={isDrawerOpen}
      />
      <ListItem
        title={t("NEW_ANALYSIS")}
        icon={light("file-circle-plus")}
        isDrawerOpen={isDrawerOpen}
        onClick={handleCreateAnalysis}
      />
      <ListItem
        title={t("ANALYSIS")}
        icon={light("file-chart-column")}
        isDrawerOpen={isDrawerOpen}
        onClick={() => setAnalysisModalOpen(true)}
      />
      <ListAnalysisModal
        handleClose={() => setAnalysisModalOpen(false)}
        open={analysisModalOpen}
      />
    </>
  );
}

ConsulteMenu.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  isConsulteAvailable: PropTypes.bool,
};

ConsulteMenu.defaultProps = {
  isConsulteAvailable: false,
};
