import ConfigurationView from "./ConfigurationView";

export default class PayrollConfigurationCreateDTO {
  name: string | null;

  salaryColumn: string | null;

  countyColumn: string | null;

  stateColumn: string | null;

  jobOccupationColumn: string | null;

  col1Name: string | null;

  col2Name: string | null;

  col3Name: string | null;

  col4Name: string | null;

  cnaes: number[];

  companySize: number | null;

  constructor({
    name,
    salaryColumn,
    countyColumn,
    stateColumn,
    jobOccupationColumn,
    col1Name,
    col2Name,
    col3Name,
    col4Name,
    cnaes,
    companySize,
  }: PayrollConfigurationCreateDTO) {
    this.name = name;
    this.salaryColumn = salaryColumn;
    this.countyColumn = countyColumn;
    this.stateColumn = stateColumn;
    this.jobOccupationColumn = jobOccupationColumn;
    this.companySize = companySize;
    this.col1Name = col1Name;
    this.col2Name = col2Name;
    this.col3Name = col3Name;
    this.col4Name = col4Name;
    this.cnaes = cnaes;
  }

  static fromView(view: ConfigurationView): PayrollConfigurationCreateDTO {
    return new PayrollConfigurationCreateDTO({
      ...view,
      cnaes: view.cnaes.map(({ id }) => id),
      companySize: view?.companySize?.id || null,
    });
  }
}
