import _ from "lodash";
import BaseFilter from "../BaseFilter";
import ChartParameters from "../../stores/ChartParametersStore/ChartParameters";
import GlobalParameters from "../../stores/ChartParametersStore/GlobalParameters";

export default class CompareGetChartDTO {
  filterId: number;

  correctionIndex: number;

  shouldUseNewJobFilters: boolean;

  newJobFilters: BaseFilter;

  analysisId: number;

  constructor({
    filterId,
    correctionIndex,
    shouldUseNewJobFilters,
    newJobFilters,
    analysisId,
  }: {
    filterId: number;
    correctionIndex?: number;
    shouldUseNewJobFilters?: boolean;
    newJobFilters?: BaseFilter;
    analysisId: number;
  }) {
    this.filterId = filterId;
    this.correctionIndex = correctionIndex || 0;
    this.shouldUseNewJobFilters = shouldUseNewJobFilters || false;
    this.newJobFilters = (
      _.isEmpty(newJobFilters) ? new BaseFilter({}) : newJobFilters
    ).onlyWithIds();
    this.analysisId = analysisId;
  }

  static from(
    filterId: number,
    analysisId: number,
    chartParameters: ChartParameters,
    globalParameters: GlobalParameters
  ): CompareGetChartDTO {
    return new CompareGetChartDTO({
      filterId,
      correctionIndex: globalParameters.correctionIndex,
      shouldUseNewJobFilters: chartParameters.shouldUseNewJobFilters,
      newJobFilters: chartParameters.newJobFilters,
      analysisId,
    });
  }
}
