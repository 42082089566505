/* eslint-disable react/prop-types */

import React from "react";
import GradusTooltip from "../components/GradusComponents/GradusTooltip";

function withGradusTooltip(
  Button,
  { defaultButtonProps, defaultTooltipProps } = {
    defaultButtonProps: {},
    defaultTooltipProps: {
      children: <div />,
      title: "",
    },
  }
) {
  const WithGradusTooltip = (props) => {
    const { forwardedRef, title, children, disabled, placement } = props;

    return (
      <GradusTooltip
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title={title}
        placement={placement}
        {...defaultTooltipProps}
      >
        <span>
          <Button
            ref={forwardedRef}
            {...defaultButtonProps}
            {...props}
            disabled={disabled}
            title=""
          >
            {children}
          </Button>
        </span>
      </GradusTooltip>
    );
  };

  return React.forwardRef((props, ref) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <WithGradusTooltip forwardedRef={ref} {...props}>
      {props?.children}
    </WithGradusTooltip>
  ));
}

export default withGradusTooltip;
