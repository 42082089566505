class FilterField {
  key: string;

  constructor(key: string) {
    this.key = key;
  }
}

const TableField = {
  largeGroup: new FilterField("LARGE_GROUP"),
  primarySubgroup: new FilterField("PRIMARY_SUBGROUP"),
  subgroup: new FilterField("SUBGROUP"),
  family: new FilterField("FAMILY"),
  occupation: new FilterField("OCCUPATION"),
  section: new FilterField("SECTION"),
  division: new FilterField("DIVISION"),
  group: new FilterField("GROUP"),
  class: new FilterField("CLASS"),
  subclass: new FilterField("SUBCLASS"),
  geographicRegion: new FilterField("REGION"),
  geographicState: new FilterField("STATE"),
  intermediateGeographicRegion: new FilterField(
    "INTERMEDIATE_GEOGRAPHIC_REGION"
  ),
  immediateGeographicRegion: new FilterField("IMMEDIATE_GEOGRAPHIC_REGION"),
  county: new FilterField("COUNTY"),
  companySize: new FilterField("COMPANY_SIZE"),
};

export default TableField;
