import { StateCreator } from "zustand";

import {
  FilterResultPreviewType,
  FilterStoreProps,
  MarketFilterSlice,
  PayrollFilterSlice,
} from "./filterStoreUtils";
import MarketFilter from "../../dtos/MarketFilter";
import employeeMetricsApi from "../../apis/metrics/employeeMetricsApi";
import useSystemConfigurationsStore from "../useSystemConfigurationsStore";
import useChartParametersStore from "../ChartParametersStore/useChartParametersStore";
import useFilterOptionsStore from "../FilterOptionsStore/useFilterOptionsStore";
import useRelaxationSelectorStore from "../useRelaxationSelectorStore";

const initialState = {
  // Filtros salvos no banco
  appliedMarketFilters: new MarketFilter({}),

  // Filtros que estão somente no modal de filtragem, sem estarem salvos no banco
  modalSelectedMarketFilters: new MarketFilter({}),
  areSelectedMarketFiltersInsufficient: false,
};

const createMarketFilterSlice: StateCreator<
  PayrollFilterSlice & MarketFilterSlice & FilterStoreProps,
  [],
  [],
  MarketFilterSlice
> = (set, get) => ({
  ...initialState,

  _initializeMarketFilters: async (filters) => {
    const { getMarketChainedFilters } = useFilterOptionsStore.getState();
    await getMarketChainedFilters(filters);

    set((state) => ({
      ...state,
      appliedMarketFilters: filters,
      modalSelectedMarketFilters: filters,
    }));
  },

  updateModalSelectedMarketFilters: async (values, isCompare, analysisId) => {
    const {
      modalSelectedMarketFilters,
      modalSelectedPayrollFilters,
      filterResultPreview,
    } = get();
    const { globalParameters } = useChartParametersStore.getState();

    const { configurations } = useSystemConfigurationsStore.getState();

    const newSelectedFilters = new MarketFilter({
      ...modalSelectedMarketFilters,
      ...values,
    });
    set({
      filterResultPreview: {
        ...filterResultPreview,
        market: {
          quantity: 0,
          isLoading: true,
        },
      },
      modalSelectedMarketFilters: newSelectedFilters,
    });

    let promise;
    const { correctionIndex } = globalParameters.applied;
    if (isCompare) {
      const { isRelaxed } = useRelaxationSelectorStore.getState();

      promise = employeeMetricsApi.getCompareMetrics(
        newSelectedFilters,
        modalSelectedPayrollFilters,
        isRelaxed,
        correctionIndex,
        analysisId
      );
    } else {
      promise = employeeMetricsApi.getConsulteMetrics(
        newSelectedFilters,
        correctionIndex
      );
    }

    const marketMetrics = await promise;
    const quantity = marketMetrics?.quantity || 0;

    set({
      filterResultPreview: {
        ...filterResultPreview,
        market: {
          quantity,
          isLoading: false,
        },
      },
      areSelectedMarketFiltersInsufficient:
        quantity < configurations.statisticallyInsignificantQuantityThreshold,
    });

    return newSelectedFilters;
  },

  getMarketQuantityPreview: (filterResultPreview: FilterResultPreviewType) =>
    filterResultPreview?.market.quantity || 0,

  setDefaultMarketFilters: (isCompare, analysisId) => {
    const { updateModalSelectedMarketFilters } = get();

    updateModalSelectedMarketFilters(
      { ...new MarketFilter({}) },
      isCompare,
      analysisId
    );

    set((state) => ({
      ...state,
      modalSelectedMarketFilters: new MarketFilter({}),
    }));
  },
});

export default createMarketFilterSlice;
