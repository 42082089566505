/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";
import Container from "@mui/material/Container";
import { Draggable } from "react-beautiful-dnd";

const Column = ({ item, index }) => (
  <Draggable draggableId={item.field} index={index}>
    {(provided, snapshot) => (
      <Container
        sx={{
          p: "10px",
          borderRadius: "10px",
          bgcolor: "#D9D9D9",
          margin: "0 0 8px 0",
          fontWeight: "bold",
          fontSize: "14px",
          "&:hover": {
            cursor: "pointer",
          },
          textAlign: "center",
        }}
        ref={provided.innerRef}
        snapshot={snapshot}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        data-testid={`column-${item.field}`}
      >
        {item.headerLabel}
      </Container>
    )}
  </Draggable>
);

Column.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerLabel: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default Column;
