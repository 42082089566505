import React, { Component } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { changeLanguage } from "i18next";
import { resetUser, setUser } from "../stores/useUserStore";
import sessionApi from "../apis/sessionApi";
import userApi from "../apis/userApi";

export default class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      auth: null,
    };
  }

  componentDidMount() {
    const init = async () => {
      try {
        const currentSessionResponse = await sessionApi.getCurrentSession();
        setUser(currentSessionResponse.data);
        this.setState({ auth: true, isLoaded: true });
      } catch (e) {
        resetUser();
        this.setState({ auth: false, isLoaded: true });
      }

      try {
        const localeResponse = await userApi.getLocale();
        await changeLanguage(localeResponse.data);
      } catch (e) {
        await changeLanguage("pt");
      }
    };

    init();
  }

  render() {
    const { auth, isLoaded } = this.state;
    return (
      isLoaded && <div>{auth ? <Outlet /> : <Navigate to="/login" />}</div>
    );
  }
}
