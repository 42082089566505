import Card from "@mui/material/Card";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import StringUtils from "../../utils/StringUtils";
import FilterOptionDTO from "../../dtos/FilterOptionDTO";
import { GradusTable } from "../GradusComponents/GradusTable/GradusTable";
import GradusTableColumn from "../GradusComponents/GradusTable/GradusTableColumn";
import ConfigurationPreviewView from "../../dtos/PayrollConfiguration/ConfigurationPreviewView";
import ConfigurationView from "../../dtos/PayrollConfiguration/ConfigurationView";

const ParameterConfigurationPreview = ({
  configuration,
}: {
  configuration: ConfigurationView | null;
}) => {
  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n: { language },
  } = useTranslation();

  const rows: ConfigurationPreviewView[] = useMemo(() => {
    const values: ConfigurationPreviewView[] = [];

    values.push(
      new ConfigurationPreviewView({
        id: "companySize",
        system: t("COMPANY_SIZE", { lng: language }),
        payroll: t(configuration?.companySize?.name || "", {
          lng: language,
        }),
      }),
      new ConfigurationPreviewView({
        id: "cnaes",
        system: t("COMPANY_SECTORS", { lng: language }),
        payroll: (
          <div>
            <ul>
              {_.map(configuration?.cnaes || [], (cnae: FilterOptionDTO) => (
                <li key={cnae.id}>{`${StringUtils.formatCNAE(cnae.id)} - ${
                  cnae.name
                }`}</li>
              ))}
            </ul>
          </div>
        ),
      }),
      new ConfigurationPreviewView({
        id: "salaryColumn",
        system: t("SALARY", { lng: language }),
        payroll: configuration?.salaryColumn || "",
      }),
      new ConfigurationPreviewView({
        id: "jobOccupationColumn",
        system: t("OCCUPATION_CBO", { lng: language }),
        payroll: configuration?.jobOccupationColumn || "",
      })
    );

    if (!_.isEmpty(configuration?.countyColumn)) {
      values.push(
        new ConfigurationPreviewView({
          id: "countyColumn",
          system: t("COUNTY", { lng: language }),
          payroll: configuration?.countyColumn || "",
        }),
        new ConfigurationPreviewView({
          id: "stateColumn",
          system: t("STATE", { lng: language }),
          payroll: configuration?.stateColumn || "",
        })
      );
    }

    if (!_.isEmpty(configuration?.col1Name)) {
      values.push(
        new ConfigurationPreviewView({
          id: "col1Name",
          system: `${t("CUSTOM_COLUMN", { lng: language })} ${1}`,
          payroll: configuration?.col1Name || "",
        })
      );
    }
    if (!_.isEmpty(configuration?.col2Name)) {
      values.push(
        new ConfigurationPreviewView({
          id: "col2Name",
          system: `${t("CUSTOM_COLUMN", { lng: language })} ${2}`,
          payroll: configuration?.col2Name || "",
        })
      );
    }
    if (!_.isEmpty(configuration?.col3Name)) {
      values.push(
        new ConfigurationPreviewView({
          id: "col3Name",
          system: `${t("CUSTOM_COLUMN", { lng: language })} ${3}`,
          payroll: configuration?.col3Name || "",
        })
      );
    }
    if (!_.isEmpty(configuration?.col4Name)) {
      values.push(
        new ConfigurationPreviewView({
          id: "col4Name",
          system: `${t("CUSTOM_COLUMN", { lng: language })} ${4}`,
          payroll: configuration?.col4Name || "",
        })
      );
    }

    return values;
  }, [
    configuration?.cnaes,
    configuration?.col1Name,
    configuration?.col2Name,
    configuration?.col3Name,
    configuration?.col4Name,
    configuration?.companySize?.name,
    configuration?.countyColumn,
    configuration?.jobOccupationColumn,
    configuration?.salaryColumn,
    configuration?.stateColumn,
    language,
    t,
  ]);

  const columns = useMemo(
    () => [
      new GradusTableColumn<ConfigurationPreviewView>({
        field: "system",
        headerLabel: t("SYSTEM_FIELD", { lng: language }),
        order: 0,
        hasFilter: false,
      }),
      new GradusTableColumn<ConfigurationPreviewView>({
        field: "payroll",
        headerLabel: t("SPREADSHEET_FIELD", { lng: language }),
        order: 1,
        hasFilter: false,
      }),
    ],
    [language, t]
  );

  if (_.isEmpty(configuration)) {
    return null;
  }

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h5" textAlign="left" sx={{ mb: 1 }}>
        {`${t("MAPPING")}: ${configuration?.name}`}
      </Typography>
      <GradusTable
        rows={rows}
        columns={columns}
        storeName="parameter-configuration-preview"
        hasPagination={false}
      />
    </Card>
  );
};

export default ParameterConfigurationPreview;
