import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Container } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlertContext from "../../contexts/AlertContext";
import { handleApiError } from "../../utils/ErrorUtils";
import AppliedFiltersList from "../../components/AppliedFilters/AppliedFiltersList";
import MetricsBoard from "../../components/MetricsBoard/MetricsBoard";
import ChartsOrganization from "../../components/Charts/ChartsOrganization";
import withGradusLoading from "../../hocs/withGradusLoading";
import useMetricsStore from "../../stores/useMetricsStore";
import useConsulteAnalysisStore from "../../stores/useConsulteAnalysisStore";
import ConsulteDashboardHeader from "../../components/Dashboard/ConsulteDashboardHeader";

const ConsulteDashboard = () => {
  const { fireAlert } = useContext(AlertContext);
  const { analysis, renameAnalysis, initDashboard } =
    useConsulteAnalysisStore();

  const {
    t,
    // Por algum motivo o TS não está achando language dentro de i18n
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore.
    i18n: { language },
  } = useTranslation();

  const { id: analysisId } = useParams();

  const [showFilters, setShowFilters] = useState(false);

  const { isLoadingConsulteMetrics } = useMetricsStore();

  const handleRenameAnalysis = useCallback(
    async (newName: string) => {
      try {
        await renameAnalysis(newName, Number(analysisId));
        fireAlert(t("ALERTS.SUCCESSFULLY_RENAMED_ANALYSIS", { lng: language }));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      }
    },
    [analysisId, fireAlert, language, renameAnalysis, t]
  );

  // Os gráficos estavam piscando ao usar useEffect
  useLayoutEffect(() => {
    (async () => {
      try {
        await initDashboard(Number(analysisId));
      } catch (error) {
        handleApiError(error, fireAlert, t);
      }
    })();
  }, [analysisId, fireAlert, initDashboard, t]);

  const ContainerWithLoading = useMemo(() => withGradusLoading(Container), []);

  return (
    <Container sx={{ mb: 5, mt: 1 }}>
      <ConsulteDashboardHeader
        analysis={analysis}
        renameAnalysis={handleRenameAnalysis}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
      />

      <AppliedFiltersList
        key={String(analysis)}
        isShown={showFilters}
        marketFilter={{
          filter: analysis.getMarketFilters(),
          title: t("MARKET"),
        }}
        isCompare={false}
      />

      <Divider sx={{ mt: 2.5, mb: 3, mx: 3 }} />

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ContainerWithLoading
        isLoading={isLoadingConsulteMetrics()}
        gradusLoadingContainerSx={{ mb: 2, mt: 2 }}
        disableGutters
      >
        <MetricsBoard />
        <ChartsOrganization analysis={analysis} />
      </ContainerWithLoading>
    </Container>
  );
};

export default ConsulteDashboard;
