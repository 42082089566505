import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ListAnalysisModal from "../Modals/ListAnalysisModal/ListAnalysisModal";
import ListItem from "./ListItem";
import ListHeader from "./ListHeader";
import UnavailableListHeader from "./UnavailableListHeader";

export default function CompareMenu({ isDrawerOpen, isCompareAvailable }) {
  const [analysisModalOpen, setAnalysisModalOpen] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!isCompareAvailable) {
    return (
      <UnavailableListHeader
        itemTitle={t("COMPARE")}
        icon={light("code-compare")}
        isDrawerOpen={isDrawerOpen}
        tooltipText={t("BUY_COMPARE_MODULE")}
      />
    );
  }

  return (
    <>
      <ListHeader
        itemTitle={t("COMPARE")}
        icon={light("code-compare")}
        isDrawerOpen={isDrawerOpen}
      />
      <ListItem
        title={t("NEW_ANALYSIS")}
        icon={light("file-circle-plus")}
        isDrawerOpen={isDrawerOpen}
        onClick={() => navigate("/compare/create-analysis")}
      />
      <ListItem
        title={t("ANALYSIS")}
        icon={light("file-chart-column")}
        isDrawerOpen={isDrawerOpen}
        onClick={() => setAnalysisModalOpen(true)}
      />
      <ListAnalysisModal
        handleClose={() => setAnalysisModalOpen(false)}
        open={analysisModalOpen}
        isCompare
      />
    </>
  );
}

CompareMenu.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  isCompareAvailable: PropTypes.bool,
};

CompareMenu.defaultProps = {
  isCompareAvailable: false,
};
