import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  FilterOptionsStore,
  MarketFilterOptionsSlice,
  PayrollFilterOptionsSlice,
} from "./filterOptionsStoreUtils";
import createMarketFilterOptionSlice from "./createMarketFilterOptionsSlice";
import createPayrollFilterOptionSlice from "./createPayrollFilterOptionsSlice";
import AnalysisPresenter from "../../dtos/AnalysisPresenter";
import CompareAnalysisPresenter from "../../dtos/CompareAnalysis/CompareAnalysisPresenter";

const useFilterOptionsStore = create<
  PayrollFilterOptionsSlice & MarketFilterOptionsSlice & FilterOptionsStore
>()(
  devtools(
    (set, get, store) => ({
      ...createMarketFilterOptionSlice(set, get, store),
      ...createPayrollFilterOptionSlice(set, get, store),

      initOptions: async (analysis: AnalysisPresenter) => {
        const { _initPayrollOptions, _initMarketOptions } = get();

        const initializers = [_initMarketOptions(analysis)];
        if (analysis instanceof CompareAnalysisPresenter) {
          initializers.push(_initPayrollOptions(analysis));
        }

        await Promise.all(initializers);
      },
    }),
    { name: "useFilterOptionsStore" }
  )
);

export default useFilterOptionsStore;
