import "./App.css";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BaseRouter from "./pages/BaseRouter";
import SystemAlert from "./components/SystemAlert/SystemAlert";
import theme from "./pages/theme";
import SystemInitializationContextProvider from "./contexts/SystemInitializationContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SystemAlert>
            <SystemInitializationContextProvider>
              <BaseRouter />
            </SystemInitializationContextProvider>
          </SystemAlert>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
