import Stack from "@mui/material/Stack";
import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AlertContext from "../../../../contexts/AlertContext";
import MarketFilters from "../MarketFilters";
import useFilterStore from "../../../../stores/FilterStore/useFilterStore";
import FilterTabFooter from "../Footer/FilterTabFooter";
import FilterTabHeader from "../Header/FilterTabHeader";
import useCompareAnalysisStore from "../../../../stores/useCompareAnalysisStore";
import { handleApiError } from "../../../../utils/ErrorUtils";

function CompareMarketFilterTab({ closeModal }: { closeModal: () => void }) {
  const { fireAlert } = useContext(AlertContext);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { analysis, updateAnalysis } = useCompareAnalysisStore((state) => ({
    analysis: state.analysis,
    updateAnalysis: state.updateAnalysis,
  }));
  const { setDefaultMarketFilters } = useFilterStore((state) => ({
    setDefaultMarketFilters: state.setDefaultMarketFilters,
  }));

  const handleFilter = useCallback(async () => {
    try {
      await updateAnalysis();
      fireAlert(
        t("ALERTS.SUCCESSFULLY_APPLIED_FILTER", { lng: language }),
        false
      );
      closeModal();
    } catch (error) {
      handleApiError(error, fireAlert, t);
    }
  }, [closeModal, fireAlert, language, t, updateAnalysis]);

  return (
    <Stack direction="column" spacing={3}>
      <FilterTabHeader />
      <MarketFilters analysis={analysis} isCompare />
      <FilterTabFooter
        setDefaultFilters={() =>
          setDefaultMarketFilters(true, analysis.getId())
        }
        handleFilter={handleFilter}
        isCompare
      />
    </Stack>
  );
}

CompareMarketFilterTab.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CompareMarketFilterTab;
