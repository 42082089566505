import React from "react";
import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { FireAlertFn } from "../components/SystemAlert/SystemAlert";

function handleApiError(error: any, fireAlert: FireAlertFn, t: TFunction) {
  let message = t("ALERTS.UNKNOWN_ERROR_PLEASE_CONTACT_SUPPORT");
  const {
    request,
    response,
    stack,
  }: { request: AxiosResponse; response: AxiosResponse; stack: string } = error;

  // FIXME: o melhor seria ter um ErrorBoundary para tratar isso tudo
  if (request) {
    if (request.status === 403) {
      message = t("ALERTS.DENIED_ACCESS");
    }
    if (request.status >= 400 && request.status < 500 && response?.data) {
      if (response.data?.errors) {
        message = response.data.errors.map((e: string) => (
          <p>{t(`BACKEND_MESSAGES.${e}`)}</p>
        ));
      } else if (response.data?.message) {
        message = response.data.message;
      }
    }
  } else {
    console.error(stack);
  }
  fireAlert(message, true);
}

// eslint-disable-next-line import/prefer-default-export
export { handleApiError };
