import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import _ from "lodash";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import useDashboardVisualOptionsStore from "../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import withHideOnPresentationMode from "../../hocs/withHideOnPresentationMode";
import AccountMenu from "../../components/AccountMenu/AccountMenu";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    !_.includes(
      [
        "open",
        "isPresentationMode",
        "openDrawerWidth",
        "closedDrawerWidth",
        "animated",
      ],
      prop
    ),
})(
  ({
    theme,
    open,
    isPresentationMode,
    openDrawerWidth,
    closedDrawerWidth,
    animated,
  }) => ({
    zIndex: theme.zIndex.drawer - 1,
    ...(animated && {
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    ...(open
      ? {
          marginLeft: openDrawerWidth,
          width: `calc(100% - ${openDrawerWidth}px)`,
          ...(animated && {
            transition: theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }
      : {
          marginLeft: closedDrawerWidth,
          width: `calc(100% - ${closedDrawerWidth}px)`,
        }),
    ...(isPresentationMode
      ? {
          width: "100%",
          color: "white",
          backgroundColor: theme.palette.primary.main,
        }
      : {
          backgroundColor: "white",
          color: "black",
        }),
  })
);

function SystemAppBar({ isDrawerOpen, openDrawerWidth, closedDrawerWidth }) {
  const { isPresentationMode } = useDashboardVisualOptionsStore((state) => ({
    isPresentationMode: state.isPresentationMode,
  }));

  const theme = useTheme();

  const HideOnPresentationAccountMenu = withHideOnPresentationMode(AccountMenu);

  const typographyProps = isPresentationMode
    ? {
        color: "white",
        align: "center",
        fontWeight: "bold",
      }
    : {
        color: theme.palette.grey[700],
        align: "left",
        fontWeight: "none",
      };

  return (
    <AppBar
      position="absolute"
      open={isDrawerOpen}
      isPresentationMode={isPresentationMode}
      openDrawerWidth={openDrawerWidth}
      closedDrawerWidth={closedDrawerWidth}
      sx={{ boxShadow: 1 }}
    >
      <Toolbar style={{ minHeight: "56px" }}>
        <Stack
          sx={{ pl: 3, width: "100%" }}
          direction="row"
          justifyContent={isPresentationMode ? "center" : "space-between"}
        >
          <Typography component="h1" variant="h6" noWrap {...typographyProps}>
            Remuneratio
          </Typography>
          <HideOnPresentationAccountMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

SystemAppBar.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  openDrawerWidth: PropTypes.number.isRequired,
  closedDrawerWidth: PropTypes.number.isRequired,
};

export default SystemAppBar;
