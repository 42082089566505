import pt from "./pt";
import en from "./en";
import es from "./es";

// TODO: pegar dinamicamente os arquivos de tradução
export default {
  pt,
  en,
  es,
};
