import React, { useMemo } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import EmployeesBySalaryRangeMarket from "./EmployeesBySalaryRange/EmployeesBySalaryRangeMarket";
import useDashboardVisualOptionsStore from "../../stores/DashboardVisualOptions/useDashboardVisualOptionsStore";
import Chart from "./Chart";
import AnalysisPresenter from "../../dtos/AnalysisPresenter";
import EmployeesBySalaryRangeCompany from "./EmployeesBySalaryRange/EmployeesBySalaryRangeCompany";
import EmployeesBySalaryRangeUnified from "./EmployeesBySalaryRange/EmployeesBySalaryRangeUnified";
import SalaryAverageByOccupation from "./SalaryAverageByOccupation/SalaryAverageByOccupation";
import SalaryAverageByField from "./SalaryAverageByField/SalaryAverageByField";
import State from "../../data/State";
import ChartOptionDTO from "../../dtos/ChartOptionDTO";

const ChartsOrganization = ({
  isCompare,
  analysis,
}: {
  isCompare?: boolean;
  analysis: AnalysisPresenter;
}) => {
  const { selectedChartOptions } = useDashboardVisualOptionsStore((state) => ({
    selectedChartOptions: state.selectedChartOptions,
  }));

  const { t } = useTranslation();

  const charts = useMemo(
    () =>
      selectedChartOptions
        .map(({ id, name }: ChartOptionDTO) => {
          switch (id) {
            case Chart.EMPLOYEES_BY_SALARY_RANGE_MARKET:
              return (
                <EmployeesBySalaryRangeMarket
                  key={id}
                  isCompare={isCompare}
                  title={t(name)}
                  analysis={analysis}
                />
              );

            case Chart.EMPLOYEES_BY_SALARY_RANGE_COMPANY:
              return (
                <EmployeesBySalaryRangeCompany
                  key={id}
                  isCompare={isCompare}
                  title={t(name)}
                  analysis={analysis}
                />
              );

            case Chart.EMPLOYEES_BY_SALARY_RANGE_BOTH:
              return (
                <EmployeesBySalaryRangeUnified
                  key={id}
                  title={t(name)}
                  analysis={analysis}
                />
              );

            case Chart.SALARY_RANGE_BY_STATE:
              return (
                <SalaryAverageByField
                  label={t("STATE")}
                  title={t("CHARTS_DASHBOARD.SALARY_RANGE_BY_STATE")}
                  key={id}
                  isCompare={isCompare}
                  fieldAxisProps={{
                    tickFormatter: (value: string) => State.getStateAbbr(value),
                  }}
                  chart={Chart.SALARY_RANGE_BY_STATE}
                  analysis={analysis}
                />
              );

            case Chart.SALARY_RANGE_BY_COMPANY_SIZE:
              return (
                <SalaryAverageByField
                  label={t("COMPANY_SIZE")}
                  title={t("CHARTS_DASHBOARD.SALARY_RANGE_BY_COMPANY_SIZE")}
                  key={id}
                  isCompare={isCompare}
                  fieldAxisProps={{
                    tickFormatter: t,
                  }}
                  tooltipTitleFormatter={t}
                  chart={Chart.SALARY_RANGE_BY_COMPANY_SIZE}
                  analysis={analysis}
                />
              );

            case Chart.SALARY_AVERAGE_BY_OCCUPATION:
              return <SalaryAverageByOccupation key={id} />;

            default:
              return null;
          }
        })
        .filter((metric: any) => !_.isEmpty(metric)),
    [analysis, isCompare, selectedChartOptions, t]
  );

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {charts}
      </Grid>
    </Container>
  );
};

ChartsOrganization.defaultProps = {
  isCompare: false,
};

export default ChartsOrganization;
