import api from "./api";

const baseUrl = "/api/v1/payroll-example";

const payrollExampleApi = {
  getPayrollExample: () =>
    api.get(`${baseUrl}/templates/payroll`, {
      responseType: "blob",
    }),
};

export default payrollExampleApi;
